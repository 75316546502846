
import Slider from '@mui/material/Slider';
import { useState, useEffect } from 'react';
import Prompt from '../Prompt';

// d:/Source/PROJETO_JETO/psicotecnico/components/ESAVI/QuestionESAVI.js
export default function QuestionESAVI({ pergunta, resposta, onChange, id, color = 'primary' }) {

    const [value, setValue] = useState(resposta || 0);

    useEffect(() => {
        setValue(resposta);
    }, [resposta]);



    const marks = [
        { value: 1, label: 'N' },
        { value: 2, label: 'PV' },
        { value: 3, label: 'AV' },
        { value: 4, label: 'MV' },
        { value: 5, label: 'S' },
    ]

    const legenda = () => {
        if (value === 1) {
            return 'Nunca';
        }
        if (value === 2) {
            return 'Poucas Vezes';
        }
        if (value === 3) {
            return 'Às Vezes';
        }
        if (value === 4) {
            return 'Muitas Vezes';
        }
        if (value === 5) {
            return 'Sempre';
        }

    }

    return (
        <div key={id} id={'question-' + id} >
            <div className="m-2 sombra-forte rounded" sx={{ minWidth: 275 }}>
                <div>

                    <Prompt prompt={pergunta} color={color} />
                    <div style={{ height: '1.5em' }} className={'text-center pt-1 text-' + color}>{legenda()}</div>
                    <div className='p-3'>

                        <Slider

                            aria-label="Always visible"
                            min={1}
                            max={5}
                            step={1}
                            color={color}
                            marks={marks}
                            value={value}
                            onChange={(e, newValue) => {
                                setValue(newValue);
                                onChange(e, id);

                            }}




                        />
                    </div>
                </div>

            </div>
        </div>
    );
}


