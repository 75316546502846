import { MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import Layout from "./layout";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import BackHome from "../components/EATA/BackHome";
import { useNavigate } from "react-router-dom";

export default function ModuloTestes() {
    const [cookieType, setCookieType] = useState(null);
    const [textValue, setTextValue] = useState(null);
    const navigate = useNavigate();

    const handleSetCoockie = () => {
        if (textValue && cookieType) {
            if (cookieType === "NeoPIR") {
                let text = "[" + textValue.trim().replaceAll('\n', ',') + "]";
                text = text.replaceAll('DF', 0).replaceAll('D', 25).replaceAll('N', 50).replaceAll('C', 75).replaceAll('CF', 100);
                Cookies.set(cookieType, text);
            }

            //atribua a text uma string retirando qualquer espaço embranco ou \n do seu final
            const text = "["+textValue.trim().replaceAll('\n', ',')+"]";
       
            Cookies.set(cookieType, text);
            navigate("/")


            
        }
        else{
            console.log("Erro")
        }
    }
    return (
        <Layout>
            <div className="d-flex flex-column">

                <Select
                    className="my-2"
                    onChange={e => setCookieType(e.target.value)}
                    value={cookieType}
                    placeholder="Selecione um teste"
             

                >
                    <MenuItem value="EATA">EATA</MenuItem>
                    <MenuItem value="ESAVI">ESAVI</MenuItem>
                    <MenuItem value="IFPII">IFPII</MenuItem>
                    <MenuItem value="BFP">BFP</MenuItem>
                    <MenuItem value="NEOFIR">NEOFIR</MenuItem>
                    <MenuItem value="NeoPIR">NeoPIR</MenuItem>

                </Select>
                <TextField
                label="Cookie Value"  
                className="my-2" 
                onChange={e => setTextValue(e.target.value)} 
                value={textValue}
                multiline
                placeholder="Cole aqui o valor copiado na planilha"
            
                 />
                <Button onClick={handleSetCoockie} className="my-2">Atualizar Cookie</Button>
                <BackHome />
            </div>
        </Layout>


    )
}
