import "../App.css"
import { Instagram } from '@mui/icons-material'
import { Link } from '@mui/material'

export default function Layout({ children }) {

    return (

      
            <div className="container">
                <Header />
                <div className='children'>

                    {children}
                </div>
                <Footer />
            </div>
 

    )
}

function Header() {
    return (
        <div className='background-title'>


            <div className='d-flex justify-content-between'>
                <div className='d-block text-center m-2'>
                    <img src={"/img/psico.svg"} alt="psico" width={80} height={62} />
                    <div style={{fontSize: "1.1em"}} className='chackra-petch text-light'>DOMINIO PSICO</div>
                </div>
                <div>

                    <img style={{ transform: "scaleX(-1)" }} src={"/img/soldado.jpg"} alt='soldado' width={170} height={120} />
                </div>
               

            </div>
        </div>
    )
}

function Footer() {

    return (
        <div className="row title p-1 text-light align-items-end pr-3">
          
            <div className='col-7'>
                <Link href="https://www.instagram.com/francilho.garcia?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className='text-light'>
                <Instagram  className='text-light' />
                <span className='ml-2'>@francilho.garcia</span>
                </Link>
            </div>

            <div className="mt-5 col-5" style={{ textAlign: 'right' }}>&reg;Todos os direitos reservados</div>
        </div>
    )

}
