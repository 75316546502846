// d:/Source/PROJETO_JETO/psicotecnico/data/DataNeoPIR.js
export const NeoPIR = [
    {
        "numero": 1,
        "prompt": "Sou uma pessoa despreocupada",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 2,
        "prompt": "Gosto realmente da maioria das pessoas que encontro",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 3,
        "prompt": "Tenho uma imaginação muito fértil",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 4,
        "prompt": "Tendo a ser descrente frente às intenções dos outros",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Confiança"
    },
    {
        "numero": 5,
        "prompt": "Sou conhecido (a) pela minha prudência e bom senso",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Competência"
    },
    {
        "numero": 6,
        "prompt": "Muitas vezes, fico irritado (a) com a maneira como as pessoas me tratam",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Raiva"
    },
    {
        "numero": 7,
        "prompt": "Não gosto de multidões e por isso as evito",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 8,
        "prompt": "Assuntos de estética e arte não são muito importantes para mim",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Estética"
    },
    {
        "numero": 9,
        "prompt": "Não sou dissimulado (a) nem espertalhão (ona)",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 10,
        "prompt": "Prefiro deixar as opções em aberto que planejar tudo com antecedência",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Ordem"
    },
    {
        "numero": 11,
        "prompt": "Raramente me sinto só ou triste",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Depressão"
    },
    {
        "numero": 12,
        "prompt": "Sou dominador (a), firme e assertivo (a)",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 13,
        "prompt": "Sem emoções fortes a vida não teria muito graça para mim",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 14,
        "prompt": "Algumas pessoas pensam que sou invejoso (a) e egoísta",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 15,
        "prompt": "Tento cumprir todas as minhas obrigações com responsabilidade",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 16,
        "prompt": "Ao lidar com outras pessoas, sempre tenho medo de fazer alguma coisa errada",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 17,
        "prompt": "Gosto de realizar as coisas com calma",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 18,
        "prompt": "Sou bastante agarrado (a) às minhas próprias maneiras de agir",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 19,
        "prompt": "Prefiro colaborar com as outras pessoas a competir com elas",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Complacência"
    },
    {
        "numero": 20,
        "prompt": "Sou despreocupado (a) e pouco determinado (a)",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 21,
        "prompt": "Raramente me excedo em qualquer coisa",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 22,
        "prompt": "Sinto, muitas vezes, forte necessidade de me divertir",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 23,
        "prompt": "Muitas vezes, sinto prazer em brincar com teorias e ideias abstratas",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ideias"
    },
    {
        "numero": 24,
        "prompt": "Não me incomodo de me vangloriar de meus talentos e sucessos",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 25,
        "prompt": "Eu sou bom (boa) em organizar meu tempo de maneira a fazer as coisas dentro do prazo",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 26,
        "prompt": "Sinto-me, muitas vezes, desamparado (a) desejando que alguém resolva meus problemas",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 27,
        "prompt": "Para dizer a verdade, nunca pulei de alegria",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 28,
        "prompt": "Acredito que deixar estudantes ouvirem pessoas com ideias controversas só vai confundi?los e desorientá?los",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Valores"
    },
    {
        "numero": 29,
        "prompt": "Os governantes precisam estar muito mais atentos para o lado humano dos seus projetos políticos",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 30,
        "prompt": "Ao longo dos anos cometi muitos erros",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 31,
        "prompt": "Assusto-me facilmente",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 32,
        "prompt": "Eu não gosto muito de ficar conversando com as pessoas",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 33,
        "prompt": "Tento sempre organizar os meus pensamentos de forma realista, não dando asas a imaginação",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 34,
        "prompt": "Acredito que a maioria das pessoas é, no fundo, bem intencionada",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Confiança"
    },
    {
        "numero": 35,
        "prompt": "Não levo muito a sério os deveres cívicos, como votar",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Competência"
    },
    {
        "numero": 36,
        "prompt": "Sou uma pessoa tranquila",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Raiva"
    },
    {
        "numero": 37,
        "prompt": "Gosto de ter muita gente à minha volta",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 38,
        "prompt": "Em algumas ocasiões, quando ouço musica, não presto atenção em mais nada",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Estética"
    },
    {
        "numero": 39,
        "prompt": "Ser for necessário, estou disposto (a) a manipular as pessoas para conseguir aquilo que quero",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 40,
        "prompt": "Mantenho as minhas coisas limpas e em ordem",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ordem"
    },
    {
        "numero": 41,
        "prompt": "Às vezes, sinto que eu não valho nada",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Depressão"
    },
    {
        "numero": 42,
        "prompt": "Às vezes, eu deveria mostrar maior firmeza em minhas ações",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 43,
        "prompt": "Raramente experimento forte emoções",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 44,
        "prompt": "Tento ser educado (a) com todas as pessoas que encontro",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 45,
        "prompt": "Às vezes, não sou tão confiável como deveria ser",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 46,
        "prompt": "Raramente me sinto constrangido (a) quando estou rodeado (a) de pessoas",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 47,
        "prompt": "Quando faço alguma coisa, eu faço com todo entusiasmo",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 48,
        "prompt": "Acho interessante aprender e cultivar novos hobbies (passatempos)",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 49,
        "prompt": "Sei ser sarcástico (a) e irônico (a) quando necessário",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Complacência"
    },
    {
        "numero": 50,
        "prompt": "Tenho objetivos claros e busco atingi?los de forma organizada",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 51,
        "prompt": "Tenho dificuldades em resistir aos meus desejos",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 52,
        "prompt": "Não gostaria de passar as férias em um lugar muito freqüentado e agitado",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 53,
        "prompt": "Acho as discussões filosóficas enfadonhas (chatas)",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Ideias"
    },
    {
        "numero": 54,
        "prompt": "Quando eu estou conversando com as pessoas, prefiro não falar de mim mesmo (a) e das minhas realizações",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 55,
        "prompt": "Perco muito tempo antes de me concentrar no trabalho",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 56,
        "prompt": "Sinto que sou capaz de resolver a maioria dos problemas",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 57,
        "prompt": "Já experimentei, algumas vezes, sensações de grande alegria ou êxtase (arrebatamento)",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 58,
        "prompt": "Acredito que as leis e a políticas sociais deveriam mudar para que possam refletir as necessidades atuais",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Valores"
    },
    {
        "numero": 59,
        "prompt": "Sou inflexível e teimoso (a) nas minhas atitudes",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 60,
        "prompt": "Penso muito antes de tomar uma decisão",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 61,
        "prompt": "Raramente me sinto amedrontado (a) ou ansioso (a)",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 62,
        "prompt": "Sou uma pessoa amigável e simpática",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 63,
        "prompt": "Tenho uma grande capacidade de fantasiar",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 64,
        "prompt": "Acredito que, se você deixar, as pessoas vão te passar para trás (enganar)",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Confiança"
    },
    {
        "numero": 65,
        "prompt": "Mantenho?me informado (a) e, geralmente, tomo decisões inteligentes",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Competência"
    },
    {
        "numero": 66,
        "prompt": "Eu sou conhecido (a) como uma pessoa esquentada",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Raiva"
    },
    {
        "numero": 67,
        "prompt": "Normalmente, prefiro fazer as coisas sozinho (a)",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 68,
        "prompt": "Assistir balé ou dança moderna me deixa entediado (a)",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Estética"
    },
    {
        "numero": 69,
        "prompt": "Mesmo que quisesse, não conseguiria enganar ninguém",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 70,
        "prompt": "Sou uma pessoa muito metódica (ordeira)",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ordem"
    },
    {
        "numero": 71,
        "prompt": "Raramente estou triste ou deprimido (a)",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Depressão"
    },
    {
        "numero": 72,
        "prompt": "Já fui muitas vezes líder de grupos a que pertenci",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 73,
        "prompt": "É importante para mim como eu me sinto frente a pessoas ou situações",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 74,
        "prompt": "Algumas pessoas consideram?me frio (a) e calculista",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 75,
        "prompt": "Pago as minhas dividas em dias",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 76,
        "prompt": "Algumas vezes senti tão envergonhado (a) que simplesmente queria sumir",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 77,
        "prompt": "Trabalho devagar, mas persistentemente (de forma regular)",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 78,
        "prompt": "Quando encontro a maneira correta de fazer uma coisa me agarro a ela",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 79,
        "prompt": "Apesar de ter direito, tenho receio de mostrara minha indignação e raiva, pois posso magoar as pessoas",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Complacência"
    },
    {
        "numero": 80,
        "prompt": "Quando inicio um programa de modificação pessoal (regime, esporte), deixo de cumpri?lo após alguns dias",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 81,
        "prompt": "Eu não tenho dificuldades de resistir às tentações",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 82,
        "prompt": "Já fiz algumas coisas só por puro entusiasmo",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 83,
        "prompt": "Gosto de resolver problemas e quebra?cabeças",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ideias"
    },
    {
        "numero": 84,
        "prompt": "Sou melhor que a maioria das pessoas e tenho consciência disso",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 85,
        "prompt": "Eu sou uma pessoa produtiva que sempre consegue fazer as coisas",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 86,
        "prompt": "Quando estou sob uma grande tensão, algumas vezes sinto que não vou resistir",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 87,
        "prompt": "Não sou um (a) grande otimista",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 88,
        "prompt": "Acredito que devemos confiar nas nossas autoridades religiosas para decisões sobre assuntos morais",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Valores"
    },
    {
        "numero": 89,
        "prompt": "Nunca fazemos o suficiente pelos pobres e pelos velhos",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 90,
        "prompt": "Às vezes, ajo primeiro e penso depois",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 91,
        "prompt": "Freqüentemente, sinto-me tenso (a) e agitado (a)",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 92,
        "prompt": "Muitas pessoas de vêem como alguém um pouco frio e distante",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 93,
        "prompt": "Não gosto de perder tempo sonhando acordado (a)",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 94,
        "prompt": "Penso que a maioria das pessoas com quem lido é honesta e digna de confiança",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Confiança"
    },
    {
        "numero": 95,
        "prompt": "Freqüentemente entro em situações sem estar totalmente preparado (a) para elas",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Competência"
    },
    {
        "numero": 96,
        "prompt": "Não sou considerado (a) uma pessoa melindrosa e temperamental",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Raiva"
    },
    {
        "numero": 97,
        "prompt": "Sinto muita necessidade de estar com outras pessoas quando fico sozinho (a) durante muito tempo",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 98,
        "prompt": "Fico admirado (a) com a variedade de padrões que encontro na arte e na natureza",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Estética"
    },
    {
        "numero": 99,
        "prompt": "Penso que ser totalmente honesto (a) não é uma boa maneira de fazer negócios",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 100,
        "prompt": "Gosto de ter as coisas no seu lugar, pois assim sei onde posso encontrá?las",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ordem"
    },
    {
        "numero": 101,
        "prompt": "Já senti, algumas vezes, uma sensação profunda de culpa ou de ter pecado",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Depressão"
    },
    {
        "numero": 102,
        "prompt": "Geralmente, nas reuniões, deixo os outros falarem",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 103,
        "prompt": "Raramente presto muita atenção ao que estou sentindo no momento",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 104,
        "prompt": "Geralmente, procuro ser atencioso (a) e delicado (a)",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 105,
        "prompt": "Em alguns jogos, acho divertido conseguir trapacear os outros sem ser percebido",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 106,
        "prompt": "Não fico desconcertado (a) se as pessoas me ridicularizam ou me provocam",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 107,
        "prompt": "Frequentemente, sinto-me explodindo de energia",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 108,
        "prompt": "Frequentemente, experimento comidas novas e desconhecidas",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 109,
        "prompt": "Quando não gosto de alguém, faço questão de demonstrar",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Complacência"
    },
    {
        "numero": 110,
        "prompt": "Trabalho muito para conseguir o que quero",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 111,
        "prompt": "Tenho tendência a me esbaldar, a agir em excesso, quando estou diante de coisas ou situações que gosto muito",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 112,
        "prompt": "Procuro evitar filmes com cenas muito chocantes ou assustadoras",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 113,
        "prompt": "Às vezes, perco o interesse quando as pessoas começam a falar sobre os assuntos muito teóricos e abstratos",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Ideias"
    },
    {
        "numero": 114,
        "prompt": "Tento ser humilde",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 115,
        "prompt": "Tenho dificuldade em começar a fazer o que eu devo fazer",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 116,
        "prompt": "Em casos de emergência, mantenho a cabeça fria",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 117,
        "prompt": "Às vezes, sinto que vou explodir de alegria",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 118,
        "prompt": "Considero que definir o que é certo e errado depende da cultura em que as pessoas vivem",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Valores"
    },
    {
        "numero": 119,
        "prompt": "Não gosto de mendigos",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 120,
        "prompt": "Sempre penso nas conseqüências antes de agir",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 121,
        "prompt": "Fico apreensivo (a) com o futuro",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 122,
        "prompt": "Gosto muito de falar com as outras pessoas",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 123,
        "prompt": "Gosto de me concentrar numa fantasia e explorar todas as suas possibilidades,deixando crescer e se desenvolver",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 124,
        "prompt": "Fico desconfiado (a) quando alguém faz alguma coisa boa para mim",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Confiança"
    },
    {
        "numero": 125,
        "prompt": "Tenho orgulho do meu bom senso",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Competência"
    },
    {
        "numero": 126,
        "prompt": "Freqüentemente, sinto simpatia pelas pessoas com as quais tenho que conviver",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Raiva"
    },
    {
        "numero": 127,
        "prompt": "Prefiro trabalhos que possa fazer sozinho (a), sem ser incomodado (a) por outras pessoas",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 128,
        "prompt": "A poesia pouco ou nada me sensibiliza",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Estética"
    },
    {
        "numero": 129,
        "prompt": "Ficaria aborrecido (a) se alguém me considerasse uma pessoa hipócrita",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 130,
        "prompt": "Parece que nunca consigo ser organizado (a)",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Ordem"
    },
    {
        "numero": 131,
        "prompt": "Tenho tendência a me culpar se alguma coisa dá errado",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Depressão"
    },
    {
        "numero": 132,
        "prompt": "Muitas vezes, outras pessoas pedem para eu ajudá?las a tomar decisões",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 133,
        "prompt": "Experimento uma grande variedade de emoções e sentimentos",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 134,
        "prompt": "Não sou conhecido (a) como alguém generoso (a)",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 135,
        "prompt": "Quando assumo um compromisso as pessoas confiam que vou cumprir",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 136,
        "prompt": "sinto-me, muitas vezes inferior as outras pessoas",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 137,
        "prompt": "Não sou tão ativo (a) e animado (a) como as outras pessoas",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Atividade"
    },
    {
        "numero": 138,
        "prompt": "Prefiro ficar em ambientes conhecidos",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 139,
        "prompt": "Quando sou insultado (a), tendo a perdoar e esquecer",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Complacência"
    },
    {
        "numero": 140,
        "prompt": "Não sou ambicioso (a)",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 141,
        "prompt": "Tenho facilidade em resistir a meus impulsos",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 142,
        "prompt": "Gosto de estar em lugares animados",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 143,
        "prompt": "Gosto de resolver problemas complicados do tipo quebra?cabeça",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ideias"
    },
    {
        "numero": 144,
        "prompt": "Eu tenho uma opinião boa sobre mim",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 145,
        "prompt": "Quando começo um projeto, quase sempre termino",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 146,
        "prompt": "Frequentemente, tenho dificuldade em tomar decisões",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 147,
        "prompt": "Não me considero uma pessoa alegre",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 148,
        "prompt": "Acredito que é mais importante ser fiel aos meus ideias e princípios do que ter uma mente aberta",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Valores"
    },
    {
        "numero": 149,
        "prompt": "As necessidades humanas são mais importantes que os interesses econômicos",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 150,
        "prompt": "Costumo agir sem pensar",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 151,
        "prompt": "Frequentemente me preocupo com as coisas que podem dar errado",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 152,
        "prompt": "É fácil para mim sorrir e ser simpático (a) com pessoas desconhecidas",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 153,
        "prompt": "Quando percebo que estou sendo levado (a) pela minha própria imaginação, procuro me concentrar ocupando?me com algum trabalho ou atividade",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 154,
        "prompt": "Minha primeira reação é confiar nas pessoas",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Confiança"
    },
    {
        "numero": 155,
        "prompt": "Eu acho que não tive sucesso completo em coisa nenhuma",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Competência"
    },
    {
        "numero": 156,
        "prompt": "É preciso muito para me irritar",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Raiva"
    },
    {
        "numero": 157,
        "prompt": "Prefiro passar as férias numa praia cheia a um chalé isolado nas montanhas",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 158,
        "prompt": "Fico extasiado com certos tipos de musica",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Estética"
    },
    {
        "numero": 159,
        "prompt": "Por vezes, levo as pessoas a fazerem o que eu quero",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 160,
        "prompt": "Sou uma pessoa exigente e meticulosa",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ordem"
    },
    {
        "numero": 161,
        "prompt": "Tenho uma opinião pouco favorável de mim de mesmo (a)",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Depressão"
    },
    {
        "numero": 162,
        "prompt": "Prefiro tratar da minha vida a ser um (a) líder para outras pessoas",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 163,
        "prompt": "Raramente me dou conta dos humores ou sentimentos que diferentes ambientes causam",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 164,
        "prompt": "A maioria das pessoas que me conhece gosta de mim",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 165,
        "prompt": "Sigo, rigorosamente, meus princípios éticos",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 166,
        "prompt": "sinto-me a vontade na presença do meu chefe ou de outras autoridades",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 167,
        "prompt": "As pessoas acham que estou sempre com pressa",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 168,
        "prompt": "Por vezes, mudo coisas em minha casa só para experimentar a diferença",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 169,
        "prompt": "Se alguém começa uma briga, estou sempre pronto (a) para revidar",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Complacência"
    },
    {
        "numero": 170,
        "prompt": "Esforço?me para conseguir tudo que puder",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 171,
        "prompt": "Às vezes, como até me sentir mal",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 172,
        "prompt": "Adoraria sentir as emoções de pular de pára?quedas",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 173,
        "prompt": "Tenho pouco interesse em especular sobre a natureza do universo ou da condição humana",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Ideias"
    },
    {
        "numero": 174,
        "prompt": "Sinto que não sou melhor que os outros, seja qual for a condição deles",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 175,
        "prompt": "Quando um projeto se torna difícil, minha tendência é começar de novo",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 176,
        "prompt": "Consigo me controlar muito bem diante numa situação de crise",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 177,
        "prompt": "Sou uma pessoa alegre e bem disposta",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 178,
        "prompt": "Considero?me um individuo aberto e tolerante quanto ao estilo de vida das outras pessoas",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Valores"
    },
    {
        "numero": 179,
        "prompt": "Penso que todos os seres humanos são dignos de respeito",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 180,
        "prompt": "Raramente tomo decisões precipitadas",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 181,
        "prompt": "Tenho menos temores que a maioria das pessoas",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 182,
        "prompt": "Tenho fortes ligações afetivas com meus amigos",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 183,
        "prompt": "Quando criança, raramente me divertia com jogos de faz de conta",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 184,
        "prompt": "Tendo a pensar o melhor acerca das pessoas",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Confiança"
    },
    {
        "numero": 185,
        "prompt": "Sou uma pessoa muito competente",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Competência"
    },
    {
        "numero": 186,
        "prompt": "Algumas vezes senti ressentimento e amargura",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Raiva"
    },
    {
        "numero": 187,
        "prompt": "Geralmente eu fico entediado (a) em encontro social",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 188,
        "prompt": "Às vezes, ao ler poesia ou ao olhar para uma obra de arte, sinto um arrepio, uma onda de emoção",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Estética"
    },
    {
        "numero": 189,
        "prompt": "Às vezes, intimido ou bajulo as pessoas para que elas façam o que eu quero",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 190,
        "prompt": "Não tenho obsessão por limpeza",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Ordem"
    },
    {
        "numero": 191,
        "prompt": "Às vezes, as coisas parecem?me bastante tristes e sem esperança",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Depressão"
    },
    {
        "numero": 192,
        "prompt": "Nas conversas, tendo a falar mais do que os outros",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 193,
        "prompt": "Acho difícil ser empático (a), ou seja, o que os outros sentem",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 194,
        "prompt": "Considero?me uma pessoa caridosa",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 195,
        "prompt": "Tento fazer as tarefas com todo cuidado, para não ter necessidade fazê?las novamente",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 196,
        "prompt": "Caso eu tenha dito ou feito algo errado a alguém, custa?me muito encará?lo outra vez",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 197,
        "prompt": "Minha vida é muito agitada",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 198,
        "prompt": "Quando estou em férias, prefiro voltar a um lugar já conhecido",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 199,
        "prompt": "Sou cabeçudo (a) e teimoso (a)",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Complacência"
    },
    {
        "numero": 200,
        "prompt": "Esforço?me por ser excelente em tudo que faço",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 201,
        "prompt": "Às vezes, faço coisas de modo tão impulsivo que, mais tarde, me arrependo",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 202,
        "prompt": "Sinto-me atraído (a) por cores alegres e os estilos exuberantes",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 203,
        "prompt": "Tenho muita curiosidade intelectual",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ideias"
    },
    {
        "numero": 204,
        "prompt": "Prefiro muito mais elogiar os outros a ser elogiado (a)",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 205,
        "prompt": "Têm tantas pequenas coisas que precisam ser feitas que, as vezes, eu simplesmente ignoro todas elas",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 206,
        "prompt": "Mesmo quando tudo parece estar dando errado, ainda consigo tomar boas decisões",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 207,
        "prompt": "É raro eu utilizar palavras como “fantástico”ou “sensacional”para descrever minhas experiências",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 208,
        "prompt": "Acho que se as pessoas com 25 anos de idade não sabem no que acreditam, alguma coisa está errada com elas",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Valores"
    },
    {
        "numero": 209,
        "prompt": "Tenho pena de pessoas com menos sorte do que eu",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 210,
        "prompt": "Planejo as minhas viagens com antecedência e com cuidado",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ponderação"
    },
    {
        "numero": 211,
        "prompt": "Às vezes, tenho pensamentos aterrorizantes",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Ansiedade"
    },
    {
        "numero": 212,
        "prompt": "Crio facilmente vínculos sociais com as pessoas com quem trabalho",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Acolhimento Caloroso"
    },
    {
        "numero": 213,
        "prompt": "Tenho dificuldade em soltar a minha imaginação (devanear)",
        "fator": "Abertura à Experiência",
        "escala": -1,
        "aspecto": "Fantasia"
    },
    {
        "numero": 214,
        "prompt": "Tenho bastante fé na natureza humana",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Confiança"
    },
    {
        "numero": 215,
        "prompt": "Sou eficiente e eficaz no meu trabalho",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Competência"
    },
    {
        "numero": 216,
        "prompt": "Mesmo os pequenos contratempos podem ser frustrantes para mim",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Raiva"
    },
    {
        "numero": 217,
        "prompt": "Gosto de festas com muita gente",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Gregarismo"
    },
    {
        "numero": 218,
        "prompt": "Gosto mais de ler poesia do que histórias estruturadas (romance e contos)",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Estética"
    },
    {
        "numero": 219,
        "prompt": "Orgulho?me do meu jeito de lidar com as pessoas",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Franqueza"
    },
    {
        "numero": 220,
        "prompt": "Gasto muito tempo à procura de coisas que coloquei fora do lugar",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Ordem"
    },
    {
        "numero": 221,
        "prompt": "Muitas vezes, quando as coisas dão errado, fico desanimado (a) e tenho vontade de desistir",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Depressão"
    },
    {
        "numero": 222,
        "prompt": "Não considero fácil dirigir (comandar) uma situação",
        "fator": "Extroversão",
        "escala": -1,
        "aspecto": "Assertividade"
    },
    {
        "numero": 223,
        "prompt": "Coisas diferentes, como certos cheiros ou nomes de locais distantes, podem evocar em mim fortes emoções",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Sentimentos"
    },
    {
        "numero": 224,
        "prompt": "Quando posso, deixo o que estou fazendo para ajudar os outros",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Altruísmo"
    },
    {
        "numero": 225,
        "prompt": "Só faltaria ao trabalho se estivesse realmente doente",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Senso de Dever"
    },
    {
        "numero": 226,
        "prompt": "Quando as pessoas que eu conheço fazem uma bobagem, fico constrangido (a) por elas",
        "fator": "Neuroticismo",
        "escala": 1,
        "aspecto": "Embaraço"
    },
    {
        "numero": 227,
        "prompt": "Sou uma pessoa muito ativa",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Atividade"
    },
    {
        "numero": 228,
        "prompt": "Sigo sempre o mesmo caminho quando vou a qualquer lugar",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ações Variadas"
    },
    {
        "numero": 229,
        "prompt": "Frequentemente me envolvo em discussões com a minha família e colegas de trabalho",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Complacência"
    },
    {
        "numero": 230,
        "prompt": "Sou um pouco viciado (a) em trabalho",
        "fator": "Conscienciosidade",
        "escala": -1,
        "aspecto": "Esforço por Realização"
    },
    {
        "numero": 231,
        "prompt": "Consigo sempre manter os meus sentimentos sob controle",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Impulsividade"
    },
    {
        "numero": 232,
        "prompt": "Gosto de fazer parte da multidão em acontecimentos esportivos",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Busca de Sensações"
    },
    {
        "numero": 233,
        "prompt": "Tenho uma grande variedade de interesses intelectuais",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Ideias"
    },
    {
        "numero": 234,
        "prompt": "Considero que sou melhor que a maioria das pessoas",
        "fator": "Amabilidade",
        "escala": -1,
        "aspecto": "Modéstia"
    },
    {
        "numero": 235,
        "prompt": "Tenho muita autodisciplina",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Auto Disciplina"
    },
    {
        "numero": 236,
        "prompt": "Sou bastante estável do ponto de vista emocional",
        "fator": "Neuroticismo",
        "escala": -1,
        "aspecto": "Vulnerabilidade"
    },
    {
        "numero": 237,
        "prompt": "Sou propenso (a) a rir facilmente",
        "fator": "Extroversão",
        "escala": 1,
        "aspecto": "Emoções Positivas"
    },
    {
        "numero": 238,
        "prompt": "Penso que a nova moralidade, que consiste em tudo permitir, não é moralidade nenhuma",
        "fator": "Abertura à Experiência",
        "escala": 1,
        "aspecto": "Valores"
    },
    {
        "numero": 239,
        "prompt": "Prefiro ser conhecido (a) como uma pessoa “compreensiva” (inclinada a perdoar) do que como uma pessoa “justa” (inclinada ao rigor) ",
        "fator": "Amabilidade",
        "escala": 1,
        "aspecto": "Sensibilidade"
    },
    {
        "numero": 240,
        "prompt": "Penso duas vezes antes de responder a uma pergunta                                                                                  ",
        "fator": "Conscienciosidade",
        "escala": 1,
        "aspecto": "Ponderação"
    }
]
