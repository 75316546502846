import Title from "../components/Title";
import Layout from "./layout";
import BackHome from "../components/EATA/BackHome";
import { Accordion, AccordionDetails, AccordionSummary, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Table } from "react-bootstrap";
import IntervaloFechado from "../components/IntervaloFechado";
// pages\NeoFIRResult.js


var result = {
    NEUROTICISMO: 0,
    score_T_NEUROTICISMO: 0,
    id_teste_classificacao_NEUROTICISMO: 0,
    classificacao_NEUROTICISMO: "",
    percentil_NEUROTICISMO: 0,
    apto_NEUROTICISMO: false,
    EXTROVERSÃO: 0,
    score_T_EXTROVERSAO: 0,
    id_teste_classificacao_EXTROVERSAO: 0,
    classificacao_EXTROVERSAO: "",
    percentil_EXTROVERSAO: 0,
    apto_EXTROVERSAO: false,
    ABERTURA: 0,
    score_T_ABERTURA: 0,
    id_teste_classificacao_ABERTURA: 0,
    classificacao_ABERTURA: "",
    percentil_ABERTURA: 0,
    apto_ABERTURA: false,
    AMABILIDADE: 0,
    score_T_AMABILIDADE: 0,
    id_teste_classificacao_AMABILIDADE: 0,
    classificacao_AMABILIDADE: "",
    percentil_AMABILIDADE: 0,
    apto_AMABILIDADE: false,
    CONSCIENCIOSIDADE: 0,
    score_T_CONSCIENCIOSIDADE: 0,
    id_teste_classificacao_CONSCIENCIOSIDADE: 0,
    classificacao_CONSCIENCIOSIDADE: "",
    percentil_CONSCIENCIOSIDADE: 0,
    apto_CONSCIENCIOSIDADE: false,
    aquiescencia: 0,
    neutralidade: 0,
    negacao: 0
}
var intervalo_neuroticismo = [0, 55]
var intervalo_extroversao = [0, 65]
var intervalo_abertura = [35, 65]
var intervalo_amabilidade = [35, 65]
var intervalo_conscienciosidade = [45, 0]

export default function NeoFIRResult() {

    if (typeof window !== 'undefined') {
        result = JSON.parse(localStorage.getItem("NEOFIR"));

    }



    let instructions = (
        <div className="text-justify">
            <p>
                O teste NeoFIR (The NEO Five Factor Inventory Revised) é um questionário de personalidade que avalia os cinco grandes fatores da personalidade:
            </p>

            <ol>
                <li>Abertura à experiência: Mede a busca por novas experiências, ideias e valores.</li>
                <li>Consciência: Avalia a organização, o planejamento e o foco em metas.</li>
                <li>Extroversão: Quantifica o nível de sociabilidade, assertividade e energia social.</li>
                <li>Agradabilidade: Mede a empatia, altrîsmo e cooperação.</li>
                <li>Neuroticismo: Avalia a tendência à instabilidade emocional e ao estresse.</li>
            </ol>

        </div>
    )
    return (
        <Layout>


            <Title title="RESULTADO Neo FII-R" instructions={instructions} />

            <div className="my-4">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="text-center">Aquiescência</TableCell>
                            <TableCell className="text-center">Neutralidade</TableCell>
                            <TableCell className="text-center">Negação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableCell className="text-center">{result.aquiescencia}</TableCell>
                        <TableCell className="text-center">{result.neutralidade}</TableCell>
                        <TableCell className="text-center">{result.negacao}</TableCell>
                    </TableBody>
                </Table>
            </div>
            <div id="acordions" className="mt-4">
                <Accordion className="my-2">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="text-light" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="bg-neuroticismo"
                    >
                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableCell>Pontos</TableCell>
                                <TableCell>{result.NEUROTICISMO}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Escore T</TableCell>
                                <TableCell>{result.score_T_NEUROTICISMO}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Classificação</TableCell>
                                <TableCell>{result.classificacao_NEUROTICISMO}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Resultado</TableCell>
                                <TableCell className="font-weight-bold">{result.apto_NEUROTICISMO ?
                                    <div className="text-success">Apto</div> :
                                    <div className="text-danger">Não Apto</div>}</TableCell>

                            </TableBody>
                            <TableBody>
                                <TableCell>Escore Exigido</TableCell>
                                <TableCell>{<IntervaloFechado max={intervalo_neuroticismo[1]} min={intervalo_neuroticismo[0]} value={result.percentil_NEUROTICISMO} />}</TableCell>
                            </TableBody>
                        </Table>
                    </AccordionDetails>

                </Accordion>
                <Accordion className="my-2">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="text-light" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="bg-extroversao"
                    >
                        <h3 className="text-light">Extroversão</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableCell>Pontos</TableCell>
                                <TableCell>{result.EXTROVERSÃO}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Escore T</TableCell>
                                <TableCell>{result.score_T_EXTROVERSAO}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Classificação</TableCell>
                                <TableCell>{result.classificacao_EXTROVERSAO}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Resultado</TableCell>
                                <TableCell className="font-weight-bold">{result.apto_EXTROVERSAO ?
                                    <div className="text-success">Apto</div> :
                                    <div className="text-danger">Não Apto</div>}</TableCell>

                            </TableBody>
                            <TableBody>
                                <TableCell>Escore Exigido</TableCell>
                                <TableCell>{<IntervaloFechado max={intervalo_extroversao[1]} min={intervalo_extroversao[0]} value={result.percentil_EXTROVERSAO} />}</TableCell>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-2">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="text-light" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="bg-abertura"
                    >
                        <h3 className="text-light">Abertura</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableCell>Pontos</TableCell>
                                <TableCell>{result.ABERTURA}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Escore T</TableCell>
                                <TableCell>{result.score_T_ABERTURA}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Classificação</TableCell>
                                <TableCell>{result.classificacao_ABERTURA}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Resultado</TableCell>
                                <TableCell className="font-weight-bold">{result.apto_ABERTURA ?
                                    <div className="text-success">Apto</div> :
                                    <div className="text-danger">Não Apto</div>}</TableCell>

                            </TableBody>
                            <TableBody>
                                <TableCell>Escore Exigido</TableCell>
                                <TableCell>{<IntervaloFechado max={intervalo_abertura[1]} min={intervalo_abertura[0]} value={result.percentil_ABERTURA} />}</TableCell>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-2">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="text-light" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="bg-amabilidade"
                    >
                        <h3 className="text-light">Agradabilidade</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableCell>Pontos</TableCell>
                                <TableCell>{result.AMABILIDADE}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Escore T</TableCell>
                                <TableCell>{result.score_T_AMABILIDADE}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Classificação</TableCell>
                                <TableCell>{result.classificacao_AMABILIDADE}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Resultado</TableCell>
                                <TableCell className="font-weight-bold">{result.apto_AMABILIDADE ?
                                    <div className="text-success">Apto</div> :
                                    <div className="text-danger">Não Apto</div>}</TableCell>

                            </TableBody>
                            <TableBody>
                                <TableCell>Escore Exigido</TableCell>
                                <TableCell>{<IntervaloFechado max={intervalo_amabilidade[1]} min={intervalo_amabilidade[0]} value={result.percentil_AMABILIDADE} />}</TableCell>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-2">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="text-light" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="bg-conscienciosidade"
                    >
                        <h3 className="text-light">Conscienciosidade</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableCell>Pontos</TableCell>
                                <TableCell>{result.CONSCIENCIOSIDADE}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Escore T</TableCell>
                                <TableCell>{result.score_T_CONSCIENCIOSIDADE}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Classificação</TableCell>
                                <TableCell>{result.classificacao_CONSCIENCIOSIDADE}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell>Resultado</TableCell>
                                <TableCell className="font-weight-bold">{result.apto_CONSCIENCIOSIDADE ?
                                    <div className="text-success">Apto</div> :
                                    <div className="text-danger">Não Apto</div>}</TableCell>

                            </TableBody>
                            <TableBody>
                                <TableCell>Escore Exigido</TableCell>
                                <TableCell>{<IntervaloFechado max={intervalo_conscienciosidade[1]} min={intervalo_conscienciosidade[0]} value={result.percentil_CONSCIENCIOSIDADE} />}</TableCell>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            </div>



            <BackHome />



        </Layout >
    )
}
