import Layout from "./layout";
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import {Link} from 'react-router-dom';


export default function Home() {
    const apagarTodosCookes = () => {

        if (window.confirm("Deseja apagar todos os cookies?")) {
            Cookies.remove("EATA")
            Cookies.remove("NeoFIR")
            Cookies.remove("NeoPIR")
            Cookies.remove("ESAVI")
            Cookies.remove("IFPII")
            Cookies.remove("BFP")
            window.location.reload()
    
        }

        
    }
    return (
        <>
            <Layout>
                <div className="preenche-pagina">
                    {/*<}Link href={"/ModuloTestes"}>Modulo Teste</Link>*/}
                    <div id="banners-div" className='py-4 d-block'>
                        <div className='d-flex justify-content-center'>
                            <div className='banner-container sombra-forte rounded'>
                                <Link to="/EATAPage">
                                    <img src={"/img/eata_banner.jpg"} alt='eata_banner' width={350} height={100} />
                                </Link>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>

                            <div className='banner-container sombra-forte rounded'>
                                <Link to="/NeoFIRPage">
                                    <img src={"/img/neofii_banner.jpg"} alt='neofir_banner' width={350} height={100} />

                                </Link>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='banner-container sombra-forte rounded'>
                                <Link to="/NeoPIRPage">
                                    <img src={"/img/neopi_banner.jpg"} alt='neopir_banner' width={350} height={100} />
                                </Link>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='banner-container sombra-forte rounded'>
                                <Link to="/BFPPage">
                                    <img src={"/img/bfp_banner.jpg"} alt='bfp_banner' width={350} height={100} />
                                </Link>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='banner-container sombra-forte rounded'>
                                <Link to="/IFPIIPage">
                                    <img src={"/img/ifpii_banner.jpg"} alt='ifpii_banner' width={350} height={100} />
                                </Link>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>

                            <div className='banner-container sombra-forte rounded'>
                                <Link to="/ESAVIPage">
                                    <img src={"/img/esavi_banner.jpg"} alt='esavi_banner' width={350} height={100} />
                                </Link>
                            </div>
                        </div>
                    </div>
                <div className='m-5'>
                    <Button onClick={apagarTodosCookes} variant="contained" className="bg-danger" fullWidth>Zerar Respostas</Button>

                </div>
                </div>
            </Layout>
        </>
    )

}
