import Title from "../components/Title";
import Layout from "../pages/layout"
import { DataNeoFFII, tableScoreT } from "../data/DataNeoFFII";
import Cookies from "js-cookie";
import ButtonSubmit from "../components/ButtonSubmit";
import { useState, useEffect } from "react";
import QuestionNEOFIR from "../components/NEOFIR/QuestionNEOFIR";
import { useNavigate } from "react-router-dom";
// pages\NeoFIRPage.js


export default function NeoFIRQuestions() {

    const navigate = useNavigate()

    const [data, setData] = useState([...DataNeoFFII])


    useEffect(() => {
        if (Cookies.get('NEOFIR')) {
            const cookie = JSON.parse(Cookies.get('NEOFIR'))
            const copyData = [...DataNeoFFII]
            copyData.forEach((p, i) => {
                p.response = cookie[i]
                p.color = 'success'
                if (p.fator === -1) {
                    p.result = 4 - p.response
                }
                else {
                    p.result = p.response
                }
            })
            setData(copyData)
            
        }
        
    }, [])

    const handleOnChange = (e, index) => {
        setData(prevState => {
            const newState = [...prevState]
            const value = e.target.value
            newState[index].response = value
            if (newState[index].fator === -1) {
                newState[index].result = 4 - value
            }
            else {
                newState[index].result = value
            }


            newState[index].color = "success"


            return newState
        })








    }


    let instructions = (
        <div className="d-none d-md-block">
            <div>Responsda as Questões de acordo com as seguintes legendas:</div>
            <p>DF = Discordo Fortemente</p>
            <p>D = Discordo</p>
            <p>N = Neutro</p>
            <p>C = Concordo</p>
            <p>C = Concordo Fortemente</p>

        </div>

    )

    const handleTotalSelectedValueCalculation = () => {
        let hadNull = false
        let objScore = {}
        let results = {}
        const cookie = []


        let classificacao = {
            6: 'MUITO BAIXO',
            7: 'BAIXO',
            8: 'MEDIO',
            9: 'ALTO',
            10: 'MUITO ALTO',


        }
        let aquiescencia = 0
        let neutralidade = 0
        let negacao = 0
        data.forEach((p, i) => {






            if (data.result === null) {
                hadNull = true
                setData(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "danger"
                    return newState
                })
            }
            else {
                cookie.push(p.response)


                if (p.response === 4 || p.response === 3) {
                    aquiescencia++
                }
                if (p.response === 2) {
                    neutralidade++
                }
                if (p.response === 1 || p.response === 0) {
                    negacao++
                }
            }
        })
        if (hadNull) {

            alert("Por favor, responda todas as questoões");
        }

        else {
            results.NEUROTICISMO = data.filter(obj => obj.dominio === "NEUROTICISMO")
                .reduce((acc, obj) => acc + obj.result, 0)

            objScore = tableScoreT.find(obj => obj.id_Teste === '2' && obj.id_Teste_Variavel === '44' && obj.id_Teste_Tabela === '5' && obj.resultado === results.NEUROTICISMO.toString())
            results.score_T_NEUROTICISMO = parseInt(objScore.escore_T)
            results.id_teste_classificacao_NEUROTICISMO = objScore.id_Teste_Classificacao
            results.classificacao_NEUROTICISMO = classificacao[objScore.id_Teste_Classificacao]
            results.percentil_NEUROTICISMO = objScore.percentil
            results.apto_NEUROTICISMO = results.score_T_NEUROTICISMO <= 55



            results.EXTROVERSÃO = DataNeoFFII.filter(obj => obj.dominio === "EXTROVERSÃO")
                .reduce((acc, obj) => acc + obj.result, 0)
            objScore = tableScoreT.find(obj => obj.id_Teste === '2' && obj.id_Teste_Variavel === '43' && obj.id_Teste_Tabela === '5' && obj.resultado === results.EXTROVERSÃO.toString())
            results.score_T_EXTROVERSAO = parseInt(objScore.escore_T)
            results.id_teste_classificacao_EXTROVERSAO = objScore.id_Teste_Classificacao
            results.classificacao_EXTROVERSAO = classificacao[objScore.id_Teste_Classificacao]
            results.percentil_EXTROVERSAO = objScore.percentil
            results.apto_EXTROVERSAO = results.score_T_EXTROVERSAO <= 65

            results.ABERTURA = DataNeoFFII.filter(obj => obj.dominio === "ABERTURA")
                .reduce((acc, obj) => acc + obj.result, 0)
            objScore = tableScoreT.find(obj => obj.id_Teste === '2' && obj.id_Teste_Variavel === '40' && obj.id_Teste_Tabela === '5' && obj.resultado === results.ABERTURA.toString())
            results.score_T_ABERTURA = parseInt(objScore.escore_T)
            results.id_teste_classificacao_ABERTURA = objScore.id_Teste_Classificacao
            results.classificacao_ABERTURA = classificacao[objScore.id_Teste_Classificacao]
            results.percentil_ABERTURA = objScore.percentil
            results.apto_ABERTURA = results.score_T_ABERTURA <= 65 && results.score_T_ABERTURA >= 35


            results.AMABILIDADE = DataNeoFFII.filter(obj => obj.dominio === "AMABILIDADE")
                .reduce((acc, obj) => acc + obj.result, 0)
            objScore = tableScoreT.find(obj => obj.id_Teste === '2' && obj.id_Teste_Variavel === '41' && obj.id_Teste_Tabela === '5' && obj.resultado === results.AMABILIDADE.toString())
            results.score_T_AMABILIDADE = parseInt(objScore.escore_T)
            results.id_teste_classificacao_AMABILIDADE = objScore.id_Teste_Classificacao
            results.classificacao_AMABILIDADE = classificacao[objScore.id_Teste_Classificacao]
            results.percentil_AMABILIDADE = objScore.percentil
            results.apto_AMABILIDADE = results.score_T_AMABILIDADE >= 35 && results.score_T_AMABILIDADE <= 65;



            results.CONSCIENCIOSIDADE = DataNeoFFII.filter(obj => obj.dominio === "CONSCIENCIOSIDADE")
                .reduce((acc, obj) => acc + obj.result, 0)
            objScore = tableScoreT.find(obj => obj.id_Teste === '2' && obj.id_Teste_Variavel === '42' && obj.id_Teste_Tabela === '5' && obj.resultado === results.CONSCIENCIOSIDADE.toString())
            results.score_T_CONSCIENCIOSIDADE = parseInt(objScore.escore_T)
            results.id_teste_classificacao_CONSCIENCIOSIDADE = objScore.id_Teste_Classificacao
            results.classificacao_CONSCIENCIOSIDADE = classificacao[objScore.id_Teste_Classificacao]
            results.percentil_CONSCIENCIOSIDADE = objScore.percentil
            results.apto_CONSCIENCIOSIDADE = results.score_T_CONSCIENCIOSIDADE >= 45




            results.aquiescencia = aquiescencia
            results.neutralidade = neutralidade
            results.negacao = negacao

            Cookies.set('NEOFIR', JSON.stringify(cookie), { expires: 30 })
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('NEOFIR', JSON.stringify(results))
            }
      


        navigate("/NeoFIRResult")
        }



    }






    return (
        <>
            <Layout>
                <div>
                    <Title title="Neo FII-R" instructions={instructions} />
                    <div className="mt-5" id="box-responses">
                        {
                            data.map(
                                (p, i) => (<QuestionNEOFIR
                                    pergunta={(i + 1) + '. ' + p.pergunta}
                                    resposta={parseInt(p.response)}
                                    color={p.color}
                                    id={i}
                                    key={i}
                                    onChange={handleOnChange}


                                />)
                            )
                        }

                    </div>
                    <ButtonSubmit onClick={handleTotalSelectedValueCalculation} />

                </div>
            </Layout>
        </>
    )
}