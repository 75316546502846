
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import Prompt from '../Prompt';

// d:/Source/PROJETO_JETO/psicotecnico/components/EATA/QuestionEATA.js
export default function QuestionEATA({ pergunta, resposta,  onChange, id, color = 'primary' }) {

    const [value, setValue] = useState(resposta |-1 );

    
    const marks = [
        { value: 0, label: 'NR' },
        { value: 1, label: 'AV' },
        { value: 2, label: 'MF' },

    ]
    const legenda = ()=>{
        if (value === 0) {
            return 'Nunca ou Raramente';
        } else if (value === 1) {
            return 'Às Vezes';
        } else if (value === 2) {
            return 'Muito Frequentemente';
        }
    }

    return (
        <div key={id} id = {'question-' + id}>
            <div className="m-2 sombra-forte rounded" sx={{ minWidth: 275 }}>
                <div >

                    <Prompt prompt={pergunta} color={color}  />
                 
                    <div style={{height:  '1.5em'}} className={'text-center pt-1 text-'+ color}>{legenda()}</div>
                    <div className="p-3">

                      <Slider
                          
                          aria-label="Always visible"
                          min={0}
                          max={2}
                          step={1}
                          color={color}
                          marks = {marks}
                          value={value}
                          key = {id}

                          onChange={(e, newValue) => {
                            setValue(newValue);
                            onChange(e, id);

                        }}


                          
                      />
                    </div>
           
                </div>

            </div>
        </div>
    );
}
