export default function IntervaloFechado({ min, max }) {
    return (
        <>
            <span >{min}</span>
            <span className="ps-1 pe-1">
                <img alt="intervalo" src="/img/intervalo_fechado.svg" width={20} height={5} />
            </span>
            <span >{max}</span>
        </>
    )
}