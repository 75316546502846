import QuestionBPF from "../components/BFP/QuestionBPF";
import * as DataBFP from "../data/DataBFP";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Title from "../components/Title";
import { useNavigate } from "react-router-dom";
import {Divider } from "@mui/material";
import ButtonSubmit from "../components/ButtonSubmit";
import Layout from "./layout";

export default function BFPPage() {

    const data = [...DataBFP.DataBFP]
    const navigate = useNavigate()
    const [dataValues, setDataValues] = useState(data)
    useEffect(() => {
        
        const coockieResponses = Cookies.get("BFP")
        if (coockieResponses) {
            const copyData = [...DataBFP.DataBFP]
            const cookie = JSON.parse(coockieResponses)
            copyData.forEach((p, i) => {
                p.response = cookie[i]
                if (p.fator === -1){
                    p.result = 8 - p.response
                }
                else{
                    p.result = p.response
                }
            })
            setDataValues(copyData)
        }
    }, [])
   
    

    const calculaClassificacao = (percentil) => {
        if (percentil >= 0 && percentil <= 14) {
            return "Muito Baixo";
        }
        else if (percentil > 14 && percentil <= 29) {
            return "Baixo";
        }
        else if (percentil > 29 && percentil <= 70) {
            return "Medio";
        }
        else if (percentil > 70 && percentil <= 85) {
            return "Alto";

        }
        else {
            return "Muito Alto";
        }

    }
    const handleTotalSelectedValueCalculation = () => {
        var results = {
            neuroticismo: 0,
            extroversao: 0,
            socializacao: 0,
            realizacao: 0,
            abertura: 0,
            liberalismo: 0,
            amabilidade: 0,
            altivez: 0,
            buscaPorNovidades: 0,
            confiancaNasPessoas: 0,
            interacoesSociais: 0,
            ponderacao: 0,
            passividadeFaltaEnergia: 0,
            depressao: 0,
            comunicacao: 0,
            proSociabilidade: 0,
            dinamismo: 0,
            aberturaIdeias: 0,
            instabilidadeEmocional: 0,
            competencia: 0,
            empenhoComprometimento: 0,
            vulnerabilidade: 0
        }
        const escalasFunction = {
            "LIBERALISMO": (value) => {
                results.liberalismo += value;
                results.abertura += value

            },
            "AMABILIDADE": (value) => {
                results.amabilidade += value;
                results.socializacao += value
            },
            "ALTIVEZ": (value) => {
                results.altivez += value;
                results.extroversao += value
            },
            "BUSCA POR NOVIDADES": (value) => {
                results.buscaPorNovidades += value;
                results.abertura += value
            },
            "CONFIANÇA NAS PESSOAS": (value) => {
                results.confiancaNasPessoas += value;
                results.socializacao += value
            },
            "INTERAÇÕES SOCIAIS": (value) => {
                results.interacoesSociais += value;
                results.extroversao += value
            },
            "PONDERAÇÃO": (value) => {
                results.ponderacao += value;
                results.realizacao += value
            },
            "PASSIVIDADE/FALTA DE ENERGIA": (value) => {
                results.passividadeFaltaEnergia += value;
                results.neuroticismo += value
            },
            "DEPRESSÃO": (value) => {
                results.depressao += value;
                results.neuroticismo += value;
            },
            "COMUNICAÇÃO": (value) => {
                results.comunicacao += value;
                results.extroversao += value
            },
            "PRÓ-SOCIABILIDADE": (value) => {
                results.proSociabilidade += value;
                results.socializacao += value
            },
            "DINAMISMO": (value) => {
                results.dinamismo += value;
                results.extroversao += value
            },
            "ABERTURA A IDEIAS": (value) => {
                results.aberturaIdeias += value;
                results.abertura += value
            },
            "INSTABILIDADE EMOCIONAL": (value) => {
                results.instabilidadeEmocional += value;
                results.neuroticismo += value
            },
            "COMPETÊNCIA": (value) => {
                results.competencia += value;
                results.realizacao += value;
            },
            "EMPENHO/COMPROMETIMENTO": (value) => {
                results.empenhoComprometimento += value;
                results.realizacao += value
            },
            "VUNERABILIDADE": (value) => {
                results.vulnerabilidade += value;
                results.neuroticismo += value
            }
        }
        let hadNull = false
        let responsesData = []

        dataValues.forEach((p, i) => {
           
            if (p.response === undefined) {
                hadNull = true
                setDataValues(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "error"
                    return newState
                })


            }
            else {
                escalasFunction[p.escala](p.result)
                responsesData.push(p.response) 
                setDataValues(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "success"
                    return newState
                })


            }


            

        })
        if (hadNull) {
            alert("Por favor, responda todas as questões")

        }

        
        else {
            results.vulnerabilidade_eb = (results.vulnerabilidade / 9);
            results.vulnerabilidade_percentil = DataBFP.escalaN1[results.vulnerabilidade_eb.toFixed(2)]!==undefined?DataBFP.escalaN1[results.vulnerabilidade_eb.toFixed(2)]:99;
            results.vulnerabilidade_classificacao = calculaClassificacao(results.vulnerabilidade_percentil);

            results.instabilidadeEmocional_eb = (results.instabilidadeEmocional / 6);
            results.instabilidadeEmocional_percentil = DataBFP.escalaN2[results.instabilidadeEmocional_eb.toFixed(2)]!==undefined?DataBFP.escalaN2[results.instabilidadeEmocional_eb.toFixed(2)]:99;
            results.instabilidadeEmocional_classificacao = calculaClassificacao(results.instabilidadeEmocional_percentil);


            results.passividadeFaltaEnergia_eb = (results.passividadeFaltaEnergia / 6);
            results.passividadeFaltaEnergia_percentil = DataBFP.escalaN3[results.passividadeFaltaEnergia_eb.toFixed(2)]!==undefined?DataBFP.escalaN3[results.passividadeFaltaEnergia_eb.toFixed(2)]:99;
            results.passividadeFaltaEnergia_classificacao = calculaClassificacao(results.passividadeFaltaEnergia_percentil);

            results.depressao_eb = (results.depressao / 8);
            results.depressao_percentil = DataBFP.escalaN4[results.depressao_eb.toFixed(2)]!==undefined?DataBFP.escalaN4[results.depressao_eb.toFixed(2)]:99;
            results.depressao_classificacao = calculaClassificacao(results.depressao_percentil);

            results.neuroticismo_eb = (results.vulnerabilidade_eb + results.instabilidadeEmocional_eb + results.passividadeFaltaEnergia_eb + results.depressao_eb) / 4;
            results.neuroticismo_percentil = DataBFP.escalaNeuroticismo[results.neuroticismo_eb.toFixed(2)] !== undefined ? DataBFP.escalaNeuroticismo[results.neuroticismo_eb.toFixed(2)] : 99;
            results.neuroticismo_classificacao = calculaClassificacao(results.neuroticismo_percentil);


            results.comunicacao_eb = (results.comunicacao / 6);
            results.comunicacao_percentil = DataBFP.escalaE1[results.comunicacao_eb.toFixed(2)]!==undefined?DataBFP.escalaE1[results.comunicacao_eb.toFixed(2)]:99;
            results.comunicacao_classificacao = calculaClassificacao(results.comunicacao_percentil);

            results.altivez_eb = (results.altivez / 7);
            results.altivez_percentil = DataBFP.escalaE2[results.altivez_eb.toFixed(2)]!==undefined?DataBFP.escalaE2[results.altivez_eb.toFixed(2)]:99;
            results.altivez_classificacao = calculaClassificacao(results.altivez_percentil);

            results.dinamismo_eb = (results.dinamismo / 5);
            results.dinamismo_percentil = DataBFP.escalaE3[results.dinamismo_eb.toFixed(2)]!==undefined?DataBFP.escalaE3[results.dinamismo_eb.toFixed(2)]:99;
            results.dinamismo_classificacao = calculaClassificacao(results.dinamismo_percentil);

            results.interacoesSociais_eb = (results.interacoesSociais / 7);
            results.interacoesSociais_percentil = DataBFP.escalaE4[results.interacoesSociais_eb.toFixed(2)]!==undefined?DataBFP.escalaE4[results.interacoesSociais_eb.toFixed(2)]:99;
            results.interacoesSociais_classificacao = calculaClassificacao(results.interacoesSociais_percentil);

            results.extroversao_eb = (results.comunicacao_eb + results.altivez_eb + results.dinamismo_eb + results.interacoesSociais_eb) / 4;
            results.extroversao_percentil = DataBFP.escalaExtroversao[results.extroversao_eb.toFixed(2)]!==undefined?DataBFP.escalaExtroversao[results.extroversao_eb.toFixed(2)]:99;
            results.extroversao_classificacao = calculaClassificacao(results.extroversao_percentil);


            results.amabilidade_eb = (results.amabilidade / 12);
            results.amabilidade_percentil = DataBFP.escalaS1[results.amabilidade_eb.toFixed(2)]!==undefined?DataBFP.escalaS1[results.amabilidade_eb.toFixed(2)]:99;            results.amabilidade_classificacao = calculaClassificacao(results.amabilidade_percentil);

            results.proSociabilidade_eb = (results.proSociabilidade / 8);
            results.proSociabilidade_percentil = DataBFP.escalaS2[results.proSociabilidade_eb.toFixed(2)]!==undefined?DataBFP.escalaS2[results.proSociabilidade_eb.toFixed(2)]:99;
            results.proSociabilidade_classificacao = calculaClassificacao(results.proSociabilidade_percentil);

            results.confiancaNasPessoas_eb = (results.confiancaNasPessoas / 8);
            results.confiancaNasPessoas_percentil = DataBFP.escalaS3[results.confiancaNasPessoas_eb.toFixed(2)]!==undefined?DataBFP.escalaS3[results.confiancaNasPessoas_eb.toFixed(2)]:99;
            results.confiancaNasPessoas_classificacao = calculaClassificacao(results.confiancaNasPessoas_percentil);

            results.socializacao_eb = (results.amabilidade_eb + results.proSociabilidade_eb + results.confiancaNasPessoas_eb) / 3;
            results.socializacao_percentil = DataBFP.escalaSocializacao[results.socializacao_eb.toFixed(2)]!==undefined?DataBFP.escalaSocializacao[results.socializacao_eb.toFixed(2)]:99;
            results.socializacao_classificacao = calculaClassificacao(results.socialacao_percentil);

            results.competencia_eb = (results.competencia / 10);
            results.competencia_percentil = DataBFP.escalaR1[results.competencia_eb.toFixed(2)]!==undefined?DataBFP.escalaR1[results.competencia_eb.toFixed(2)]:99;
            results.competencia_classificacao = calculaClassificacao(results.competencia_percentil);

            results.ponderacao_eb = (results.ponderacao / 4);
            results.ponderacao_percentil = DataBFP.escalaR2[results.ponderacao_eb.toFixed(2)];
            results.ponderacao_classificacao = calculaClassificacao(results.ponderacao_percentil);

            results.empenhoComprometimento_eb = (results.empenhoComprometimento / 7);
            results.empenhoComprometimento_percentil = DataBFP.escalaR3[results.empenhoComprometimento_eb.toFixed(2)]!==undefined?DataBFP.escalaR3[results.empenhoComprometimento_eb.toFixed(2)]:99;
            results.empenhoComprometimento_classificacao = calculaClassificacao(results.empenhoComprometimento_percentil);

            results.realizacao_eb = (results.competencia_eb + results.ponderacao_eb + results.empenhoComprometimento_eb) / 3;
            results.realizacao_percentil = DataBFP.escalaRealizacao[results.realizacao_eb.toFixed(2)]!==undefined?DataBFP.escalaRealizacao[results.realizacao_eb.toFixed(2)]:99;
            results.realizacao_classificacao = calculaClassificacao(results.realizacao_percentil);


            results.aberturaIdeias_eb = (results.aberturaIdeias / 10);
            results.aberturaIdeias_percentil = DataBFP.escalaA1[results.aberturaIdeias_eb.toFixed(2)]!==undefined?DataBFP.escalaA1[results.aberturaIdeias_eb.toFixed(2)]:99;
            results.aberturaIdeias_classificacao = calculaClassificacao(results.aberturaIdeias_percentil);

            results.liberalismo_eb = (results.liberalismo / 7);
            results.liberalismo_percentil = DataBFP.escalaA2[results.liberalismo_eb.toFixed(2)]!==undefined?DataBFP.escalaA2[results.liberalismo_eb.toFixed(2)]:99;
            results.liberalismo_classificacao = calculaClassificacao(results.liberalismo_percentil);

            results.buscaPorNovidades_eb = (results.buscaPorNovidades / 6);
            results.buscaPorNovidades_percentil = DataBFP.escalaA3[results.buscaPorNovidades_eb.toFixed(2)]!==undefined?DataBFP.escalaA3[results.buscaPorNovidades_eb.toFixed(2)]:99;
            results.buscaPorNovidades_classificacao = calculaClassificacao(results.buscaPorNovidades_percentil);

            results.abertura_eb = (results.aberturaIdeias_eb + results.liberalismo_eb + results.buscaPorNovidades_eb) / 3;
            results.abertura_percentil = DataBFP.escalaAbertura[results.abertura_eb.toFixed(2)]!==undefined?DataBFP.escalaAbertura[results.abertura_eb.toFixed(2)]:99;
            results.abertura_classificacao = calculaClassificacao(results.abertura_percentil);

            Cookies.set("BFP", JSON.stringify(responsesData), { expires: 365, sameSite: true });
       
            if (window !== undefined) {
                localStorage.setItem("BFP", JSON.stringify(results), { expires: 30});

            }

           navigate("/BFPResult")














        }
    }


    const handleOnChange = (e, index) => {
        setDataValues(prevState => {
            const newState = [...prevState]
            const value = e.target.value
            newState[index].response = value
            newState[index].color = "success"
            if(newState[index].fator === -1){
                newState[index].result = 8 - value
            }
            else{
                newState[index].result = value
            }
    
            return newState
        })
        
      
        


    }


    return (
        <Layout>
            <Title title="BFP" instructions="Responda as perguntas de 1 a 7 com relação ao que você pensa a respeito das afirmativas" />
            {dataValues.map((p, i) => {
                return (
                    

                    <div key={i}>
                        <QuestionBPF
                            pergunta={(i + 1) + ". " + p.pergunta}
                            resposta={p.response}
                            escala={p.escala}
                            seriePercentil={p.seriePercentil}
                            color={p.color}
                            id={i}
                            
                            onChange={handleOnChange}
                        />
                        
                    </div>


                    
                )
            }
            )}
            <Divider />
            <ButtonSubmit onClick={handleTotalSelectedValueCalculation} />


        </Layout>

    )




}