import Title from "../components/Title";
import Layout from "./layout";
import { Accordion, AccordionSummary, AccordionDetails, Table, Card, TableRow, TableCell } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BackHome from "../components/EATA/BackHome";
import IntervaloFechado from "../components/IntervaloFechado";


export default function BFPResult() {

    const rangeVulnerabilidade = [15, 75];
    const rangeInstabilidadeEmocional = [0, 50];
    const rangePassividadeFaltaEnergia = [0, 50];
    const rangeDepressao = [15, 70];
    const rangeComunicacao = [30, 100];
    const rangeAltivez = [15, 75];
    const rangeDinamismo = [30, 100];
    const rangeInteracoesSociais = [30, 100];
    const rangeAmabilidade = [30, 100];
    const rangeProSociabilidade = [30, 100];
    const rangeConfiancaNasPessoas = [20, 85];
    const rangeCompetencia = [60, 100];
    const rangePonderacao = [60, 100];
    const rangeEmpenho = [60, 100];
    const rangeAberturaIdeias = [15, 100]
    const rangeLiberalismo = [15, 85]
    const rangeBuscaPorNovidades = [15, 85]



    const classClassificacao = (classificacao) => {
        if (classificacao === 'Muito Baixo') {
            return "muito-baixo"
        }
        else if (classificacao === 'Baixo') {
            return "baixo"
        }
        else if (classificacao === 'Medio') {
            return "medio"
        }
        else if (classificacao === 'Alto') {
            return "alto"
        }
        else {
            return "muito-alto"
        }

    }
    const instructions = (
        <div>
            <p>A Bateria Fatorial de Personalidade (BFP) é um teste psicológico que avalia a personalidade de uma pessoa. Ele foi desenvolvido pelo psicólogo brasileiro Reynaldo Bordin em 1973. O BFP é composto por 120 itens que medem 16 fatores de personalidade. </p>
            <p>O BFP é um teste muito usado no Brasil e em outros países da América Latina. Ele é usado para avaliar a personalidade de pessoas em diferentes contextos, como no trabalho, na escola e na clínica. O BFP também é usado para ajudar a diagnosticar transtornos de personalidade.</p>
        </div>
    )
    let data = {
        "neuroticismo": 0,
        "extroversao": 0,
        "socializacao": 0,
        "realizacao": 0,
        "abertura": 0,
        "liberalismo": 0,
        "amabilidade": 0,
        "altivez": 0,
        "buscaPorNovidades": 0,
        "confiancaNasPessoas": 0,
        "interacoesSociais": 0,
        "ponderacao": 0,
        "passividadeFaltaEnergia": 0,
        "depressao": 0,
        "comunicacao": 0,
        "proSociabilidade": 0,
        "dinamismo": 0,
        "aberturaIdeias": 0,
        "instabilidadeEmocional": 0,
        "competencia": 0,
        "empenhoComprometimento": 0,
        "vulnerabilidade": 0,
        "vulnerabilidade_eb": 0,
        "vulnerabilidade_percentil": 0,
        "vulnerabilidade_classificacao": 0,
        "instabilidadeEmocional_eb": 0,
        "instabilidadeEmocional_percentil": 0,
        "instabilidadeEmocional_classificacao": 0,
        "passividadeFaltaEnergia_eb": 0,
        "passividadeFaltaEnergia_percentil": 0,
        "passividadeFaltaEnergia_classificacao": 0,
        "depressao_eb": 0,
        "depressao_percentil": 0,
        "depressao_classificacao": 0,
        "neuroticismo_eb": 0,
        "neuroticismo_percentil": 0,
        "neuroticismo_classificacao": 0,
        "comunicacao_eb": 0,
        "comunicacao_percentil": 0,
        "comunicacao_classificacao": 0,
        "altivez_eb": 0,
        "altivez_percentil": 0,
        "altivez_classificacao": 0,
        "dinamismo_eb": 0,
        "dinamismo_percentil": 0,
        "dinamismo_classificacao": 0,
        "interacoesSociais_eb": 0,
        "interacoesSociais_percentil": 0,
        "interacoesSociais_classificacao": 0,
        "extroversao_eb": 0,
        "extroversao_percentil": 0,
        "extroversao_classificacao": 0,
        "amabilidade_eb": 0,
        "amabilidade_percentil": 0,
        "amabilidade_classificacao": 0,
        "proSociabilidade_eb": 0,
        "proSociabilidade_percentil": 0,
        "proSociabilidade_classificacao": 0,
        "confiancaNasPessoas_eb": 0,
        "confiancaNasPessoas_percentil": 0,
        "confiancaNasPessoas_classificacao": 0,
        "socializacao_eb": 0,
        "socializacao_percentil": 0,
        "socializacao_classificacao": 0,
        "competencia_eb": 0,
        "competencia_percentil": 0,
        "competencia_classificacao": 0,
        "ponderacao_eb": 0,
        "ponderacao_percentil": 0,
        "ponderacao_classificacao": 0,
        "empenhoComprometimento_eb": 0,
        "empenhoComprometimento_percentil": 0,
        "empenhoComprometimento_classificacao": 0,
        "realizacao_eb": 0,
        "realizacao_percentil": 0,
        "realizacao_classificacao": 0,
        "aberturaIdeias_eb": 0,
        "aberturaIdeias_percentil": 0,
        "aberturaIdeias_classificacao": 0,
        "liberalismo_eb": 0,
        "liberalismo_percentil": 0,
        "liberalismo_classificacao": 0,
        "buscaPorNovidades_eb": 0,
        "buscaPorNovidades_percentil": 0,
        "buscaPorNovidades_classificacao": 0,
        "abertura_eb": 0,
        "abertura_percentil": 0,
        "abertura_classificacao": 0
    }


    if (typeof window !== 'undefined') {
        data = JSON.parse(localStorage.getItem('BFP'))
    }



    return (


        <div>
            <Layout>
                <Title title="BFP" instructions={instructions} />
                <Card className="mt-5 shadow">
                    <div className="bg-neuroticismo p-3">Neuroticismo</div>
                    <Table padding="none">
                        <TableRow >
                            <TableCell className="p-2">Escore</TableCell>
                            <TableCell>{data.neuroticismo}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Escore Bruto</TableCell>
                            <TableCell>{data.neuroticismo_eb.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Percentil</TableCell>
                            <TableCell>{data.neuroticismo_percentil + "%"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell
                                className={classClassificacao(data
                                    .neuroticismo_classificacao) +
                                    " font-weight-bold"}>
                                {data.neuroticismo_classificacao}</TableCell>
                        </TableRow>

                    </Table>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={apto(rangeVulnerabilidade[0],
                                rangeVulnerabilidade[1],
                                data.vulnerabilidade_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Vulnerabilidade"
                                min={rangeVulnerabilidade[0]}
                                max={rangeVulnerabilidade[1]}
                                percentil={data.vulnerabilidade_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.vulnerabilidade}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.vulnerabilidade_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.vulnerabilidade_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .vulnerabilidade_classificacao) +
                                            " font-weight-bold"}>
                                        {data.vulnerabilidade_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeVulnerabilidade[0]}
                                        max={rangeVulnerabilidade[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-1" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel1b-content"
                            id="panel1b-header"
                            className={apto(rangeInstabilidadeEmocional[0],
                                rangeInstabilidadeEmocional[1],
                                data.instabilidadeEmocional_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Instabilidade Emocional"
                                min={rangeInstabilidadeEmocional[0]}
                                max={rangeInstabilidadeEmocional[1]}
                                percentil={data.instabilidadeEmocional_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.instabilidadeEmocional}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.instabilidadeEmocional_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.instabilidadeEmocional_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .instabilidadeEmocional_classificacao) +
                                            " font-weight-bold"}>
                                        {data.instabilidadeEmocional_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeInstabilidadeEmocional[0]}
                                        max={rangeInstabilidadeEmocional[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-1" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                            className={apto(0, 50, data.passividadeFaltaEnergia_percentil) ? " bg-success text-light" : "bg-danger text-light"}

                        >

                            <Aptidao criterio="Passividade/Falta de Energia" min={rangePassividadeFaltaEnergia[0]} max={rangePassividadeFaltaEnergia[1]} percentil={data.passividadeFaltaEnergia_percentil} />
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.passividadeFaltaEnergia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.passividadeFaltaEnergia_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.passividadeFaltaEnergia_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .passividadeFaltaEnergia_classificacao) +
                                            " font-weight-bold"}>
                                        {data.passividadeFaltaEnergia_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangePassividadeFaltaEnergia[0]} max={rangePassividadeFaltaEnergia[1]} /></TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-1" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            className={apto(15, 70, data.depressao_percentil) ? " bg-success text-light" : "bg-danger text-light"}

                        >

                            <Aptidao criterio="Depressão" min="15" max="70" percentil={data.depressao_percentil} />
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.depressao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.depressao_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.depressao_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .depressao_classificacao) +
                                            " font-weight-bold"}>
                                        {data.depressao_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeDepressao[0]} max={rangeDepressao[1]} /></TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                </Card>
                <Card className="mt-5 shadow">
                    <div className="bg-extroversao p-3">Extroversão</div>
                    <Table padding="none">
                        <TableRow >
                            <TableCell className="p-2">Escore</TableCell>
                            <TableCell>{data.extroversao}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Escore Bruto</TableCell>
                            <TableCell>{data.extroversao_eb.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Percentil</TableCell>
                            <TableCell>{data.extroversao_percentil + "%"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell
                                className={classClassificacao(data
                                    .extroversao_classificacao) +
                                    " font-weight-bold"}>
                                {data.extroversao_classificacao}</TableCell>
                        </TableRow>
                    </Table>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className={apto(rangeComunicacao[0],
                                rangeComunicacao[1],
                                data.comunicacao_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Comunicação"
                                min={rangeComunicacao[0]}
                                max={rangeComunicacao[1]}
                                percentil={data.comunicacao_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.comunicacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.comunicacao_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.comunicacao_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .comunicacao_classificacao) +
                                            " font-weight-bold"}>
                                        {data.comunicacao_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeComunicacao[0]}
                                        max={rangeComunicacao[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel2b-content"
                            id="panel2b-header"
                            className={apto(rangeAltivez[0],
                                rangeAltivez[1],
                                data.altivez_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Altivez"
                                min={rangeAltivez[0]}
                                max={rangeAltivez[1]}
                                percentil={data.altivez_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.altivez}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.altivez_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.altivez_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .altivez_classificacao) +
                                            " font-weight-bold"}>
                                        {data.altivez_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeAltivez[0]}
                                        max={rangeAltivez[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel2c-content"
                            id="panel2c-header"
                            className={apto(rangeDinamismo[0],
                                rangeDinamismo[1],
                                data.dinamismo_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Dinamismo"
                                min={rangeDinamismo[0]}
                                max={rangeDinamismo[1]}
                                percentil={data.dinamismo_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.dinamismo}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.dinamismo_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.dinamismo_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .dinamismo_classificacao) +
                                            " font-weight-bold"}>
                                        {data.dinamismo_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeDinamismo[0]}
                                        max={rangeDinamismo[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                            className={apto(rangeInteracoesSociais[0],
                                rangeInteracoesSociais[1],
                                data.interacoesSociais_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Interações Sociais"
                                min={rangeInteracoesSociais[0]}
                                max={rangeInteracoesSociais[1]}
                                percentil={data.interacoesSociais_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow>
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.interacoesSociais}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.interacoesSociais_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.interacoesSociais_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .interacoesSociais_classificacao) +
                                            " font-weight-bold"}>
                                        {data.interacoesSociais_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeInteracoesSociais[0]}
                                        max={rangeInteracoesSociais[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>


                </Card>
                <Card className="mt-5 shadow">
                    <div className="bg-abertura p-3">Socialização</div>
                    <Table padding="none">
                        <TableRow >
                            <TableCell className="p-2">Escore</TableCell>
                            <TableCell>{data.socializacao}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Escore Bruto</TableCell>
                            <TableCell>{data.socializacao_eb.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Percentil</TableCell>
                            <TableCell>{data.socializacao_percentil + "%"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell
                                className={classClassificacao(data
                                    .socializacao_classificacao) +
                                    " font-weight-bold"}>
                                {data.socializacao_classificacao}</TableCell>
                        </TableRow>

                    </Table>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                            className={apto(rangeAmabilidade[0],
                                rangeAmabilidade[1],
                                data.amabilidade_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Amabilidade"
                                min={rangeAmabilidade[0]}
                                max={rangeAmabilidade[1]}
                                percentil={data.amabilidade_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.amabilidade}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.amabilidade_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.amabilidade_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .amabilidade_classificacao) +
                                            " font-weight-bold"}>
                                        {data.amabilidade_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeAmabilidade[0]}
                                        max={rangeAmabilidade[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel3b-content"
                            id="panel3b-header"
                            className={apto(rangeProSociabilidade[0],
                                rangeProSociabilidade[1],
                                data.proSociabilidade_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Pro Sociabilidade"
                                min={rangeProSociabilidade[0]}
                                max={rangeProSociabilidade[1]}
                                percentil={data.proSociabilidade_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.proSociabilidade}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.proSociabilidade_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.proSociabilidade_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .proSociabilidade_classificacao) +
                                            " font-weight-bold"}>
                                        {data.proSociabilidade_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeProSociabilidade[0]}
                                        max={rangeProSociabilidade[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel3c-content"
                            id="panel3c-header"
                            className={apto(rangeConfiancaNasPessoas[0],
                                rangeConfiancaNasPessoas[1],
                                data.confiancaNasPessoas_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Confiança nas Pessoas"
                                min={rangeConfiancaNasPessoas[0]}
                                max={rangeConfiancaNasPessoas[1]}
                                percentil={data.confiancaNasPessoas_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.confiancaNasPessoas}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.confiancaNasPessoas_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.confiancaNasPessoas_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .confiancaNasPessoas_classificacao) +
                                            " font-weight-bold"}>
                                        {data.confiancaNasPessoas_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeConfiancaNasPessoas[0]}
                                        max={rangeConfiancaNasPessoas[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>



                </Card>
                <Card className="mt-5 shadow">
                    <div className="bg-conscienciosidade p-3">Realização</div>
                    <Table padding="none">
                        <TableRow >
                            <TableCell className="p-2">Escore</TableCell>
                            <TableCell>{data.realizacao}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Escore Bruto</TableCell>
                            <TableCell>{data.realizacao_eb.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Percentil</TableCell>
                            <TableCell>{data.realizacao_percentil + "%"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell
                                className={classClassificacao(data
                                    .realizacao_classificacao) +
                                    " font-weight-bold"}>
                                {data.realizacao_classificacao}</TableCell>
                        </TableRow>

                    </Table>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                            className={apto(rangeCompetencia[0],
                                rangeCompetencia[1],
                                data.competencia_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Competência"
                                min={rangeCompetencia[0]}
                                max={rangeCompetencia[1]}
                                percentil={data.competencia_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.competencia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.competencia_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.competencia_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .competencia_classificacao) +
                                            " font-weight-bold"}>
                                        {data.competencia_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeCompetencia[0]}
                                        max={rangeCompetencia[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel4b-content"
                            id="panel4b-header"
                            className={apto(rangePonderacao[0],
                                rangePonderacao[1],
                                data.ponderacao_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Ponderação"
                                min={rangePonderacao[0]}
                                max={rangePonderacao[1]}
                                percentil={data.ponderacao_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.ponderacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.ponderacao_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.ponderacao_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .ponderacao_classificacao) +
                                            " font-weight-bold"}>
                                        {data.ponderacao_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangePonderacao[0]}
                                        max={rangePonderacao[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel4c-content"
                            id="panel4c-header"
                            className={apto(rangeEmpenho[0],
                                rangeEmpenho[1],
                                data.empenhoComprometimento_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Empenho"
                                min={rangeEmpenho[0]}
                                max={rangeEmpenho[1]}
                                percentil={data.empenhoComprometimento_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.empenhoComprometimento}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.empenhoComprometimento_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.empenhoComprometimento_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .empenhoComprometimento_classificacao) +
                                            " font-weight-bold"}>
                                        {data.empenhoComprometimento_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeEmpenho[0]}
                                        max={rangeEmpenho[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>





                </Card>

                <Card className="mt-5 shadow">
                    <div className="bg-abertura p-3">Abertura</div>
                    <Table padding="none">
                        <TableRow >
                            <TableCell className="p-2">Escore</TableCell>
                            <TableCell>{data.abertura}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Escore Bruto</TableCell>
                            <TableCell>{data.abertura_eb.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Percentil</TableCell>
                            <TableCell>{data.abertura_percentil + "%"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell
                                className={classClassificacao(data
                                    .abertura_classificacao) +
                                    " font-weight-bold"}
                            >
                                {data.abertura_classificacao}</TableCell>
                        </TableRow>
                    </Table>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                            className={apto(rangeAberturaIdeias[0],
                                rangeAberturaIdeias[1],
                                data.aberturaIdeias_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Abertura a Ideias"
                                min={rangeAberturaIdeias[0]}
                                max={rangeAberturaIdeias[1]}
                                percentil={data.aberturaIdeias_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.aberturaIdeias}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.aberturaIdeias_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.aberturaIdeias_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .aberturaIdeias_classificacao) +
                                            " font-weight-bold"}
                                    >
                                        {data.aberturaIdeias_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeAberturaIdeias[0]}
                                        max={rangeAberturaIdeias[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel5b-content"
                            id="panel5b-header"
                            className={apto(rangeLiberalismo[0],
                                rangeLiberalismo[1],
                                data.liberalismo_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Liberalismo"
                                min={rangeLiberalismo[0]}
                                max={rangeLiberalismo[1]}
                                percentil={data.liberalismo_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.liberalismo}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.liberalismo_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.liberalismo_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .liberalismo_classificacao) +
                                            " font-weight-bold"}
                                    >
                                        {data.liberalismo_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeLiberalismo[0]}
                                        max={rangeLiberalismo[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="mt-2" >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon className="text-light" />}
                            aria-controls="panel5c-content"
                            id="panel5c-header"
                            className={apto(rangeBuscaPorNovidades[0],
                                rangeBuscaPorNovidades[1],
                                data.buscaPorNovidades_percentil) ?
                                "bg-success text-light" :
                                "bg-danger text-light"}

                        >

                            <Aptidao
                                criterio="Busca por Novidades"
                                min={rangeBuscaPorNovidades[0]}
                                max={rangeBuscaPorNovidades[1]}
                                percentil={data.buscaPorNovidades_percentil} />

                        </AccordionSummary>
                        <AccordionDetails sx={{ color: 'black' }}>
                            <Table padding="none">
                                <TableRow >
                                    <TableCell className="p-2">Escore</TableCell>
                                    <TableCell>{data.buscaPorNovidades}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Escore Bruto</TableCell>
                                    <TableCell>{data.buscaPorNovidades_eb.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil</TableCell>
                                    <TableCell>{data.buscaPorNovidades_percentil + "%"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Classificação</TableCell>
                                    <TableCell
                                        className={classClassificacao(data
                                            .buscaPorNovidades_classificacao) +
                                            " font-weight-bold"}
                                    >
                                        {data.buscaPorNovidades_classificacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="p-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado
                                        min={rangeBuscaPorNovidades[0]}
                                        max={rangeBuscaPorNovidades[1]} />
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </AccordionDetails>

                    </Accordion>

                </Card>

                <BackHome />






            </Layout>
        </div>
    )

}



function Aptidao({ criterio, min, max, percentil }) {
    const aptidao = percentil >= min && percentil <= max ? "Apto" : "Inapto"

    return (
        <div>{`${criterio} (${aptidao})`}</div>

    )
}
function apto(min, max, percentil) {
    return percentil >= min && percentil <= max
}