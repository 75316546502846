
import { Button } from "@mui/material";

export default function ButtonSubmit({ onClick }) {
  return (
    <div className="mt-5 text-center mb-5 button-submit">
      <Button variant="contained" className="bg-dark" fullWidth onClick={onClick}>
        Enviar
      </Button>
        
  </div>
  );
}