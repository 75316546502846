import Title from "../components/Title";
import Layout from "./layout";
import {useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Accordion, AccordionDetails, AccordionSummary, Card, Table, TableBody, TableCell, TableRow } from "@mui/material";
import BackHome from "../components/EATA/BackHome";



var data = {
    masculino: {
        "afago": 0,
        "afiliacao": 0,
        "agressividade": 0,
        "assistencia": 0,
        "autonomia": 0,
        "deferencia": 0,
        "desempenho": 0,
        "dominancia": 0,
        "exibicao": 0,
        "intracepcao": 0,
        "mudanca": 0,
        "ordem": 0,
        "persistencia": 0,
    },
    feminino: {
        "afago": 0,
        "afiliacao": 0,
        "agressividade": 0,
        "assistencia": 0,
        "autonomia": 0,
        "deferencia": 0,
        "desempenho": 0,
        "dominancia": 0,
        "exibicao": 0,
        "intracepcao": 0,
        "mudanca": 0,
        "ordem": 0,
        "persistencia": 0,
    }
}

const rangeAssistencia = { min: 30, max: 100 }
const rangeIntracepcao = { min: 0, max: 70 }
const rangeAfago = { min: 0, max: 70 }
const rangeDeferencia = { min: 30, max: 100 }
const rangeAfiliacao = { min: 30, max: 100 }
const rangeDominancia = { min: 0, max: 60 }
const rangeDesempenho = { min: 50, max: 100 }
const rangeExibicao = { min: 0, max: 50 }
const rangeAgressividade = { min: 20, max: 80 }
const rangeOrdem = { min: 40, max: 100 }
const rangePersistencia = { min: 50, max: 100 }
const rangeMudanca = { min: 0, max: 60 }
const rangeAutonomia = { min: 40, max: 100 }


export default function IFPIIResult() {
    const [index, setIndex] = useState(0)


    const handleSelect = (e) => {

        if (index === 0) {
            setIndex(1);

        }
        else if (index === 1) {
            setIndex(0);


        }
    };

    if (typeof window !== "undefined") {
        data = JSON.parse(localStorage.getItem("IFPII"))
    }
    data.aptoMasculino = apto(rangeAssistencia.min, rangeAssistencia.max, data.masculino.assistencia) &&
        apto(rangeIntracepcao.min, rangeIntracepcao.max, data.masculino.intracepcao) &&
        apto(rangeAfago.min, rangeAfago.max, data.masculino.afago) &&
        apto(rangeDeferencia.min, rangeDeferencia.max, data.masculino.deferencia) &&
        apto(rangeAfiliacao.min, rangeAfiliacao.max, data.masculino.afiliacao) &&
        apto(rangeDominancia.min, rangeDominancia.max, data.masculino.dominancia) &&
        apto(rangeDesempenho.min, rangeDesempenho.max, data.masculino.desempenho) &&
        apto(rangeExibicao.min, rangeExibicao.max, data.masculino.exibicao) &&
        apto(rangeAgressividade.min, rangeAgressividade.max, data.masculino.agressividade) &&
        apto(rangeOrdem.min, rangeOrdem.max, data.masculino.ordem) &&
        apto(rangePersistencia.min, rangePersistencia.max, data.masculino.persistencia) &&
        apto(rangeMudanca.min, rangeMudanca.max, data.masculino.mudanca) &&
        apto(rangeAutonomia.min, rangeAutonomia.max, data.masculino.autonomia)

    data.aptoFeminino = apto(rangeAssistencia.min, rangeAssistencia.max, data.feminino.assistencia) &&
        apto(rangeIntracepcao.min, rangeIntracepcao.max, data.feminino.intracepcao) &&
        apto(rangeAfago.min, rangeAfago.max, data.feminino.afago) &&
        apto(rangeDeferencia.min, rangeDeferencia.max, data.feminino.deferencia) &&
        apto(rangeAfiliacao.min, rangeAfiliacao.max, data.feminino.afiliacao) &&
        apto(rangeDominancia.min, rangeDominancia.max, data.feminino.dominancia) &&
        apto(rangeDesempenho.min, rangeDesempenho.max, data.feminino.desempenho) &&
        apto(rangeExibicao.min, rangeExibicao.max, data.feminino.exibicao) &&
        apto(rangeAgressividade.min, rangeAgressividade.max, data.feminino.agressividade) &&
        apto(rangeOrdem.min, rangeOrdem.max, data.feminino.ordem) &&
        apto(rangePersistencia.min, rangePersistencia.max, data.feminino.persistencia) &&
        apto(rangeMudanca.min, rangeMudanca.max, data.feminino.mudanca) &&
        apto(rangeAutonomia.min, rangeAutonomia.max, data.feminino.autonomia)






    const instructions = (
        <div className="text-justify">Inventário Fatorial de Personalidade II, é um instrumento psicológico que avalia 13 necessidades básicas da personalidade humana, segundo a teoria de Henry Murray. O objetivo do teste é traçar o perfil de personalidade do indivíduo, fornecendo informações sobre seus principais motivadores e comportamentos.</div>
    )
    return (
        <Layout>
            <Title title="RESULTADO IFPII" instructions={instructions} />

            <div className="preenche-pagina">
                {/*<Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                    touch={true}
                    controls={false}
                    indicatorLabels={["Masculino", "Feminino"]}




                >


                    {[CardMasculino, CardFeminino].map((Card, index) => (

                        <Carousel.Item key={index}>
                            <Card onChange={handleSelect} />
                        </Carousel.Item>
                    ))}

                    </Carousel>*/}
                    <CardMasculino onChange={handleSelect} />

            </div>
            <div>
                {index === 0 ?
                    (<div className="text-center">
                        {data.aptoMasculino ? <h3 className="text-success">Apto</h3> : <h3 className="text-danger">Inapto</h3>}
                    </div>) :
                    (<div className="text-center">
                        {data.aptoFeminino ? <h3 className="text-success">Apta</h3> : <h3 className="text-danger">Inapta</h3>}
                    </div>)}

            </div>


            <BackHome />




        </Layout>
    )
}


function IntervaloFechado({ min, max }) {
    return (
        <>
            <span className="mr-1">{min}</span><span><img alt="intervalo" src="intervalo_fechado.svg" width={20} height={5} /></span><span className="ml-1">{max}</span>
        </>
    )
}


function Aptidao({ min, max, percentil }) {
    const aptidao = percentil >= min && percentil <= max ? "Apto" : "Inapto"
    const classApto = aptidao === "Apto" ? "text-success" : "text-danger"

    return (
        <div className={classApto + " font-weight-bold"}>{`${aptidao}`}</div>

    )
}
function apto(min, max, percentil) {
    return percentil >= min && percentil <= max
}





function CardMasculino({ onChange, apto }) {
    return (
        <>
            <Card className=" mt-5">
                <div className="bg-masculino p-3 text-light font-weight-bold mb-3 flex">
                    <div className=" d-flex justify-content-center">
                        {/*<div className="btn" ></div>*/}
                        <div className="h3">Masculino</div>
                        {/*<div className="btn" onClick={onChange}><ArrowForwardIos /></div>*/}
                    </div>

                </div>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Afago

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.afago_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.afago}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeAfago.min} max={rangeAfago.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeAfago.min} max={rangeAfago.max} percentil={data.masculino.afago} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Afiliação

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.afiliacao_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.afiliacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeAfiliacao.min} max={rangeAfiliacao.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeAfiliacao.min} max={rangeAfiliacao.max} percentil={data.masculino.afiliacao} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Agressividade

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.agressividade_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.agressividade}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeAgressividade.min} max={rangeAgressividade.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeAgressividade.min} max={rangeAgressividade.max} percentil={data.masculino.agressividade} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Assistência

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.assistencia_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.assistencia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeAssistencia.min} max={rangeAssistencia.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeAssistencia.min} max={rangeAssistencia.max} percentil={data.masculino.assistencia} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Autonomia

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.autonomia_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.autonomia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeAutonomia.min} max={rangeAutonomia.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeAutonomia.min} max={rangeAutonomia.max} percentil={data.masculino.autonomia} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Deferência

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.deferencia_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.deferencia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeDeferencia.min} max={rangeDeferencia.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeDeferencia.min} max={rangeDeferencia.max} percentil={data.masculino.deferencia} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Desempenho

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.desempenho_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.desempenho}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeDesempenho.min} max={rangeDesempenho.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeDesempenho.min} max={rangeDesempenho.max} percentil={data.masculino.desempenho} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Dominância

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.dominancia_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.dominancia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeDominancia.min} max={rangeDominancia.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeDominancia.min} max={rangeDominancia.max} percentil={data.masculino.dominancia} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Exibição

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.exibicao_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.exibicao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeExibicao.min} max={rangeExibicao.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeExibicao.min} max={rangeExibicao.max} percentil={data.masculino.exibicao} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Intracepção

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.intracepcao_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.intracepcao}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeIntracepcao.min} max={rangeIntracepcao.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeIntracepcao.min} max={rangeIntracepcao.max} percentil={data.masculino.intracepcao} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Mudança

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.mudanca_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.mudanca}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeMudanca.min} max={rangeMudanca.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeMudanca.min} max={rangeMudanca.max} percentil={data.masculino.mudanca} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Ordem

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.ordem_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.ordem}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangeOrdem.min} max={rangeOrdem.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangeOrdem.min} max={rangeOrdem.max} percentil={data.masculino.ordem} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="my-1">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        className="bg-masculino-light"

                    >
                        Persistência

                    </AccordionSummary>
                    <AccordionDetails>
                        <Table padding="none">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="py-2">Pontos</TableCell>
                                    <TableCell>{data.masculino.persistencia_n}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil</TableCell>
                                    <TableCell>{data.masculino.persistencia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">Percentil Exigido</TableCell>
                                    <TableCell><IntervaloFechado min={rangePersistencia.min} max={rangePersistencia.max} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="py-2">resultado</TableCell>
                                    <TableCell><Aptidao min={rangePersistencia.min} max={rangePersistencia.max} percentil={data.masculino.persistencia} /></TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>





            </Card>

        </>

    )
}
/*
function CardFeminino({ onChange }) {
    return (
        <Card className="mb-5 mt-5">
            <div className="bg-feminino p-3 text-light font-weight-bold mb-3 flex">
                <div className=" d-flex justify-content-between">
                    <div className="btn" onClick={onChange}><ArrowBackIos /></div>
                    <div className="h3">Feminino</div>
                    <div ></div>
                </div>

            </div>
            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Afago

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.afago_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.afago}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeAfago.min} max={rangeAfago.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeAfago.min} max={rangeAfago.max} percentil={data.feminino.afago} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Afiliação

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.afiliacao_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.afiliacao}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeAfiliacao.min} max={rangeAfiliacao.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeAfiliacao.min} max={rangeAfiliacao.max} percentil={data.feminino.afiliacao} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Agressividade

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.agressividade_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.agressividade}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeAgressividade.min} max={rangeAgressividade.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeAgressividade.min} max={rangeAgressividade.max} percentil={data.feminino.agressividade} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Assistência

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.assistencia_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.assistencia}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeAssistencia.min} max={rangeAssistencia.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeAssistencia.min} max={rangeAssistencia.max} percentil={data.feminino.assistencia} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Autonomia

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.autonomia_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.autonomia}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeAutonomia.min} max={rangeAutonomia.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeAutonomia.min} max={rangeAutonomia.max} percentil={data.feminino.autonomia} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Deferência

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.deferencia_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.deferencia}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeDeferencia.min} max={rangeDeferencia.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeDeferencia.min} max={rangeDeferencia.max} percentil={data.feminino.deferencia} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Desempenho

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.desempenho_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.desempenho}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeDesempenho.min} max={rangeDesempenho.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeDesempenho.min} max={rangeDesempenho.max} percentil={data.feminino.desempenho} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Dominância

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.dominancia_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.dominancia}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeDominancia.min} max={rangeDominancia.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeDominancia.min} max={rangeDominancia.max} percentil={data.feminino.dominancia} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Exibição

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.exibicao_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.exibicao}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeExibicao.min} max={rangeExibicao.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeExibicao.min} max={rangeExibicao.max} percentil={data.feminino.exibicao} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Intracepção

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.intracepcao_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.intracepcao}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeIntracepcao.min} max={rangeIntracepcao.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeIntracepcao.min} max={rangeIntracepcao.max} percentil={data.feminino.intracepcao} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Mudança

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.mudanca_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.mudanca}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeMudanca.min} max={rangeMudanca.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeMudanca.min} max={rangeMudanca.max} percentil={data.feminino.mudanca} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Ordem

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.ordem_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.ordem}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangeOrdem.min} max={rangeOrdem.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangeOrdem.min} max={rangeOrdem.max} percentil={data.feminino.ordem} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion className="my-1">
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    className="bg-feminino-light"

                >
                    Persistência

                </AccordionSummary>
                <AccordionDetails>
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell className="py-2">Pontos</TableCell>
                                <TableCell>{data.feminino.persistencia_n}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil</TableCell>
                                <TableCell>{data.feminino.persistencia}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">Percentil Exigido</TableCell>
                                <TableCell><IntervaloFechado min={rangePersistencia.min} max={rangePersistencia.max} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="py-2">resultado</TableCell>
                                <TableCell><Aptidao min={rangePersistencia.min} max={rangePersistencia.max} percentil={data.feminino.persistencia} /></TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>






        </Card>

    )
}
*/
