import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BackHome() {
    const navigate = useNavigate();

    return (
        <div className="mt-5 text-center mb-5 button-submit">
            <Button variant="contained" className="bg-dark" fullWidth onClick={() => navigate("/")}>
                Voltar para pagina inicial
            </Button>
        </div>
    );
}