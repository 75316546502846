import QuestionBPF from "../components/BFP/QuestionBPF";
import {perguntas, calculaResultado} from "../data/DataIFPII";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Layout from "./layout";
import Title from "../components/Title";
import {Divider } from "@mui/material";
import ButtonSubmit from "../components/ButtonSubmit";
import { useNavigate } from "react-router-dom";
// pages\IFPIIPage.js
export default function IfpiPage() {

    const data = [...perguntas]
    const [dataValues, setDataValues] = useState(data)
    const navigate = useNavigate()

    useEffect(() => {
        
        const coockieResponses = Cookies.get("IFPII")
        if (coockieResponses) {
            const copyData = [...perguntas]

            copyData.forEach((p, i) => {
                p.response = JSON.parse(coockieResponses)[i]
            })
            setDataValues(copyData)
        }
    }, [])
   
    

   
    const handleTotalSelectedValueCalculation = () => {
  
        let hadNull = false
        let responsesData = []

        dataValues.map((p, i) => {
           
            if (p.response === undefined) {
                hadNull = true
                setDataValues(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "error"
                    return newState
                })


            }
            else {
                
                responsesData.push(p.response)
                setDataValues(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "success"
                    return newState
                })


            }


            

        })
        if (hadNull) {
            alert("Por favor, responda todas as questões")

        }

        
        else {
    
            const results = calculaResultado(data)
            Cookies.set("IFPII", JSON.stringify(responsesData), { expires: 30 });
            if (typeof window !== undefined) {
                localStorage.setItem("IFPII", JSON.stringify(results));

               
            }

            navigate("/IFPIIResult")

            
            
















        }
    }


    const handleOnChange = (e, index) => {
        setDataValues(prevState => {
            const newState = [...prevState]
            const value = e.target.value
            newState[index].response = value
            newState[index].color = "success"
            if(newState[index].fator === -1){
                newState[index].result = 8 - value
            }
            else{
                newState[index].result = value
            }
    
            return newState
        })
        
      
        


    }


    return (
        <Layout>
            <Title title="IFPII" instructions="Responda as perguntas de 1 a 7 com relação ao que você pensa a respeito das afirmativas" />
            {dataValues.map((p, i) => {
                return (
                    

                    <div key={i}>
                        <QuestionBPF
                            pergunta={(i + 1) + ". "  + p.pergunta}
                            resposta={p.response}
                            escala={p.escala}
                            seriePercentil={p.seriePercentil}
                            color={p.color}
                            id={i}
                            
                            onChange={handleOnChange}
                        />
                        
                    </div>


                    
                )
            }
            )}
            <Divider />
            <ButtonSubmit onClick={handleTotalSelectedValueCalculation} />


        </Layout>

    )




}