import Slider from '@mui/material/Slider';
import { useState, useEffect } from 'react';
import Prompt from '../Prompt';


export default function QuestionBPF({ pergunta, resposta, onChange, id, color = 'primary' }) {

    const [value, setValue] = useState(resposta || 0);

    useEffect(() => {
        setValue(resposta);
    }, [resposta]);



    const marks = [
        { value: 0, label: 'DF' },
        { value: 25, label: 'D' },
        { value: 50, label: 'N' },
        { value: 75, label: 'C' },
        { value: 100, label: 'CF' },
    ]

    const legenda = () => {
        if (value === 0) {
            return 'Discordo Fortemente';
        }
        if (value === 25) {
            return 'Discordo';
        }
        if (value === 50) {
            return 'Neutro';
        }
        if (value === 75) {
            return 'Concordo';
        }
        if (value === 100) {
            return 'Concordo Fortemente';
        }

    }
    //return um card com pergunta resposta keyu um divider para respoetas de 1 a 7 e um divider
    return (
        <div key={id} id={'question-' + id} >
            <div className="m-2 sombra-forte rounded" sx={{ minWidth: 275 }}>
                <div>

                    <Prompt prompt={pergunta} color={color} />

                    <div style={{ height: '1.5em' }} className={'text-center pt-1 text-' + color}>{legenda()}</div>
                    <div className='p-3'>

                        <Slider

                            aria-label="Always visible"

                            min={0}
                            max={100}
                            step={25}
                            color={color}
                            marks={marks}
                            value={value}
                            onChange={(e, newValue) => {
                                setValue(newValue);
                                onChange(e, id);

                            }}



                        />
                    </div>
                </div>

            </div>
        </div>
    );
}


