// d:/Source/PROJETO_JETO/psicotecnico/data/DataBFP.js
export const DataBFP =[
    {
        "pergunta": "Procuro seguir as regras sociais sem questioná-las",
        "escala": "LIBERALISMO",
        "fator": -1,
        "seriePercentil": "A2"
    },
    {
        "pergunta": "Tento fazer com que as pessoas se sintam bem",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Gosto de falar sobre mim",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Tenho um coração mole",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Falo tudo que penso",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Gosto de fazer coisas que nunca fiz antes",
        "escala": "BUSCA POR NOVIDADES",
        "fator": 1,
        "seriePercentil": "A3"
    },
    {
        "pergunta": "Acredito que as pessoas têm boas intenções",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": 1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Sou divertido",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Tomo cuidado com o que falo",
        "escala": "PONDERAÇÃO",
        "fator": 1,
        "seriePercentil": "R2"
    },
    {
        "pergunta": "Dificilmente perdoo",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": -1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Divirto me quando estou entre varias pessoas",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Respeito os sentimentos alheios",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Mesmo quando preciso resolver alguma coisa para mim, costumo adiar até o último momento",
        "escala": "PASSIVIDADE/FALTA DE ENERGIA",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Tento influenciar os outros",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Sou generoso",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Estou satisfeito comigo mesmo",
        "escala": "DEPRESSÃO",
        "fator": -1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Não falo muito",
        "escala": "COMUNICAÇÃO",
        "fator": -1,
        "seriePercentil": " E1"
    },
    {
        "pergunta": "Posso agredir fisicamente as pessoas quando fico muito irritado",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": -1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Resolvo meus problemas sem pensar muito",
        "escala": "PONDERAÇÃO",
        "fator": -1,
        "seriePercentil": "R2"
    },
    {
        "pergunta": "Preocupo-me com todos",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Geralmente me sinto feliz",
        "escala": "DINAMISMO",
        "fator": 1,
        "seriePercentil": "E3"
    },
    {
        "pergunta": "Preciso de estímulos para começar a fazer as coisas",
        "escala": "PASSIVIDADE/FALTA DE ENERGIA",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Tenho pouco interesse por exposições de arte",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Divirto-me contrariando as pessoas",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": -1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Com frequência tomo decisões precipitadas",
        "escala": "INSTABILIDADE EMOCIONAL",
        "fator": 1,
        "seriePercentil": "N2"
    },
    {
        "pergunta": "Facilmente coloco as minhas ideias em prática",
        "escala": "DINAMISMO",
        "fator": 1,
        "seriePercentil": "E3"
    },
    {
        "pergunta": "Uso as pessoas para conseguir o que desejo",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": -1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Posso lidar com muitas tarefas ao mesmo tempo",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Quase sempre me sinto desanimado",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Suspeito das intenções das pessoas",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": -1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Atualmente, defendo ideias diferentes daquelas que defendia antigamente",
        "escala": "LIBERALISMO",
        "fator": 1,
        "seriePercentil": "A2"
    },
    {
        "pergunta": "Consigo o que eu quero",
        "escala": "DINAMISMO",
        "fator": 1,
        "seriePercentil": "E3"
    },
    {
        "pergunta": "Tenho pouca curiosidade para conhecer novos estilos musicais",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Dedico-me muito para fazer bem as coisas",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Espero pela decisão dos outros",
        "escala": "PASSIVIDADE/FALTA DE ENERGIA",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Interesso-me por teorias que tentam explicar o universo",
        "escala": "ABERTURA A IDEIAS",
        "fator": 1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Tenho pouca paciência para terminar tarefas muito longas ou difíceis",
        "escala": "PASSIVIDADE/FALTA DE ENERGIA",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Sou uma pessoa tímida",
        "escala": "COMUNICAÇÃO",
        "fator": -1,
        "seriePercentil": " E1"
    },
    {
        "pergunta": "Tenho alguns inimigos",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": -1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Acho que a minha vida é vazia e sem emoção",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Começo rapidamente as tarefas que tenho para fazer",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Acho pouco interessantes exposições fotográficas",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Respeito o ponto de vista dos outros",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Tenho dificuldade para me adaptar a trabalhos que envolvam uma rotina fixa",
        "escala": "BUSCA POR NOVIDADES",
        "fator": 1,
        "seriePercentil": "A3"
    },
    {
        "pergunta": "Antes de agir, penso no que pode me acontecer",
        "escala": "PONDERAÇÃO",
        "fator": 1,
        "seriePercentil": "R2"
    },
    {
        "pergunta": "Sinto-me mal se não cumpro algo que prometi",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Adoro atividades em grupo",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Tudo o que posso ver a minha frente é mais desprazer do que prazer",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Gosto e ir a lugares que não conheço",
        "escala": "BUSCA POR NOVIDADES",
        "fator": 1,
        "seriePercentil": "A3"
    },
    {
        "pergunta": "Converso com muitas pessoas diferentes quando vou a festas",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Ajo impulsivamente quando alguma coisa está me aborrecendo",
        "escala": "INSTABILIDADE EMOCIONAL",
        "fator": 1,
        "seriePercentil": "N2"
    },
    {
        "pergunta": "Gosto de ter uma vida social agitada",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Participar de atividades que envolvam criatividade e/ou fantasia me empolga",
        "escala": "ABERTURA A IDEIAS",
        "fator": 1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Me esforço para ter destaque na escola ou no trabalho",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Geralmente faço o que meus amigos e parentes querem,embora não concorde com eles, com medo de que se afastem de mim",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Tenho pouco interesse por ideias abstratas",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Acho que os outros zombam de mim",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": -1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Costumo fazer sacrifícios para conseguir o que quero",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Acho natural que os valores morais mudem ao longo do tempo",
        "escala": "LIBERALISMO",
        "fator": 1,
        "seriePercentil": "A2"
    },
    {
        "pergunta": "Tenho muito medo de que meus amigos deixem de gostar de mim",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Tento incentivar as pessoas",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Sou uma pessoa com pouca imaginação",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Faço coisas consideradas perigosas",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": -1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Penso sobre o que preciso fazer para alcançar meus objetivos",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Sou uma pessoa nervosa",
        "escala": "INSTABILIDADE EMOCIONAL",
        "fator": 1,
        "seriePercentil": "N2"
    },
    {
        "pergunta": "Costumo ficar calado quando estou entre estranhos",
        "escala": "COMUNICAÇÃO",
        "fator": -1,
        "seriePercentil": " E1"
    },
    {
        "pergunta": "Resolvo meus problemas com rapidez",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Confio no que as pessoas dizem",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": 1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Acho que não existe uma verdade absoluta",
        "escala": "LIBERALISMO",
        "fator": 1,
        "seriePercentil": "A2"
    },
    {
        "pergunta": "Por mais que me esforce, sei que não sou capaz de superar os obstáculos que tenho que enfrentar no dia a dia",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Envolvo-me rapidamente com os outros",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Gosto de pensar sobre soluções diferentes para problemas complexos",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Deixo de fazer as coisas que desejo por medo de ser criticado pelos outros",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Acredito que a maioria dos valores morais são dependentes da época e do lugar",
        "escala": "LIBERALISMO",
        "fator": 1,
        "seriePercentil": "A2"
    },
    {
        "pergunta": "Fico muito tímido quando estou entre desconhecidos",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Preocupo-me em agir segundo as leis",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": 1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Meu humor varia constantemente",
        "escala": "INSTABILIDADE EMOCIONAL",
        "fator": 1,
        "seriePercentil": "N2"
    },
    {
        "pergunta": "Necessito estar no centro das atenções",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Sinto-me muito inseguro quando tenho que fazer coisas que nunca fiz antes",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "As pessoas dizem que sou muito detalhista",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Evito discussões filosóficas",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Não gosto de expressar minhas ideias, pois tenho medo de ser ridicularizado",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Sou  capaz de assumir tarefas importantes",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Gosto de manter a rotina",
        "escala": "BUSCA POR NOVIDADES",
        "fator": -1,
        "seriePercentil": "A3"
    },
    {
        "pergunta": "Acho que faço bem as coisas",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Sou uma pessoa irritável",
        "escala": "INSTABILIDADE EMOCIONAL",
        "fator": 1,
        "seriePercentil": "N2"
    },
    {
        "pergunta": "Costumo enganar as pessoas",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": -1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Gosto de trabalhos artísticos que são considerados estranhos",
        "escala": "ABERTURA A IDEIAS",
        "fator": 1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Tenho muita dificuldade de tomar decisões em minha vida",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Vivo minhas emoções intensamente",
        "escala": "INTERAÇÕES SOCIAIS",
        "fator": 1,
        "seriePercentil": "E4"
    },
    {
        "pergunta": "Gosto de fazer coisas que exigem muito de mim",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Sofro quando encontro alguém que está com dificuldade",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "É comum terem inveja de mim",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Sempre que posso, mudo os trajetos nos meus percursos diários",
        "escala": "BUSCA POR NOVIDADES",
        "fator": 1,
        "seriePercentil": "A3"
    },
    {
        "pergunta": "Tenho dificuldade para terminar as tarefas, pois distraio com outras coisas",
        "escala": "PASSIVIDADE/FALTA DE ENERGIA",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Preocupo-me com aqueles que estão numa situação pior que a minha",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Sou comunicativo",
        "escala": "COMUNICAÇÃO",
        "fator": 1,
        "seriePercentil": " E1"
    },
    {
        "pergunta": "Acho que os outros podem tentar me prejudicar",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": -1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Sinto uma incontrolável vontade de falar, mesmo que seja com quem não conheço",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Eu paro de fazer as coisas quando elas ficam difíceis",
        "escala": "PASSIVIDADE/FALTA DE ENERGIA",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Escolho as palavras com cuidado",
        "escala": "PONDERAÇÃO",
        "fator": 1,
        "seriePercentil": "R2"
    },
    {
        "pergunta": "Com frequência, passo por períodos em que fico extremamente irritável, incomodando-me com qualquer coisa",
        "escala": "INSTABILIDADE EMOCIONAL",
        "fator": 1,
        "seriePercentil": "N2"
    },
    {
        "pergunta": "Raramente mostro um trabalho a outras pessoas antes de revisá-lo cuidadosamente",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Importo-me com os sentimentos dos outros",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Faço muitas coisas durante minhas horas de folga",
        "escala": "COMUNICAÇÃO",
        "fator": 1,
        "seriePercentil": " E1"
    },
    {
        "pergunta": "Estou cansado de viver",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Gosto de quebrar regras",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": -1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Costumo tomar a iniciativa e conversar com os outros",
        "escala": "DINAMISMO",
        "fator": 1,
        "seriePercentil": "E3"
    },
    {
        "pergunta": "Respeito autoridades",
        "escala": "PRÓ-SOCIABILIDADE",
        "fator": 1,
        "seriePercentil": "S2"
    },
    {
        "pergunta": "Sou uma pessoa insegura",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Quando estou entre um grupo, gosto que me deem atenção",
        "escala": "ALTIVEZ",
        "fator": 1,
        "seriePercentil": "E2"
    },
    {
        "pergunta": "Meus amigos dizem que eu trabalho/estudo demais",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Sinto-me entendiado quando tenho que fazer as mesmas coisas",
        "escala": "BUSCA POR NOVIDADES",
        "fator": 1,
        "seriePercentil": "A3"
    },
    {
        "pergunta": "Exijo muito de mim mesmo",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Tenho dificuldade de participar de atividades que exijam imaginação ou fantasia",
        "escala": "ABERTURA A IDEIAS",
        "fator": -1,
        "seriePercentil": "A1"
    },
    {
        "pergunta": "Gosto de programar detalhadamente as coisas que tenho para fazer",
        "escala": "EMPENHO/COMPROMETIMENTO",
        "fator": 1,
        "seriePercentil": "R3"
    },
    {
        "pergunta": "Usualmente, tomo a inciativa nas situações",
        "escala": "DINAMISMO",
        "fator": 1,
        "seriePercentil": "E3"
    },
    {
        "pergunta": "Sinto-me muito mal quando recebo alguma crítica",
        "escala": "VUNERABILIDADE",
        "fator": 1,
        "seriePercentil": "N1"
    },
    {
        "pergunta": "Acredito que as pessoas têm uma natureza ruim",
        "escala": "CONFIANÇA NAS PESSOAS",
        "fator": -1,
        "seriePercentil": "S3"
    },
    {
        "pergunta": "Dificilmente fico sem jeito",
        "escala": "COMUNICAÇÃO",
        "fator": 1,
        "seriePercentil": " E1"
    },
    {
        "pergunta": "Só me aproximo de uma pessoa quando estou certo de que ela concorda com as minhas opiniões e atitudes, para evitar críticas ou desaprovação",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Sei o que quero para minha vida",
        "escala": "COMPETÊNCIA",
        "fator": 1,
        "seriePercentil": "R1"
    },
    {
        "pergunta": "Frequentemente questiono regras e costumes sociais",
        "escala": "LIBERALISMO",
        "fator": 1,
        "seriePercentil": "A2"
    },
    {
        "pergunta": "Tenho uma grande dificuldade em dormir",
        "escala": "DEPRESSÃO",
        "fator": 1,
        "seriePercentil": "N3"
    },
    {
        "pergunta": "Preocupo-me em agradar as pessoas",
        "escala": "AMABILIDADE",
        "fator": 1,
        "seriePercentil": "S1"
    },
    {
        "pergunta": "Sou disposto a rever meus posicionamentos sobre diferentes assuntos",
        "escala": "LIBERALISMO",
        "fator": 1,
        "seriePercentil": "A2"
    }
]
export const escalaNeuroticismo = {
    "1.00": 1,
    "2.00": 10,
    "3.00": 45,
    "4.00": 75,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 5,
    "1.67": 5,
    "1.68": 5,
    "1.69": 5,
    "1.70": 5,
    "1.71": 5,
    "1.72": 5,
    "1.73": 5,
    "1.74": 5,
    "1.75": 5,
    "1.76": 5,
    "1.77": 5,
    "1.78": 5,
    "1.79": 5,
    "1.80": 5,
    "1.81": 5,
    "1.82": 5,
    "1.83": 5,
    "1.84": 5,
    "1.85": 5,
    "1.86": 5,
    "1.87": 5,
    "1.88": 5,
    "1.89": 5,
    "1.90": 5,
    "1.91": 5,
    "1.92": 10,
    "1.93": 10,
    "1.94": 10,
    "1.95": 10,
    "1.96": 10,
    "1.97": 10,
    "1.98": 10,
    "1.99": 10,
    "2.01": 10,
    "2.02": 10,
    "2.03": 10,
    "2.04": 10,
    "2.05": 10,
    "2.06": 10,
    "2.07": 10,
    "2.08": 10,
    "2.09": 10,
    "2.10": 10,
    "2.11": 10,
    "2.12": 10,
    "2.13": 15,
    "2.14": 15,
    "2.15": 15,
    "2.16": 15,
    "2.17": 15,
    "2.18": 15,
    "2.19": 15,
    "2.20": 15,
    "2.21": 15,
    "2.22": 15,
    "2.23": 15,
    "2.24": 15,
    "2.25": 15,
    "2.26": 15,
    "2.27": 15,
    "2.28": 15,
    "2.29": 15,
    "2.30": 20,
    "2.31": 20,
    "2.32": 20,
    "2.33": 20,
    "2.34": 20,
    "2.35": 20,
    "2.36": 20,
    "2.37": 20,
    "2.38": 20,
    "2.39": 20,
    "2.40": 20,
    "2.41": 20,
    "2.42": 20,
    "2.43": 20,
    "2.44": 20,
    "2.45": 25,
    "2.46": 25,
    "2.47": 25,
    "2.48": 25,
    "2.49": 25,
    "2.50": 25,
    "2.51": 25,
    "2.52": 25,
    "2.53": 25,
    "2.54": 25,
    "2.55": 25,
    "2.56": 25,
    "2.57": 25,
    "2.58": 25,
    "2.59": 30,
    "2.60": 30,
    "2.61": 30,
    "2.62": 30,
    "2.63": 30,
    "2.64": 30,
    "2.65": 30,
    "2.66": 30,
    "2.67": 30,
    "2.68": 30,
    "2.69": 30,
    "2.70": 30,
    "2.71": 30,
    "2.72": 30,
    "2.73": 35,
    "2.74": 35,
    "2.75": 35,
    "2.76": 35,
    "2.77": 35,
    "2.78": 35,
    "2.79": 35,
    "2.80": 35,
    "2.81": 35,
    "2.82": 35,
    "2.83": 35,
    "2.84": 35,
    "2.85": 35,
    "2.86": 35,
    "2.87": 40,
    "2.88": 40,
    "2.89": 40,
    "2.90": 40,
    "2.91": 40,
    "2.92": 40,
    "2.93": 40,
    "2.94": 40,
    "2.95": 40,
    "2.96": 40,
    "2.97": 40,
    "2.98": 40,
    "2.99": 40,
    "3.01": 45,
    "3.02": 45,
    "3.03": 45,
    "3.04": 45,
    "3.05": 45,
    "3.06": 45,
    "3.07": 45,
    "3.08": 45,
    "3.09": 45,
    "3.10": 45,
    "3.11": 45,
    "3.12": 50,
    "3.13": 50,
    "3.14": 50,
    "3.15": 50,
    "3.16": 50,
    "3.17": 50,
    "3.18": 50,
    "3.19": 50,
    "3.20": 50,
    "3.21": 50,
    "3.22": 50,
    "3.23": 50,
    "3.24": 50,
    "3.25": 55,
    "3.26": 55,
    "3.27": 55,
    "3.28": 55,
    "3.29": 55,
    "3.30": 55,
    "3.31": 55,
    "3.32": 55,
    "3.33": 55,
    "3.34": 55,
    "3.35": 55,
    "3.36": 55,
    "3.37": 55,
    "3.38": 55,
    "3.39": 60,
    "3.40": 60,
    "3.41": 60,
    "3.42": 60,
    "3.43": 60,
    "3.44": 60,
    "3.45": 60,
    "3.46": 60,
    "3.47": 60,
    "3.48": 60,
    "3.49": 60,
    "3.50": 60,
    "3.51": 60,
    "3.52": 60,
    "3.53": 60,
    "3.54": 65,
    "3.55": 65,
    "3.56": 65,
    "3.57": 65,
    "3.58": 65,
    "3.59": 65,
    "3.60": 65,
    "3.61": 65,
    "3.62": 65,
    "3.63": 65,
    "3.64": 65,
    "3.65": 65,
    "3.66": 65,
    "3.67": 65,
    "3.68": 70,
    "3.69": 70,
    "3.70": 70,
    "3.71": 70,
    "3.72": 70,
    "3.73": 70,
    "3.74": 70,
    "3.75": 70,
    "3.76": 70,
    "3.77": 70,
    "3.78": 70,
    "3.79": 70,
    "3.80": 70,
    "3.81": 70,
    "3.82": 70,
    "3.83": 70,
    "3.84": 70,
    "3.85": 70,
    "3.86": 75,
    "3.87": 75,
    "3.88": 75,
    "3.89": 75,
    "3.90": 75,
    "3.91": 75,
    "3.92": 75,
    "3.93": 75,
    "3.94": 75,
    "3.95": 75,
    "3.96": 75,
    "3.97": 75,
    "3.98": 75,
    "3.99": 75,
    "4.01": 75,
    "4.02": 75,
    "4.03": 75,
    "4.04": 75,
    "4.05": 80,
    "4.06": 80,
    "4.07": 80,
    "4.08": 80,
    "4.09": 80,
    "4.10": 80,
    "4.11": 80,
    "4.12": 80,
    "4.13": 80,
    "4.14": 80,
    "4.15": 80,
    "4.16": 80,
    "4.17": 80,
    "4.18": 80,
    "4.19": 80,
    "4.20": 80,
    "4.21": 80,
    "4.22": 80,
    "4.23": 80,
    "4.24": 80,
    "4.25": 85,
    "4.26": 85,
    "4.27": 85,
    "4.28": 85,
    "4.29": 85,
    "4.30": 85,
    "4.31": 85,
    "4.32": 85,
    "4.33": 85,
    "4.34": 85,
    "4.35": 85,
    "4.36": 85,
    "4.37": 85,
    "4.38": 85,
    "4.39": 85,
    "4.40": 85,
    "4.41": 85,
    "4.42": 85,
    "4.43": 85,
    "4.44": 85,
    "4.45": 85,
    "4.46": 85,
    "4.47": 85,
    "4.48": 85,
    "4.49": 85,
    "4.50": 85,
    "4.51": 85,
    "4.52": 90,
    "4.53": 90,
    "4.54": 90,
    "4.55": 90,
    "4.56": 90,
    "4.57": 90,
    "4.58": 90,
    "4.59": 90,
    "4.60": 90,
    "4.61": 90,
    "4.62": 90,
    "4.63": 90,
    "4.64": 90,
    "4.65": 90,
    "4.66": 90,
    "4.67": 90,
    "4.68": 90,
    "4.69": 90,
    "4.70": 90,
    "4.71": 90,
    "4.72": 90,
    "4.73": 90,
    "4.74": 90,
    "4.75": 90,
    "4.76": 90,
    "4.77": 90,
    "4.78": 90,
    "4.79": 90,
    "4.80": 90,
    "4.81": 90,
    "4.82": 90,
    "4.83": 90,
    "4.84": 90,
    "4.85": 90,
    "4.86": 90,
    "4.87": 90,
    "4.88": 90,
    "4.89": 90,
    "4.90": 90,
    "4.91": 90,
    "4.92": 90,
    "4.93": 90,
    "4.94": 95

}

export const escalaN1 = {
    "1.00": 1,
    "2.00": 10,
    "3.00": 35,
    "4.00": 65,
    "5.00": 85,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 5,
    "1.58": 5,
    "1.59": 5,
    "1.60": 5,
    "1.61": 5,
    "1.62": 5,
    "1.63": 5,
    "1.64": 5,
    "1.65": 5,
    "1.66": 5,
    "1.67": 5,
    "1.68": 5,
    "1.69": 5,
    "1.70": 5,
    "1.71": 5,
    "1.72": 5,
    "1.73": 5,
    "1.74": 5,
    "1.75": 5,
    "1.76": 5,
    "1.77": 5,
    "1.78": 5,
    "1.79": 5,
    "1.80": 5,
    "1.81": 5,
    "1.82": 5,
    "1.83": 5,
    "1.84": 5,
    "1.85": 5,
    "1.86": 10,
    "1.87": 10,
    "1.88": 10,
    "1.89": 10,
    "1.90": 10,
    "1.91": 10,
    "1.92": 10,
    "1.93": 10,
    "1.94": 10,
    "1.95": 10,
    "1.96": 10,
    "1.97": 10,
    "1.98": 10,
    "1.99": 10,
    "2.01": 10,
    "2.02": 10,
    "2.03": 10,
    "2.04": 10,
    "2.05": 10,
    "2.06": 10,
    "2.07": 10,
    "2.08": 10,
    "2.09": 10,
    "2.10": 10,
    "2.11": 10,
    "2.12": 10,
    "2.13": 10,
    "2.14": 15,
    "2.15": 15,
    "2.16": 15,
    "2.17": 15,
    "2.18": 15,
    "2.19": 15,
    "2.20": 15,
    "2.21": 15,
    "2.22": 15,
    "2.23": 15,
    "2.24": 15,
    "2.25": 15,
    "2.26": 15,
    "2.27": 15,
    "2.28": 15,
    "2.29": 15,
    "2.30": 15,
    "2.31": 15,
    "2.32": 15,
    "2.33": 20,
    "2.34": 20,
    "2.35": 20,
    "2.36": 20,
    "2.37": 20,
    "2.38": 20,
    "2.39": 20,
    "2.40": 20,
    "2.41": 20,
    "2.42": 20,
    "2.43": 20,
    "2.44": 20,
    "2.45": 20,
    "2.46": 20,
    "2.47": 20,
    "2.48": 20,
    "2.49": 20,
    "2.50": 20,
    "2.51": 20,
    "2.52": 20,
    "2.53": 20,
    "2.54": 20,
    "2.55": 20,
    "2.56": 20,
    "2.57": 25,
    "2.58": 25,
    "2.59": 25,
    "2.60": 25,
    "2.61": 25,
    "2.62": 25,
    "2.63": 25,
    "2.64": 25,
    "2.65": 25,
    "2.66": 25,
    "2.67": 25,
    "2.68": 25,
    "2.69": 25,
    "2.70": 25,
    "2.71": 30,
    "2.72": 30,
    "2.73": 30,
    "2.74": 30,
    "2.75": 30,
    "2.76": 30,
    "2.77": 30,
    "2.78": 30,
    "2.79": 30,
    "2.80": 30,
    "2.81": 30,
    "2.82": 30,
    "2.83": 30,
    "2.84": 30,
    "2.85": 30,
    "2.86": 30,
    "2.87": 30,
    "2.88": 30,
    "2.89": 35,
    "2.90": 35,
    "2.91": 35,
    "2.92": 35,
    "2.93": 35,
    "2.94": 35,
    "2.95": 35,
    "2.96": 35,
    "2.97": 35,
    "2.98": 35,
    "2.99": 35,
    "3.01": 35,
    "3.02": 35,
    "3.03": 35,
    "3.04": 35,
    "3.05": 35,
    "3.06": 35,
    "3.07": 35,
    "3.08": 35,
    "3.09": 35,
    "3.10": 35,
    "3.11": 40,
    "3.12": 40,
    "3.13": 40,
    "3.14": 40,
    "3.15": 40,
    "3.16": 40,
    "3.17": 40,
    "3.18": 40,
    "3.19": 40,
    "3.20": 40,
    "3.21": 40,
    "3.22": 40,
    "3.23": 40,
    "3.24": 40,
    "3.25": 40,
    "3.26": 40,
    "3.27": 40,
    "3.28": 40,
    "3.29": 45,
    "3.30": 45,
    "3.31": 45,
    "3.32": 45,
    "3.33": 45,
    "3.34": 45,
    "3.35": 45,
    "3.36": 45,
    "3.37": 45,
    "3.38": 45,
    "3.39": 45,
    "3.40": 45,
    "3.41": 45,
    "3.42": 45,
    "3.43": 45,
    "3.44": 50,
    "3.45": 50,
    "3.46": 50,
    "3.47": 50,
    "3.48": 50,
    "3.49": 50,
    "3.50": 50,
    "3.51": 50,
    "3.52": 50,
    "3.53": 50,
    "3.54": 50,
    "3.55": 50,
    "3.56": 50,
    "3.57": 55,
    "3.58": 55,
    "3.59": 55,
    "3.60": 55,
    "3.61": 55,
    "3.62": 55,
    "3.63": 55,
    "3.64": 55,
    "3.65": 55,
    "3.66": 55,
    "3.67": 55,
    "3.68": 55,
    "3.69": 55,
    "3.70": 55,
    "3.71": 55,
    "3.72": 55,
    "3.73": 55,
    "3.74": 55,
    "3.75": 55,
    "3.76": 55,
    "3.77": 55,
    "3.78": 60,
    "3.79": 60,
    "3.80": 60,
    "3.81": 60,
    "3.82": 60,
    "3.83": 60,
    "3.84": 60,
    "3.85": 60,
    "3.86": 60,
    "3.87": 60,
    "3.88": 60,
    "3.89": 65,
    "3.90": 65,
    "3.91": 65,
    "3.92": 65,
    "3.93": 65,
    "3.94": 65,
    "3.95": 65,
    "3.96": 65,
    "3.97": 65,
    "3.98": 65,
    "3.99": 65,
    "4.01": 65,
    "4.02": 65,
    "4.03": 65,
    "4.04": 65,
    "4.05": 65,
    "4.06": 65,
    "4.07": 65,
    "4.08": 65,
    "4.09": 65,
    "4.10": 65,
    "4.11": 70,
    "4.12": 70,
    "4.13": 70,
    "4.14": 70,
    "4.15": 70,
    "4.16": 70,
    "4.17": 70,
    "4.18": 70,
    "4.19": 70,
    "4.20": 70,
    "4.21": 70,
    "4.22": 70,
    "4.23": 70,
    "4.24": 70,
    "4.25": 70,
    "4.26": 70,
    "4.27": 70,
    "4.28": 70,
    "4.29": 70,
    "4.30": 70,
    "4.31": 70,
    "4.32": 70,
    "4.33": 75,
    "4.34": 75,
    "4.35": 75,
    "4.36": 75,
    "4.37": 75,
    "4.38": 75,
    "4.39": 75,
    "4.40": 75,
    "4.41": 75,
    "4.42": 75,
    "4.43": 75,
    "4.44": 75,
    "4.45": 75,
    "4.46": 75,
    "4.47": 75,
    "4.48": 75,
    "4.49": 75,
    "4.50": 75,
    "4.51": 75,
    "4.52": 75,
    "4.53": 75,
    "4.54": 75,
    "4.55": 75,
    "4.56": 80,
    "4.57": 80,
    "4.58": 80,
    "4.59": 80,
    "4.60": 80,
    "4.61": 80,
    "4.62": 80,
    "4.63": 80,
    "4.64": 80,
    "4.65": 80,
    "4.66": 80,
    "4.67": 80,
    "4.68": 80,
    "4.69": 80,
    "4.70": 80,
    "4.71": 80,
    "4.72": 80,
    "4.73": 80,
    "4.74": 80,
    "4.75": 80,
    "4.76": 80,
    "4.77": 80,
    "4.78": 80,
    "4.79": 80,
    "4.80": 80,
    "4.81": 80,
    "4.82": 80,
    "4.83": 80,
    "4.84": 80,
    "4.85": 80,
    "4.86": 85,
    "4.87": 85,
    "4.88": 85,
    "4.89": 85,
    "4.90": 85,
    "4.91": 85,
    "4.92": 85,
    "4.93": 85,
    "4.94": 85,
    "4.95": 85,
    "4.96": 85,
    "4.97": 85,
    "4.98": 85,
    "4.99": 85,
    "5.01": 85,
    "5.02": 85,
    "5.03": 85,
    "5.04": 85,
    "5.05": 85,
    "5.06": 85,
    "5.07": 85,
    "5.08": 85,
    "5.09": 85,
    "5.10": 85,
    "5.11": 85,
    "5.12": 85,
    "5.13": 85,
    "5.14": 90,
    "5.15": 90,
    "5.16": 90,
    "5.17": 90,
    "5.18": 90,
    "5.19": 90,
    "5.20": 90,
    "5.21": 90,
    "5.22": 90,
    "5.23": 90,
    "5.24": 90,
    "5.25": 90,
    "5.26": 90,
    "5.27": 90,
    "5.28": 90,
    "5.29": 90,
    "5.30": 90,
    "5.31": 90,
    "5.32": 90,
    "5.33": 90,
    "5.34": 90,
    "5.35": 90,
    "5.36": 90,
    "5.37": 90,
    "5.38": 90,
    "5.39": 90,
    "5.40": 90,
    "5.41": 90,
    "5.42": 90,
    "5.43": 90,
    "5.44": 90,
    "5.45": 90,
    "5.46": 90,
    "5.47": 90,
    "5.48": 90,
    "5.49": 90,
    "5.50": 90,
    "5.51": 90,
    "5.52": 90,
    "5.53": 90,
    "5.54": 90,
    "5.55": 90,
    "5.56": 90,
    "5.57": 90,
    "5.58": 90,
    "5.59": 90,
    "5.60": 90,
    "5.61": 90,
    "5.62": 90,
    "5.63": 90,
    "5.64": 90,
    "5.65": 90,
    "5.66": 90,
    "5.67": 95
}

export const escalaN2 = {
    "1.00": 1,
    "2.00": 10,
    "3.00": 35,
    "4.00": 65,
    "5.00": 85,
    "6.00": 100,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 5,
    "1.58": 5,
    "1.59": 5,
    "1.60": 5,
    "1.61": 5,
    "1.62": 5,
    "1.63": 5,
    "1.64": 5,
    "1.65": 5,
    "1.66": 5,
    "1.67": 5,
    "1.68": 5,
    "1.69": 5,
    "1.70": 5,
    "1.71": 5,
    "1.72": 5,
    "1.73": 5,
    "1.74": 5,
    "1.75": 5,
    "1.76": 5,
    "1.77": 5,
    "1.78": 5,
    "1.79": 5,
    "1.80": 5,
    "1.81": 5,
    "1.82": 5,
    "1.83": 5,
    "1.84": 5,
    "1.85": 5,
    "1.86": 10,
    "1.87": 10,
    "1.88": 10,
    "1.89": 10,
    "1.90": 10,
    "1.91": 10,
    "1.92": 10,
    "1.93": 10,
    "1.94": 10,
    "1.95": 10,
    "1.96": 10,
    "1.97": 10,
    "1.98": 10,
    "1.99": 10,
    "2.01": 10,
    "2.02": 10,
    "2.03": 10,
    "2.04": 10,
    "2.05": 10,
    "2.06": 10,
    "2.07": 10,
    "2.08": 10,
    "2.09": 10,
    "2.10": 10,
    "2.11": 10,
    "2.12": 10,
    "2.13": 10,
    "2.14": 15,
    "2.15": 15,
    "2.16": 15,
    "2.17": 15,
    "2.18": 15,
    "2.19": 15,
    "2.20": 15,
    "2.21": 15,
    "2.22": 15,
    "2.23": 15,
    "2.24": 15,
    "2.25": 15,
    "2.26": 15,
    "2.27": 15,
    "2.28": 15,
    "2.29": 15,
    "2.30": 15,
    "2.31": 15,
    "2.32": 15,
    "2.33": 20,
    "2.34": 20,
    "2.35": 20,
    "2.36": 20,
    "2.37": 20,
    "2.38": 20,
    "2.39": 20,
    "2.40": 20,
    "2.41": 20,
    "2.42": 20,
    "2.43": 20,
    "2.44": 20,
    "2.45": 20,
    "2.46": 20,
    "2.47": 20,
    "2.48": 20,
    "2.49": 20,
    "2.50": 20,
    "2.51": 20,
    "2.52": 20,
    "2.53": 20,
    "2.54": 20,
    "2.55": 20,
    "2.56": 20,
    "2.57": 25,
    "2.58": 25,
    "2.59": 25,
    "2.60": 25,
    "2.61": 25,
    "2.62": 25,
    "2.63": 25,
    "2.64": 25,
    "2.65": 25,
    "2.66": 25,
    "2.67": 25,
    "2.68": 25,
    "2.69": 25,
    "2.70": 25,
    "2.71": 30,
    "2.72": 30,
    "2.73": 30,
    "2.74": 30,
    "2.75": 30,
    "2.76": 30,
    "2.77": 30,
    "2.78": 30,
    "2.79": 30,
    "2.80": 30,
    "2.81": 30,
    "2.82": 30,
    "2.83": 30,
    "2.84": 30,
    "2.85": 30,
    "2.86": 30,
    "2.87": 30,
    "2.88": 30,
    "2.89": 35,
    "2.90": 35,
    "2.91": 35,
    "2.92": 35,
    "2.93": 35,
    "2.94": 35,
    "2.95": 35,
    "2.96": 35,
    "2.97": 35,
    "2.98": 35,
    "2.99": 35,
    "3.01": 35,
    "3.02": 35,
    "3.03": 35,
    "3.04": 35,
    "3.05": 35,
    "3.06": 35,
    "3.07": 35,
    "3.08": 35,
    "3.09": 35,
    "3.10": 35,
    "3.11": 40,
    "3.12": 40,
    "3.13": 40,
    "3.14": 40,
    "3.15": 40,
    "3.16": 40,
    "3.17": 40,
    "3.18": 40,
    "3.19": 40,
    "3.20": 40,
    "3.21": 40,
    "3.22": 40,
    "3.23": 40,
    "3.24": 40,
    "3.25": 40,
    "3.26": 40,
    "3.27": 40,
    "3.28": 40,
    "3.29": 45,
    "3.30": 45,
    "3.31": 45,
    "3.32": 45,
    "3.33": 45,
    "3.34": 45,
    "3.35": 45,
    "3.36": 45,
    "3.37": 45,
    "3.38": 45,
    "3.39": 45,
    "3.40": 45,
    "3.41": 45,
    "3.42": 45,
    "3.43": 45,
    "3.44": 50,
    "3.45": 50,
    "3.46": 50,
    "3.47": 50,
    "3.48": 50,
    "3.49": 50,
    "3.50": 50,
    "3.51": 50,
    "3.52": 50,
    "3.53": 50,
    "3.54": 50,
    "3.55": 50,
    "3.56": 50,
    "3.57": 55,
    "3.58": 55,
    "3.59": 55,
    "3.60": 55,
    "3.61": 55,
    "3.62": 55,
    "3.63": 55,
    "3.64": 55,
    "3.65": 55,
    "3.66": 55,
    "3.67": 55,
    "3.68": 55,
    "3.69": 55,
    "3.70": 55,
    "3.71": 55,
    "3.72": 55,
    "3.73": 55,
    "3.74": 55,
    "3.75": 55,
    "3.76": 55,
    "3.77": 55,
    "3.78": 60,
    "3.79": 60,
    "3.80": 60,
    "3.81": 60,
    "3.82": 60,
    "3.83": 60,
    "3.84": 60,
    "3.85": 60,
    "3.86": 60,
    "3.87": 60,
    "3.88": 60,
    "3.89": 65,
    "3.90": 65,
    "3.91": 65,
    "3.92": 65,
    "3.93": 65,
    "3.94": 65,
    "3.95": 65,
    "3.96": 65,
    "3.97": 65,
    "3.98": 65,
    "3.99": 65,
    "4.01": 65,
    "4.02": 65,
    "4.03": 65,
    "4.04": 65,
    "4.05": 65,
    "4.06": 65,
    "4.07": 65,
    "4.08": 65,
    "4.09": 65,
    "4.10": 65,
    "4.11": 70,
    "4.12": 70,
    "4.13": 70,
    "4.14": 70,
    "4.15": 70,
    "4.16": 70,
    "4.17": 70,
    "4.18": 70,
    "4.19": 70,
    "4.20": 70,
    "4.21": 70,
    "4.22": 70,
    "4.23": 70,
    "4.24": 70,
    "4.25": 70,
    "4.26": 70,
    "4.27": 70,
    "4.28": 70,
    "4.29": 70,
    "4.30": 70,
    "4.31": 70,
    "4.32": 70,
    "4.33": 75,
    "4.34": 75,
    "4.35": 75,
    "4.36": 75,
    "4.37": 75,
    "4.38": 75,
    "4.39": 75,
    "4.40": 75,
    "4.41": 75,
    "4.42": 75,
    "4.43": 75,
    "4.44": 75,
    "4.45": 75,
    "4.46": 75,
    "4.47": 75,
    "4.48": 75,
    "4.49": 75,
    "4.50": 75,
    "4.51": 75,
    "4.52": 75,
    "4.53": 75,
    "4.54": 75,
    "4.55": 75,
    "4.56": 80,
    "4.57": 80,
    "4.58": 80,
    "4.59": 80,
    "4.60": 80,
    "4.61": 80,
    "4.62": 80,
    "4.63": 80,
    "4.64": 80,
    "4.65": 80,
    "4.66": 80,
    "4.67": 80,
    "4.68": 80,
    "4.69": 80,
    "4.70": 80,
    "4.71": 80,
    "4.72": 80,
    "4.73": 80,
    "4.74": 80,
    "4.75": 80,
    "4.76": 80,
    "4.77": 80,
    "4.78": 80,
    "4.79": 80,
    "4.80": 80,
    "4.81": 80,
    "4.82": 80,
    "4.83": 80,
    "4.84": 80,
    "4.85": 80,
    "4.86": 85,
    "4.87": 85,
    "4.88": 85,
    "4.89": 85,
    "4.90": 85,
    "4.91": 85,
    "4.92": 85,
    "4.93": 85,
    "4.94": 85,
    "4.95": 85,
    "4.96": 85,
    "4.97": 85,
    "4.98": 85,
    "4.99": 85,
    "5.01": 85,
    "5.02": 85,
    "5.03": 85,
    "5.04": 85,
    "5.05": 85,
    "5.06": 85,
    "5.07": 85,
    "5.08": 85,
    "5.09": 85,
    "5.10": 85,
    "5.11": 85,
    "5.12": 85,
    "5.13": 85,
    "5.14": 90,
    "5.15": 90,
    "5.16": 90,
    "5.17": 90,
    "5.18": 90,
    "5.19": 90,
    "5.20": 90,
    "5.21": 90,
    "5.22": 90,
    "5.23": 90,
    "5.24": 90,
    "5.25": 90,
    "5.26": 90,
    "5.27": 90,
    "5.28": 90,
    "5.29": 90,
    "5.30": 90,
    "5.31": 90,
    "5.32": 90,
    "5.33": 90,
    "5.34": 90,
    "5.35": 90,
    "5.36": 90,
    "5.37": 90,
    "5.38": 90,
    "5.39": 90,
    "5.40": 90,
    "5.41": 90,
    "5.42": 90,
    "5.43": 90,
    "5.44": 90,
    "5.45": 90,
    "5.46": 90,
    "5.47": 90,
    "5.48": 90,
    "5.49": 90,
    "5.50": 90,
    "5.51": 90,
    "5.52": 90,
    "5.53": 90,
    "5.54": 90,
    "5.55": 90,
    "5.56": 90,
    "5.57": 90,
    "5.58": 90,
    "5.59": 90,
    "5.60": 90,
    "5.61": 90,
    "5.62": 90,
    "5.63": 90,
    "5.64": 90,
    "5.65": 90,
    "5.66": 90,
    "5.67": 95,
    "5.68": 100,
    "5.69": 100,
    "5.70": 100,
    "5.71": 100,
    "5.72": 100,
    "5.73": 100,
    "5.74": 100,
    "5.75": 100,
    "5.76": 100,
    "5.77": 100,
    "5.78": 100,
    "5.79": 100,
    "5.80": 100,
    "5.81": 100,
    "5.82": 100,
    "5.83": 100,
    "5.84": 100,
    "5.85": 100,
    "5.86": 100,
    "5.87": 100,
    "5.88": 100,
    "5.89": 100,
    "5.90": 100,
    "5.91": 100,
    "5.92": 100,
    "5.93": 100,
    "5.94": 100,
    "5.95": 100,
    "5.96": 100,
    "5.97": 100,
    "5.98": 100,
    "5.99": 100,
    "6.01": 100,
    "6.02": 100,
    "6.03": 100,
    "6.04": 100,
    "6.05": 100,
    "6.06": 100,
    "6.07": 100,
    "6.08": 100,
    "6.09": 100,
    "6.10": 100,
    "6.11": 100,
    "6.12": 100,
    "6.13": 100,
    "6.14": 100,
    "6.15": 100,
    "6.16": 100,
    "6.17": 100
}

export const escalaN3 = {
    "1.00": 1,
    "2.00": 10,
    "3.00": 40,
    "4.00": 65,
    "5.00": 90,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 5,
    "1.51": 5,
    "1.52": 5,
    "1.53": 5,
    "1.54": 5,
    "1.55": 5,
    "1.56": 5,
    "1.57": 5,
    "1.58": 5,
    "1.59": 5,
    "1.60": 5,
    "1.61": 5,
    "1.62": 5,
    "1.63": 5,
    "1.64": 5,
    "1.65": 5,
    "1.66": 5,
    "1.67": 5,
    "1.68": 5,
    "1.69": 5,
    "1.70": 5,
    "1.71": 5,
    "1.72": 5,
    "1.73": 5,
    "1.74": 5,
    "1.75": 5,
    "1.76": 5,
    "1.77": 5,
    "1.78": 5,
    "1.79": 5,
    "1.80": 5,
    "1.81": 5,
    "1.82": 5,
    "1.83": 10,
    "1.84": 10,
    "1.85": 10,
    "1.86": 10,
    "1.87": 10,
    "1.88": 10,
    "1.89": 10,
    "1.90": 10,
    "1.91": 10,
    "1.92": 10,
    "1.93": 10,
    "1.94": 10,
    "1.95": 10,
    "1.96": 10,
    "1.97": 10,
    "1.98": 10,
    "1.99": 10,
    "2.01": 10,
    "2.02": 10,
    "2.03": 10,
    "2.04": 10,
    "2.05": 10,
    "2.06": 10,
    "2.07": 10,
    "2.08": 10,
    "2.09": 10,
    "2.10": 10,
    "2.11": 10,
    "2.12": 10,
    "2.13": 10,
    "2.14": 10,
    "2.15": 10,
    "2.16": 10,
    "2.17": 15,
    "2.18": 15,
    "2.19": 15,
    "2.20": 15,
    "2.21": 15,
    "2.22": 15,
    "2.23": 15,
    "2.24": 15,
    "2.25": 15,
    "2.26": 15,
    "2.27": 15,
    "2.28": 15,
    "2.29": 15,
    "2.30": 15,
    "2.31": 15,
    "2.32": 15,
    "2.33": 20,
    "2.34": 20,
    "2.35": 20,
    "2.36": 20,
    "2.37": 20,
    "2.38": 20,
    "2.39": 20,
    "2.40": 20,
    "2.41": 20,
    "2.42": 20,
    "2.43": 20,
    "2.44": 20,
    "2.45": 20,
    "2.46": 20,
    "2.47": 20,
    "2.48": 20,
    "2.49": 20,
    "2.50": 25,
    "2.51": 25,
    "2.52": 25,
    "2.53": 25,
    "2.54": 25,
    "2.55": 25,
    "2.56": 25,
    "2.57": 25,
    "2.58": 25,
    "2.59": 25,
    "2.60": 25,
    "2.61": 25,
    "2.62": 25,
    "2.63": 25,
    "2.64": 25,
    "2.65": 25,
    "2.66": 25,
    "2.67": 30,
    "2.68": 30,
    "2.69": 30,
    "2.70": 30,
    "2.71": 30,
    "2.72": 30,
    "2.73": 30,
    "2.74": 30,
    "2.75": 30,
    "2.76": 30,
    "2.77": 30,
    "2.78": 30,
    "2.79": 30,
    "2.80": 30,
    "2.81": 30,
    "2.82": 30,
    "2.83": 35,
    "2.84": 35,
    "2.85": 35,
    "2.86": 35,
    "2.87": 35,
    "2.88": 35,
    "2.89": 35,
    "2.90": 35,
    "2.91": 35,
    "2.92": 35,
    "2.93": 35,
    "2.94": 35,
    "2.95": 35,
    "2.96": 35,
    "2.97": 35,
    "2.98": 35,
    "2.99": 35,
    "3.01": 40,
    "3.02": 40,
    "3.03": 40,
    "3.04": 40,
    "3.05": 40,
    "3.06": 40,
    "3.07": 40,
    "3.08": 40,
    "3.09": 40,
    "3.10": 40,
    "3.11": 40,
    "3.12": 40,
    "3.13": 40,
    "3.14": 40,
    "3.15": 40,
    "3.16": 40,
    "3.17": 40,
    "3.18": 40,
    "3.19": 40,
    "3.20": 45,
    "3.21": 45,
    "3.22": 45,
    "3.23": 45,
    "3.24": 45,
    "3.25": 45,
    "3.26": 45,
    "3.27": 45,
    "3.28": 45,
    "3.29": 45,
    "3.30": 45,
    "3.31": 45,
    "3.32": 45,
    "3.33": 45,
    "3.34": 45,
    "3.35": 45,
    "3.36": 45,
    "3.37": 45,
    "3.38": 45,
    "3.39": 45,
    "3.40": 50,
    "3.41": 50,
    "3.42": 50,
    "3.43": 50,
    "3.44": 50,
    "3.45": 50,
    "3.46": 50,
    "3.47": 50,
    "3.48": 50,
    "3.49": 50,
    "3.50": 50,
    "3.51": 50,
    "3.52": 50,
    "3.53": 50,
    "3.54": 50,
    "3.55": 50,
    "3.56": 50,
    "3.57": 50,
    "3.58": 50,
    "3.59": 50,
    "3.60": 55,
    "3.61": 55,
    "3.62": 55,
    "3.63": 55,
    "3.64": 55,
    "3.65": 55,
    "3.66": 55,
    "3.67": 55,
    "3.68": 55,
    "3.69": 55,
    "3.70": 55,
    "3.71": 55,
    "3.72": 55,
    "3.73": 55,
    "3.74": 55,
    "3.75": 55,
    "3.76": 55,
    "3.77": 55,
    "3.78": 55,
    "3.79": 55,
    "3.80": 60,
    "3.81": 60,
    "3.82": 60,
    "3.83": 60,
    "3.84": 60,
    "3.85": 60,
    "3.86": 60,
    "3.87": 60,
    "3.88": 60,
    "3.89": 60,
    "3.90": 60,
    "3.91": 60,
    "3.92": 60,
    "3.93": 60,
    "3.94": 60,
    "3.95": 60,
    "3.96": 60,
    "3.97": 60,
    "3.98": 60,
    "3.99": 60,
    "4.01": 65,
    "4.02": 65,
    "4.03": 65,
    "4.04": 65,
    "4.05": 65,
    "4.06": 65,
    "4.07": 65,
    "4.08": 65,
    "4.09": 65,
    "4.10": 65,
    "4.11": 65,
    "4.12": 65,
    "4.13": 65,
    "4.14": 65,
    "4.15": 65,
    "4.16": 65,
    "4.17": 70,
    "4.18": 70,
    "4.19": 70,
    "4.20": 70,
    "4.21": 70,
    "4.22": 70,
    "4.23": 70,
    "4.24": 70,
    "4.25": 70,
    "4.26": 70,
    "4.27": 70,
    "4.28": 70,
    "4.29": 70,
    "4.30": 70,
    "4.31": 70,
    "4.32": 70,
    "4.33": 75,
    "4.34": 75,
    "4.35": 75,
    "4.36": 75,
    "4.37": 75,
    "4.38": 75,
    "4.39": 75,
    "4.40": 75,
    "4.41": 75,
    "4.42": 75,
    "4.43": 75,
    "4.44": 75,
    "4.45": 75,
    "4.46": 75,
    "4.47": 75,
    "4.48": 75,
    "4.49": 75,
    "4.50": 75,
    "4.51": 75,
    "4.52": 75,
    "4.53": 75,
    "4.54": 75,
    "4.55": 75,
    "4.56": 75,
    "4.57": 75,
    "4.58": 75,
    "4.59": 75,
    "4.60": 80,
    "4.61": 80,
    "4.62": 80,
    "4.63": 80,
    "4.64": 80,
    "4.65": 80,
    "4.66": 80,
    "4.67": 80,
    "4.68": 80,
    "4.69": 80,
    "4.70": 80,
    "4.71": 80,
    "4.72": 80,
    "4.73": 80,
    "4.74": 80,
    "4.75": 80,
    "4.76": 80,
    "4.77": 80,
    "4.78": 80,
    "4.79": 80,
    "4.80": 80,
    "4.81": 80,
    "4.82": 80,
    "4.83": 85,
    "4.84": 85,
    "4.85": 85,
    "4.86": 85,
    "4.87": 85,
    "4.88": 85,
    "4.89": 85,
    "4.90": 85,
    "4.91": 85,
    "4.92": 85,
    "4.93": 85,
    "4.94": 85,
    "4.95": 85,
    "4.96": 85,
    "4.97": 85,
    "4.98": 85,
    "4.99": 85,
    "5.01": 90,
    "5.02": 90,
    "5.03": 90,
    "5.04": 90,
    "5.05": 90,
    "5.06": 90,
    "5.07": 90,
    "5.08": 90,
    "5.09": 90,
    "5.10": 90,
    "5.11": 90,
    "5.12": 90,
    "5.13": 90,
    "5.14": 90,
    "5.15": 90,
    "5.16": 90,
    "5.17": 90,
    "5.18": 90,
    "5.19": 90,
    "5.20": 90,
    "5.21": 90,
    "5.22": 90,
    "5.23": 90,
    "5.24": 90,
    "5.25": 90,
    "5.26": 90,
    "5.27": 90,
    "5.28": 90,
    "5.29": 90,
    "5.30": 90,
    "5.31": 90,
    "5.32": 90,
    "5.33": 90,
    "5.34": 90,
    "5.35": 90,
    "5.36": 90,
    "5.37": 90,
    "5.38": 90,
    "5.39": 90,
    "5.40": 90,
    "5.41": 90,
    "5.42": 90,
    "5.43": 90,
    "5.44": 90,
    "5.45": 90,
    "5.46": 90,
    "5.47": 90,
    "5.48": 90,
    "5.49": 90,
    "5.50": 95
}

export const escalaN4 = {
    "1.00": 5,
    "2.00": 45,
    "3.00": 75,
    "4.00": 90,
    "1.01": 5,
    "1.02": 5,
    "1.03": 5,
    "1.04": 5,
    "1.05": 5,
    "1.06": 5,
    "1.07": 5,
    "1.08": 5,
    "1.09": 5,
    "1.10": 5,
    "1.11": 5,
    "1.12": 5,
    "1.13": 5,
    "1.14": 10,
    "1.15": 10,
    "1.16": 10,
    "1.17": 10,
    "1.18": 10,
    "1.19": 10,
    "1.20": 10,
    "1.21": 10,
    "1.22": 10,
    "1.23": 10,
    "1.24": 10,
    "1.25": 15,
    "1.26": 15,
    "1.27": 15,
    "1.28": 15,
    "1.29": 15,
    "1.30": 15,
    "1.31": 15,
    "1.32": 15,
    "1.33": 15,
    "1.34": 15,
    "1.35": 15,
    "1.36": 15,
    "1.37": 15,
    "1.38": 20,
    "1.39": 20,
    "1.40": 20,
    "1.41": 20,
    "1.42": 20,
    "1.43": 20,
    "1.44": 20,
    "1.45": 20,
    "1.46": 20,
    "1.47": 20,
    "1.48": 20,
    "1.49": 20,
    "1.50": 25,
    "1.51": 25,
    "1.52": 25,
    "1.53": 25,
    "1.54": 25,
    "1.55": 25,
    "1.56": 25,
    "1.57": 25,
    "1.58": 25,
    "1.59": 25,
    "1.60": 25,
    "1.61": 25,
    "1.62": 25,
    "1.63": 30,
    "1.64": 30,
    "1.65": 30,
    "1.66": 30,
    "1.67": 30,
    "1.68": 30,
    "1.69": 30,
    "1.70": 30,
    "1.71": 30,
    "1.72": 30,
    "1.73": 30,
    "1.74": 30,
    "1.75": 35,
    "1.76": 35,
    "1.77": 35,
    "1.78": 35,
    "1.79": 35,
    "1.80": 35,
    "1.81": 35,
    "1.82": 35,
    "1.83": 40,
    "1.84": 40,
    "1.85": 40,
    "1.86": 40,
    "1.87": 40,
    "1.88": 40,
    "1.89": 40,
    "1.90": 40,
    "1.91": 40,
    "1.92": 40,
    "1.93": 40,
    "1.94": 40,
    "1.95": 40,
    "1.96": 40,
    "1.97": 40,
    "1.98": 40,
    "1.99": 40,
    "2.01": 50,
    "2.02": 50,
    "2.03": 50,
    "2.04": 50,
    "2.05": 50,
    "2.06": 50,
    "2.07": 50,
    "2.08": 50,
    "2.09": 50,
    "2.10": 50,
    "2.11": 50,
    "2.12": 50,
    "2.13": 50,
    "2.14": 55,
    "2.15": 55,
    "2.16": 55,
    "2.17": 55,
    "2.18": 55,
    "2.19": 55,
    "2.20": 55,
    "2.21": 55,
    "2.22": 55,
    "2.23": 55,
    "2.24": 55,
    "2.25": 55,
    "2.26": 55,
    "2.27": 55,
    "2.28": 55,
    "2.29": 60,
    "2.30": 60,
    "2.31": 60,
    "2.32": 60,
    "2.33": 60,
    "2.34": 60,
    "2.35": 60,
    "2.36": 60,
    "2.37": 60,
    "2.38": 60,
    "2.39": 60,
    "2.40": 60,
    "2.41": 60,
    "2.42": 60,
    "2.43": 60,
    "2.44": 60,
    "2.45": 60,
    "2.46": 60,
    "2.47": 60,
    "2.48": 60,
    "2.49": 60,
    "2.50": 65,
    "2.51": 65,
    "2.52": 65,
    "2.53": 65,
    "2.54": 65,
    "2.55": 65,
    "2.56": 65,
    "2.57": 65,
    "2.58": 65,
    "2.59": 65,
    "2.60": 65,
    "2.61": 65,
    "2.62": 65,
    "2.63": 70,
    "2.64": 70,
    "2.65": 70,
    "2.66": 70,
    "2.67": 70,
    "2.68": 70,
    "2.69": 70,
    "2.70": 70,
    "2.71": 70,
    "2.72": 70,
    "2.73": 70,
    "2.74": 70,
    "2.75": 70,
    "2.76": 70,
    "2.77": 70,
    "2.78": 70,
    "2.79": 70,
    "2.80": 70,
    "2.81": 70,
    "2.82": 70,
    "2.83": 70,
    "2.84": 70,
    "2.85": 70,
    "2.86": 70,
    "2.87": 70,
    "2.88": 75,
    "2.89": 75,
    "2.90": 75,
    "2.91": 75,
    "2.92": 75,
    "2.93": 75,
    "2.94": 75,
    "2.95": 75,
    "2.96": 75,
    "2.97": 75,
    "2.98": 75,
    "2.99": 75,
    "3.01": 75,
    "3.02": 75,
    "3.03": 75,
    "3.04": 75,
    "3.05": 75,
    "3.06": 75,
    "3.07": 75,
    "3.08": 75,
    "3.09": 75,
    "3.10": 75,
    "3.11": 75,
    "3.12": 75,
    "3.13": 75,
    "3.14": 75,
    "3.15": 75,
    "3.16": 75,
    "3.17": 80,
    "3.18": 80,
    "3.19": 80,
    "3.20": 80,
    "3.21": 80,
    "3.22": 80,
    "3.23": 80,
    "3.24": 80,
    "3.25": 80,
    "3.26": 80,
    "3.27": 80,
    "3.28": 80,
    "3.29": 80,
    "3.30": 80,
    "3.31": 80,
    "3.32": 80,
    "3.33": 80,
    "3.34": 80,
    "3.35": 80,
    "3.36": 80,
    "3.37": 80,
    "3.38": 80,
    "3.39": 80,
    "3.40": 80,
    "3.41": 80,
    "3.42": 80,
    "3.43": 80,
    "3.44": 80,
    "3.45": 80,
    "3.46": 80,
    "3.47": 80,
    "3.48": 80,
    "3.49": 80,
    "3.50": 85,
    "3.51": 85,
    "3.52": 85,
    "3.53": 85,
    "3.54": 85,
    "3.55": 85,
    "3.56": 85,
    "3.57": 85,
    "3.58": 85,
    "3.59": 85,
    "3.60": 85,
    "3.61": 85,
    "3.62": 85,
    "3.63": 85,
    "3.64": 85,
    "3.65": 85,
    "3.66": 85,
    "3.67": 85,
    "3.68": 85,
    "3.69": 85,
    "3.70": 85,
    "3.71": 85,
    "3.72": 85,
    "3.73": 85,
    "3.74": 85,
    "3.75": 85,
    "3.76": 85,
    "3.77": 85,
    "3.78": 85,
    "3.79": 85,
    "3.80": 85,
    "3.81": 85,
    "3.82": 85,
    "3.83": 85,
    "3.84": 85,
    "3.85": 85,
    "3.86": 85,
    "3.87": 85,
    "3.88": 90,
    "3.89": 90,
    "3.90": 90,
    "3.91": 90,
    "3.92": 90,
    "3.93": 90,
    "3.94": 90,
    "3.95": 90,
    "3.96": 90,
    "3.97": 90,
    "3.98": 90,
    "3.99": 90,
    "4.01": 90,
    "4.02": 90,
    "4.03": 90,
    "4.04": 90,
    "4.05": 90,
    "4.06": 90,
    "4.07": 90,
    "4.08": 90,
    "4.09": 90,
    "4.10": 90,
    "4.11": 90,
    "4.12": 90,
    "4.13": 90,
    "4.14": 90,
    "4.15": 90,
    "4.16": 90,
    "4.17": 90,
    "4.18": 90,
    "4.19": 90,
    "4.20": 90,
    "4.21": 90,
    "4.22": 90,
    "4.23": 90,
    "4.24": 90,
    "4.25": 90,
    "4.26": 90,
    "4.27": 90,
    "4.28": 90,
    "4.29": 90,
    "4.30": 90,
    "4.31": 90,
    "4.32": 90,
    "4.33": 90,
    "4.34": 90,
    "4.35": 90,
    "4.36": 90,
    "4.37": 90,
    "4.38": 90,
    "4.39": 90,
    "4.40": 90,
    "4.41": 90,
    "4.42": 90,
    "4.43": 90,
    "4.44": 90,
    "4.45": 90,
    "4.46": 90,
    "4.47": 90,
    "4.48": 90,
    "4.49": 90,
    "4.50": 90,
    "4.51": 90,
    "4.52": 90,
    "4.53": 90,
    "4.54": 90,
    "4.55": 90,
    "4.56": 90,
    "4.57": 90,
    "4.58": 90,
    "4.59": 90,
    "4.60": 90,
    "4.61": 90,
    "4.62": 90,
    "4.63": 95
}
export const escalaExtroversao = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 5,
    "4.00": 30,
    "5.00": 75,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 5,
    "2.88": 5,
    "2.89": 5,
    "2.90": 5,
    "2.91": 5,
    "2.92": 5,
    "2.93": 5,
    "2.94": 5,
    "2.95": 5,
    "2.96": 5,
    "2.97": 5,
    "2.98": 5,
    "2.99": 5,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 10,
    "3.20": 10,
    "3.21": 10,
    "3.22": 10,
    "3.23": 10,
    "3.24": 10,
    "3.25": 10,
    "3.26": 10,
    "3.27": 10,
    "3.28": 10,
    "3.29": 10,
    "3.30": 10,
    "3.31": 10,
    "3.32": 10,
    "3.33": 10,
    "3.34": 10,
    "3.35": 10,
    "3.36": 10,
    "3.37": 10,
    "3.38": 10,
    "3.39": 10,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 15,
    "3.45": 15,
    "3.46": 15,
    "3.47": 15,
    "3.48": 15,
    "3.49": 15,
    "3.50": 15,
    "3.51": 15,
    "3.52": 15,
    "3.53": 15,
    "3.54": 15,
    "3.55": 15,
    "3.56": 15,
    "3.57": 15,
    "3.58": 15,
    "3.59": 15,
    "3.60": 15,
    "3.61": 15,
    "3.62": 20,
    "3.63": 20,
    "3.64": 20,
    "3.65": 20,
    "3.66": 20,
    "3.67": 20,
    "3.68": 20,
    "3.69": 20,
    "3.70": 20,
    "3.71": 20,
    "3.72": 20,
    "3.73": 20,
    "3.74": 20,
    "3.75": 20,
    "3.76": 20,
    "3.77": 25,
    "3.78": 25,
    "3.79": 25,
    "3.80": 25,
    "3.81": 25,
    "3.82": 25,
    "3.83": 25,
    "3.84": 25,
    "3.85": 25,
    "3.86": 25,
    "3.87": 25,
    "3.88": 25,
    "3.89": 30,
    "3.90": 30,
    "3.91": 30,
    "3.92": 30,
    "3.93": 30,
    "3.94": 30,
    "3.95": 30,
    "3.96": 30,
    "3.97": 30,
    "3.98": 30,
    "3.99": 30,
    "4.01": 30,
    "4.02": 35,
    "4.03": 35,
    "4.04": 35,
    "4.05": 35,
    "4.06": 35,
    "4.07": 35,
    "4.08": 35,
    "4.09": 35,
    "4.10": 35,
    "4.11": 35,
    "4.12": 35,
    "4.13": 35,
    "4.14": 35,
    "4.15": 40,
    "4.16": 40,
    "4.17": 40,
    "4.18": 40,
    "4.19": 40,
    "4.20": 40,
    "4.21": 40,
    "4.22": 40,
    "4.23": 40,
    "4.24": 40,
    "4.25": 45,
    "4.26": 45,
    "4.27": 45,
    "4.28": 45,
    "4.29": 45,
    "4.30": 45,
    "4.31": 45,
    "4.32": 45,
    "4.33": 45,
    "4.34": 45,
    "4.35": 45,
    "4.36": 45,
    "4.37": 45,
    "4.38": 50,
    "4.39": 50,
    "4.40": 50,
    "4.41": 50,
    "4.42": 50,
    "4.43": 50,
    "4.44": 50,
    "4.45": 50,
    "4.46": 50,
    "4.47": 50,
    "4.48": 55,
    "4.49": 55,
    "4.50": 55,
    "4.51": 55,
    "4.52": 55,
    "4.53": 55,
    "4.54": 55,
    "4.55": 55,
    "4.56": 55,
    "4.57": 55,
    "4.58": 60,
    "4.59": 60,
    "4.60": 60,
    "4.61": 60,
    "4.62": 60,
    "4.63": 60,
    "4.64": 60,
    "4.65": 60,
    "4.66": 60,
    "4.67": 60,
    "4.68": 65,
    "4.69": 65,
    "4.70": 65,
    "4.71": 65,
    "4.72": 65,
    "4.73": 65,
    "4.74": 65,
    "4.75": 65,
    "4.76": 65,
    "4.77": 65,
    "4.78": 65,
    "4.79": 65,
    "4.80": 70,
    "4.81": 70,
    "4.82": 70,
    "4.83": 70,
    "4.84": 70,
    "4.85": 70,
    "4.86": 70,
    "4.87": 70,
    "4.88": 70,
    "4.89": 70,
    "4.90": 70,
    "4.91": 70,
    "4.92": 70,
    "4.93": 75,
    "4.94": 75,
    "4.95": 75,
    "4.96": 75,
    "4.97": 75,
    "4.98": 75,
    "4.99": 75,
    "5.01": 75,
    "5.02": 75,
    "5.03": 75,
    "5.04": 75,
    "5.05": 75,
    "5.06": 75,
    "5.07": 80,
    "5.08": 80,
    "5.09": 80,
    "5.10": 80,
    "5.11": 80,
    "5.12": 80,
    "5.13": 80,
    "5.14": 80,
    "5.15": 80,
    "5.16": 80,
    "5.17": 80,
    "5.18": 80,
    "5.19": 80,
    "5.20": 80,
    "5.21": 80,
    "5.22": 80,
    "5.23": 80,
    "5.24": 85,
    "5.25": 85,
    "5.26": 85,
    "5.27": 85,
    "5.28": 85,
    "5.29": 85,
    "5.30": 85,
    "5.31": 85,
    "5.32": 85,
    "5.33": 85,
    "5.34": 85,
    "5.35": 85,
    "5.36": 85,
    "5.37": 85,
    "5.38": 85,
    "5.39": 85,
    "5.40": 85,
    "5.41": 85,
    "5.42": 85,
    "5.43": 85,
    "5.44": 90,
    "5.45": 90,
    "5.46": 90,
    "5.47": 90,
    "5.48": 90,
    "5.49": 90,
    "5.50": 90,
    "5.51": 90,
    "5.52": 90,
    "5.53": 90,
    "5.54": 90,
    "5.55": 90,
    "5.56": 90,
    "5.57": 90,
    "5.58": 90,
    "5.59": 90,
    "5.60": 90,
    "5.61": 90,
    "5.62": 90,
    "5.63": 90,
    "5.64": 90,
    "5.65": 90,
    "5.66": 90,
    "5.67": 90,
    "5.68": 90,
    "5.69": 90,
    "5.70": 90,
    "5.71": 90,
    "5.72": 95
}
export const escalaE1 = {
    "1.00": 1,
    "2.00": 5,
    "3.00": 15,
    "4.00": 40,
    "5.00": 70,
    "6.00": 90,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 5,
    "2.02": 5,
    "2.03": 5,
    "2.04": 5,
    "2.05": 5,
    "2.06": 5,
    "2.07": 5,
    "2.08": 5,
    "2.09": 5,
    "2.10": 5,
    "2.11": 5,
    "2.12": 5,
    "2.13": 5,
    "2.14": 5,
    "2.15": 5,
    "2.16": 5,
    "2.17": 5,
    "2.18": 5,
    "2.19": 5,
    "2.20": 5,
    "2.21": 5,
    "2.22": 5,
    "2.23": 5,
    "2.24": 5,
    "2.25": 5,
    "2.26": 5,
    "2.27": 5,
    "2.28": 5,
    "2.29": 5,
    "2.30": 5,
    "2.31": 5,
    "2.32": 5,
    "2.33": 5,
    "2.34": 5,
    "2.35": 5,
    "2.36": 5,
    "2.37": 5,
    "2.38": 5,
    "2.39": 5,
    "2.40": 5,
    "2.41": 5,
    "2.42": 5,
    "2.43": 5,
    "2.44": 5,
    "2.45": 5,
    "2.46": 5,
    "2.47": 5,
    "2.48": 5,
    "2.49": 5,
    "2.50": 10,
    "2.51": 10,
    "2.52": 10,
    "2.53": 10,
    "2.54": 10,
    "2.55": 10,
    "2.56": 10,
    "2.57": 10,
    "2.58": 10,
    "2.59": 10,
    "2.60": 10,
    "2.61": 10,
    "2.62": 10,
    "2.63": 10,
    "2.64": 10,
    "2.65": 10,
    "2.66": 10,
    "2.67": 10,
    "2.68": 10,
    "2.69": 10,
    "2.70": 10,
    "2.71": 10,
    "2.72": 10,
    "2.73": 10,
    "2.74": 10,
    "2.75": 10,
    "2.76": 10,
    "2.77": 10,
    "2.78": 10,
    "2.79": 10,
    "2.80": 10,
    "2.81": 10,
    "2.82": 10,
    "2.83": 10,
    "2.84": 10,
    "2.85": 10,
    "2.86": 10,
    "2.87": 10,
    "2.88": 10,
    "2.89": 10,
    "2.90": 10,
    "2.91": 10,
    "2.92": 10,
    "2.93": 10,
    "2.94": 10,
    "2.95": 10,
    "2.96": 10,
    "2.97": 10,
    "2.98": 10,
    "2.99": 10,
    "3.01": 15,
    "3.02": 15,
    "3.03": 15,
    "3.04": 15,
    "3.05": 15,
    "3.06": 15,
    "3.07": 15,
    "3.08": 15,
    "3.09": 15,
    "3.10": 15,
    "3.11": 15,
    "3.12": 15,
    "3.13": 15,
    "3.14": 15,
    "3.15": 15,
    "3.16": 15,
    "3.17": 20,
    "3.18": 20,
    "3.19": 20,
    "3.20": 20,
    "3.21": 20,
    "3.22": 20,
    "3.23": 20,
    "3.24": 20,
    "3.25": 20,
    "3.26": 20,
    "3.27": 20,
    "3.28": 20,
    "3.29": 20,
    "3.30": 20,
    "3.31": 20,
    "3.32": 20,
    "3.33": 20,
    "3.34": 20,
    "3.35": 20,
    "3.36": 20,
    "3.37": 20,
    "3.38": 20,
    "3.39": 20,
    "3.40": 20,
    "3.41": 20,
    "3.42": 20,
    "3.43": 20,
    "3.44": 20,
    "3.45": 20,
    "3.46": 20,
    "3.47": 20,
    "3.48": 20,
    "3.49": 20,
    "3.50": 25,
    "3.51": 25,
    "3.52": 25,
    "3.53": 25,
    "3.54": 25,
    "3.55": 25,
    "3.56": 25,
    "3.57": 25,
    "3.58": 25,
    "3.59": 25,
    "3.60": 25,
    "3.61": 25,
    "3.62": 25,
    "3.63": 25,
    "3.64": 25,
    "3.65": 25,
    "3.66": 25,
    "3.67": 30,
    "3.68": 30,
    "3.69": 30,
    "3.70": 30,
    "3.71": 30,
    "3.72": 30,
    "3.73": 30,
    "3.74": 30,
    "3.75": 30,
    "3.76": 30,
    "3.77": 30,
    "3.78": 30,
    "3.79": 30,
    "3.80": 30,
    "3.81": 30,
    "3.82": 30,
    "3.83": 35,
    "3.84": 35,
    "3.85": 35,
    "3.86": 35,
    "3.87": 35,
    "3.88": 35,
    "3.89": 35,
    "3.90": 35,
    "3.91": 35,
    "3.92": 35,
    "3.93": 35,
    "3.94": 35,
    "3.95": 35,
    "3.96": 35,
    "3.97": 35,
    "3.98": 35,
    "3.99": 35,
    "4.01": 40,
    "4.02": 40,
    "4.03": 40,
    "4.04": 40,
    "4.05": 40,
    "4.06": 40,
    "4.07": 40,
    "4.08": 40,
    "4.09": 40,
    "4.10": 40,
    "4.11": 40,
    "4.12": 40,
    "4.13": 40,
    "4.14": 40,
    "4.15": 40,
    "4.16": 40,
    "4.17": 45,
    "4.18": 45,
    "4.19": 45,
    "4.20": 45,
    "4.21": 45,
    "4.22": 45,
    "4.23": 45,
    "4.24": 45,
    "4.25": 45,
    "4.26": 45,
    "4.27": 45,
    "4.28": 45,
    "4.29": 45,
    "4.30": 45,
    "4.31": 45,
    "4.32": 45,
    "4.33": 50,
    "4.34": 50,
    "4.35": 50,
    "4.36": 50,
    "4.37": 50,
    "4.38": 50,
    "4.39": 50,
    "4.40": 50,
    "4.41": 50,
    "4.42": 50,
    "4.43": 50,
    "4.44": 50,
    "4.45": 50,
    "4.46": 50,
    "4.47": 50,
    "4.48": 50,
    "4.49": 50,
    "4.50": 55,
    "4.51": 55,
    "4.52": 55,
    "4.53": 55,
    "4.54": 55,
    "4.55": 55,
    "4.56": 55,
    "4.57": 55,
    "4.58": 55,
    "4.59": 55,
    "4.60": 55,
    "4.61": 55,
    "4.62": 55,
    "4.63": 55,
    "4.64": 55,
    "4.65": 55,
    "4.66": 55,
    "4.67": 60,
    "4.68": 60,
    "4.69": 60,
    "4.70": 60,
    "4.71": 60,
    "4.72": 60,
    "4.73": 60,
    "4.74": 60,
    "4.75": 60,
    "4.76": 60,
    "4.77": 60,
    "4.78": 60,
    "4.79": 60,
    "4.80": 60,
    "4.81": 60,
    "4.82": 60,
    "4.83": 65,
    "4.84": 65,
    "4.85": 65,
    "4.86": 65,
    "4.87": 65,
    "4.88": 65,
    "4.89": 65,
    "4.90": 65,
    "4.91": 65,
    "4.92": 65,
    "4.93": 65,
    "4.94": 65,
    "4.95": 65,
    "4.96": 65,
    "4.97": 65,
    "4.98": 65,
    "4.99": 65,
    "5.01": 70,
    "5.02": 70,
    "5.03": 70,
    "5.04": 70,
    "5.05": 70,
    "5.06": 70,
    "5.07": 70,
    "5.08": 70,
    "5.09": 70,
    "5.10": 70,
    "5.11": 70,
    "5.12": 70,
    "5.13": 70,
    "5.14": 70,
    "5.15": 70,
    "5.16": 70,
    "5.17": 75,
    "5.18": 75,
    "5.19": 75,
    "5.20": 75,
    "5.21": 75,
    "5.22": 75,
    "5.23": 75,
    "5.24": 75,
    "5.25": 75,
    "5.26": 75,
    "5.27": 75,
    "5.28": 75,
    "5.29": 75,
    "5.30": 75,
    "5.31": 75,
    "5.32": 75,
    "5.33": 80,
    "5.34": 80,
    "5.35": 80,
    "5.36": 80,
    "5.37": 80,
    "5.38": 80,
    "5.39": 80,
    "5.40": 80,
    "5.41": 80,
    "5.42": 80,
    "5.43": 80,
    "5.44": 80,
    "5.45": 80,
    "5.46": 80,
    "5.47": 80,
    "5.48": 80,
    "5.49": 80,
    "5.50": 80,
    "5.51": 80,
    "5.52": 80,
    "5.53": 80,
    "5.54": 80,
    "5.55": 80,
    "5.56": 80,
    "5.57": 80,
    "5.58": 80,
    "5.59": 80,
    "5.60": 80,
    "5.61": 80,
    "5.62": 80,
    "5.63": 80,
    "5.64": 80,
    "5.65": 80,
    "5.66": 80,
    "5.67": 85,
    "5.68": 85,
    "5.69": 85,
    "5.70": 85,
    "5.71": 85,
    "5.72": 85,
    "5.73": 85,
    "5.74": 85,
    "5.75": 85,
    "5.76": 85,
    "5.77": 85,
    "5.78": 85,
    "5.79": 85,
    "5.80": 85,
    "5.81": 85,
    "5.82": 85,
    "5.83": 85,
    "5.84": 85,
    "5.85": 85,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 85,
    "5.96": 85,
    "5.97": 85,
    "5.98": 85,
    "5.99": 85,
    "6.01": 90,
    "6.02": 90,
    "6.03": 90,
    "6.04": 90,
    "6.05": 90,
    "6.06": 90,
    "6.07": 90,
    "6.08": 90,
    "6.09": 90,
    "6.10": 90,
    "6.11": 90,
    "6.12": 90,
    "6.13": 90,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 90,
    "6.18": 90,
    "6.19": 90,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 90,
    "6.26": 90,
    "6.27": 90,
    "6.28": 90,
    "6.29": 90,
    "6.30": 90,
    "6.31": 90,
    "6.32": 90,
    "6.33": 95
}
export const escalaE2 = {
    "1.00": 1,
    "2.00": 5,
    "3.00": 25,
    "4.00": 65,
    "5.00": 85,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 5,
    "2.02": 5,
    "2.03": 5,
    "2.04": 5,
    "2.05": 5,
    "2.06": 5,
    "2.07": 5,
    "2.08": 5,
    "2.09": 5,
    "2.10": 5,
    "2.11": 5,
    "2.12": 5,
    "2.13": 5,
    "2.14": 5,
    "2.15": 5,
    "2.16": 5,
    "2.17": 5,
    "2.18": 5,
    "2.19": 5,
    "2.20": 5,
    "2.21": 5,
    "2.22": 5,
    "2.23": 5,
    "2.24": 5,
    "2.25": 5,
    "2.26": 5,
    "2.27": 5,
    "2.28": 5,
    "2.29": 10,
    "2.30": 10,
    "2.31": 10,
    "2.32": 10,
    "2.33": 10,
    "2.34": 10,
    "2.35": 10,
    "2.36": 10,
    "2.37": 10,
    "2.38": 10,
    "2.39": 10,
    "2.40": 10,
    "2.41": 10,
    "2.42": 10,
    "2.43": 10,
    "2.44": 10,
    "2.45": 10,
    "2.46": 10,
    "2.47": 10,
    "2.48": 10,
    "2.49": 10,
    "2.50": 10,
    "2.51": 10,
    "2.52": 10,
    "2.53": 10,
    "2.54": 10,
    "2.55": 10,
    "2.56": 10,
    "2.57": 15,
    "2.58": 15,
    "2.59": 15,
    "2.60": 15,
    "2.61": 15,
    "2.62": 15,
    "2.63": 15,
    "2.64": 15,
    "2.65": 15,
    "2.66": 15,
    "2.67": 15,
    "2.68": 15,
    "2.69": 15,
    "2.70": 15,
    "2.71": 20,
    "2.72": 20,
    "2.73": 20,
    "2.74": 20,
    "2.75": 20,
    "2.76": 20,
    "2.77": 20,
    "2.78": 20,
    "2.79": 20,
    "2.80": 20,
    "2.81": 20,
    "2.82": 20,
    "2.83": 20,
    "2.84": 20,
    "2.85": 20,
    "2.86": 25,
    "2.87": 25,
    "2.88": 25,
    "2.89": 25,
    "2.90": 25,
    "2.91": 25,
    "2.92": 25,
    "2.93": 25,
    "2.94": 25,
    "2.95": 25,
    "2.96": 25,
    "2.97": 25,
    "2.98": 25,
    "2.99": 25,
    "3.01": 25,
    "3.02": 25,
    "3.03": 25,
    "3.04": 25,
    "3.05": 25,
    "3.06": 25,
    "3.07": 25,
    "3.08": 25,
    "3.09": 30,
    "3.10": 30,
    "3.11": 30,
    "3.12": 30,
    "3.13": 30,
    "3.14": 30,
    "3.15": 30,
    "3.16": 30,
    "3.17": 35,
    "3.18": 35,
    "3.19": 35,
    "3.20": 35,
    "3.21": 35,
    "3.22": 35,
    "3.23": 35,
    "3.24": 35,
    "3.25": 35,
    "3.26": 35,
    "3.27": 35,
    "3.28": 35,
    "3.29": 35,
    "3.30": 35,
    "3.31": 35,
    "3.32": 35,
    "3.33": 40,
    "3.34": 40,
    "3.35": 40,
    "3.36": 40,
    "3.37": 40,
    "3.38": 40,
    "3.39": 40,
    "3.40": 40,
    "3.41": 40,
    "3.42": 40,
    "3.43": 45,
    "3.44": 45,
    "3.45": 45,
    "3.46": 45,
    "3.47": 45,
    "3.48": 45,
    "3.49": 45,
    "3.50": 45,
    "3.51": 45,
    "3.52": 45,
    "3.53": 45,
    "3.54": 45,
    "3.55": 45,
    "3.56": 45,
    "3.57": 50,
    "3.58": 50,
    "3.59": 50,
    "3.60": 50,
    "3.61": 50,
    "3.62": 50,
    "3.63": 50,
    "3.64": 50,
    "3.65": 50,
    "3.66": 50,
    "3.67": 50,
    "3.68": 50,
    "3.69": 50,
    "3.70": 50,
    "3.71": 55,
    "3.72": 55,
    "3.73": 55,
    "3.74": 55,
    "3.75": 55,
    "3.76": 55,
    "3.77": 55,
    "3.78": 55,
    "3.79": 55,
    "3.80": 55,
    "3.81": 55,
    "3.82": 55,
    "3.83": 55,
    "3.84": 55,
    "3.85": 55,
    "3.86": 60,
    "3.87": 60,
    "3.88": 60,
    "3.89": 60,
    "3.90": 60,
    "3.91": 60,
    "3.92": 60,
    "3.93": 60,
    "3.94": 60,
    "3.95": 60,
    "3.96": 60,
    "3.97": 60,
    "3.98": 60,
    "3.99": 60,
    "4.01": 65,
    "4.02": 65,
    "4.03": 65,
    "4.04": 65,
    "4.05": 65,
    "4.06": 65,
    "4.07": 65,
    "4.08": 65,
    "4.09": 65,
    "4.10": 65,
    "4.11": 65,
    "4.12": 65,
    "4.13": 65,
    "4.14": 70,
    "4.15": 70,
    "4.16": 70,
    "4.17": 70,
    "4.18": 70,
    "4.19": 70,
    "4.20": 70,
    "4.21": 70,
    "4.22": 70,
    "4.23": 70,
    "4.24": 70,
    "4.25": 70,
    "4.26": 70,
    "4.27": 70,
    "4.28": 70,
    "4.29": 70,
    "4.30": 70,
    "4.31": 70,
    "4.32": 70,
    "4.33": 70,
    "4.34": 70,
    "4.35": 70,
    "4.36": 70,
    "4.37": 70,
    "4.38": 70,
    "4.39": 70,
    "4.40": 70,
    "4.41": 70,
    "4.42": 70,
    "4.43": 75,
    "4.44": 75,
    "4.45": 75,
    "4.46": 75,
    "4.47": 75,
    "4.48": 75,
    "4.49": 75,
    "4.50": 75,
    "4.51": 75,
    "4.52": 75,
    "4.53": 75,
    "4.54": 75,
    "4.55": 75,
    "4.56": 75,
    "4.57": 80,
    "4.58": 80,
    "4.59": 80,
    "4.60": 80,
    "4.61": 80,
    "4.62": 80,
    "4.63": 80,
    "4.64": 80,
    "4.65": 80,
    "4.66": 80,
    "4.67": 80,
    "4.68": 80,
    "4.69": 80,
    "4.70": 80,
    "4.71": 80,
    "4.72": 80,
    "4.73": 80,
    "4.74": 80,
    "4.75": 80,
    "4.76": 80,
    "4.77": 80,
    "4.78": 80,
    "4.79": 80,
    "4.80": 80,
    "4.81": 80,
    "4.82": 80,
    "4.83": 85,
    "4.84": 85,
    "4.85": 85,
    "4.86": 85,
    "4.87": 85,
    "4.88": 85,
    "4.89": 85,
    "4.90": 85,
    "4.91": 85,
    "4.92": 85,
    "4.93": 85,
    "4.94": 85,
    "4.95": 85,
    "4.96": 85,
    "4.97": 85,
    "4.98": 85,
    "4.99": 85,
    "5.01": 85,
    "5.02": 85,
    "5.03": 85,
    "5.04": 85,
    "5.05": 85,
    "5.06": 85,
    "5.07": 85,
    "5.08": 85,
    "5.09": 85,
    "5.10": 85,
    "5.11": 85,
    "5.12": 85,
    "5.13": 85,
    "5.14": 90,
    "5.15": 90,
    "5.16": 90,
    "5.17": 90,
    "5.18": 90,
    "5.19": 90,
    "5.20": 90,
    "5.21": 90,
    "5.22": 90,
    "5.23": 90,
    "5.24": 90,
    "5.25": 90,
    "5.26": 90,
    "5.27": 90,
    "5.28": 90,
    "5.29": 90,
    "5.30": 90,
    "5.31": 90,
    "5.32": 90,
    "5.33": 90,
    "5.34": 90,
    "5.35": 90,
    "5.36": 90,
    "5.37": 90,
    "5.38": 90,
    "5.39": 90,
    "5.40": 90,
    "5.41": 90,
    "5.42": 90,
    "5.43": 90,
    "5.44": 90,
    "5.45": 90,
    "5.46": 90,
    "5.47": 90,
    "5.48": 90,
    "5.49": 90,
    "5.50": 90,
    "5.51": 90,
    "5.52": 90,
    "5.53": 90,
    "5.54": 90,
    "5.55": 90,
    "5.56": 90,
    "5.57": 95
}
export const escalaE3 = {
    "1.00": 1,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.00": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.00": 5,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 5,
    "3.26": 5,
    "3.27": 5,
    "3.28": 5,
    "3.29": 5,
    "3.30": 5,
    "3.31": 5,
    "3.32": 5,
    "3.33": 5,
    "3.34": 5,
    "3.35": 5,
    "3.36": 5,
    "3.37": 5,
    "3.38": 5,
    "3.39": 5,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 10,
    "3.45": 10,
    "3.46": 10,
    "3.47": 10,
    "3.48": 10,
    "3.49": 10,
    "3.50": 10,
    "3.51": 10,
    "3.52": 10,
    "3.53": 10,
    "3.54": 10,
    "3.55": 10,
    "3.56": 10,
    "3.57": 10,
    "3.58": 10,
    "3.59": 10,
    "3.60": 10,
    "3.61": 10,
    "3.62": 10,
    "3.63": 10,
    "3.64": 10,
    "3.65": 10,
    "3.66": 10,
    "3.67": 10,
    "3.68": 10,
    "3.69": 10,
    "3.70": 10,
    "3.71": 10,
    "3.72": 10,
    "3.73": 10,
    "3.74": 10,
    "3.75": 10,
    "3.76": 10,
    "3.77": 10,
    "3.78": 10,
    "3.79": 10,
    "3.80": 15,
    "3.81": 15,
    "3.82": 15,
    "3.83": 15,
    "3.84": 15,
    "3.85": 15,
    "3.86": 15,
    "3.87": 15,
    "3.88": 15,
    "3.89": 15,
    "3.90": 15,
    "3.91": 15,
    "3.92": 15,
    "3.93": 15,
    "3.94": 15,
    "3.95": 15,
    "3.96": 15,
    "3.97": 15,
    "3.98": 15,
    "3.99": 15,
    "4.00": 20,
    "4.01": 20,
    "4.02": 20,
    "4.03": 20,
    "4.04": 20,
    "4.05": 20,
    "4.06": 20,
    "4.07": 20,
    "4.08": 20,
    "4.09": 20,
    "4.10": 20,
    "4.11": 20,
    "4.12": 20,
    "4.13": 20,
    "4.14": 20,
    "4.15": 20,
    "4.16": 20,
    "4.17": 20,
    "4.18": 20,
    "4.19": 20,
    "4.20": 25,
    "4.21": 30,
    "4.22": 30,
    "4.23": 30,
    "4.24": 30,
    "4.25": 30,
    "4.26": 30,
    "4.27": 30,
    "4.28": 30,
    "4.29": 30,
    "4.30": 30,
    "4.31": 30,
    "4.32": 30,
    "4.33": 30,
    "4.34": 30,
    "4.35": 30,
    "4.36": 30,
    "4.37": 30,
    "4.38": 30,
    "4.39": 30,
    "4.40": 35,
    "4.41": 35,
    "4.42": 35,
    "4.43": 35,
    "4.44": 35,
    "4.45": 35,
    "4.46": 35,
    "4.47": 35,
    "4.48": 35,
    "4.49": 35,
    "4.50": 35,
    "4.51": 35,
    "4.52": 35,
    "4.53": 35,
    "4.54": 35,
    "4.55": 35,
    "4.56": 35,
    "4.57": 35,
    "4.58": 35,
    "4.59": 35,
    "4.60": 40,
    "4.61": 45,
    "4.62": 45,
    "4.63": 45,
    "4.64": 45,
    "4.65": 45,
    "4.66": 45,
    "4.67": 45,
    "4.68": 45,
    "4.69": 45,
    "4.70": 45,
    "4.71": 45,
    "4.72": 45,
    "4.73": 45,
    "4.74": 45,
    "4.75": 45,
    "4.76": 45,
    "4.77": 45,
    "4.78": 45,
    "4.79": 45,
    "4.80": 50,
    "4.81": 50,
    "4.82": 50,
    "4.83": 50,
    "4.84": 50,
    "4.85": 50,
    "4.86": 50,
    "4.87": 50,
    "4.88": 50,
    "4.89": 50,
    "4.90": 50,
    "4.91": 50,
    "4.92": 50,
    "4.93": 50,
    "4.94": 50,
    "4.95": 50,
    "4.96": 50,
    "4.97": 50,
    "4.98": 50,
    "4.99": 50,
    "5.00": 55,
    "5.01": 60,
    "5.02": 60,
    "5.03": 60,
    "5.04": 60,
    "5.05": 60,
    "5.06": 60,
    "5.07": 60,
    "5.08": 60,
    "5.09": 60,
    "5.10": 60,
    "5.11": 60,
    "5.12": 60,
    "5.13": 60,
    "5.14": 60,
    "5.15": 60,
    "5.16": 60,
    "5.17": 60,
    "5.18": 60,
    "5.19": 60,
    "5.20": 65,
    "5.21": 65,
    "5.22": 65,
    "5.23": 65,
    "5.24": 65,
    "5.25": 65,
    "5.26": 65,
    "5.27": 65,
    "5.28": 65,
    "5.29": 65,
    "5.30": 65,
    "5.31": 65,
    "5.32": 65,
    "5.33": 65,
    "5.34": 65,
    "5.35": 65,
    "5.36": 65,
    "5.37": 65,
    "5.38": 65,
    "5.39": 65,
    "5.40": 70,
    "5.41": 70,
    "5.42": 70,
    "5.43": 70,
    "5.44": 70,
    "5.45": 70,
    "5.46": 70,
    "5.47": 70,
    "5.48": 70,
    "5.49": 70,
    "5.50": 70,
    "5.51": 70,
    "5.52": 70,
    "5.53": 70,
    "5.54": 70,
    "5.55": 70,
    "5.56": 70,
    "5.57": 70,
    "5.58": 70,
    "5.59": 70,
    "5.60": 75,
    "5.61": 80,
    "5.62": 80,
    "5.63": 80,
    "5.64": 80,
    "5.65": 80,
    "5.66": 80,
    "5.67": 80,
    "5.68": 80,
    "5.69": 80,
    "5.70": 80,
    "5.71": 80,
    "5.72": 80,
    "5.73": 80,
    "5.74": 80,
    "5.75": 80,
    "5.76": 80,
    "5.77": 80,
    "5.78": 80,
    "5.79": 80,
    "5.80": 80,
    "5.81": 85,
    "5.82": 85,
    "5.83": 85,
    "5.84": 85,
    "5.85": 85,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 85,
    "5.96": 85,
    "5.97": 85,
    "5.98": 85,
    "5.99": 85,
    "6.00": 85,
    "6.01": 85,
    "6.02": 85,
    "6.03": 85,
    "6.04": 85,
    "6.05": 85,
    "6.06": 85,
    "6.07": 85,
    "6.08": 85,
    "6.09": 85,
    "6.10": 85,
    "6.11": 85,
    "6.12": 85,
    "6.13": 85,
    "6.14": 85,
    "6.15": 85,
    "6.16": 85,
    "6.17": 85,
    "6.18": 85,
    "6.19": 85,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 90,
    "6.26": 90,
    "6.27": 90,
    "6.28": 90,
    "6.29": 90,
    "6.30": 90,
    "6.31": 90,
    "6.32": 90,
    "6.33": 90,
    "6.34": 90,
    "6.35": 90,
    "6.36": 90,
    "6.37": 90,
    "6.38": 90,
    "6.39": 90,
    "6.40": 90,
    "7.00": 99
}
export const escalaE4 = {
        "1.00": 1,
        "2.00": 1,
        "3.00": 5,
        "4.00": 20,
        "5.00": 55,
        "6.00": 85,
        "1.01": 1,
        "1.02": 1,
        "1.03": 1,
        "1.04": 1,
        "1.05": 1,
        "1.06": 1,
        "1.07": 1,
        "1.08": 1,
        "1.09": 1,
        "1.10": 1,
        "1.11": 1,
        "1.12": 1,
        "1.13": 1,
        "1.14": 1,
        "1.15": 1,
        "1.16": 1,
        "1.17": 1,
        "1.18": 1,
        "1.19": 1,
        "1.20": 1,
        "1.21": 1,
        "1.22": 1,
        "1.23": 1,
        "1.24": 1,
        "1.25": 1,
        "1.26": 1,
        "1.27": 1,
        "1.28": 1,
        "1.29": 1,
        "1.30": 1,
        "1.31": 1,
        "1.32": 1,
        "1.33": 1,
        "1.34": 1,
        "1.35": 1,
        "1.36": 1,
        "1.37": 1,
        "1.38": 1,
        "1.39": 1,
        "1.40": 1,
        "1.41": 1,
        "1.42": 1,
        "1.43": 1,
        "1.44": 1,
        "1.45": 1,
        "1.46": 1,
        "1.47": 1,
        "1.48": 1,
        "1.49": 1,
        "1.50": 1,
        "1.51": 1,
        "1.52": 1,
        "1.53": 1,
        "1.54": 1,
        "1.55": 1,
        "1.56": 1,
        "1.57": 1,
        "1.58": 1,
        "1.59": 1,
        "1.60": 1,
        "1.61": 1,
        "1.62": 1,
        "1.63": 1,
        "1.64": 1,
        "1.65": 1,
        "1.66": 1,
        "1.67": 1,
        "1.68": 1,
        "1.69": 1,
        "1.70": 1,
        "1.71": 1,
        "1.72": 1,
        "1.73": 1,
        "1.74": 1,
        "1.75": 1,
        "1.76": 1,
        "1.77": 1,
        "1.78": 1,
        "1.79": 1,
        "1.80": 1,
        "1.81": 1,
        "1.82": 1,
        "1.83": 1,
        "1.84": 1,
        "1.85": 1,
        "1.86": 1,
        "1.87": 1,
        "1.88": 1,
        "1.89": 1,
        "1.90": 1,
        "1.91": 1,
        "1.92": 1,
        "1.93": 1,
        "1.94": 1,
        "1.95": 1,
        "1.96": 1,
        "1.97": 1,
        "1.98": 1,
        "1.99": 1,
        "2.01": 1,
        "2.02": 1,
        "2.03": 1,
        "2.04": 1,
        "2.05": 1,
        "2.06": 1,
        "2.07": 1,
        "2.08": 1,
        "2.09": 1,
        "2.10": 1,
        "2.11": 1,
        "2.12": 1,
        "2.13": 1,
        "2.14": 1,
        "2.15": 1,
        "2.16": 1,
        "2.17": 1,
        "2.18": 1,
        "2.19": 1,
        "2.20": 1,
        "2.21": 1,
        "2.22": 1,
        "2.23": 1,
        "2.24": 1,
        "2.25": 1,
        "2.26": 1,
        "2.27": 1,
        "2.28": 1,
        "2.29": 1,
        "2.30": 1,
        "2.31": 1,
        "2.32": 1,
        "2.33": 1,
        "2.34": 1,
        "2.35": 1,
        "2.36": 1,
        "2.37": 1,
        "2.38": 1,
        "2.39": 1,
        "2.40": 1,
        "2.41": 1,
        "2.42": 1,
        "2.43": 1,
        "2.44": 1,
        "2.45": 1,
        "2.46": 1,
        "2.47": 1,
        "2.48": 1,
        "2.49": 1,
        "2.50": 1,
        "2.51": 1,
        "2.52": 1,
        "2.53": 1,
        "2.54": 1,
        "2.55": 1,
        "2.56": 1,
        "2.57": 1,
        "2.58": 1,
        "2.59": 1,
        "2.60": 1,
        "2.61": 1,
        "2.62": 1,
        "2.63": 1,
        "2.64": 1,
        "2.65": 1,
        "2.66": 1,
        "2.67": 1,
        "2.68": 1,
        "2.69": 1,
        "2.70": 1,
        "2.71": 1,
        "2.72": 1,
        "2.73": 1,
        "2.74": 1,
        "2.75": 1,
        "2.76": 1,
        "2.77": 1,
        "2.78": 1,
        "2.79": 1,
        "2.80": 1,
        "2.81": 1,
        "2.82": 1,
        "2.83": 5,
        "2.84": 5,
        "2.85": 5,
        "2.86": 5,
        "2.87": 5,
        "2.88": 5,
        "2.89": 5,
        "2.90": 5,
        "2.91": 5,
        "2.92": 5,
        "2.93": 5,
        "2.94": 5,
        "2.95": 5,
        "2.96": 5,
        "2.97": 5,
        "2.98": 5,
        "2.99": 5,
        "3.01": 5,
        "3.02": 5,
        "3.03": 5,
        "3.04": 5,
        "3.05": 5,
        "3.06": 5,
        "3.07": 5,
        "3.08": 5,
        "3.09": 5,
        "3.10": 5,
        "3.11": 5,
        "3.12": 5,
        "3.13": 5,
        "3.14": 5,
        "3.15": 5,
        "3.16": 5,
        "3.17": 5,
        "3.18": 5,
        "3.19": 5,
        "3.20": 5,
        "3.21": 5,
        "3.22": 5,
        "3.23": 5,
        "3.24": 5,
        "3.25": 5,
        "3.26": 5,
        "3.27": 5,
        "3.28": 5,
        "3.29": 5,
        "3.30": 5,
        "3.31": 5,
        "3.32": 5,
        "3.33": 10,
        "3.34": 10,
        "3.35": 10,
        "3.36": 10,
        "3.37": 10,
        "3.38": 10,
        "3.39": 10,
        "3.40": 10,
        "3.41": 10,
        "3.42": 10,
        "3.43": 10,
        "3.44": 10,
        "3.45": 10,
        "3.46": 10,
        "3.47": 10,
        "3.48": 10,
        "3.49": 10,
        "3.50": 10,
        "3.51": 10,
        "3.52": 10,
        "3.53": 10,
        "3.54": 10,
        "3.55": 10,
        "3.56": 10,
        "3.57": 10,
        "3.58": 10,
        "3.59": 10,
        "3.60": 10,
        "3.61": 10,
        "3.62": 10,
        "3.63": 10,
        "3.64": 10,
        "3.65": 10,
        "3.66": 10,
        "3.67": 15,
        "3.68": 15,
        "3.69": 15,
        "3.70": 15,
        "3.71": 15,
        "3.72": 15,
        "3.73": 15,
        "3.74": 15,
        "3.75": 15,
        "3.76": 15,
        "3.77": 15,
        "3.78": 15,
        "3.79": 15,
        "3.80": 15,
        "3.81": 15,
        "3.82": 15,
        "3.83": 15,
        "3.84": 15,
        "3.85": 15,
        "3.86": 20,
        "3.87": 20,
        "3.88": 20,
        "3.89": 20,
        "3.90": 20,
        "3.91": 20,
        "3.92": 20,
        "3.93": 20,
        "3.94": 20,
        "3.95": 20,
        "3.96": 20,
        "3.97": 20,
        "3.98": 20,
        "3.99": 20,
        "4.01": 20,
        "4.02": 20,
        "4.03": 20,
        "4.04": 20,
        "4.05": 20,
        "4.06": 20,
        "4.07": 20,
        "4.08": 20,
        "4.09": 20,
        "4.10": 20,
        "4.11": 20,
        "4.12": 20,
        "4.13": 20,
        "4.14": 25,
        "4.15": 25,
        "4.16": 25,
        "4.17": 25,
        "4.18": 25,
        "4.19": 25,
        "4.20": 25,
        "4.21": 25,
        "4.22": 25,
        "4.23": 25,
        "4.24": 25,
        "4.25": 25,
        "4.26": 25,
        "4.27": 25,
        "4.28": 25,
        "4.29": 30,
        "4.30": 30,
        "4.31": 30,
        "4.32": 30,
        "4.33": 30,
        "4.34": 30,
        "4.35": 30,
        "4.36": 30,
        "4.37": 30,
        "4.38": 30,
        "4.39": 30,
        "4.40": 30,
        "4.41": 30,
        "4.42": 30,
        "4.43": 35,
        "4.44": 35,
        "4.45": 35,
        "4.46": 35,
        "4.47": 35,
        "4.48": 35,
        "4.49": 35,
        "4.50": 35,
        "4.51": 35,
        "4.52": 35,
        "4.53": 35,
        "4.54": 35,
        "4.55": 35,
        "4.56": 35,
        "4.57": 40,
        "4.58": 40,
        "4.59": 40,
        "4.60": 40,
        "4.61": 40,
        "4.62": 40,
        "4.63": 40,
        "4.64": 40,
        "4.65": 40,
        "4.66": 40,
        "4.67": 40,
        "4.68": 40,
        "4.69": 40,
        "4.70": 40,
        "4.71": 45,
        "4.72": 45,
        "4.73": 45,
        "4.74": 45,
        "4.75": 45,
        "4.76": 45,
        "4.77": 45,
        "4.78": 45,
        "4.79": 45,
        "4.80": 45,
        "4.81": 45,
        "4.82": 45,
        "4.83": 45,
        "4.84": 45,
        "4.85": 45,
        "4.86": 50,
        "4.87": 50,
        "4.88": 50,
        "4.89": 50,
        "4.90": 50,
        "4.91": 50,
        "4.92": 50,
        "4.93": 50,
        "4.94": 50,
        "4.95": 50,
        "4.96": 50,
        "4.97": 50,
        "4.98": 50,
        "4.99": 50,
        "5.01": 55,
        "5.02": 55,
        "5.03": 55,
        "5.04": 55,
        "5.05": 55,
        "5.06": 55,
        "5.07": 55,
        "5.08": 55,
        "5.09": 55,
        "5.10": 55,
        "5.11": 55,
        "5.12": 55,
        "5.13": 55,
        "5.14": 55,
        "5.15": 55,
        "5.16": 55,
        "5.17": 60,
        "5.18": 60,
        "5.19": 60,
        "5.20": 60,
        "5.21": 60,
        "5.22": 60,
        "5.23": 60,
        "5.24": 60,
        "5.25": 60,
        "5.26": 60,
        "5.27": 60,
        "5.28": 60,
        "5.29": 60,
        "5.30": 60,
        "5.31": 60,
        "5.32": 60,
        "5.33": 65,
        "5.34": 65,
        "5.35": 65,
        "5.36": 65,
        "5.37": 65,
        "5.38": 65,
        "5.39": 65,
        "5.40": 65,
        "5.41": 65,
        "5.42": 65,
        "5.43": 65,
        "5.44": 65,
        "5.45": 65,
        "5.46": 65,
        "5.47": 65,
        "5.48": 65,
        "5.49": 65,
        "5.50": 70,
        "5.51": 70,
        "5.52": 70,
        "5.53": 70,
        "5.54": 70,
        "5.55": 70,
        "5.56": 70,
        "5.57": 70,
        "5.58": 70,
        "5.59": 70,
        "5.60": 70,
        "5.61": 70,
        "5.62": 70,
        "5.63": 70,
        "5.64": 70,
        "5.65": 70,
        "5.66": 70,
        "5.67": 75,
        "5.68": null,
        "5.69": null,
        "5.70": null,
        "5.71": null,
        "5.72": null,
        "5.73": null,
        "5.74": null,
        "5.75": null,
        "5.76": null,
        "5.77": null,
        "5.78": null,
        "5.79": null,
        "5.80": null,
        "5.81": null,
        "5.82": null,
        "5.83": 80,
        "5.84": 80,
        "5.85": 80,
        "5.86": 80,
        "5.87": 80,
        "5.88": 80,
        "5.89": 80,
        "5.90": 80,
        "5.91": 80,
        "5.92": 80,
        "5.93": 80,
        "5.94": 80,
        "5.95": 80,
        "5.96": 80,
        "5.97": 80,
        "5.98": 80,
        "5.99": 80,
        "6.01": 85,
        "6.02": 85,
        "6.03": 85,
        "6.04": 85,
        "6.05": 85,
        "6.06": 85,
        "6.07": 85,
        "6.08": 85,
        "6.09": 85,
        "6.10": 85,
        "6.11": 85,
        "6.12": 85,
        "6.13": 85,
        "6.14": 85,
        "6.15": 85,
        "6.16": 85,
        "6.17": 85
    }

export const escalaSocializacao = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 5,
    "5.00": 30,
    "6.00": 80,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 1,
    "3.15": 1,
    "3.16": 1,
    "3.17": 1,
    "3.18": 1,
    "3.19": 1,
    "3.20": 1,
    "3.21": 1,
    "3.22": 1,
    "3.23": 1,
    "3.24": 1,
    "3.25": 1,
    "3.26": 1,
    "3.27": 1,
    "3.28": 1,
    "3.29": 1,
    "3.30": 1,
    "3.31": 1,
    "3.32": 1,
    "3.33": 1,
    "3.34": 1,
    "3.35": 1,
    "3.36": 1,
    "3.37": 1,
    "3.38": 1,
    "3.39": 1,
    "3.40": 1,
    "3.41": 1,
    "3.42": 1,
    "3.43": 1,
    "3.44": 1,
    "3.45": 1,
    "3.46": 1,
    "3.47": 1,
    "3.48": 1,
    "3.49": 1,
    "3.50": 1,
    "3.51": 1,
    "3.52": 1,
    "3.53": 1,
    "3.54": 1,
    "3.55": 1,
    "3.56": 1,
    "3.57": 1,
    "3.58": 1,
    "3.59": 1,
    "3.60": 1,
    "3.61": 1,
    "3.62": 1,
    "3.63": 1,
    "3.64": 1,
    "3.65": 1,
    "3.66": 1,
    "3.67": 1,
    "3.68": 1,
    "3.69": 1,
    "3.70": 1,
    "3.71": 1,
    "3.72": 1,
    "3.73": 1,
    "3.74": 1,
    "3.75": 1,
    "3.76": 1,
    "3.77": 1,
    "3.78": 1,
    "3.79": 1,
    "3.80": 1,
    "3.81": 1,
    "3.82": 1,
    "3.83": 1,
    "3.84": 1,
    "3.85": 1,
    "3.86": 1,
    "3.87": 1,
    "3.88": 1,
    "3.89": 1,
    "3.90": 1,
    "3.91": 1,
    "3.92": 1,
    "3.93": 1,
    "3.94": 1,
    "3.95": 1,
    "3.96": 1,
    "3.97": 5,
    "3.98": 5,
    "3.99": 5,
    "4.01": 5,
    "4.02": 5,
    "4.03": 5,
    "4.04": 5,
    "4.05": 5,
    "4.06": 5,
    "4.07": 5,
    "4.08": 5,
    "4.09": 5,
    "4.10": 5,
    "4.11": 5,
    "4.12": 5,
    "4.13": 5,
    "4.14": 5,
    "4.15": 5,
    "4.16": 5,
    "4.17": 5,
    "4.18": 5,
    "4.19": 5,
    "4.20": 5,
    "4.21": 5,
    "4.22": 5,
    "4.23": 5,
    "4.24": 5,
    "4.25": 5,
    "4.26": 5,
    "4.27": 5,
    "4.28": 5,
    "4.29": 5,
    "4.30": 5,
    "4.31": 5,
    "4.32": 5,
    "4.33": 5,
    "4.34": 5,
    "4.35": 10,
    "4.36": 10,
    "4.37": 10,
    "4.38": 10,
    "4.39": 10,
    "4.40": 10,
    "4.41": 10,
    "4.42": 10,
    "4.43": 10,
    "4.44": 10,
    "4.45": 10,
    "4.46": 10,
    "4.47": 10,
    "4.48": 10,
    "4.49": 10,
    "4.50": 10,
    "4.51": 10,
    "4.52": 10,
    "4.53": 10,
    "4.54": 10,
    "4.55": 10,
    "4.56": 10,
    "4.57": 15,
    "4.58": 15,
    "4.59": 15,
    "4.60": 15,
    "4.61": 15,
    "4.62": 15,
    "4.63": 15,
    "4.64": 15,
    "4.65": 15,
    "4.66": 15,
    "4.67": 15,
    "4.68": 15,
    "4.69": 15,
    "4.70": 15,
    "4.71": 15,
    "4.72": 15,
    "4.73": 15,
    "4.74": 20,
    "4.75": 20,
    "4.76": 20,
    "4.77": 20,
    "4.78": 20,
    "4.79": 20,
    "4.80": 20,
    "4.81": 20,
    "4.82": 20,
    "4.83": 20,
    "4.84": 20,
    "4.85": 20,
    "4.86": 20,
    "4.87": 20,
    "4.88": 25,
    "4.89": 25,
    "4.90": 25,
    "4.91": 25,
    "4.92": 25,
    "4.93": 25,
    "4.94": 25,
    "4.95": 25,
    "4.96": 25,
    "4.97": 25,
    "4.98": 25,
    "4.99": 30,
    "5.01": 30,
    "5.02": 30,
    "5.03": 30,
    "5.04": 30,
    "5.05": 30,
    "5.06": 30,
    "5.07": 30,
    "5.08": 30,
    "5.09": 30,
    "5.10": 35,
    "5.11": 35,
    "5.12": 35,
    "5.13": 35,
    "5.14": 35,
    "5.15": 35,
    "5.16": 35,
    "5.17": 35,
    "5.18": 35,
    "5.19": 35,
    "5.20": 35,
    "5.21": 40,
    "5.22": 40,
    "5.23": 40,
    "5.24": 40,
    "5.25": 40,
    "5.26": 40,
    "5.27": 40,
    "5.28": 40,
    "5.29": 45,
    "5.30": 45,
    "5.31": 45,
    "5.32": 45,
    "5.33": 45,
    "5.34": 45,
    "5.35": 45,
    "5.36": 45,
    "5.37": 45,
    "5.38": 45,
    "5.39": 50,
    "5.40": 50,
    "5.41": 50,
    "5.42": 50,
    "5.43": 50,
    "5.44": 50,
    "5.45": 50,
    "5.46": 50,
    "5.47": 50,
    "5.48": 50,
    "5.49": 55,
    "5.50": 55,
    "5.51": 55,
    "5.52": 55,
    "5.53": 55,
    "5.54": 55,
    "5.55": 55,
    "5.56": 55,
    "5.57": 60,
    "5.58": 60,
    "5.59": 60,
    "5.60": 60,
    "5.61": 60,
    "5.62": 60,
    "5.63": 60,
    "5.64": 60,
    "5.65": 65,
    "5.66": 65,
    "5.67": 65,
    "5.68": 65,
    "5.69": 65,
    "5.70": 65,
    "5.71": 65,
    "5.72": 65,
    "5.73": 65,
    "5.74": 65,
    "5.75": 70,
    "5.76": 70,
    "5.77": 70,
    "5.78": 70,
    "5.79": 70,
    "5.80": 70,
    "5.81": 70,
    "5.82": 70,
    "5.83": 70,
    "5.84": 70,
    "5.85": 75,
    "5.86": 75,
    "5.87": 75,
    "5.88": 75,
    "5.89": 75,
    "5.90": 75,
    "5.91": 75,
    "5.92": 75,
    "5.93": 75,
    "5.94": 80,
    "5.95": 80,
    "5.96": 80,
    "5.97": 80,
    "5.98": 80,
    "5.99": 80,
    "6.01": 80,
    "6.02": 80,
    "6.03": 80,
    "6.04": 80,
    "6.05": 85,
    "6.06": 85,
    "6.07": 85,
    "6.08": 85,
    "6.09": 85,
    "6.10": 85,
    "6.11": 85,
    "6.12": 85,
    "6.13": 85,
    "6.14": 85,
    "6.15": 85,
    "6.16": 85,
    "6.17": 85,
    "6.18": 90,
    "6.19": 90,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 90,
    "6.26": 90,
    "6.27": 90,
    "6.28": 90,
    "6.29": 90,
    "6.30": 90,
    "6.31": 90,
    "6.32": 90,
    "6.33": 90,
    "6.34": 90,
    "6.35": 95
}
export const escalaS1 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 5,
    "5.00": 20,
    "6.00": 60,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 1,
    "3.15": 1,
    "3.16": 1,
    "3.17": 1,
    "3.18": 1,
    "3.19": 1,
    "3.20": 1,
    "3.21": 1,
    "3.22": 1,
    "3.23": 1,
    "3.24": 1,
    "3.25": 1,
    "3.26": 1,
    "3.27": 1,
    "3.28": 1,
    "3.29": 1,
    "3.30": 1,
    "3.31": 1,
    "3.32": 1,
    "3.33": 1,
    "3.34": 1,
    "3.35": 1,
    "3.36": 1,
    "3.37": 1,
    "3.38": 1,
    "3.39": 1,
    "3.40": 1,
    "3.41": 1,
    "3.42": 1,
    "3.43": 1,
    "3.44": 1,
    "3.45": 1,
    "3.46": 1,
    "3.47": 1,
    "3.48": 1,
    "3.49": 1,
    "3.50": 1,
    "3.51": 1,
    "3.52": 1,
    "3.53": 1,
    "3.54": 1,
    "3.55": 1,
    "3.56": 1,
    "3.57": 1,
    "3.58": 1,
    "3.59": 1,
    "3.60": 1,
    "3.61": 1,
    "3.62": 1,
    "3.63": 1,
    "3.64": 1,
    "3.65": 1,
    "3.66": 1,
    "3.67": 1,
    "3.68": 1,
    "3.69": 1,
    "3.70": 1,
    "3.71": 1,
    "3.72": 1,
    "3.73": 1,
    "3.74": 1,
    "3.75": 1,
    "3.76": 1,
    "3.77": 1,
    "3.78": 1,
    "3.79": 1,
    "3.80": 1,
    "3.81": 1,
    "3.82": 1,
    "3.83": 1,
    "3.84": 1,
    "3.85": 1,
    "3.86": 1,
    "3.87": 1,
    "3.88": 1,
    "3.89": 1,
    "3.90": 1,
    "3.91": 1,
    "3.92": 5,
    "3.93": 5,
    "3.94": 5,
    "3.95": 5,
    "3.96": 5,
    "3.97": 5,
    "3.98": 5,
    "3.99": 5,
    "4.01": 5,
    "4.02": 5,
    "4.03": 5,
    "4.04": 5,
    "4.05": 5,
    "4.06": 5,
    "4.07": 5,
    "4.08": 5,
    "4.09": 5,
    "4.10": 5,
    "4.11": 5,
    "4.12": 5,
    "4.13": 5,
    "4.14": 5,
    "4.15": 5,
    "4.16": 5,
    "4.17": 5,
    "4.18": 5,
    "4.19": 5,
    "4.20": 5,
    "4.21": 5,
    "4.22": 5,
    "4.23": 5,
    "4.24": 5,
    "4.25": 5,
    "4.26": 5,
    "4.27": 5,
    "4.28": 5,
    "4.29": 5,
    "4.30": 5,
    "4.31": 5,
    "4.32": 5,
    "4.33": 5,
    "4.34": 5,
    "4.35": 5,
    "4.36": 5,
    "4.37": 5,
    "4.38": 5,
    "4.39": 5,
    "4.40": 5,
    "4.41": 5,
    "4.42": 10,
    "4.43": 10,
    "4.44": 10,
    "4.45": 10,
    "4.46": 10,
    "4.47": 10,
    "4.48": 10,
    "4.49": 10,
    "4.50": 10,
    "4.51": 10,
    "4.52": 10,
    "4.53": 10,
    "4.54": 10,
    "4.55": 10,
    "4.56": 10,
    "4.57": 10,
    "4.58": 10,
    "4.59": 10,
    "4.60": 10,
    "4.61": 10,
    "4.62": 10,
    "4.63": 10,
    "4.64": 10,
    "4.65": 10,
    "4.66": 10,
    "4.67": 10,
    "4.68": 10,
    "4.69": 10,
    "4.70": 10,
    "4.71": 10,
    "4.72": 10,
    "4.73": 15,
    "4.74": 15,
    "4.75": 15,
    "4.76": 15,
    "4.77": 15,
    "4.78": 15,
    "4.79": 15,
    "4.80": 15,
    "4.81": 15,
    "4.82": 15,
    "4.83": 15,
    "4.84": 15,
    "4.85": 15,
    "4.86": 15,
    "4.87": 15,
    "4.88": 15,
    "4.89": 15,
    "4.90": 15,
    "4.91": 15,
    "4.92": 20,
    "4.93": 20,
    "4.94": 20,
    "4.95": 20,
    "4.96": 20,
    "4.97": 20,
    "4.98": 20,
    "4.99": 20,
    "5.01": 20,
    "5.02": 20,
    "5.03": 20,
    "5.04": 20,
    "5.05": 20,
    "5.06": 20,
    "5.07": 20,
    "5.08": 20,
    "5.09": 25,
    "5.10": 25,
    "5.11": 25,
    "5.12": 25,
    "5.13": 25,
    "5.14": 25,
    "5.15": 25,
    "5.16": 25,
    "5.17": 25,
    "5.18": 25,
    "5.19": 25,
    "5.20": 25,
    "5.21": 25,
    "5.22": 25,
    "5.23": 25,
    "5.24": 25,
    "5.25": 30,
    "5.26": 30,
    "5.27": 30,
    "5.28": 30,
    "5.29": 30,
    "5.30": 30,
    "5.31": 30,
    "5.32": 30,
    "5.33": 30,
    "5.34": 30,
    "5.35": 30,
    "5.36": 30,
    "5.37": 30,
    "5.38": 30,
    "5.39": 30,
    "5.40": 30,
    "5.41": 30,
    "5.42": 35,
    "5.43": 35,
    "5.44": 35,
    "5.45": 35,
    "5.46": 35,
    "5.47": 35,
    "5.48": 35,
    "5.49": 35,
    "5.50": 40,
    "5.51": 40,
    "5.52": 40,
    "5.53": 40,
    "5.54": 40,
    "5.55": 40,
    "5.56": 40,
    "5.57": 40,
    "5.58": 45,
    "5.59": 45,
    "5.60": 45,
    "5.61": 45,
    "5.62": 45,
    "5.63": 45,
    "5.64": 45,
    "5.65": 45,
    "5.66": 45,
    "5.67": 45,
    "5.68": 45,
    "5.69": 45,
    "5.70": 45,
    "5.71": 45,
    "5.72": 45,
    "5.73": 50,
    "5.74": 50,
    "5.75": 50,
    "5.76": 50,
    "5.77": 50,
    "5.78": 50,
    "5.79": 50,
    "5.80": 50,
    "5.81": 50,
    "5.82": 50,
    "5.83": 55,
    "5.84": 55,
    "5.85": 55,
    "5.86": 55,
    "5.87": 55,
    "5.88": 55,
    "5.89": 55,
    "5.90": 55,
    "5.91": 55,
    "5.92": 60,
    "5.93": 60,
    "5.94": 60,
    "5.95": 60,
    "5.96": 60,
    "5.97": 60,
    "5.98": 60,
    "5.99": 60,
    "6.01": 60,
    "6.02": 60,
    "6.03": 60,
    "6.04": 60,
    "6.05": 60,
    "6.06": 60,
    "6.07": 60,
    "6.08": 65,
    "6.09": 65,
    "6.10": 65,
    "6.11": 65,
    "6.12": 65,
    "6.13": 65,
    "6.14": 65,
    "6.15": 65,
    "6.16": 65,
    "6.17": 70,
    "6.18": 70,
    "6.19": 70,
    "6.20": 70,
    "6.21": 70,
    "6.22": 70,
    "6.23": 70,
    "6.24": 70,
    "6.25": 70,
    "6.26": 75,
    "6.27": 75,
    "6.28": 75,
    "6.29": 75,
    "6.30": 75,
    "6.31": 75,
    "6.32": 75,
    "6.33": 75,
    "6.34": 75,
    "6.35": 75,
    "6.36": 80,
    "6.37": 80,
    "6.38": 80,
    "6.39": 80,
    "6.40": 80,
    "6.41": 80,
    "6.42": 80,
    "6.43": 80,
    "6.44": 80,
    "6.45": 80,
    "6.46": 80,
    "6.47": 80,
    "6.48": 80,
    "6.49": 80,
    "6.50": 85,
    "6.51": 85,
    "6.52": 85,
    "6.53": 85,
    "6.54": 85,
    "6.55": 85,
    "6.56": 85,
    "6.57": 85,
    "6.58": 85,
    "6.59": 85,
    "6.60": 85,
    "6.61": 85,
    "6.62": 85,
    "6.63": 85,
    "6.64": 90,
    "6.65": 90,
    "6.66": 90,
    "6.67": 90,
    "6.68": 90,
    "6.69": 90,
    "6.70": 90,
    "6.71": 90,
    "6.72": 90,
    "6.73": 90,
    "6.74": 90,
    "6.75": 90,
    "6.76": 90,
    "6.77": 90,
    "6.78": 90,
    "6.79": 90,
    "6.80": 90,
    "6.81": 90,
    "6.82": 95
}
export const escalaS2 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 5,
    "5.00": 25,
    "6.00": 60,
    "7.00": 95,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 1,
    "3.15": 1,
    "3.16": 1,
    "3.17": 1,
    "3.18": 1,
    "3.19": 1,
    "3.20": 1,
    "3.21": 1,
    "3.22": 1,
    "3.23": 1,
    "3.24": 1,
    "3.25": 1,
    "3.26": 1,
    "3.27": 1,
    "3.28": 1,
    "3.29": 1,
    "3.30": 1,
    "3.31": 1,
    "3.32": 1,
    "3.33": 1,
    "3.34": 1,
    "3.35": 1,
    "3.36": 1,
    "3.37": 1,
    "3.38": 1,
    "3.39": 1,
    "3.40": 1,
    "3.41": 1,
    "3.42": 1,
    "3.43": 1,
    "3.44": 1,
    "3.45": 1,
    "3.46": 1,
    "3.47": 1,
    "3.48": 1,
    "3.49": 1,
    "3.50": 1,
    "3.51": 1,
    "3.52": 1,
    "3.53": 1,
    "3.54": 1,
    "3.55": 1,
    "3.56": 1,
    "3.57": 1,
    "3.58": 1,
    "3.59": 1,
    "3.60": 1,
    "3.61": 1,
    "3.62": 1,
    "3.63": 1,
    "3.64": 1,
    "3.65": 1,
    "3.66": 1,
    "3.67": 1,
    "3.68": 1,
    "3.69": 1,
    "3.70": 1,
    "3.71": 5,
    "3.72": 5,
    "3.73": 5,
    "3.74": 5,
    "3.75": 5,
    "3.76": 5,
    "3.77": 5,
    "3.78": 5,
    "3.79": 5,
    "3.80": 5,
    "3.81": 5,
    "3.82": 5,
    "3.83": 5,
    "3.84": 5,
    "3.85": 5,
    "3.86": 5,
    "3.87": 5,
    "3.88": 5,
    "3.89": 5,
    "3.90": 5,
    "3.91": 5,
    "3.92": 5,
    "3.93": 5,
    "3.94": 5,
    "3.95": 5,
    "3.96": 5,
    "3.97": 5,
    "3.98": 5,
    "3.99": 5,
    "4.01": 5,
    "4.02": 5,
    "4.03": 5,
    "4.04": 5,
    "4.05": 5,
    "4.06": 5,
    "4.07": 5,
    "4.08": 5,
    "4.09": 5,
    "4.10": 5,
    "4.11": 5,
    "4.12": 5,
    "4.13": 5,
    "4.14": 5,
    "4.15": 5,
    "4.16": 5,
    "4.17": 5,
    "4.18": 5,
    "4.19": 5,
    "4.20": 5,
    "4.21": 5,
    "4.22": 5,
    "4.23": 5,
    "4.24": 5,
    "4.25": 10,
    "4.26": 10,
    "4.27": 10,
    "4.28": 10,
    "4.29": 10,
    "4.30": 10,
    "4.31": 10,
    "4.32": 10,
    "4.33": 10,
    "4.34": 10,
    "4.35": 10,
    "4.36": 10,
    "4.37": 10,
    "4.38": 10,
    "4.39": 10,
    "4.40": 10,
    "4.41": 10,
    "4.42": 10,
    "4.43": 10,
    "4.44": 10,
    "4.45": 10,
    "4.46": 10,
    "4.47": 10,
    "4.48": 10,
    "4.49": 10,
    "4.50": 10,
    "4.51": 10,
    "4.52": 10,
    "4.53": 10,
    "4.54": 10,
    "4.55": 10,
    "4.56": 10,
    "4.57": 15,
    "4.58": 15,
    "4.59": 15,
    "4.60": 15,
    "4.61": 15,
    "4.62": 15,
    "4.63": 15,
    "4.64": 15,
    "4.65": 15,
    "4.66": 15,
    "4.67": 15,
    "4.68": 15,
    "4.69": 15,
    "4.70": 15,
    "4.71": 15,
    "4.72": 15,
    "4.73": 15,
    "4.74": 15,
    "4.75": 15,
    "4.76": 15,
    "4.77": 15,
    "4.78": 15,
    "4.79": 15,
    "4.80": 15,
    "4.81": 15,
    "4.82": 15,
    "4.83": 15,
    "4.84": 15,
    "4.85": 15,
    "4.86": 20,
    "4.87": 20,
    "4.88": 20,
    "4.89": 20,
    "4.90": 20,
    "4.91": 20,
    "4.92": 20,
    "4.93": 20,
    "4.94": 20,
    "4.95": 20,
    "4.96": 20,
    "4.97": 20,
    "4.98": 20,
    "4.99": 20,
    "5.01": 25,
    "5.02": 25,
    "5.03": 25,
    "5.04": 25,
    "5.05": 25,
    "5.06": 25,
    "5.07": 25,
    "5.08": 25,
    "5.09": 25,
    "5.10": 25,
    "5.11": 25,
    "5.12": 25,
    "5.13": 25,
    "5.14": 25,
    "5.15": 25,
    "5.16": 25,
    "5.17": 25,
    "5.18": 25,
    "5.19": 30,
    "5.20": 30,
    "5.21": 30,
    "5.22": 30,
    "5.23": 30,
    "5.24": 30,
    "5.25": 30,
    "5.26": 30,
    "5.27": 30,
    "5.28": 30,
    "5.29": 30,
    "5.30": 30,
    "5.31": 30,
    "5.32": 30,
    "5.33": 30,
    "5.34": 30,
    "5.35": 30,
    "5.36": 30,
    "5.37": 30,
    "5.38": 35,
    "5.39": 35,
    "5.40": 35,
    "5.41": 35,
    "5.42": 35,
    "5.43": 35,
    "5.44": 35,
    "5.45": 35,
    "5.46": 35,
    "5.47": 35,
    "5.48": 35,
    "5.49": 35,
    "5.50": 40,
    "5.51": 40,
    "5.52": 40,
    "5.53": 40,
    "5.54": 40,
    "5.55": 40,
    "5.56": 40,
    "5.57": 40,
    "5.58": 40,
    "5.59": 40,
    "5.60": 40,
    "5.61": 40,
    "5.62": 40,
    "5.63": 45,
    "5.64": 45,
    "5.65": 45,
    "5.66": 45,
    "5.67": 45,
    "5.68": 45,
    "5.69": 45,
    "5.70": 45,
    "5.71": 45,
    "5.72": 45,
    "5.73": 45,
    "5.74": 45,
    "5.75": 50,
    "5.76": 50,
    "5.77": 50,
    "5.78": 50,
    "5.79": 50,
    "5.80": 50,
    "5.81": 50,
    "5.82": 50,
    "5.83": 50,
    "5.84": 50,
    "5.85": 50,
    "5.86": 50,
    "5.87": 50,
    "5.88": 55,
    "5.89": 55,
    "5.90": 55,
    "5.91": 55,
    "5.92": 55,
    "5.93": 55,
    "5.94": 55,
    "5.95": 55,
    "5.96": 55,
    "5.97": 55,
    "5.98": 55,
    "5.99": 55,
    "6.01": 60,
    "6.02": 60,
    "6.03": 60,
    "6.04": 60,
    "6.05": 60,
    "6.06": 60,
    "6.07": 60,
    "6.08": 60,
    "6.09": 60,
    "6.10": 60,
    "6.11": 60,
    "6.12": 60,
    "6.13": 65,
    "6.14": 65,
    "6.15": 65,
    "6.16": 65,
    "6.17": 65,
    "6.18": 65,
    "6.19": 65,
    "6.20": 65,
    "6.21": 65,
    "6.22": 65,
    "6.23": 65,
    "6.24": 65,
    "6.25": 70,
    "6.26": 70,
    "6.27": 70,
    "6.28": 70,
    "6.29": 75,
    "6.30": 75,
    "6.31": 75,
    "6.32": 75,
    "6.33": 75,
    "6.34": 75,
    "6.35": 75,
    "6.36": 75,
    "6.37": 75,
    "6.38": 75,
    "6.39": 75,
    "6.40": 75,
    "6.41": 75,
    "6.42": 75,
    "6.43": 75,
    "6.44": 75,
    "6.45": 75,
    "6.46": 75,
    "6.47": 75,
    "6.48": 75,
    "6.49": 75,
    "6.50": 80,
    "6.51": 80,
    "6.52": 80,
    "6.53": 80,
    "6.54": 80,
    "6.55": 80,
    "6.56": 80,
    "6.57": 85,
    "6.58": 85,
    "6.59": 85,
    "6.60": 85,
    "6.61": 85,
    "6.62": 85,
    "6.63": 85,
    "6.64": 85,
    "6.65": 85,
    "6.66": 85,
    "6.67": 85,
    "6.68": 85,
    "6.69": 85,
    "6.70": 85,
    "6.71": 85,
    "6.72": 85,
    "6.73": 85,
    "6.74": 85,
    "6.75": 90,
    "6.76": 90,
    "6.77": 90,
    "6.78": 90,
    "6.79": 90,
    "6.80": 90,
    "6.81": 90,
    "6.82": 90,
    "6.83": 90,
    "6.84": 90,
    "6.85": 90,
    "6.86": 90,
    "6.87": 90,
    "6.88": 90,
    "6.89": 90,
    "6.90": 90,
    "6.91": 90,
    "6.92": 90,
    "6.93": 90,
    "6.94": 90,
    "6.95": 90,
    "6.96": 90,
    "6.97": 90,
    "6.98": 90,
    "6.99": 90
}

export const escalaS3 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 5,
    "4.00": 20,
    "5.00": 55,
    "6.00": 90,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 5,
    "3.26": 5,
    "3.27": 5,
    "3.28": 5,
    "3.29": 5,
    "3.30": 5,
    "3.31": 5,
    "3.32": 5,
    "3.33": 5,
    "3.34": 5,
    "3.35": 5,
    "3.36": 5,
    "3.37": 5,
    "3.38": 10,
    "3.39": 10,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 10,
    "3.45": 10,
    "3.46": 10,
    "3.47": 10,
    "3.48": 10,
    "3.49": 10,
    "3.50": 10,
    "3.51": 10,
    "3.52": 10,
    "3.53": 10,
    "3.54": 10,
    "3.55": 10,
    "3.56": 10,
    "3.57": 10,
    "3.58": 10,
    "3.59": 10,
    "3.60": 10,
    "3.61": 10,
    "3.62": 10,
    "3.63": 15,
    "3.64": 15,
    "3.65": 15,
    "3.66": 15,
    "3.67": 15,
    "3.68": 15,
    "3.69": 15,
    "3.70": 15,
    "3.71": 15,
    "3.72": 15,
    "3.73": 15,
    "3.74": 15,
    "3.75": 15,
    "3.76": 15,
    "3.77": 15,
    "3.78": 15,
    "3.79": 15,
    "3.80": 15,
    "3.81": 15,
    "3.82": 15,
    "3.83": 15,
    "3.84": 15,
    "3.85": 15,
    "3.86": 15,
    "3.87": 15,
    "3.88": 20,
    "3.89": 20,
    "3.90": 20,
    "3.91": 20,
    "3.92": 20,
    "3.93": 20,
    "3.94": 20,
    "3.95": 20,
    "3.96": 20,
    "3.97": 20,
    "3.98": 20,
    "3.99": 20,
    "4.01": 20,
    "4.02": 20,
    "4.03": 20,
    "4.04": 20,
    "4.05": 20,
    "4.06": 20,
    "4.07": 20,
    "4.08": 20,
    "4.09": 20,
    "4.10": 20,
    "4.11": 20,
    "4.12": 20,
    "4.13": 25,
    "4.14": 25,
    "4.15": 25,
    "4.16": 25,
    "4.17": 25,
    "4.18": 25,
    "4.19": 25,
    "4.20": 25,
    "4.21": 25,
    "4.22": 25,
    "4.23": 25,
    "4.24": 25,
    "4.25": 30,
    "4.26": 30,
    "4.27": 30,
    "4.28": 30,
    "4.29": 30,
    "4.30": 30,
    "4.31": 30,
    "4.32": 30,
    "4.33": 30,
    "4.34": 30,
    "4.35": 30,
    "4.36": 30,
    "4.37": 30,
    "4.38": 35,
    "4.39": 35,
    "4.40": 35,
    "4.41": 35,
    "4.42": 35,
    "4.43": 35,
    "4.44": 35,
    "4.45": 35,
    "4.46": 35,
    "4.47": 35,
    "4.48": 35,
    "4.49": 35,
    "4.50": 40,
    "4.51": 40,
    "4.52": 40,
    "4.53": 40,
    "4.54": 40,
    "4.55": 40,
    "4.56": 40,
    "4.57": 40,
    "4.58": 40,
    "4.59": 40,
    "4.60": 40,
    "4.61": 40,
    "4.62": 40,
    "4.63": 45,
    "4.64": 45,
    "4.65": 45,
    "4.66": 45,
    "4.67": 45,
    "4.68": 45,
    "4.69": 45,
    "4.70": 45,
    "4.71": 45,
    "4.72": 45,
    "4.73": 45,
    "4.74": 45,
    "4.75": 50,
    "4.76": 50,
    "4.77": 50,
    "4.78": 50,
    "4.79": 50,
    "4.80": 50,
    "4.81": 50,
    "4.82": 50,
    "4.83": 50,
    "4.84": 50,
    "4.85": 50,
    "4.86": 50,
    "4.87": 50,
    "4.88": 50,
    "4.89": 50,
    "4.90": 50,
    "4.91": 50,
    "4.92": 50,
    "4.93": 50,
    "4.94": 50,
    "4.95": 50,
    "4.96": 50,
    "4.97": 50,
    "4.98": 50,
    "4.99": 50,
    "5.01": 55,
    "5.02": 55,
    "5.03": 55,
    "5.04": 55,
    "5.05": 55,
    "5.06": 55,
    "5.07": 55,
    "5.08": 55,
    "5.09": 55,
    "5.10": 55,
    "5.11": 55,
    "5.12": 55,
    "5.13": 60,
    "5.14": 65,
    "5.15": 65,
    "5.16": 65,
    "5.17": 65,
    "5.18": 65,
    "5.19": 65,
    "5.20": 65,
    "5.21": 65,
    "5.22": 65,
    "5.23": 65,
    "5.24": 65,
    "5.25": 70,
    "5.26": 70,
    "5.27": 70,
    "5.28": 70,
    "5.29": 70,
    "5.30": 70,
    "5.31": 70,
    "5.32": 70,
    "5.33": 70,
    "5.34": 70,
    "5.35": 70,
    "5.36": 70,
    "5.37": 70,
    "5.38": 70,
    "5.39": 70,
    "5.40": 70,
    "5.41": 70,
    "5.42": 70,
    "5.43": 70,
    "5.44": 70,
    "5.45": 70,
    "5.46": 70,
    "5.47": 70,
    "5.48": 70,
    "5.49": 70,
    "5.50": 75,
    "5.51": 75,
    "5.52": 75,
    "5.53": 75,
    "5.54": 75,
    "5.55": 75,
    "5.56": 75,
    "5.57": 75,
    "5.58": 75,
    "5.59": 75,
    "5.60": 75,
    "5.61": 75,
    "5.62": 75,
    "5.63": 80,
    "5.64": 80,
    "5.65": 80,
    "5.66": 80,
    "5.67": 80,
    "5.68": 80,
    "5.69": 80,
    "5.70": 80,
    "5.71": 80,
    "5.72": 80,
    "5.73": 80,
    "5.74": 80,
    "5.75": 85,
    "5.76": 85,
    "5.77": 85,
    "5.78": 85,
    "5.79": 85,
    "5.80": 85,
    "5.81": 85,
    "5.82": 85,
    "5.83": 85,
    "5.84": 85,
    "5.85": 85,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 85,
    "5.96": 85,
    "5.97": 85,
    "5.98": 85,
    "5.99": 85,
    "6.01": 90,
    "6.02": 90,
    "6.03": 90,
    "6.04": 90,
    "6.05": 90,
    "6.06": 90,
    "6.07": 90,
    "6.08": 90,
    "6.09": 90,
    "6.10": 90,
    "6.11": 90,
    "6.12": 90,
    "6.13": 90,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 90,
    "6.18": 90,
    "6.19": 90,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 95
}

export const escalaRealizacao = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 10,
    "5.00": 45,
    "6.00": 90,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 1,
    "3.15": 1,
    "3.16": 1,
    "3.17": 1,
    "3.18": 1,
    "3.19": 1,
    "3.20": 1,
    "3.21": 1,
    "3.22": 1,
    "3.23": 1,
    "3.24": 1,
    "3.25": 1,
    "3.26": 1,
    "3.27": 1,
    "3.28": 1,
    "3.29": 1,
    "3.30": 1,
    "3.31": 1,
    "3.32": 1,
    "3.33": 1,
    "3.34": 1,
    "3.35": 1,
    "3.36": 1,
    "3.37": 1,
    "3.38": 1,
    "3.39": 1,
    "3.40": 1,
    "3.41": 1,
    "3.42": 1,
    "3.43": 1,
    "3.44": 1,
    "3.45": 1,
    "3.46": 1,
    "3.47": 1,
    "3.48": 1,
    "3.49": 1,
    "3.50": 5,
    "3.51": 5,
    "3.52": 5,
    "3.53": 5,
    "3.54": 5,
    "3.55": 5,
    "3.56": 5,
    "3.57": 5,
    "3.58": 5,
    "3.59": 5,
    "3.60": 5,
    "3.61": 5,
    "3.62": 5,
    "3.63": 5,
    "3.64": 5,
    "3.65": 5,
    "3.66": 5,
    "3.67": 5,
    "3.68": 5,
    "3.69": 5,
    "3.70": 5,
    "3.71": 5,
    "3.72": 5,
    "3.73": 5,
    "3.74": 5,
    "3.75": 5,
    "3.76": 5,
    "3.77": 5,
    "3.78": 5,
    "3.79": 5,
    "3.80": 5,
    "3.81": 5,
    "3.82": 5,
    "3.83": 5,
    "3.84": 5,
    "3.85": 10,
    "3.86": 10,
    "3.87": 10,
    "3.88": 10,
    "3.89": 10,
    "3.90": 10,
    "3.91": 10,
    "3.92": 10,
    "3.93": 10,
    "3.94": 10,
    "3.95": 10,
    "3.96": 10,
    "3.97": 10,
    "3.98": 10,
    "3.99": 10,
    "4.01": 10,
    "4.02": 10,
    "4.03": 10,
    "4.04": 10,
    "4.05": 10,
    "4.06": 10,
    "4.07": 10,
    "4.08": 10,
    "4.09": 10,
    "4.10": 10,
    "4.11": 15,
    "4.12": 15,
    "4.13": 15,
    "4.14": 15,
    "4.15": 15,
    "4.16": 15,
    "4.17": 15,
    "4.18": 15,
    "4.19": 15,
    "4.20": 15,
    "4.21": 15,
    "4.22": 15,
    "4.23": 15,
    "4.24": 15,
    "4.25": 15,
    "4.26": 15,
    "4.27": 15,
    "4.28": 15,
    "4.29": 15,
    "4.30": 20,
    "4.31": 20,
    "4.32": 20,
    "4.33": 20,
    "4.34": 20,
    "4.35": 20,
    "4.36": 20,
    "4.37": 20,
    "4.38": 20,
    "4.39": 20,
    "4.40": 20,
    "4.41": 20,
    "4.42": 20,
    "4.43": 20,
    "4.44": 20,
    "4.45": 20,
    "4.46": 25,
    "4.47": 25,
    "4.48": 25,
    "4.49": 25,
    "4.50": 25,
    "4.51": 25,
    "4.52": 25,
    "4.53": 25,
    "4.54": 25,
    "4.55": 25,
    "4.56": 25,
    "4.57": 25,
    "4.58": 30,
    "4.59": 30,
    "4.60": 30,
    "4.61": 30,
    "4.62": 30,
    "4.63": 30,
    "4.64": 30,
    "4.65": 30,
    "4.66": 30,
    "4.67": 30,
    "4.68": 30,
    "4.69": 30,
    "4.70": 35,
    "4.71": 35,
    "4.72": 35,
    "4.73": 35,
    "4.74": 35,
    "4.75": 35,
    "4.76": 35,
    "4.77": 35,
    "4.78": 35,
    "4.79": 35,
    "4.80": 35,
    "4.81": 35,
    "4.82": 35,
    "4.83": 40,
    "4.84": 40,
    "4.85": 40,
    "4.86": 40,
    "4.87": 40,
    "4.88": 40,
    "4.89": 40,
    "4.90": 40,
    "4.91": 40,
    "4.92": 40,
    "4.93": 40,
    "4.94": 45,
    "4.95": 45,
    "4.96": 45,
    "4.97": 45,
    "4.98": 45,
    "4.99": 45,
    "5.01": 45,
    "5.02": 45,
    "5.03": 50,
    "5.04": 50,
    "5.05": 50,
    "5.06": 50,
    "5.07": 50,
    "5.08": 50,
    "5.09": 50,
    "5.10": 50,
    "5.11": 50,
    "5.12": 50,
    "5.13": 55,
    "5.14": 55,
    "5.15": 55,
    "5.16": 55,
    "5.17": 55,
    "5.18": 55,
    "5.19": 55,
    "5.20": 55,
    "5.21": 55,
    "5.22": 55,
    "5.23": 55,
    "5.24": 60,
    "5.25": 60,
    "5.26": 60,
    "5.27": 60,
    "5.28": 60,
    "5.29": 60,
    "5.30": 60,
    "5.31": 60,
    "5.32": 60,
    "5.33": 65,
    "5.34": 65,
    "5.35": 65,
    "5.36": 65,
    "5.37": 65,
    "5.38": 65,
    "5.39": 65,
    "5.40": 65,
    "5.41": 65,
    "5.42": 65,
    "5.43": 70,
    "5.44": 70,
    "5.45": 70,
    "5.46": 70,
    "5.47": 70,
    "5.48": 70,
    "5.49": 70,
    "5.50": 70,
    "5.51": 70,
    "5.52": 70,
    "5.53": 70,
    "5.54": 75,
    "5.55": 75,
    "5.56": 75,
    "5.57": 75,
    "5.58": 75,
    "5.59": 75,
    "5.60": 75,
    "5.61": 75,
    "5.62": 75,
    "5.63": 75,
    "5.64": 75,
    "5.65": 80,
    "5.66": 80,
    "5.67": 80,
    "5.68": 80,
    "5.69": 80,
    "5.70": 80,
    "5.71": 80,
    "5.72": 80,
    "5.73": 80,
    "5.74": 80,
    "5.75": 80,
    "5.76": 80,
    "5.77": 80,
    "5.78": 85,
    "5.79": 85,
    "5.80": 85,
    "5.81": 85,
    "5.82": 85,
    "5.83": 85,
    "5.84": 85,
    "5.85": 85,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 90,
    "5.96": 90,
    "5.97": 90,
    "5.98": 90,
    "5.99": 90,
    "6.01": 90,
    "6.02": 90,
    "6.03": 90,
    "6.04": 90,
    "6.05": 90,
    "6.06": 90,
    "6.07": 90,
    "6.08": 90,
    "6.09": 90,
    "6.10": 90,
    "6.11": 90,
    "6.12": 90,
    "6.13": 90,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 90,
    "6.18": 95
}

export const escalaR1 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 10,
    "5.00": 40,
    "6.00": 80,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 1,
    "3.15": 1,
    "3.16": 1,
    "3.17": 1,
    "3.18": 1,
    "3.19": 1,
    "3.20": 1,
    "3.21": 1,
    "3.22": 1,
    "3.23": 1,
    "3.24": 1,
    "3.25": 1,
    "3.26": 1,
    "3.27": 1,
    "3.28": 1,
    "3.29": 1,
    "3.30": 1,
    "3.31": 1,
    "3.32": 1,
    "3.33": 1,
    "3.34": 1,
    "3.35": 1,
    "3.36": 1,
    "3.37": 1,
    "3.38": 1,
    "3.39": 1,
    "3.40": 1,
    "3.41": 1,
    "3.42": 1,
    "3.43": 1,
    "3.44": 1,
    "3.45": 1,
    "3.46": 1,
    "3.47": 1,
    "3.48": 1,
    "3.49": 1,
    "3.50": 5,
    "3.51": 5,
    "3.52": 5,
    "3.53": 5,
    "3.54": 5,
    "3.55": 5,
    "3.56": 5,
    "3.57": 5,
    "3.58": 5,
    "3.59": 5,
    "3.60": 5,
    "3.61": 5,
    "3.62": 5,
    "3.63": 5,
    "3.64": 5,
    "3.65": 5,
    "3.66": 5,
    "3.67": 5,
    "3.68": 5,
    "3.69": 5,
    "3.70": 5,
    "3.71": 5,
    "3.72": 5,
    "3.73": 5,
    "3.74": 5,
    "3.75": 5,
    "3.76": 5,
    "3.77": 5,
    "3.78": 5,
    "3.79": 5,
    "3.80": 5,
    "3.81": 5,
    "3.82": 5,
    "3.83": 5,
    "3.84": 5,
    "3.85": 5,
    "3.86": 5,
    "3.87": 5,
    "3.88": 5,
    "3.89": 5,
    "3.90": 10,
    "3.91": 10,
    "3.92": 10,
    "3.93": 10,
    "3.94": 10,
    "3.95": 10,
    "3.96": 10,
    "3.97": 10,
    "3.98": 10,
    "3.99": 10,
    "4.01": 10,
    "4.02": 10,
    "4.03": 10,
    "4.04": 10,
    "4.05": 10,
    "4.06": 10,
    "4.07": 10,
    "4.08": 10,
    "4.09": 10,
    "4.10": 10,
    "4.11": 10,
    "4.12": 10,
    "4.13": 10,
    "4.14": 10,
    "4.15": 10,
    "4.16": 10,
    "4.17": 10,
    "4.18": 10,
    "4.19": 10,
    "4.20": 15,
    "4.21": 15,
    "4.22": 15,
    "4.23": 15,
    "4.24": 15,
    "4.25": 15,
    "4.26": 15,
    "4.27": 15,
    "4.28": 15,
    "4.29": 15,
    "4.30": 15,
    "4.31": 15,
    "4.32": 15,
    "4.33": 15,
    "4.34": 15,
    "4.35": 15,
    "4.36": 15,
    "4.37": 15,
    "4.38": 15,
    "4.39": 15,
    "4.40": 15,
    "4.41": 15,
    "4.42": 15,
    "4.43": 20,
    "4.44": 20,
    "4.45": 20,
    "4.46": 20,
    "4.47": 20,
    "4.48": 20,
    "4.49": 20,
    "4.50": 20,
    "4.51": 20,
    "4.52": 20,
    "4.53": 20,
    "4.54": 20,
    "4.55": 20,
    "4.56": 20,
    "4.57": 20,
    "4.58": 20,
    "4.59": 20,
    "4.60": 25,
    "4.61": 25,
    "4.62": 25,
    "4.63": 25,
    "4.64": 25,
    "4.65": 25,
    "4.66": 25,
    "4.67": 25,
    "4.68": 25,
    "4.69": 25,
    "4.70": 25,
    "4.71": 25,
    "4.72": 25,
    "4.73": 25,
    "4.74": 25,
    "4.75": 25,
    "4.76": 25,
    "4.77": 25,
    "4.78": 25,
    "4.79": 25,
    "4.80": 30,
    "4.81": 30,
    "4.82": 30,
    "4.83": 30,
    "4.84": 30,
    "4.85": 30,
    "4.86": 30,
    "4.87": 30,
    "4.88": 30,
    "4.89": 30,
    "4.90": 35,
    "4.91": 35,
    "4.92": 35,
    "4.93": 35,
    "4.94": 35,
    "4.95": 35,
    "4.96": 35,
    "4.97": 35,
    "4.98": 35,
    "4.99": 35,
    "5.01": 40,
    "5.02": 40,
    "5.03": 40,
    "5.04": 40,
    "5.05": 40,
    "5.06": 40,
    "5.07": 40,
    "5.08": 40,
    "5.09": 40,
    "5.10": 40,
    "5.11": 40,
    "5.12": 40,
    "5.13": 40,
    "5.14": 45,
    "5.15": 45,
    "5.16": 45,
    "5.17": 45,
    "5.18": 45,
    "5.19": 45,
    "5.20": 45,
    "5.21": 45,
    "5.22": 45,
    "5.23": 45,
    "5.24": 45,
    "5.25": 45,
    "5.26": 45,
    "5.27": 45,
    "5.28": 45,
    "5.29": 50,
    "5.30": 50,
    "5.31": 50,
    "5.32": 50,
    "5.33": 50,
    "5.34": 50,
    "5.35": 50,
    "5.36": 50,
    "5.37": 50,
    "5.38": 50,
    "5.39": 50,
    "5.40": 55,
    "5.41": 55,
    "5.42": 55,
    "5.43": 55,
    "5.44": 55,
    "5.45": 55,
    "5.46": 55,
    "5.47": 55,
    "5.48": 55,
    "5.49": 55,
    "5.50": 60,
    "5.51": 60,
    "5.52": 60,
    "5.53": 60,
    "5.54": 60,
    "5.55": 60,
    "5.56": 60,
    "5.57": 60,
    "5.58": 60,
    "5.59": 60,
    "5.60": 65,
    "5.61": 65,
    "5.62": 65,
    "5.63": 65,
    "5.64": 65,
    "5.65": 65,
    "5.66": 65,
    "5.67": 65,
    "5.68": 65,
    "5.69": 65,
    "5.70": 70,
    "5.71": 70,
    "5.72": 70,
    "5.73": 70,
    "5.74": 70,
    "5.75": 70,
    "5.76": 70,
    "5.77": 70,
    "5.78": 70,
    "5.79": 70,
    "5.80": 70,
    "5.81": 70,
    "5.82": 70,
    "5.83": 70,
    "5.84": 70,
    "5.85": 70,
    "5.86": 75,
    "5.87": 75,
    "5.88": 75,
    "5.89": 75,
    "5.90": 75,
    "5.91": 75,
    "5.92": 75,
    "5.93": 75,
    "5.94": 75,
    "5.95": 75,
    "5.96": 75,
    "5.97": 75,
    "5.98": 75,
    "5.99": 75,
    "6.01": 80,
    "6.02": 80,
    "6.03": 80,
    "6.04": 80,
    "6.05": 80,
    "6.06": 80,
    "6.07": 80,
    "6.08": 80,
    "6.09": 80,
    "6.10": 85,
    "6.11": 85,
    "6.12": 85,
    "6.13": 85,
    "6.14": 85,
    "6.15": 85,
    "6.16": 85,
    "6.17": 85,
    "6.18": 85,
    "6.19": 85,
    "6.20": 85,
    "6.21": 85,
    "6.22": 85,
    "6.23": 85,
    "6.24": 85,
    "6.25": 85,
    "6.26": 85,
    "6.27": 85,
    "6.28": 85,
    "6.29": 90,
    "6.30": 90,
    "6.31": 90,
    "6.32": 90,
    "6.33": 90,
    "6.34": 90,
    "6.35": 90,
    "6.36": 90,
    "6.37": 90,
    "6.38": 90,
    "6.39": 90,
    "6.40": 90,
    "6.41": 90,
    "6.42": 90,
    "6.43": 90,
    "6.44": 90,
    "6.45": 90,
    "6.46": 90,
    "6.47": 90,
    "6.48": 90,
    "6.49": 90,
    "6.50": 95
}
export const escalaR2 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 5,
    "4.00": 20,
    "5.00": 50,
    "6.00": 80,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 5,
    "2.76": 5,
    "2.77": 5,
    "2.78": 5,
    "2.79": 5,
    "2.80": 5,
    "2.81": 5,
    "2.82": 5,
    "2.83": 5,
    "2.84": 5,
    "2.85": 5,
    "2.86": 5,
    "2.87": 5,
    "2.88": 5,
    "2.89": 5,
    "2.90": 5,
    "2.91": 5,
    "2.92": 5,
    "2.93": 5,
    "2.94": 5,
    "2.95": 5,
    "2.96": 5,
    "2.97": 5,
    "2.98": 5,
    "2.99": 5,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 10,
    "3.26": 10,
    "3.27": 10,
    "3.28": 10,
    "3.29": 10,
    "3.30": 10,
    "3.31": 10,
    "3.32": 10,
    "3.33": 10,
    "3.34": 10,
    "3.35": 10,
    "3.36": 10,
    "3.37": 10,
    "3.38": 10,
    "3.39": 10,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 10,
    "3.45": 10,
    "3.46": 10,
    "3.47": 10,
    "3.48": 10,
    "3.49": 10,
    "3.50": 10,
    "3.51": 10,
    "3.52": 10,
    "3.53": 10,
    "3.54": 10,
    "3.55": 10,
    "3.56": 10,
    "3.57": 10,
    "3.58": 10,
    "3.59": 10,
    "3.60": 10,
    "3.61": 10,
    "3.62": 10,
    "3.63": 10,
    "3.64": 10,
    "3.65": 10,
    "3.66": 10,
    "3.67": 10,
    "3.68": 10,
    "3.69": 10,
    "3.70": 10,
    "3.71": 10,
    "3.72": 10,
    "3.73": 10,
    "3.74": 10,
    "3.75": 15,
    "3.76": 15,
    "3.77": 15,
    "3.78": 15,
    "3.79": 15,
    "3.80": 15,
    "3.81": 15,
    "3.82": 15,
    "3.83": 15,
    "3.84": 15,
    "3.85": 15,
    "3.86": 15,
    "3.87": 15,
    "3.88": 15,
    "3.89": 15,
    "3.90": 15,
    "3.91": 15,
    "3.92": 15,
    "3.93": 15,
    "3.94": 15,
    "3.95": 15,
    "3.96": 15,
    "3.97": 15,
    "3.98": 15,
    "3.99": 15,
    "4.01": 25,
    "4.02": 25,
    "4.03": 25,
    "4.04": 25,
    "4.05": 25,
    "4.06": 25,
    "4.07": 25,
    "4.08": 25,
    "4.09": 25,
    "4.10": 25,
    "4.11": 25,
    "4.12": 25,
    "4.13": 25,
    "4.14": 25,
    "4.15": 25,
    "4.16": 25,
    "4.17": 25,
    "4.18": 25,
    "4.19": 25,
    "4.20": 25,
    "4.21": 25,
    "4.22": 25,
    "4.23": 25,
    "4.24": 25,
    "4.25": 30,
    "4.26": 30,
    "4.27": 30,
    "4.28": 30,
    "4.29": 30,
    "4.30": 30,
    "4.31": 30,
    "4.32": 30,
    "4.33": 30,
    "4.34": 30,
    "4.35": 30,
    "4.36": 30,
    "4.37": 30,
    "4.38": 30,
    "4.39": 30,
    "4.40": 30,
    "4.41": 30,
    "4.42": 30,
    "4.43": 30,
    "4.44": 30,
    "4.45": 30,
    "4.46": 30,
    "4.47": 30,
    "4.48": 30,
    "4.49": 30,
    "4.50": 35,
    "4.51": 35,
    "4.52": 35,
    "4.53": 35,
    "4.54": 35,
    "4.55": 35,
    "4.56": 35,
    "4.57": 35,
    "4.58": 35,
    "4.59": 35,
    "4.60": 35,
    "4.61": 35,
    "4.62": 35,
    "4.63": 35,
    "4.64": 35,
    "4.65": 35,
    "4.66": 35,
    "4.67": 35,
    "4.68": 35,
    "4.69": 35,
    "4.70": 35,
    "4.71": 35,
    "4.72": 35,
    "4.73": 35,
    "4.74": 35,
    "4.75": 40,
    "4.76": 45,
    "4.77": 45,
    "4.78": 45,
    "4.79": 45,
    "4.80": 45,
    "4.81": 45,
    "4.82": 45,
    "4.83": 45,
    "4.84": 45,
    "4.85": 45,
    "4.86": 45,
    "4.87": 45,
    "4.88": 45,
    "4.89": 45,
    "4.90": 45,
    "4.91": 45,
    "4.92": 45,
    "4.93": 45,
    "4.94": 45,
    "4.95": 45,
    "4.96": 45,
    "4.97": 45,
    "4.98": 45,
    "4.99": 45,
    "5.01": 50,
    "5.02": 50,
    "5.03": 50,
    "5.04": 50,
    "5.05": 50,
    "5.06": 50,
    "5.07": 50,
    "5.08": 50,
    "5.09": 50,
    "5.10": 50,
    "5.11": 50,
    "5.12": 50,
    "5.13": 50,
    "5.14": 50,
    "5.15": 50,
    "5.16": 50,
    "5.17": 50,
    "5.18": 50,
    "5.19": 50,
    "5.20": 50,
    "5.21": 50,
    "5.22": 50,
    "5.23": 50,
    "5.24": 50,
    "5.25": 55,
    "5.26": 60,
    "5.27": 60,
    "5.28": 60,
    "5.29": 60,
    "5.30": 60,
    "5.31": 60,
    "5.32": 60,
    "5.33": 60,
    "5.34": 60,
    "5.35": 60,
    "5.36": 60,
    "5.37": 60,
    "5.38": 60,
    "5.39": 60,
    "5.40": 60,
    "5.41": 60,
    "5.42": 60,
    "5.43": 60,
    "5.44": 60,
    "5.45": 60,
    "5.46": 60,
    "5.47": 60,
    "5.48": 60,
    "5.49": 60,
    "5.50": 65,
    "5.51": 70,
    "5.52": 70,
    "5.53": 70,
    "5.54": 70,
    "5.55": 70,
    "5.56": 70,
    "5.57": 70,
    "5.58": 70,
    "5.59": 70,
    "5.60": 70,
    "5.61": 70,
    "5.62": 70,
    "5.63": 70,
    "5.64": 70,
    "5.65": 70,
    "5.66": 70,
    "5.67": 70,
    "5.68": 70,
    "5.69": 70,
    "5.70": 70,
    "5.71": 70,
    "5.72": 70,
    "5.73": 70,
    "5.74": 70,
    "5.75": 75,
    "5.76": 75,
    "5.77": 75,
    "5.78": 75,
    "5.79": 75,
    "5.80": 75,
    "5.81": 75,
    "5.82": 75,
    "5.83": 75,
    "5.84": 75,
    "5.85": 75,
    "5.86": 75,
    "5.87": 75,
    "5.88": 75,
    "5.89": 75,
    "5.90": 75,
    "5.91": 75,
    "5.92": 75,
    "5.93": 75,
    "5.94": 75,
    "5.95": 75,
    "5.96": 75,
    "5.97": 75,
    "5.98": 75,
    "5.99": 75,
    "6.01": 80,
    "6.02": 80,
    "6.03": 80,
    "6.04": 80,
    "6.05": 80,
    "6.06": 80,
    "6.07": 80,
    "6.08": 80,
    "6.09": 80,
    "6.10": 80,
    "6.11": 80,
    "6.12": 80,
    "6.13": 80,
    "6.14": 80,
    "6.15": 80,
    "6.16": 80,
    "6.17": 80,
    "6.18": 80,
    "6.19": 80,
    "6.20": 80,
    "6.21": 80,
    "6.22": 80,
    "6.23": 80,
    "6.24": 80,
    "6.25": 85,
    "6.26": 85,
    "6.27": 85,
    "6.28": 85,
    "6.29": 85,
    "6.30": 85,
    "6.31": 85,
    "6.32": 85,
    "6.33": 85,
    "6.34": 85,
    "6.35": 85,
    "6.36": 85,
    "6.37": 85,
    "6.38": 85,
    "6.39": 85,
    "6.40": 85,
    "6.41": 85,
    "6.42": 85,
    "6.43": 85,
    "6.44": 85,
    "6.45": 85,
    "6.46": 85,
    "6.47": 85,
    "6.48": 85,
    "6.49": 85,
    "6.50": 90,
    "6.51": 90,
    "6.52": 90,
    "6.53": 90,
    "6.54": 90,
    "6.55": 90,
    "6.56": 90,
    "6.57": 90,
    "6.58": 90,
    "6.59": 90,
    "6.60": 90,
    "6.61": 90,
    "6.62": 90,
    "6.63": 90,
    "6.64": 90,
    "6.65": 90,
    "6.66": 90,
    "6.67": 90,
    "6.68": 90,
    "6.69": 90,
    "6.70": 90,
    "6.71": 90,
    "6.72": 90,
    "6.73": 90,
    "6.74": 90,
    "6.75": 95
}
export const escalaR3 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 5,
    "4.00": 25,
    "5.00": 55,
    "6.00": 85,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 5,
    "3.26": 5,
    "3.27": 5,
    "3.28": 5,
    "3.29": 10,
    "3.30": 10,
    "3.31": 10,
    "3.32": 10,
    "3.33": 10,
    "3.34": 10,
    "3.35": 10,
    "3.36": 10,
    "3.37": 10,
    "3.38": 10,
    "3.39": 10,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 10,
    "3.45": 10,
    "3.46": 10,
    "3.47": 10,
    "3.48": 10,
    "3.49": 10,
    "3.50": 10,
    "3.51": 10,
    "3.52": 10,
    "3.53": 10,
    "3.54": 10,
    "3.55": 10,
    "3.56": 10,
    "3.57": 15,
    "3.58": 15,
    "3.59": 15,
    "3.60": 15,
    "3.61": 15,
    "3.62": 15,
    "3.63": 15,
    "3.64": 15,
    "3.65": 15,
    "3.66": 15,
    "3.67": 15,
    "3.68": 15,
    "3.69": 15,
    "3.70": 15,
    "3.71": 15,
    "3.72": 15,
    "3.73": 15,
    "3.74": 15,
    "3.75": 15,
    "3.76": 15,
    "3.77": 15,
    "3.78": 15,
    "3.79": 15,
    "3.80": 15,
    "3.81": 15,
    "3.82": 15,
    "3.83": 15,
    "3.84": 15,
    "3.85": 15,
    "3.86": 20,
    "3.87": 20,
    "3.88": 20,
    "3.89": 20,
    "3.90": 20,
    "3.91": 20,
    "3.92": 20,
    "3.93": 20,
    "3.94": 20,
    "3.95": 20,
    "3.96": 20,
    "3.97": 20,
    "3.98": 20,
    "3.99": 20,
    "4.01": 25,
    "4.02": 25,
    "4.03": 25,
    "4.04": 25,
    "4.05": 25,
    "4.06": 25,
    "4.07": 25,
    "4.08": 25,
    "4.09": 25,
    "4.10": 25,
    "4.11": 25,
    "4.12": 25,
    "4.13": 25,
    "4.14": 25,
    "4.15": 25,
    "4.16": 25,
    "4.17": 30,
    "4.18": 30,
    "4.19": 30,
    "4.20": 30,
    "4.21": 30,
    "4.22": 30,
    "4.23": 30,
    "4.24": 30,
    "4.25": 30,
    "4.26": 30,
    "4.27": 30,
    "4.28": 30,
    "4.29": 30,
    "4.30": 30,
    "4.31": 30,
    "4.32": 30,
    "4.33": 30,
    "4.34": 30,
    "4.35": 30,
    "4.36": 30,
    "4.37": 30,
    "4.38": 30,
    "4.39": 30,
    "4.40": 30,
    "4.41": 30,
    "4.42": 30,
    "4.43": 35,
    "4.44": 35,
    "4.45": 35,
    "4.46": 35,
    "4.47": 35,
    "4.48": 35,
    "4.49": 35,
    "4.50": 35,
    "4.51": 35,
    "4.52": 35,
    "4.53": 35,
    "4.54": 35,
    "4.55": 35,
    "4.56": 35,
    "4.57": 40,
    "4.58": 40,
    "4.59": 40,
    "4.60": 40,
    "4.61": 40,
    "4.62": 40,
    "4.63": 40,
    "4.64": 40,
    "4.65": 40,
    "4.66": 40,
    "4.67": 40,
    "4.68": 40,
    "4.69": 40,
    "4.70": 40,
    "4.71": 45,
    "4.72": 45,
    "4.73": 45,
    "4.74": 45,
    "4.75": 45,
    "4.76": 45,
    "4.77": 45,
    "4.78": 45,
    "4.79": 45,
    "4.80": 45,
    "4.81": 45,
    "4.82": 45,
    "4.83": 45,
    "4.84": 45,
    "4.85": 45,
    "4.86": 50,
    "4.87": 50,
    "4.88": 50,
    "4.89": 50,
    "4.90": 50,
    "4.91": 50,
    "4.92": 50,
    "4.93": 50,
    "4.94": 50,
    "4.95": 50,
    "4.96": 50,
    "4.97": 50,
    "4.98": 50,
    "4.99": 50,
    "5.01": 55,
    "5.02": 55,
    "5.03": 55,
    "5.04": 55,
    "5.05": 55,
    "5.06": 55,
    "5.07": 55,
    "5.08": 55,
    "5.09": 55,
    "5.10": 55,
    "5.11": 55,
    "5.12": 55,
    "5.13": 55,
    "5.14": 60,
    "5.15": 60,
    "5.16": 60,
    "5.17": 60,
    "5.18": 60,
    "5.19": 60,
    "5.20": 60,
    "5.21": 60,
    "5.22": 60,
    "5.23": 60,
    "5.24": 60,
    "5.25": 60,
    "5.26": 60,
    "5.27": 60,
    "5.28": 60,
    "5.29": 65,
    "5.30": 65,
    "5.31": 65,
    "5.32": 65,
    "5.33": 65,
    "5.34": 65,
    "5.35": 65,
    "5.36": 65,
    "5.37": 65,
    "5.38": 65,
    "5.39": 65,
    "5.40": 65,
    "5.41": 65,
    "5.42": 65,
    "5.43": 70,
    "5.44": 70,
    "5.45": 70,
    "5.46": 70,
    "5.47": 70,
    "5.48": 70,
    "5.49": 70,
    "5.50": 70,
    "5.51": 70,
    "5.52": 70,
    "5.53": 70,
    "5.54": 70,
    "5.55": 70,
    "5.56": 70,
    "5.57": 75,
    "5.58": 75,
    "5.59": 75,
    "5.60": 75,
    "5.61": 75,
    "5.62": 75,
    "5.63": 75,
    "5.64": 75,
    "5.65": 75,
    "5.66": 75,
    "5.67": 75,
    "5.68": 75,
    "5.69": 75,
    "5.70": 75,
    "5.71": 80,
    "5.72": 80,
    "5.73": 80,
    "5.74": 80,
    "5.75": 80,
    "5.76": 80,
    "5.77": 80,
    "5.78": 80,
    "5.79": 80,
    "5.80": 80,
    "5.81": 80,
    "5.82": 80,
    "5.83": 80,
    "5.84": 80,
    "5.85": 80,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 85,
    "5.96": 85,
    "5.97": 85,
    "5.98": 85,
    "5.99": 85,
    "6.01": 85,
    "6.02": 85,
    "6.03": 85,
    "6.04": 85,
    "6.05": 85,
    "6.06": 85,
    "6.07": 85,
    "6.08": 85,
    "6.09": 85,
    "6.10": 85,
    "6.11": 85,
    "6.12": 85,
    "6.13": 85,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 90,
    "6.18": 90,
    "6.19": 90,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 90,
    "6.26": 90,
    "6.27": 90,
    "6.28": 90,
    "6.29": 90,
    "6.30": 90,
    "6.31": 90,
    "6.32": 90,
    "6.33": 90,
    "6.34": 90,
    "6.35": 90,
    "6.36": 90,
    "6.37": 90,
    "6.38": 90,
    "6.39": 90,
    "6.40": 90,
    "6.41": 90,
    "6.42": 90,
    "6.43": 95
}
export const escalaAbertura = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 15,
    "5.00": 65,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 1,
    "3.15": 1,
    "3.16": 1,
    "3.17": 1,
    "3.18": 1,
    "3.19": 1,
    "3.20": 1,
    "3.21": 1,
    "3.22": 1,
    "3.23": 1,
    "3.24": 1,
    "3.25": 1,
    "3.26": 1,
    "3.27": 1,
    "3.28": 1,
    "3.29": 1,
    "3.30": 1,
    "3.31": 1,
    "3.32": 1,
    "3.33": 1,
    "3.34": 1,
    "3.35": 1,
    "3.36": 1,
    "3.37": 1,
    "3.38": 1,
    "3.39": 1,
    "3.40": 1,
    "3.41": 1,
    "3.42": 1,
    "3.43": 1,
    "3.44": 1,
    "3.45": 1,
    "3.46": 1,
    "3.47": 1,
    "3.48": 1,
    "3.49": 1,
    "3.50": 1,
    "3.51": 1,
    "3.52": 1,
    "3.53": 1,
    "3.54": 5,
    "3.55": 5,
    "3.56": 5,
    "3.57": 5,
    "3.58": 5,
    "3.59": 5,
    "3.60": 5,
    "3.61": 5,
    "3.62": 5,
    "3.63": 5,
    "3.64": 5,
    "3.65": 5,
    "3.66": 5,
    "3.67": 5,
    "3.68": 5,
    "3.69": 5,
    "3.70": 5,
    "3.71": 5,
    "3.72": 5,
    "3.73": 5,
    "3.74": 5,
    "3.75": 5,
    "3.76": 5,
    "3.77": 5,
    "3.78": 10,
    "3.79": 10,
    "3.80": 10,
    "3.81": 10,
    "3.82": 10,
    "3.83": 10,
    "3.84": 10,
    "3.85": 10,
    "3.86": 10,
    "3.87": 10,
    "3.88": 10,
    "3.89": 10,
    "3.90": 10,
    "3.91": 10,
    "3.92": 10,
    "3.93": 10,
    "3.94": 10,
    "3.95": 15,
    "3.96": 15,
    "3.97": 15,
    "3.98": 15,
    "3.99": 15,
    "4.01": 15,
    "4.02": 15,
    "4.03": 15,
    "4.04": 15,
    "4.05": 15,
    "4.06": 15,
    "4.07": 15,
    "4.08": 20,
    "4.09": 20,
    "4.10": 20,
    "4.11": 20,
    "4.12": 20,
    "4.13": 20,
    "4.14": 20,
    "4.15": 20,
    "4.16": 20,
    "4.17": 20,
    "4.18": 25,
    "4.19": 25,
    "4.20": 25,
    "4.21": 25,
    "4.22": 25,
    "4.23": 25,
    "4.24": 25,
    "4.25": 25,
    "4.26": 25,
    "4.27": 25,
    "4.28": 25,
    "4.29": 25,
    "4.30": 30,
    "4.31": 30,
    "4.32": 30,
    "4.33": 30,
    "4.34": 30,
    "4.35": 30,
    "4.36": 30,
    "4.37": 30,
    "4.38": 30,
    "4.39": 35,
    "4.40": 35,
    "4.41": 35,
    "4.42": 35,
    "4.43": 35,
    "4.44": 35,
    "4.45": 35,
    "4.46": 35,
    "4.47": 35,
    "4.48": 40,
    "4.49": 40,
    "4.50": 40,
    "4.51": 40,
    "4.52": 40,
    "4.53": 40,
    "4.54": 40,
    "4.55": 40,
    "4.56": 45,
    "4.57": 45,
    "4.58": 45,
    "4.59": 45,
    "4.60": 45,
    "4.61": 45,
    "4.62": 45,
    "4.63": 45,
    "4.64": 45,
    "4.65": 50,
    "4.66": 50,
    "4.67": 50,
    "4.68": 50,
    "4.69": 50,
    "4.70": 50,
    "4.71": 50,
    "4.72": 50,
    "4.73": 55,
    "4.74": 55,
    "4.75": 55,
    "4.76": 55,
    "4.77": 55,
    "4.78": 55,
    "4.79": 55,
    "4.80": 55,
    "4.81": 55,
    "4.82": 60,
    "4.83": 60,
    "4.84": 60,
    "4.85": 60,
    "4.86": 60,
    "4.87": 60,
    "4.88": 60,
    "4.89": 60,
    "4.90": 60,
    "4.91": 65,
    "4.92": 65,
    "4.93": 65,
    "4.94": 65,
    "4.95": 65,
    "4.96": 65,
    "4.97": 65,
    "4.98": 65,
    "4.99": 65,
    "5.01": 65,
    "5.02": 65,
    "5.03": 70,
    "5.04": 70,
    "5.05": 70,
    "5.06": 70,
    "5.07": 70,
    "5.08": 70,
    "5.09": 70,
    "5.10": 70,
    "5.11": 70,
    "5.12": 70,
    "5.13": 70,
    "5.14": 70,
    "5.15": 70,
    "5.16": 75,
    "5.17": 75,
    "5.18": 75,
    "5.19": 75,
    "5.20": 75,
    "5.21": 75,
    "5.22": 75,
    "5.23": 75,
    "5.24": 75,
    "5.25": 75,
    "5.26": 75,
    "5.27": 75,
    "5.28": 75,
    "5.29": 80,
    "5.30": 80,
    "5.31": 80,
    "5.32": 80,
    "5.33": 80,
    "5.34": 80,
    "5.35": 80,
    "5.36": 80,
    "5.37": 80,
    "5.38": 80,
    "5.39": 80,
    "5.40": 80,
    "5.41": 80,
    "5.42": 80,
    "5.43": 80,
    "5.44": 80,
    "5.45": 80,
    "5.46": 80,
    "5.47": 85,
    "5.48": 85,
    "5.49": 85,
    "5.50": 85,
    "5.51": 85,
    "5.52": 85,
    "5.53": 85,
    "5.54": 85,
    "5.55": 85,
    "5.56": 85,
    "5.57": 85,
    "5.58": 85,
    "5.59": 85,
    "5.60": 85,
    "5.61": 85,
    "5.62": 85,
    "5.63": 85,
    "5.64": 85,
    "5.65": 85,
    "5.66": 90,
    "5.67": 90,
    "5.68": 90,
    "5.69": 90,
    "5.70": 90,
    "5.71": 90,
    "5.72": 90,
    "5.73": 90,
    "5.74": 90,
    "5.75": 90,
    "5.76": 90,
    "5.77": 90,
    "5.78": 90,
    "5.79": 90,
    "5.80": 90,
    "5.81": 90,
    "5.82": 90,
    "5.83": 90,
    "5.84": 90,
    "5.85": 90,
    "5.86": 90,
    "5.87": 90,
    "5.88": 90,
    "5.89": 90,
    "5.90": 95
}
export const escalaA1 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 5,
    "4.00": 30,
    "5.00": 65,
    "6.00": 90,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 5,
    "2.91": 5,
    "2.92": 5,
    "2.93": 5,
    "2.94": 5,
    "2.95": 5,
    "2.96": 5,
    "2.97": 5,
    "2.98": 5,
    "2.99": 5,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 5,
    "3.26": 5,
    "3.27": 5,
    "3.28": 5,
    "3.29": 5,
    "3.30": 10,
    "3.31": 10,
    "3.32": 10,
    "3.33": 10,
    "3.34": 10,
    "3.35": 10,
    "3.36": 10,
    "3.37": 10,
    "3.38": 10,
    "3.39": 10,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 10,
    "3.45": 10,
    "3.46": 10,
    "3.47": 10,
    "3.48": 10,
    "3.49": 10,
    "3.50": 15,
    "3.51": 15,
    "3.52": 15,
    "3.53": 15,
    "3.54": 15,
    "3.55": 15,
    "3.56": 15,
    "3.57": 15,
    "3.58": 15,
    "3.59": 15,
    "3.60": 15,
    "3.61": 15,
    "3.62": 15,
    "3.63": 15,
    "3.64": 15,
    "3.65": 15,
    "3.66": 15,
    "3.67": 15,
    "3.68": 15,
    "3.69": 15,
    "3.70": 20,
    "3.71": 20,
    "3.72": 20,
    "3.73": 20,
    "3.74": 20,
    "3.75": 20,
    "3.76": 20,
    "3.77": 20,
    "3.78": 20,
    "3.79": 20,
    "3.80": 20,
    "3.81": 20,
    "3.82": 20,
    "3.83": 20,
    "3.84": 20,
    "3.85": 20,
    "3.86": 20,
    "3.87": 20,
    "3.88": 20,
    "3.89": 20,
    "3.90": 25,
    "3.91": 25,
    "3.92": 25,
    "3.93": 25,
    "3.94": 25,
    "3.95": 25,
    "3.96": 25,
    "3.97": 25,
    "3.98": 25,
    "3.99": 25,
    "4.01": 30,
    "4.02": 30,
    "4.03": 30,
    "4.04": 30,
    "4.05": 30,
    "4.06": 30,
    "4.07": 30,
    "4.08": 30,
    "4.09": 30,
    "4.10": 35,
    "4.11": 35,
    "4.12": 35,
    "4.13": 35,
    "4.14": 35,
    "4.15": 35,
    "4.16": 35,
    "4.17": 35,
    "4.18": 35,
    "4.19": 35,
    "4.20": 35,
    "4.21": 35,
    "4.22": 35,
    "4.23": 35,
    "4.24": 35,
    "4.25": 35,
    "4.26": 35,
    "4.27": 35,
    "4.28": 35,
    "4.29": 35,
    "4.30": 40,
    "4.31": 40,
    "4.32": 40,
    "4.33": 40,
    "4.34": 40,
    "4.35": 40,
    "4.36": 40,
    "4.37": 40,
    "4.38": 40,
    "4.39": 40,
    "4.40": 45,
    "4.41": 45,
    "4.42": 45,
    "4.43": 45,
    "4.44": 45,
    "4.45": 45,
    "4.46": 45,
    "4.47": 45,
    "4.48": 45,
    "4.49": 45,
    "4.50": 45,
    "4.51": 45,
    "4.52": 45,
    "4.53": 45,
    "4.54": 45,
    "4.55": 45,
    "4.56": 45,
    "4.57": 45,
    "4.58": 45,
    "4.59": 45,
    "4.60": 50,
    "4.61": 50,
    "4.62": 50,
    "4.63": 50,
    "4.64": 50,
    "4.65": 50,
    "4.66": 50,
    "4.67": 50,
    "4.68": 50,
    "4.69": 50,
    "4.70": 55,
    "4.71": 55,
    "4.72": 55,
    "4.73": 55,
    "4.74": 55,
    "4.75": 55,
    "4.76": 55,
    "4.77": 55,
    "4.78": 55,
    "4.79": 55,
    "4.80": 60,
    "4.81": 60,
    "4.82": 60,
    "4.83": 60,
    "4.84": 60,
    "4.85": 60,
    "4.86": 60,
    "4.87": 60,
    "4.88": 60,
    "4.89": 60,
    "4.90": 60,
    "4.91": 60,
    "4.92": 60,
    "4.93": 60,
    "4.94": 60,
    "4.95": 60,
    "4.96": 60,
    "4.97": 60,
    "4.98": 60,
    "4.99": 60,
    "5.01": 65,
    "5.02": 65,
    "5.03": 65,
    "5.04": 65,
    "5.05": 65,
    "5.06": 65,
    "5.07": 65,
    "5.08": 65,
    "5.09": 65,
    "5.10": 70,
    "5.11": 70,
    "5.12": 70,
    "5.13": 70,
    "5.14": 70,
    "5.15": 70,
    "5.16": 70,
    "5.17": 70,
    "5.18": 70,
    "5.19": 70,
    "5.20": 70,
    "5.21": 70,
    "5.22": 70,
    "5.23": 70,
    "5.24": 70,
    "5.25": 70,
    "5.26": 70,
    "5.27": 70,
    "5.28": 70,
    "5.29": 70,
    "5.30": 75,
    "5.31": 75,
    "5.32": 75,
    "5.33": 75,
    "5.34": 75,
    "5.35": 75,
    "5.36": 75,
    "5.37": 75,
    "5.38": 75,
    "5.39": 75,
    "5.40": 75,
    "5.41": 75,
    "5.42": 75,
    "5.43": 75,
    "5.44": 75,
    "5.45": 75,
    "5.46": 75,
    "5.47": 75,
    "5.48": 75,
    "5.49": 75,
    "5.50": 80,
    "5.51": 80,
    "5.52": 80,
    "5.53": 80,
    "5.54": 80,
    "5.55": 80,
    "5.56": 80,
    "5.57": 80,
    "5.58": 80,
    "5.59": 80,
    "5.60": 80,
    "5.61": 80,
    "5.62": 80,
    "5.63": 80,
    "5.64": 80,
    "5.65": 80,
    "5.66": 80,
    "5.67": 80,
    "5.68": 80,
    "5.69": 80,
    "5.70": 85,
    "5.71": 85,
    "5.72": 85,
    "5.73": 85,
    "5.74": 85,
    "5.75": 85,
    "5.76": 85,
    "5.77": 85,
    "5.78": 85,
    "5.79": 85,
    "5.80": 85,
    "5.81": 85,
    "5.82": 85,
    "5.83": 85,
    "5.84": 85,
    "5.85": 85,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 85,
    "5.96": 85,
    "5.97": 85,
    "5.98": 85,
    "5.99": 85,
    "6.01": 90,
    "6.02": 90,
    "6.03": 90,
    "6.04": 90,
    "6.05": 90,
    "6.06": 90,
    "6.07": 90,
    "6.08": 90,
    "6.09": 90,
    "6.10": 90,
    "6.11": 90,
    "6.12": 90,
    "6.13": 90,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 90,
    "6.18": 90,
    "6.19": 90,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 90,
    "6.26": 90,
    "6.27": 90,
    "6.28": 90,
    "6.29": 90,
    "6.30": 95
}
export const escalaA2 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 1,
    "4.00": 20,
    "5.00": 55,
    "6.00": 85,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 1,
    "2.84": 1,
    "2.85": 1,
    "2.86": 1,
    "2.87": 1,
    "2.88": 1,
    "2.89": 1,
    "2.90": 1,
    "2.91": 1,
    "2.92": 1,
    "2.93": 1,
    "2.94": 1,
    "2.95": 1,
    "2.96": 1,
    "2.97": 1,
    "2.98": 1,
    "2.99": 1,
    "3.01": 1,
    "3.02": 1,
    "3.03": 1,
    "3.04": 1,
    "3.05": 1,
    "3.06": 1,
    "3.07": 1,
    "3.08": 1,
    "3.09": 1,
    "3.10": 1,
    "3.11": 1,
    "3.12": 1,
    "3.13": 1,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 5,
    "3.26": 5,
    "3.27": 5,
    "3.28": 5,
    "3.29": 5,
    "3.30": 5,
    "3.31": 5,
    "3.32": 5,
    "3.33": 5,
    "3.34": 5,
    "3.35": 5,
    "3.36": 5,
    "3.37": 5,
    "3.38": 5,
    "3.39": 5,
    "3.40": 5,
    "3.41": 5,
    "3.42": 5,
    "3.43": 5,
    "3.44": 5,
    "3.45": 5,
    "3.46": 5,
    "3.47": 5,
    "3.48": 5,
    "3.49": 5,
    "3.50": 5,
    "3.51": 5,
    "3.52": 5,
    "3.53": 5,
    "3.54": 5,
    "3.55": 5,
    "3.56": 5,
    "3.57": 10,
    "3.58": 10,
    "3.59": 10,
    "3.60": 10,
    "3.61": 10,
    "3.62": 10,
    "3.63": 10,
    "3.64": 10,
    "3.65": 10,
    "3.66": 10,
    "3.67": 10,
    "3.68": 10,
    "3.69": 10,
    "3.70": 10,
    "3.71": 15,
    "3.72": 15,
    "3.73": 15,
    "3.74": 15,
    "3.75": 15,
    "3.76": 15,
    "3.77": 15,
    "3.78": 15,
    "3.79": 15,
    "3.80": 15,
    "3.81": 15,
    "3.82": 15,
    "3.83": 15,
    "3.84": 15,
    "3.85": 15,
    "3.86": 15,
    "3.87": 15,
    "3.88": 15,
    "3.89": 15,
    "3.90": 15,
    "3.91": 15,
    "3.92": 15,
    "3.93": 15,
    "3.94": 15,
    "3.95": 15,
    "3.96": 15,
    "3.97": 15,
    "3.98": 15,
    "3.99": 15,
    "4.01": 20,
    "4.02": 20,
    "4.03": 20,
    "4.04": 20,
    "4.05": 20,
    "4.06": 20,
    "4.07": 20,
    "4.08": 20,
    "4.09": 20,
    "4.10": 20,
    "4.11": 20,
    "4.12": 20,
    "4.13": 20,
    "4.14": 25,
    "4.15": 25,
    "4.16": 25,
    "4.17": 25,
    "4.18": 25,
    "4.19": 25,
    "4.20": 25,
    "4.21": 25,
    "4.22": 25,
    "4.23": 25,
    "4.24": 25,
    "4.25": 25,
    "4.26": 25,
    "4.27": 25,
    "4.28": 25,
    "4.29": 30,
    "4.30": 30,
    "4.31": 30,
    "4.32": 30,
    "4.33": 30,
    "4.34": 30,
    "4.35": 30,
    "4.36": 30,
    "4.37": 30,
    "4.38": 30,
    "4.39": 30,
    "4.40": 30,
    "4.41": 30,
    "4.42": 30,
    "4.43": 35,
    "4.44": 35,
    "4.45": 35,
    "4.46": 35,
    "4.47": 35,
    "4.48": 35,
    "4.49": 35,
    "4.50": 35,
    "4.51": 35,
    "4.52": 35,
    "4.53": 35,
    "4.54": 35,
    "4.55": 35,
    "4.56": 35,
    "4.57": 40,
    "4.58": 40,
    "4.59": 40,
    "4.60": 40,
    "4.61": 40,
    "4.62": 40,
    "4.63": 40,
    "4.64": 40,
    "4.65": 40,
    "4.66": 40,
    "4.67": 40,
    "4.68": 40,
    "4.69": 40,
    "4.70": 40,
    "4.71": 45,
    "4.72": 45,
    "4.73": 45,
    "4.74": 45,
    "4.75": 45,
    "4.76": 45,
    "4.77": 45,
    "4.78": 45,
    "4.79": 45,
    "4.80": 45,
    "4.81": 45,
    "4.82": 45,
    "4.83": 45,
    "4.84": 45,
    "4.85": 45,
    "4.86": 50,
    "4.87": 50,
    "4.88": 50,
    "4.89": 50,
    "4.90": 50,
    "4.91": 50,
    "4.92": 50,
    "4.93": 50,
    "4.94": 50,
    "4.95": 50,
    "4.96": 50,
    "4.97": 50,
    "4.98": 50,
    "4.99": 50,
    "5.01": 55,
    "5.02": 55,
    "5.03": 55,
    "5.04": 55,
    "5.05": 55,
    "5.06": 55,
    "5.07": 55,
    "5.08": 55,
    "5.09": 55,
    "5.10": 55,
    "5.11": 55,
    "5.12": 55,
    "5.13": 55,
    "5.14": 60,
    "5.15": 60,
    "5.16": 60,
    "5.17": 60,
    "5.18": 60,
    "5.19": 60,
    "5.20": 60,
    "5.21": 60,
    "5.22": 60,
    "5.23": 60,
    "5.24": 60,
    "5.25": 60,
    "5.26": 60,
    "5.27": 60,
    "5.28": 60,
    "5.29": 65,
    "5.30": 65,
    "5.31": 65,
    "5.32": 65,
    "5.33": 65,
    "5.34": 65,
    "5.35": 65,
    "5.36": 65,
    "5.37": 65,
    "5.38": 65,
    "5.39": 65,
    "5.40": 65,
    "5.41": 65,
    "5.42": 65,
    "5.43": 70,
    "5.44": 70,
    "5.45": 70,
    "5.46": 70,
    "5.47": 70,
    "5.48": 70,
    "5.49": 70,
    "5.50": 70,
    "5.51": 70,
    "5.52": 70,
    "5.53": 70,
    "5.54": 70,
    "5.55": 70,
    "5.56": 70,
    "5.57": 75,
    "5.58": 75,
    "5.59": 75,
    "5.60": 75,
    "5.61": 75,
    "5.62": 75,
    "5.63": 75,
    "5.64": 75,
    "5.65": 75,
    "5.66": 75,
    "5.67": 75,
    "5.68": 75,
    "5.69": 75,
    "5.70": 75,
    "5.71": 80,
    "5.72": 80,
    "5.73": 80,
    "5.74": 80,
    "5.75": 80,
    "5.76": 80,
    "5.77": 80,
    "5.78": 80,
    "5.79": 80,
    "5.80": 80,
    "5.81": 80,
    "5.82": 80,
    "5.83": 80,
    "5.84": 80,
    "5.85": 80,
    "5.86": 80,
    "5.87": 80,
    "5.88": 80,
    "5.89": 80,
    "5.90": 80,
    "5.91": 80,
    "5.92": 80,
    "5.93": 80,
    "5.94": 80,
    "5.95": 80,
    "5.96": 80,
    "5.97": 80,
    "5.98": 80,
    "5.99": 80,
    "6.01": 85,
    "6.02": 85,
    "6.03": 85,
    "6.04": 85,
    "6.05": 85,
    "6.06": 85,
    "6.07": 85,
    "6.08": 85,
    "6.09": 85,
    "6.10": 85,
    "6.11": 85,
    "6.12": 85,
    "6.13": 85,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 90,
    "6.18": 90,
    "6.19": 90,
    "6.20": 90,
    "6.21": 90,
    "6.22": 90,
    "6.23": 90,
    "6.24": 90,
    "6.25": 90,
    "6.26": 90,
    "6.27": 90,
    "6.28": 90,
    "6.29": 90,
    "6.30": 90,
    "6.31": 90,
    "6.32": 90,
    "6.33": 90,
    "6.34": 90,
    "6.35": 90,
    "6.36": 90,
    "6.37": 90,
    "6.38": 90,
    "6.39": 90,
    "6.40": 90,
    "6.41": 90,
    "6.42": 90,
    "6.43": 95
}
export const escalaA3 = {
    "1.00": 1,
    "2.00": 1,
    "3.00": 5,
    "4.00": 25,
    "5.00": 60,
    "6.00": 90,
    "1.01": 1,
    "1.02": 1,
    "1.03": 1,
    "1.04": 1,
    "1.05": 1,
    "1.06": 1,
    "1.07": 1,
    "1.08": 1,
    "1.09": 1,
    "1.10": 1,
    "1.11": 1,
    "1.12": 1,
    "1.13": 1,
    "1.14": 1,
    "1.15": 1,
    "1.16": 1,
    "1.17": 1,
    "1.18": 1,
    "1.19": 1,
    "1.20": 1,
    "1.21": 1,
    "1.22": 1,
    "1.23": 1,
    "1.24": 1,
    "1.25": 1,
    "1.26": 1,
    "1.27": 1,
    "1.28": 1,
    "1.29": 1,
    "1.30": 1,
    "1.31": 1,
    "1.32": 1,
    "1.33": 1,
    "1.34": 1,
    "1.35": 1,
    "1.36": 1,
    "1.37": 1,
    "1.38": 1,
    "1.39": 1,
    "1.40": 1,
    "1.41": 1,
    "1.42": 1,
    "1.43": 1,
    "1.44": 1,
    "1.45": 1,
    "1.46": 1,
    "1.47": 1,
    "1.48": 1,
    "1.49": 1,
    "1.50": 1,
    "1.51": 1,
    "1.52": 1,
    "1.53": 1,
    "1.54": 1,
    "1.55": 1,
    "1.56": 1,
    "1.57": 1,
    "1.58": 1,
    "1.59": 1,
    "1.60": 1,
    "1.61": 1,
    "1.62": 1,
    "1.63": 1,
    "1.64": 1,
    "1.65": 1,
    "1.66": 1,
    "1.67": 1,
    "1.68": 1,
    "1.69": 1,
    "1.70": 1,
    "1.71": 1,
    "1.72": 1,
    "1.73": 1,
    "1.74": 1,
    "1.75": 1,
    "1.76": 1,
    "1.77": 1,
    "1.78": 1,
    "1.79": 1,
    "1.80": 1,
    "1.81": 1,
    "1.82": 1,
    "1.83": 1,
    "1.84": 1,
    "1.85": 1,
    "1.86": 1,
    "1.87": 1,
    "1.88": 1,
    "1.89": 1,
    "1.90": 1,
    "1.91": 1,
    "1.92": 1,
    "1.93": 1,
    "1.94": 1,
    "1.95": 1,
    "1.96": 1,
    "1.97": 1,
    "1.98": 1,
    "1.99": 1,
    "2.01": 1,
    "2.02": 1,
    "2.03": 1,
    "2.04": 1,
    "2.05": 1,
    "2.06": 1,
    "2.07": 1,
    "2.08": 1,
    "2.09": 1,
    "2.10": 1,
    "2.11": 1,
    "2.12": 1,
    "2.13": 1,
    "2.14": 1,
    "2.15": 1,
    "2.16": 1,
    "2.17": 1,
    "2.18": 1,
    "2.19": 1,
    "2.20": 1,
    "2.21": 1,
    "2.22": 1,
    "2.23": 1,
    "2.24": 1,
    "2.25": 1,
    "2.26": 1,
    "2.27": 1,
    "2.28": 1,
    "2.29": 1,
    "2.30": 1,
    "2.31": 1,
    "2.32": 1,
    "2.33": 1,
    "2.34": 1,
    "2.35": 1,
    "2.36": 1,
    "2.37": 1,
    "2.38": 1,
    "2.39": 1,
    "2.40": 1,
    "2.41": 1,
    "2.42": 1,
    "2.43": 1,
    "2.44": 1,
    "2.45": 1,
    "2.46": 1,
    "2.47": 1,
    "2.48": 1,
    "2.49": 1,
    "2.50": 1,
    "2.51": 1,
    "2.52": 1,
    "2.53": 1,
    "2.54": 1,
    "2.55": 1,
    "2.56": 1,
    "2.57": 1,
    "2.58": 1,
    "2.59": 1,
    "2.60": 1,
    "2.61": 1,
    "2.62": 1,
    "2.63": 1,
    "2.64": 1,
    "2.65": 1,
    "2.66": 1,
    "2.67": 1,
    "2.68": 1,
    "2.69": 1,
    "2.70": 1,
    "2.71": 1,
    "2.72": 1,
    "2.73": 1,
    "2.74": 1,
    "2.75": 1,
    "2.76": 1,
    "2.77": 1,
    "2.78": 1,
    "2.79": 1,
    "2.80": 1,
    "2.81": 1,
    "2.82": 1,
    "2.83": 5,
    "2.84": 5,
    "2.85": 5,
    "2.86": 5,
    "2.87": 5,
    "2.88": 5,
    "2.89": 5,
    "2.90": 5,
    "2.91": 5,
    "2.92": 5,
    "2.93": 5,
    "2.94": 5,
    "2.95": 5,
    "2.96": 5,
    "2.97": 5,
    "2.98": 5,
    "2.99": 5,
    "3.01": 5,
    "3.02": 5,
    "3.03": 5,
    "3.04": 5,
    "3.05": 5,
    "3.06": 5,
    "3.07": 5,
    "3.08": 5,
    "3.09": 5,
    "3.10": 5,
    "3.11": 5,
    "3.12": 5,
    "3.13": 5,
    "3.14": 5,
    "3.15": 5,
    "3.16": 5,
    "3.17": 5,
    "3.18": 5,
    "3.19": 5,
    "3.20": 5,
    "3.21": 5,
    "3.22": 5,
    "3.23": 5,
    "3.24": 5,
    "3.25": 5,
    "3.26": 5,
    "3.27": 5,
    "3.28": 5,
    "3.29": 5,
    "3.30": 5,
    "3.31": 5,
    "3.32": 5,
    "3.33": 10,
    "3.34": 10,
    "3.35": 10,
    "3.36": 10,
    "3.37": 10,
    "3.38": 10,
    "3.39": 10,
    "3.40": 10,
    "3.41": 10,
    "3.42": 10,
    "3.43": 10,
    "3.44": 10,
    "3.45": 10,
    "3.46": 10,
    "3.47": 10,
    "3.48": 10,
    "3.49": 10,
    "3.50": 15,
    "3.51": 15,
    "3.52": 15,
    "3.53": 15,
    "3.54": 15,
    "3.55": 15,
    "3.56": 15,
    "3.57": 15,
    "3.58": 15,
    "3.59": 15,
    "3.60": 15,
    "3.61": 15,
    "3.62": 15,
    "3.63": 15,
    "3.64": 15,
    "3.65": 15,
    "3.66": 15,
    "3.67": 15,
    "3.68": 15,
    "3.69": 15,
    "3.70": 15,
    "3.71": 15,
    "3.72": 15,
    "3.73": 15,
    "3.74": 15,
    "3.75": 20,
    "3.76": 20,
    "3.77": 20,
    "3.78": 20,
    "3.79": 20,
    "3.80": 20,
    "3.81": 20,
    "3.82": 20,
    "3.83": 20,
    "3.84": 20,
    "3.85": 20,
    "3.86": 20,
    "3.87": 20,
    "3.88": 20,
    "3.89": 20,
    "3.90": 20,
    "3.91": 20,
    "3.92": 20,
    "3.93": 20,
    "3.94": 20,
    "3.95": 20,
    "3.96": 20,
    "3.97": 20,
    "3.98": 20,
    "3.99": 20,
    "4.01": 30,
    "4.02": 30,
    "4.03": 30,
    "4.04": 30,
    "4.05": 30,
    "4.06": 30,
    "4.07": 30,
    "4.08": 30,
    "4.09": 30,
    "4.10": 30,
    "4.11": 30,
    "4.12": 30,
    "4.13": 30,
    "4.14": 30,
    "4.15": 30,
    "4.16": 30,
    "4.17": 30,
    "4.18": 30,
    "4.19": 30,
    "4.20": 30,
    "4.21": 30,
    "4.22": 30,
    "4.23": 35,
    "4.24": 35,
    "4.25": 35,
    "4.26": 35,
    "4.27": 35,
    "4.28": 35,
    "4.29": 35,
    "4.30": 35,
    "4.31": 35,
    "4.32": 35,
    "4.33": 40,
    "4.34": 40,
    "4.35": 40,
    "4.36": 40,
    "4.37": 40,
    "4.38": 40,
    "4.39": 40,
    "4.40": 40,
    "4.41": 40,
    "4.42": 40,
    "4.43": 40,
    "4.44": 40,
    "4.45": 40,
    "4.46": 40,
    "4.47": 40,
    "4.48": 40,
    "4.49": 40,
    "4.50": 45,
    "4.51": 45,
    "4.52": 45,
    "4.53": 45,
    "4.54": 45,
    "4.55": 45,
    "4.56": 45,
    "4.57": 45,
    "4.58": 45,
    "4.59": 45,
    "4.60": 45,
    "4.61": 45,
    "4.62": 45,
    "4.63": 45,
    "4.64": 45,
    "4.65": 45,
    "4.66": 45,
    "4.67": 50,
    "4.68": 50,
    "4.69": 50,
    "4.70": 50,
    "4.71": 50,
    "4.72": 50,
    "4.73": 50,
    "4.74": 50,
    "4.75": 50,
    "4.76": 50,
    "4.77": 50,
    "4.78": 50,
    "4.79": 50,
    "4.80": 50,
    "4.81": 50,
    "4.82": 50,
    "4.83": 55,
    "4.84": 55,
    "4.85": 55,
    "4.86": 55,
    "4.87": 55,
    "4.88": 55,
    "4.89": 55,
    "4.90": 55,
    "4.91": 55,
    "4.92": 55,
    "4.93": 55,
    "4.94": 55,
    "4.95": 55,
    "4.96": 55,
    "4.97": 55,
    "4.98": 55,
    "4.99": 55,
    "5.01": 65,
    "5.02": 65,
    "5.03": 65,
    "5.04": 65,
    "5.05": 65,
    "5.06": 65,
    "5.07": 65,
    "5.08": 65,
    "5.09": 65,
    "5.10": 65,
    "5.11": 65,
    "5.12": 65,
    "5.13": 65,
    "5.14": 65,
    "5.15": 65,
    "5.16": 65,
    "5.17": 70,
    "5.18": 70,
    "5.19": 70,
    "5.20": 70,
    "5.21": 70,
    "5.22": 70,
    "5.23": 70,
    "5.24": 70,
    "5.25": 70,
    "5.26": 70,
    "5.27": 70,
    "5.28": 70,
    "5.29": 70,
    "5.30": 70,
    "5.31": 70,
    "5.32": 70,
    "5.33": 75,
    "5.34": 75,
    "5.35": 75,
    "5.36": 75,
    "5.37": 75,
    "5.38": 75,
    "5.39": 75,
    "5.40": 75,
    "5.41": 75,
    "5.42": 75,
    "5.43": 75,
    "5.44": 75,
    "5.45": 75,
    "5.46": 75,
    "5.47": 75,
    "5.48": 75,
    "5.49": 75,
    "5.50": 80,
    "5.51": 80,
    "5.52": 80,
    "5.53": 80,
    "5.54": 80,
    "5.55": 80,
    "5.56": 80,
    "5.57": 80,
    "5.58": 80,
    "5.59": 80,
    "5.60": 80,
    "5.61": 80,
    "5.62": 80,
    "5.63": 80,
    "5.64": 80,
    "5.65": 80,
    "5.66": 80,
    "5.67": 85,
    "5.68": 85,
    "5.69": 85,
    "5.70": 85,
    "5.71": 85,
    "5.72": 85,
    "5.73": 85,
    "5.74": 85,
    "5.75": 85,
    "5.76": 85,
    "5.77": 85,
    "5.78": 85,
    "5.79": 85,
    "5.80": 85,
    "5.81": 85,
    "5.82": 85,
    "5.83": 85,
    "5.84": 85,
    "5.85": 85,
    "5.86": 85,
    "5.87": 85,
    "5.88": 85,
    "5.89": 85,
    "5.90": 85,
    "5.91": 85,
    "5.92": 85,
    "5.93": 85,
    "5.94": 85,
    "5.95": 85,
    "5.96": 85,
    "5.97": 85,
    "5.98": 85,
    "5.99": 85,
    "6.01": 90,
    "6.02": 90,
    "6.03": 90,
    "6.04": 90,
    "6.05": 90,
    "6.06": 90,
    "6.07": 90,
    "6.08": 90,
    "6.09": 90,
    "6.10": 90,
    "6.11": 90,
    "6.12": 90,
    "6.13": 90,
    "6.14": 90,
    "6.15": 90,
    "6.16": 90,
    "6.17": 95
}
