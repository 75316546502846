// d:/Source/PROJETO_JETO/psicotecnico/data/DataEsavi.js
export const esavi = [
    {
        "pergunta": "Perco a concentração facilmente.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Meu pensamento é disperso.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Penso em como estara minha vida daqui a 5 anos.",
        "fator": "F3",
        "calculo": 0
    },
    {
        "pergunta": "Planejo minhas ações cuidadosamente.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Fico entendiado com facilidade.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Fico nervoso quando tenho que realizar várias atividades ao mesmo tempo.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Penso antes de falar.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Tenho dificuldade de terminar o que começo.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Em salas de espera fico irritado.",
        "fator": "F1",
        "calculo": 6
    },
    {
        "pergunta": "Sou uma pessoa ousada.",
        "fator": "F4",
        "calculo": 0
    },
    {
        "pergunta": "Sou cauteloso em minhas atividades.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Em conversa com amigos, penso antes de expressar minhas opiniões.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Defino minhas estratégias antes de agir.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Procuro avaliar os riscos antes de agir.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Perco o interesse rapidamente quando começo uma atividade.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Gosto de atividades que envolvam risco",
        "fator": "F4",
        "calculo": 0
    },
    {
        "pergunta": "Gosto de prever o que irá acontecer com minha vida.",
        "fator": "F3",
        "calculo": 0
    },
    {
        "pergunta": "Sou uma pessoa ansiosa.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Acredito que viver o presente é mais importante do que planejar o futuro.",
        "fator": "F3",
        "calculo": 0
    },
    {
        "pergunta": "Tenho dificuldade em cumprir as tarefas da vida cotidiana.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Penso mais no  futuro do que no presente.",
        "fator": "F3",
        "calculo": 0
    },
    {
        "pergunta": "Ajo de forma imediata para conseguir o que quero.",
        "fator": "F4",
        "calculo": 0
    },
    {
        "pergunta": "Procuro programar o que tenho a fazer no dia.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Tenho dificuldade de manter a atenção por períodos longos.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Gosto de assumir riscos, mesmo sem obter benefícios com isso.",
        "fator": "F4",
        "calculo": 0
    },
    {
        "pergunta": "Durante minhas atividades,me distraio com facilidade.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Realizo minhas vontades,independente de qualquer coisa.",
        "fator": "F4",
        "calculo": 0
    },
    {
        "pergunta": "Gosto de buscar sensações novas.",
        "fator": "F4",
        "calculo": 0
    },
    {
        "pergunta": "Fico irritado quando tenho que esperar por algo.",
        "fator": "F1",
        "calculo": 0
    },
    {
        "pergunta": "Antes de tomar uma decisão, analiso a situação cuidadosamente.",
        "fator": "F2",
        "calculo": 0
    },
    {
        "pergunta": "Prefiro viver o presente a pensar no futuro.",
        "fator": "F3",
        "calculo": 6
    }
]


export function calculaEsavi(objeto) {
    let f1 = objeto.filter(p => p.fator === "F1")
    let f2 = objeto.filter(p => p.fator === "F2")
    let f3 = objeto.filter(p => p.fator === "F3")
    let f4 = objeto.filter(p => p.fator === "F4")

    let f1Sum = 0
    f1.forEach(p => {
        if (p.calculo === 0) {
            f1Sum += p.response
        }
        else {
            f1Sum += (6 - p.response)
        }
    })
    let f2Sum = 0
    f2.forEach(p => {
        if (p.calculo === 0) {
            f2Sum += p.response
        }
        else {
            f2Sum += (6 - p.response)
        }
    })
    let f3Sum = 0
    f3.forEach(p => {
        if (p.calculo === 0) {
            f3Sum += p.response

        }
        else {
            f3Sum += (6 - p.response)
        }
    })
    let f4Sum = 0
    f4.forEach(p => {
        if (p.calculo === 0) {
            f4Sum += p.response
        }
        else {
            f4Sum += (6 - p.response)
        }
    })
    return {
        f1: f1Sum,
        f2: f2Sum,
        f3: f3Sum,
        f4: f4Sum
    }
}


export const notasF1 = {
    "1": 1,
    "2": 1,
    "3": 1,
    "4": 1,
    "5": 1,
    "6": 1,
    "7": 1,
    "8": 1,
    "9": 1,
    "10": 1,
    "11": 1,
    "12": 1,
    "13": 1,
    "14": 1,
    "15": 2,
    "16": 2,
    "17": 2,
    "18": 2,
    "19": 2,
    "20": 2,
    "21": 3,
    "22": 3,
    "23": 3,
    "24": 3,
    "25": 3,
    "26": 3,
    "27": 3,
    "28": 3,
    "29": 3,
    "30": 3,
    "31": 4,
    "32": 4,
    "33": 4,
    "34": 4,
    "35": 4,
    "36": 4,
    "37": 4,
    "38": 5,
    "39": 5,
    "40": 5,
    "41": 5,
    "42": 5,
    "43": 5,
    "44": 5,
    "45": 5,
    "46": 5,
    "47": 5,
    "48": 5,
    "49": 5,
    "50": 5,
    "51": 5,
    "52": 5,
    "53": 5,
    "54": 5,
    "55": 5,
    "56": 5,
    "57": 5,
    "58": 5,
    "59": 5,
    "60": 5
}

export const notasF2 = {
    "1": 1,
    "2": 1,
    "3": 1,
    "4": 1,
    "5": 1,
    "6": 1,
    "7": 1,
    "8": 1,
    "9": 1,
    "10": 1,
    "11": 1,
    "12": 1,
    "13": 1,
    "14": 1,
    "15": 2,
    "16": 2,
    "17": 2,
    "18": 2,
    "19": 2,
    "20": 2,
    "21": 3,
    "22": 3,
    "23": 3,
    "24": 3,
    "25": 3,
    "26": 3,
    "27": 3,
    "28": 3,
    "29": 3,
    "30": 3,
    "31": 4,
    "32": 4,
    "33": 4,
    "34": 4,
    "35": 4,
    "36": 4,
    "37": 4,
    "38": 5,
    "39": 5,
    "40": 5,
    "41": 5,
    "42": 5,
    "43": 5,
    "44": 5,
    "45": 5,
    "46": 5,
    "47": 5,
    "48": 5,
    "49": 5,
    "50": 5,
    "51": 5,
    "52": 5,
    "53": 5,
    "54": 5,
    "55": 5,
    "56": 5,
    "57": 5,
    "58": 5,
    "59": 5,
    "60": 5
}

export const notasF3 = {
    "1": 1,
    "2": 1,
    "3": 1,
    "4": 1,
    "5": 1,
    "6": 1,
    "7": 1,
    "8": 1,
    "9": 1,
    "10": 2,
    "11": 2,
    "12": 2,
    "13": 2,
    "14": 3,
    "15": 3,
    "16": 3,
    "17": 3,
    "18": 3,
    "19": 3,
    "20": 4,
    "21": 4,
    "22": 4,
    "23": 5,
    "24": 5,
    "25": 5,
    "26": 5,
    "27": 5,
    "28": 5,
    "29": 5,
    "30": 5,
    "31": 5,
    "32": 5,
    "33": 5,
    "34": 5,
    "35": 5,
    "36": 5,
    "37": 5,
    "38": 5,
    "39": 5,
    "40": 5,
    "41": 5,
    "42": 5,
    "43": 5,
    "44": 5,
    "45": 5,
    "46": 5,
    "47": 5,
    "48": 5,
    "49": 5,
    "50": 5,
    "51": 5,
    "52": 5,
    "53": 5,
    "54": 5,
    "55": 5,
    "56": 5,
    "57": 5,
    "58": 5,
    "59": 5,
    "60": 5
}

export const notasF4 = {
    "1": 1,
    "2": 1,
    "3": 1,
    "4": 1,
    "5": 1,
    "6": 1,
    "7": 1,
    "8": 1,
    "9": 1,
    "10": 1,
    "11": 2,
    "12": 2,
    "13": 2,
    "14": 3,
    "15": 3,
    "16": 3,
    "17": 3,
    "18": 3,
    "19": 3,
    "20": 4,
    "21": 4,
    "22": 4,
    "23": 5,
    "24": 5,
    "25": 5,
    "26": 5,
    "27": 5,
    "28": 5,
    "29": 5,
    "30": 5,
    "31": 5,
    "32": 5,
    "33": 5,
    "34": 5,
    "35": 5,
    "36": 5,
    "37": 5,
    "38": 5,
    "39": 5,
    "40": 5,
    "41": 5,
    "42": 5,
    "43": 5,
    "44": 5,
    "45": 5,
    "46": 5,
    "47": 5,
    "48": 5,
    "49": 5,
    "50": 5,
    "51": 5,
    "52": 5,
    "53": 5,
    "54": 5,
    "55": 5,
    "56": 5,
    "57": 5,
    "58": 5,
    "59": 5,
    "60": 5
}

