
export default function Title(props) {
    const {title, instructions} = props

    return (
        <div className="mt-5 p-2 border shadow title text-light">
            <div className="text-center">
                <div className="teste-titulo">
                    <h1 className="mt-5">{title}</h1>
                </div>
            </div>
            <div className="mt-3">{instructions}</div>
        </div>
    )
}

