// d:/Source/PROJETO_JETO/psicotecnico/data/DataEata.js
export const Eata = [
    { prompt: 'Culpo outra pessoas por coisas que fiz' },
    { prompt: 'Ao cometer um erro, mostro indiferença' },
    { prompt: 'Falo dos outros pelas costas' },
    { prompt: 'Gosto de bater em animais' },
    { prompt: 'Gosto de brincar de empurrar ou provocar quedas para causar constrangimento' },
    { prompt: 'Gosto de deixar as pessoas com raiva de mim' },
    { prompt: 'Gosto de mostrar meus conhecimentos' },
    { prompt: 'Quando não gosto de alguém, sinto vontade de esconder a verdade para prejudicá-lo' },
    { prompt: 'Quando não gosto de alguém, sinto vontade de criar uma situação para desafiá-lo' },
    { prompt: 'Quando sinto raiva, tenho vontade de descontar em alguém' },
    { prompt: 'Relato sentimentos não verdadeiros para iniciar um relacionamento sexual' },
    { prompt: 'Tenho vontade de andar armado' },
    { prompt: 'Transo sem preservativo com diferentes parceiros' },
    { prompt: 'Xingo pessoas por não quererem ter relações sexuais comigo' },
    { prompt: 'Altero o tom de voz quando não sou atendido ou entendido' },
    { prompt: 'Aumento uma informação para provocar sentimento de culpa nos outros' },
    { prompt: 'Deixo de alimentar meu animal de estimação para puni-lo' },
    { prompt: 'Descarrego extintores para brincar ou extravar tensão' },
    { prompt: 'Durante uma conversa não deixo espaço para o outro se colocar' },
    { prompt: 'Estaciono o carro em uma vaga para deficientes físicos' },
    { prompt: 'Gosto de bater em placas de trânsito' },
    { prompt: 'Gosto de correr com o carro' },
    { prompt: 'Ponho apelido maldosos nas pessoas' },
    { prompt: 'Provoco os outros relembrando falhas que cometeram' },
    { prompt: 'Quebro e/ou arranco plantas em jardins' },
    { prompt: 'Se não tiver perigo de ser visto, entro no cinema sem pagar' },
    { prompt: 'Sinto vontade de fazer coisas independente das normas' },
    { prompt: 'Ameaço contar "segredos" da pessoas para forçá-la a fazer algo' },
    { prompt: 'Cometo erros passados mesmo sabendo o dano causado' },
    { prompt: 'Dirijo após ingerir bebida alcoólica' },
    { prompt: 'Distorço ou amenizo uma informação que poderia me trazer prejuízos' },
    { prompt: 'Faço ultrapassagens em locais proibidos' },
    { prompt: 'Gosto de brigar' },
    { prompt: 'Gosto de controlar informações para que os outros façam o que quero' },
    { prompt: 'Gosto de incomodar os outros' },
    { prompt: 'Gosto de xavecar pessoas com menos de 18 anos' },
    { prompt: 'Quando encontro com uma turma, sempre tenho fofoca para contar' },
    { prompt: 'Quando faço algo errado, disfarço ou saio para não perceberem que fui eu' },
    { prompt: 'Quando quebro alguma coisa, digo que foi outra pessoa' },
    { prompt: 'Utilizo o nome de alguém para conseguir benefícios sem seu conhecimento' }

];



