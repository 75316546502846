import QuestionEATA from "../components/EATA/QuestionEATA";
import { Eata } from "../data/DataEata";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Layout from "./layout";
import Title from "../components/Title";
import { Divider } from "@mui/material";
import ButtonSubmit from "../components/ButtonSubmit";
import {useNavigate} from "react-router-dom";

function EATAPage() {
    const [data, setData] = useState([...Eata])
    const navigate = useNavigate()


    useEffect(() => {
        if (Cookies.get("EATA")) {
    
            
            const cookieJson = JSON.parse(Cookies.get("EATA"))
            const copyData = [...Eata]
            copyData.forEach((p, i) => {
                p.response = cookieJson[i]
            })

            
            
        }
    }, [])
    

    const handleTotalSelectedValueCalculation = () => {
    
        let hadNull = false
        let soma = 0
        const cookie = []

        data.forEach((p, i) => {

            if (p.response === undefined) {

                hadNull = true
                setData(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "error"
                    return newState
                })


            }
            else {


                soma += parseInt(p.response)
                
                cookie.push(p.response)
                
                setData(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "success"
                    return newState
                })


            }




        })
        if (hadNull) {
            alert("Por favor, responda todas as questões")

        }


        else {

            Cookies.set("EATA", JSON.stringify(cookie), { expires: 365 });


            localStorage.setItem("EATA", JSON.stringify(soma));
            navigate("/EataResult")
            

        }
    }




    const handleOnChange = (e, index) => {
        setData(prevState => {
            const newState = [...prevState]
            const value = e.target.value
            newState[index].response = value
            newState[index].color = "success"


            return newState
        })
        



    }
    const instructions = (
        <>
        <p>Responda às perguntas abaixo com relação às alternativas sendo:</p>
        <ol start={0}>
            <li>NR - Nunca ou raramente </li>
            <li>AV - Ás vezes</li>
            <li>MF - Muito Frequentemente</li>
        </ol>
        </>
    )


    return (
        <Layout>
            <Title title="EATA" instructions={instructions} />
            {data.map((p, i) => {
                return (


                    <div key={i}>
                        <QuestionEATA
                            pergunta={(i + 1) + ". " + p.prompt}
                            resposta={p.response}
                            color={p.color}
                            id={i}
                            key={i}

                            onChange={handleOnChange}
                        />

                    </div>



                )
            }
            )}
            <Divider />
            <ButtonSubmit onClick={handleTotalSelectedValueCalculation} />


        </Layout>

    )




}
export default EATAPage;