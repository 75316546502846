// d:/Source/PROJETO_JETO/psicotecnico/data/DataIFPII.js
export const perguntas = [
    {
        "pergunta": "Gosto de fazer coisas que outras pessoas consideram fora do comum",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gostaria de realizar um grande feito ou grande obra na minha vida",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gostaria de experimentar novidades e mudanças em meu dia a dia",
        "escala": "Mudança"
    },
    {
        "pergunta": "Não gosto de situações em que se exige que eu me comporte de determinada maneira",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de dizer o que eu penso a respeito das coisas",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de saber o que grandes personalidades disseram sobre os problemas pelos quais eu me interesso",
        "escala": "Deferência"
    },
    {
        "pergunta": "Gosto de ser capaz de fazer as coisas melhor do que as outras pessoas",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gosto de concluir qualquer trabalho ou tarefa que tenha começado",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto de ajudar meus amigos quando eles estão com problemas",
        "escala": "Assistência"
    },
    {
        "pergunta": "Não costumo abandonar um quebra-cabeça ou problema antes que consiga resolvê-lo",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto de dizer aos outros como fazer seus trabalhos",
        "escala": "Dominância"
    },
    {
        "pergunta": "Gostaria de ser considerado uma autoridade em algum trabalho, profissão ou campo de especialização",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gostaria de experimentar e provar coisas novas",
        "escala": "Mudança"
    },
    {
        "pergunta": "Quando tenho alguma tarefa para fazer, gosto de começar logo e permanecer trabalhando até completá-la",
        "escala": "Persistência"
    },
    {
        "pergunta": "Aceito com prazer a liderança das pessoas que admiro",
        "escala": "Deferência"
    },
    {
        "pergunta": "Gosto de trabalhar horas a fio sem ser interrompido",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto que meus amigos me dêem muita atenção quando estou sofrendo ou doente",
        "escala": "Afago"
    },
    {
        "pergunta": "Costumo analisar minhas intenções e sentimentos",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Gosto de fazer com carinho pequenos favores a meus amigos",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto de ficar acordado até tarde para terminar um trabalho",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto de andar pelo pais  e viver em lugares diferentes",
        "escala": "Mudança"
    },
    {
        "pergunta": "Gosto de analisar os sentimentos e intenções dos outros",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Gosto de fazer gozação com pessoas que fazem coisas que eu considero estúpidas",
        "escala": "Agressividade"
    },
    {
        "pergunta": "Tenho vontade de me vingar quando alguém me insulta",
        "escala": "Agressividade"
    },
    {
        "pergunta": "Gosto de pensar sobre o caráter dos meus amigos e tentar descobrir o que os faz serem como são",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Sou leal aos meus amigos",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto de levar trabalho ou tarefa até o fim antes de começar outro",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto de dizer aos meus superiores que eles fizeram um bom trabalho, quando acredito nisso",
        "escala": "Deferência"
    },
    {
        "pergunta": "Gosto que meus amigos sejam solidários comigo e me animem quando estou deprimido",
        "escala": "Afago"
    },
    {
        "pergunta": "Antes de começar um trabalho, gosto de organizá-lo e planejá-lo",
        "escala": "Ordem"
    },
    {
        "pergunta": "Gosto que meus amigos demonstrem muito afeto por mim",
        "escala": "Afago"
    },
    {
        "pergunta": "Gosto de realizar tarefas que, na opinião dos outros, exigem habilidade e esforço",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gosto de ser bem sucedido nas coisas que faço",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gosto de fazer amizades",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto de ser considerado um líder pelos outros",
        "escala": "Dominância"
    },
    {
        "pergunta": "Gosto de realizar com afinco (sem descanso) qualquer trabalho que faço",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto de participar de grupos cujos membros se tratem com afeto e amizade",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Sinto-me satisfeito quando realizo bem um trabalho difícil",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Tenho vontade de mandar os outros calarem a boca quando discordo deles",
        "escala": "Agressividade"
    },
    {
        "pergunta": "Gosto de fazer coisas do meu jeito sem me importar com o que os outros possam pensar",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de viajar e conhecer o país",
        "escala": "Mudança"
    },
    {
        "pergunta": "Gosto de me fixar em um trabalho ou problema mesmo quando a solução pareça extremamente difícil",
        "escala": "Persistência"
    },
    {
        "pergunta": "Gosto de conhecer pessoas novas",
        "escala": "Mudança"
    },
    {
        "pergunta": "Gosto de dividir coisas com os outros",
        "escala": "Assistência"
    },
    {
        "pergunta": "Sinto-me satisfeito quando consigo convencer e influenciar os outros",
        "escala": "Dominância"
    },
    {
        "pergunta": "Gosto de demonstrar muita afeição por meus amigos",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto de prestar favores aos outros",
        "escala": "Assistência"
    },
    {
        "pergunta": "Gosto de seguir instruções e fazer o que é esperado de mim",
        "escala": "Deferência"
    },
    {
        "pergunta": "Gosto de elogiar alguém que admiro",
        "escala": "Deferência"
    },
    {
        "pergunta": "Quando planejo alguma coisa, procuro sugestões de pessoas que respeito",
        "escala": "Deferência"
    },
    {
        "pergunta": "Gosto de manter minhas coisas limpas e ordenadas em minha escrivaninha ou em meu local de trabalho",
        "escala": "Ordem"
    },
    {
        "pergunta": "Gosto de manter fortes laços de amizade",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto que meus amigos me ajudem quando estou com problema",
        "escala": "Afago"
    },
    {
        "pergunta": "Gosto que meus amigos mostrem boa vontade em me prestar pequenos favores",
        "escala": "Afago"
    },
    {
        "pergunta": "Gosto de manter minhas cartas, contas e outros papéis bem arrumados e arquivados de acordo com algum sistema",
        "escala": "Ordem"
    },
    {
        "pergunta": "Gosto que meus amigos sejam solidários e compreensivos quando tenho problemas",
        "escala": "Afago"
    },
    {
        "pergunta": "Prefiro fazer coisas com meus amigos a fazer sozinho",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto de tratar outras pessoas com bondade e compaixão",
        "escala": "Assistência"
    },
    {
        "pergunta": "Gosto de comer em restaurantes novo e exóticos (diferentes)",
        "escala": "Mudança"
    },
    {
        "pergunta": "Procuro entender como meus amigos se sentem a respeito de problemas que eles enfrentam",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Gosto de ser o centro das atenções em um grupo",
        "escala": "Exibição"
    },
    {
        "pergunta": "Gosto de ser um dos líderes nas organizações e grupos aos quais pertenço",
        "escala": "Dominância"
    },
    {
        "pergunta": "Gosto de ser independente dos outros para decidir o que quero fazer",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de me manter em contato com meus amigos",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Quando participo de uma comissão (reunião), gosto de ser indicado ou eleito presidente",
        "escala": "Dominância"
    },
    {
        "pergunta": "Gosto de fazer tantos amigos quanto possível",
        "escala": "Afiliação"
    },
    {
        "pergunta": "Gosto de observar como uma pessoa se sente numa determinada situação",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Quando estou em um grupo, aceito com prazer a liderança de outra pessoa para decidir o que o grupo fará",
        "escala": "Deferência"
    },
    {
        "pergunta": "Não gosto de me sentir pressionado por responsabilidades e deveres",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Às vezes, fico tão irritado que sinto vontade de jogar e quebrar coisas",
        "escala": "Agressividade"
    },
    {
        "pergunta": "Gosto de fazer perguntas que ninguém será capaz de responder",
        "escala": "Exibição"
    },
    {
        "pergunta": "Às vezes, gosto de fazer coisas simplesmente para ver o efeito que terão sobre os outros",
        "escala": "Exibição"
    },
    {
        "pergunta": "Sou solidário com meus amigos quando machucados ou doentes",
        "escala": "Assistência"
    },
    {
        "pergunta": "Não tenho medo de criticar pessoas que ocupam posições de autoridade",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de fiscalizar e dirigir os atos dos outros sempre que posso",
        "escala": "Dominância"
    },
    {
        "pergunta": "Culpo os outros quando as coisas dão errado comigo",
        "escala": "Agressividade"
    },
    {
        "pergunta": "Gosto de ajudar pessoas que têm menos sorte do que eu",
        "escala": "Assistência"
    },
    {
        "pergunta": "Gosto de planejar e organizar, em todos os detalhes, qualquer trabalho que eu faço",
        "escala": "Ordem"
    },
    {
        "pergunta": "Gosto de fazer coisas novas e diferentes",
        "escala": "Mudança"
    },
    {
        "pergunta": "Gostaria de realizar com sucesso alguma coisa de grande importância",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Quando estou com um grupo de pessoas, gosto de decidir sobre o que vamos fazer",
        "escala": "Dominância"
    },
    {
        "pergunta": "Interesso-me em conhecer a vida de grandes personalidades",
        "escala": "Deferência"
    },
    {
        "pergunta": "Procuro me adaptar ao modo de ser das pessoas que admiro",
        "escala": "Deferência"
    },
    {
        "pergunta": "Gosto de resolver quebra-cabeças e problemas com os quais outras pessoas têm dificuldades",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gosto de falar sobre os meus sucessos",
        "escala": "Exibição"
    },
    {
        "pergunta": "Gosto de dar o melhor de mim em tudo que faço",
        "escala": "Desempenho"
    },
    {
        "pergunta": "Gosto de estudar e analisar o comportamento dos outros",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Gosto de contar aos outros aventuras e coisas estranhas que acontecem comigo",
        "escala": "Exibição"
    },
    {
        "pergunta": "Perdôo as pessoas que às vezes possam me magoar",
        "escala": "Assistência"
    },
    {
        "pergunta": "Gosto de prever (entender) como meus amigos irão agir em diferentes situações",
        "escala": "Intracepção"
    },
    {
        "pergunta": "Gosto de me sentir livre para fazer o que quero",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de me sentir livre para ir e vir quando quiser",
        "escala": "Autonomia"
    },
    {
        "pergunta": "Gosto de usar palavras cujo significado as outras pessoas desconhecem",
        "escala": "Exibição"
    },
    {
        "pergunta": "Gosto de planejar antes de iniciar algo difícil",
        "escala": "Ordem"
    },
    {
        "pergunta": "Qualquer trabalho escrito que faço, gosto que seja preciso, limpo e bem organizado",
        "escala": "Ordem"
    },
    {
        "pergunta": "Gosto que as pessoas notem e comentem a minha aparência quando estou em público",
        "escala": "Exibição"
    },
    {
        "pergunta": "Gosto que meus amigos me tratem com delicadeza",
        "escala": "Afago"
    },
    {
        "pergunta": "Gosto de ser generoso com os outros",
        "escala": "Assistência"
    },
    {
        "pergunta": "Gosto de contar estórias e piadas engraçadas em festas",
        "escala": "Exibição"
    },
    {
        "pergunta": "Gosto de dizer coisas que os outros consideram engraçadas e inteligentes",
        "escala": "Exibição"
    }
]
const AFAGO = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 10,
        "feminino": 5
    },
    "22": {
        "masculino": 10,
        "feminino": 5
    },
    "23": {
        "masculino": 15,
        "feminino": 5
    },
    "24": {
        "masculino": 15,
        "feminino": 10
    },
    "25": {
        "masculino": 20,
        "feminino": 10
    },
    "26": {
        "masculino": 25,
        "feminino": 15
    },
    "27": {
        "masculino": 30,
        "feminino": 20
    },
    "28": {
        "masculino": 30,
        "feminino": 20
    },
    "29": {
        "masculino": 35,
        "feminino": 25
    },
    "30": {
        "masculino": 45,
        "feminino": 30
    },
    "31": {
        "masculino": 50,
        "feminino": 40
    },
    "32": {
        "masculino": 55,
        "feminino": 45
    },
    "33": {
        "masculino": 60,
        "feminino": 50
    },
    "34": {
        "masculino": 65,
        "feminino": 55
    },
    "35": {
        "masculino": 70,
        "feminino": 65
    },
    "36": {
        "masculino": 75,
        "feminino": 70
    },
    "37": {
        "masculino": 80,
        "feminino": 75
    },
    "38": {
        "masculino": 80,
        "feminino": 75
    },
    "39": {
        "masculino": 85,
        "feminino": 80
    },
    "40": {
        "masculino": 85,
        "feminino": 85
    },
    "41": {
        "masculino": 85,
        "feminino": 85
    },
    "42": {
        "masculino": 85,
        "feminino": 90
    },
    "43": {
        "masculino": 90,
        "feminino": 90
    },
    "44": {
        "masculino": 90,
        "feminino": 95
    },
    "45": {
        "masculino": 90,
        "feminino": 95
    },
    "46": {
        "masculino": 90,
        "feminino": 95
    },
    "47": {
        "masculino": 90,
        "feminino": 95
    },
    "48": {
        "masculino": 90,
        "feminino": 95
    },
    "49": {
        "masculino": 100,
        "feminino": 100
    }
}
const AFILIACAO = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 5,
        "feminino": 5
    },
    "28": {
        "masculino": 5,
        "feminino": 5
    },
    "29": {
        "masculino": 5,
        "feminino": 5
    },
    "30": {
        "masculino": 5,
        "feminino": 5
    },
    "31": {
        "masculino": 5,
        "feminino": 5
    },
    "32": {
        "masculino": 5,
        "feminino": 5
    },
    "33": {
        "masculino": 5,
        "feminino": 5
    },
    "34": {
        "masculino": 5,
        "feminino": 5
    },
    "35": {
        "masculino": 5,
        "feminino": 5
    },
    "36": {
        "masculino": 5,
        "feminino": 5
    },
    "37": {
        "masculino": 5,
        "feminino": 5
    },
    "38": {
        "masculino": 5,
        "feminino": 5
    },
    "39": {
        "masculino": 5,
        "feminino": 5
    },
    "40": {
        "masculino": 5,
        "feminino": 5
    },
    "41": {
        "masculino": 5,
        "feminino": 5
    },
    "42": {
        "masculino": 10,
        "feminino": 5
    },
    "43": {
        "masculino": 10,
        "feminino": 5
    },
    "44": {
        "masculino": 15,
        "feminino": 10
    },
    "45": {
        "masculino": 15,
        "feminino": 10
    },
    "46": {
        "masculino": 20,
        "feminino": 15
    },
    "47": {
        "masculino": 25,
        "feminino": 20
    },
    "48": {
        "masculino": 30,
        "feminino": 20
    },
    "49": {
        "masculino": 35,
        "feminino": 25
    },
    "50": {
        "masculino": 40,
        "feminino": 30
    },
    "51": {
        "masculino": 45,
        "feminino": 35
    },
    "52": {
        "masculino": 50,
        "feminino": 40
    },
    "53": {
        "masculino": 55,
        "feminino": 50
    },
    "54": {
        "masculino": 60,
        "feminino": 55
    },
    "55": {
        "masculino": 65,
        "feminino": 60
    },
    "56": {
        "masculino": 75,
        "feminino": 65
    },
    "57": {
        "masculino": 80,
        "feminino": 70
    },
    "58": {
        "masculino": 80,
        "feminino": 75
    },
    "59": {
        "masculino": 85,
        "feminino": 80
    },
    "60": {
        "masculino": 90,
        "feminino": 85
    },
    "61": {
        "masculino": 90,
        "feminino": 90
    },
    "62": {
        "masculino": 95,
        "feminino": 95
    },
    "63": {
        "masculino": 100,
        "feminino": 100
    }
}
const AGRESSIVIDADE = {
    "1": {
        "masculino": 15,
        "feminino": 15
    },
    "2": {
        "masculino": 15,
        "feminino": 15
    },
    "3": {
        "masculino": 15,
        "feminino": 15
    },
    "4": {
        "masculino": 15,
        "feminino": 15
    },
    "5": {
        "masculino": 15,
        "feminino": 15
    },
    "6": {
        "masculino": 25,
        "feminino": 25
    },
    "7": {
        "masculino": 35,
        "feminino": 30
    },
    "8": {
        "masculino": 40,
        "feminino": 40
    },
    "9": {
        "masculino": 50,
        "feminino": 50
    },
    "10": {
        "masculino": 55,
        "feminino": 55
    },
    "11": {
        "masculino": 65,
        "feminino": 60
    },
    "12": {
        "masculino": 70,
        "feminino": 70
    },
    "13": {
        "masculino": 75,
        "feminino": 75
    },
    "14": {
        "masculino": 80,
        "feminino": 75
    },
    "15": {
        "masculino": 80,
        "feminino": 80
    },
    "16": {
        "masculino": 85,
        "feminino": 85
    },
    "17": {
        "masculino": 90,
        "feminino": 85
    },
    "18": {
        "masculino": 90,
        "feminino": 90
    },
    "19": {
        "masculino": 90,
        "feminino": 90
    },
    "20": {
        "masculino": 95,
        "feminino": 90
    },
    "21": {
        "masculino": 95,
        "feminino": 95
    },
    "22": {
        "masculino": 95,
        "feminino": 95
    },
    "23": {
        "masculino": 95,
        "feminino": 95
    },
    "24": {
        "masculino": 95,
        "feminino": 95
    },
    "25": {
        "masculino": 95,
        "feminino": 95
    },
    "26": {
        "masculino": 95,
        "feminino": 95
    },
    "27": {
        "masculino": 95,
        "feminino": 95
    },
    "28": {
        "masculino": 95,
        "feminino": 95
    },
    "29": {
        "masculino": 95,
        "feminino": 95
    },
    "30": {
        "masculino": 95,
        "feminino": 95
    },
    "31": {
        "masculino": 95,
        "feminino": 95
    },
    "32": {
        "masculino": 95,
        "feminino": 95
    },
    "33": {
        "masculino": 95,
        "feminino": 95
    },
    "34": {
        "masculino": 95,
        "feminino": 95
    },
    "35": {
        "masculino": 100,
        "feminino": 100
    }
}
const ASSISTENCIA = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 5,
        "feminino": 5
    },
    "28": {
        "masculino": 5,
        "feminino": 5
    },
    "29": {
        "masculino": 5,
        "feminino": 5
    },
    "30": {
        "masculino": 5,
        "feminino": 5
    },
    "31": {
        "masculino": 5,
        "feminino": 5
    },
    "32": {
        "masculino": 5,
        "feminino": 5
    },
    "33": {
        "masculino": 5,
        "feminino": 5
    },
    "34": {
        "masculino": 5,
        "feminino": 5
    },
    "35": {
        "masculino": 5,
        "feminino": 5
    },
    "36": {
        "masculino": 10,
        "feminino": 5
    },
    "37": {
        "masculino": 10,
        "feminino": 5
    },
    "38": {
        "masculino": 15,
        "feminino": 10
    },
    "39": {
        "masculino": 20,
        "feminino": 10
    },
    "40": {
        "masculino": 25,
        "feminino": 15
    },
    "41": {
        "masculino": 30,
        "feminino": 25
    },
    "42": {
        "masculino": 35,
        "feminino": 30
    },
    "43": {
        "masculino": 45,
        "feminino": 35
    },
    "44": {
        "masculino": 50,
        "feminino": 40
    },
    "45": {
        "masculino": 55,
        "feminino": 50
    },
    "46": {
        "masculino": 60,
        "feminino": 55
    },
    "47": {
        "masculino": 70,
        "feminino": 60
    },
    "48": {
        "masculino": 75,
        "feminino": 65
    },
    "49": {
        "masculino": 75,
        "feminino": 70
    },
    "50": {
        "masculino": 80,
        "feminino": 75
    },
    "51": {
        "masculino": 85,
        "feminino": 80
    },
    "52": {
        "masculino": 90,
        "feminino": 85
    },
    "53": {
        "masculino": 90,
        "feminino": 90
    },
    "54": {
        "masculino": 95,
        "feminino": 90
    },
    "55": {
        "masculino": 95,
        "feminino": 95
    },
    "56": {
        "masculino": 100,
        "feminino": 100
    }
}
const AUTONOMIA = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 10,
        "feminino": 5
    },
    "28": {
        "masculino": 10,
        "feminino": 10
    },
    "29": {
        "masculino": 10,
        "feminino": 10
    },
    "30": {
        "masculino": 15,
        "feminino": 15
    },
    "31": {
        "masculino": 20,
        "feminino": 20
    },
    "32": {
        "masculino": 20,
        "feminino": 20
    },
    "33": {
        "masculino": 25,
        "feminino": 25
    },
    "34": {
        "masculino": 30,
        "feminino": 30
    },
    "35": {
        "masculino": 35,
        "feminino": 35
    },
    "36": {
        "masculino": 40,
        "feminino": 40
    },
    "37": {
        "masculino": 50,
        "feminino": 45
    },
    "38": {
        "masculino": 55,
        "feminino": 55
    },
    "39": {
        "masculino": 60,
        "feminino": 60
    },
    "40": {
        "masculino": 65,
        "feminino": 65
    },
    "41": {
        "masculino": 70,
        "feminino": 70
    },
    "42": {
        "masculino": 75,
        "feminino": 75
    },
    "43": {
        "masculino": 75,
        "feminino": 75
    },
    "44": {
        "masculino": 80,
        "feminino": 80
    },
    "45": {
        "masculino": 85,
        "feminino": 85
    },
    "46": {
        "masculino": 85,
        "feminino": 85
    },
    "47": {
        "masculino": 90,
        "feminino": 90
    },
    "48": {
        "masculino": 90,
        "feminino": 90
    },
    "49": {
        "masculino": 90,
        "feminino": 90
    },
    "50": {
        "masculino": 95,
        "feminino": 95
    },
    "51": {
        "masculino": 95,
        "feminino": 95
    },
    "52": {
        "masculino": 95,
        "feminino": 95
    },
    "53": {
        "masculino": 95,
        "feminino": 95
    },
    "54": {
        "masculino": 95,
        "feminino": 95
    },
    "55": {
        "masculino": 95,
        "feminino": 95
    },
    "56": {
        "masculino": 95,
        "feminino": 95
    },
    "57": {
        "masculino": 95,
        "feminino": 95
    },
    "58": {
        "masculino": 95,
        "feminino": 95
    },
    "59": {
        "masculino": 95,
        "feminino": 95
    },
    "60": {
        "masculino": 95,
        "feminino": 95
    },
    "61": {
        "masculino": 95,
        "feminino": 95
    },
    "62": {
        "masculino": 100,
        "feminino": 100
    },
    "63": {
        "masculino": 100,
        "feminino": 100
    }
}
const DEFERENCIA = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 5,
        "feminino": 5
    },
    "28": {
        "masculino": 5,
        "feminino": 5
    },
    "29": {
        "masculino": 5,
        "feminino": 5
    },
    "30": {
        "masculino": 5,
        "feminino": 5
    },
    "31": {
        "masculino": 5,
        "feminino": 5
    },
    "32": {
        "masculino": 5,
        "feminino": 5
    },
    "33": {
        "masculino": 5,
        "feminino": 5
    },
    "34": {
        "masculino": 5,
        "feminino": 5
    },
    "35": {
        "masculino": 5,
        "feminino": 5
    },
    "36": {
        "masculino": 5,
        "feminino": 5
    },
    "37": {
        "masculino": 5,
        "feminino": 5
    },
    "38": {
        "masculino": 5,
        "feminino": 5
    },
    "39": {
        "masculino": 10,
        "feminino": 5
    },
    "40": {
        "masculino": 10,
        "feminino": 10
    },
    "41": {
        "masculino": 15,
        "feminino": 10
    },
    "42": {
        "masculino": 15,
        "feminino": 15
    },
    "43": {
        "masculino": 20,
        "feminino": 20
    },
    "44": {
        "masculino": 25,
        "feminino": 25
    },
    "45": {
        "masculino": 30,
        "feminino": 30
    },
    "46": {
        "masculino": 35,
        "feminino": 35
    },
    "47": {
        "masculino": 40,
        "feminino": 40
    },
    "48": {
        "masculino": 45,
        "feminino": 45
    },
    "49": {
        "masculino": 50,
        "feminino": 50
    },
    "50": {
        "masculino": 60,
        "feminino": 55
    },
    "51": {
        "masculino": 65,
        "feminino": 65
    },
    "52": {
        "masculino": 70,
        "feminino": 70
    },
    "53": {
        "masculino": 75,
        "feminino": 75
    },
    "54": {
        "masculino": 80,
        "feminino": 80
    },
    "55": {
        "masculino": 85,
        "feminino": 85
    },
    "56": {
        "masculino": 85,
        "feminino": 85
    },
    "57": {
        "masculino": 90,
        "feminino": 90
    },
    "58": {
        "masculino": 90,
        "feminino": 90
    },
    "59": {
        "masculino": 95,
        "feminino": 95
    },
    "60": {
        "masculino": 95,
        "feminino": 95
    },
    "61": {
        "masculino": 95,
        "feminino": 95
    },
    "62": {
        "masculino": 95,
        "feminino": 95
    },
    "63": {
        "masculino": 100,
        "feminino": 100
    }
}
const DESEMPENHO = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 5,
        "feminino": 5
    },
    "28": {
        "masculino": 5,
        "feminino": 5
    },
    "29": {
        "masculino": 5,
        "feminino": 5
    },
    "30": {
        "masculino": 5,
        "feminino": 5
    },
    "31": {
        "masculino": 5,
        "feminino": 5
    },
    "32": {
        "masculino": 5,
        "feminino": 5
    },
    "33": {
        "masculino": 5,
        "feminino": 5
    },
    "34": {
        "masculino": 5,
        "feminino": 5
    },
    "35": {
        "masculino": 5,
        "feminino": 5
    },
    "36": {
        "masculino": 5,
        "feminino": 5
    },
    "37": {
        "masculino": 5,
        "feminino": 5
    },
    "38": {
        "masculino": 5,
        "feminino": 5
    },
    "39": {
        "masculino": 5,
        "feminino": 5
    },
    "40": {
        "masculino": 5,
        "feminino": 5
    },
    "41": {
        "masculino": 5,
        "feminino": 5
    },
    "42": {
        "masculino": 10,
        "feminino": 10
    },
    "43": {
        "masculino": 10,
        "feminino": 10
    },
    "44": {
        "masculino": 15,
        "feminino": 15
    },
    "45": {
        "masculino": 15,
        "feminino": 20
    },
    "46": {
        "masculino": 20,
        "feminino": 20
    },
    "47": {
        "masculino": 25,
        "feminino": 25
    },
    "48": {
        "masculino": 30,
        "feminino": 30
    },
    "49": {
        "masculino": 35,
        "feminino": 40
    },
    "50": {
        "masculino": 40,
        "feminino": 45
    },
    "51": {
        "masculino": 45,
        "feminino": 50
    },
    "52": {
        "masculino": 50,
        "feminino": 55
    },
    "53": {
        "masculino": 55,
        "feminino": 60
    },
    "54": {
        "masculino": 65,
        "feminino": 65
    },
    "55": {
        "masculino": 70,
        "feminino": 70
    },
    "56": {
        "masculino": 75,
        "feminino": 75
    },
    "57": {
        "masculino": 80,
        "feminino": 85
    },
    "58": {
        "masculino": 85,
        "feminino": 85
    },
    "59": {
        "masculino": 90,
        "feminino": 90
    },
    "60": {
        "masculino": 90,
        "feminino": 95
    },
    "61": {
        "masculino": 95,
        "feminino": 95
    },
    "62": {
        "masculino": 95,
        "feminino": 95
    },
    "63": {
        "masculino": 100,
        "feminino": 100
    }
}
const DOMINANCIA = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 10
    },
    "18": {
        "masculino": 5,
        "feminino": 10
    },
    "19": {
        "masculino": 10,
        "feminino": 15
    },
    "20": {
        "masculino": 10,
        "feminino": 15
    },
    "21": {
        "masculino": 15,
        "feminino": 20
    },
    "22": {
        "masculino": 15,
        "feminino": 25
    },
    "23": {
        "masculino": 20,
        "feminino": 25
    },
    "24": {
        "masculino": 25,
        "feminino": 30
    },
    "25": {
        "masculino": 30,
        "feminino": 35
    },
    "26": {
        "masculino": 35,
        "feminino": 40
    },
    "27": {
        "masculino": 40,
        "feminino": 45
    },
    "28": {
        "masculino": 45,
        "feminino": 50
    },
    "29": {
        "masculino": 50,
        "feminino": 55
    },
    "30": {
        "masculino": 55,
        "feminino": 60
    },
    "31": {
        "masculino": 60,
        "feminino": 70
    },
    "32": {
        "masculino": 65,
        "feminino": 75
    },
    "33": {
        "masculino": 70,
        "feminino": 75
    },
    "34": {
        "masculino": 75,
        "feminino": 80
    },
    "35": {
        "masculino": 80,
        "feminino": 85
    },
    "36": {
        "masculino": 80,
        "feminino": 85
    },
    "37": {
        "masculino": 85,
        "feminino": 90
    },
    "38": {
        "masculino": 85,
        "feminino": 90
    },
    "39": {
        "masculino": 90,
        "feminino": 90
    },
    "40": {
        "masculino": 90,
        "feminino": 95
    },
    "41": {
        "masculino": 95,
        "feminino": 95
    },
    "42": {
        "masculino": 95,
        "feminino": 95
    },
    "43": {
        "masculino": 95,
        "feminino": 95
    },
    "44": {
        "masculino": 95,
        "feminino": 95
    },
    "45": {
        "masculino": 95,
        "feminino": 95
    },
    "46": {
        "masculino": 95,
        "feminino": 95
    },
    "47": {
        "masculino": 95,
        "feminino": 95
    },
    "48": {
        "masculino": 95,
        "feminino": 95
    },
    "49": {
        "masculino": 100,
        "feminino": 100
    }
}
const EXIBICAO = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 10
    },
    "20": {
        "masculino": 10,
        "feminino": 10
    },
    "21": {
        "masculino": 10,
        "feminino": 15
    },
    "22": {
        "masculino": 10,
        "feminino": 15
    },
    "23": {
        "masculino": 15,
        "feminino": 20
    },
    "24": {
        "masculino": 15,
        "feminino": 20
    },
    "25": {
        "masculino": 20,
        "feminino": 25
    },
    "26": {
        "masculino": 25,
        "feminino": 30
    },
    "27": {
        "masculino": 30,
        "feminino": 35
    },
    "28": {
        "masculino": 30,
        "feminino": 40
    },
    "29": {
        "masculino": 35,
        "feminino": 45
    },
    "30": {
        "masculino": 40,
        "feminino": 45
    },
    "31": {
        "masculino": 45,
        "feminino": 50
    },
    "32": {
        "masculino": 50,
        "feminino": 55
    },
    "33": {
        "masculino": 55,
        "feminino": 60
    },
    "34": {
        "masculino": 60,
        "feminino": 65
    },
    "35": {
        "masculino": 65,
        "feminino": 70
    },
    "36": {
        "masculino": 70,
        "feminino": 70
    },
    "37": {
        "masculino": 70,
        "feminino": 75
    },
    "38": {
        "masculino": 75,
        "feminino": 80
    },
    "39": {
        "masculino": 80,
        "feminino": 80
    },
    "40": {
        "masculino": 80,
        "feminino": 85
    },
    "41": {
        "masculino": 85,
        "feminino": 85
    },
    "42": {
        "masculino": 85,
        "feminino": 90
    },
    "43": {
        "masculino": 90,
        "feminino": 90
    },
    "44": {
        "masculino": 90,
        "feminino": 90
    },
    "45": {
        "masculino": 90,
        "feminino": 90
    },
    "46": {
        "masculino": 90,
        "feminino": 95
    },
    "47": {
        "masculino": 95,
        "feminino": 95
    },
    "48": {
        "masculino": 95,
        "feminino": 95
    },
    "49": {
        "masculino": 95,
        "feminino": 95
    },
    "50": {
        "masculino": 95,
        "feminino": 95
    },
    "51": {
        "masculino": 95,
        "feminino": 95
    },
    "52": {
        "masculino": 95,
        "feminino": 95
    },
    "53": {
        "masculino": 95,
        "feminino": 95
    },
    "54": {
        "masculino": 95,
        "feminino": 95
    },
    "55": {
        "masculino": 95,
        "feminino": 95
    },
    "56": {
        "masculino": 95,
        "feminino": 95
    },
    "57": {
        "masculino": 95,
        "feminino": 95
    },
    "58": {
        "masculino": 95,
        "feminino": 95
    },
    "59": {
        "masculino": 95,
        "feminino": 95
    },
    "60": {
        "masculino": 95,
        "feminino": 95
    },
    "61": {
        "masculino": 100,
        "feminino": 95
    },
    "62": {
        "masculino": 100,
        "feminino": 95
    },
    "63": {
        "masculino": 100,
        "feminino": 100
    }
}
const INTRACEPCAO = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 10,
        "feminino": 5
    },
    "26": {
        "masculino": 10,
        "feminino": 10
    },
    "27": {
        "masculino": 15,
        "feminino": 10
    },
    "28": {
        "masculino": 20,
        "feminino": 15
    },
    "29": {
        "masculino": 25,
        "feminino": 20
    },
    "30": {
        "masculino": 25,
        "feminino": 20
    },
    "31": {
        "masculino": 30,
        "feminino": 25
    },
    "32": {
        "masculino": 40,
        "feminino": 30
    },
    "33": {
        "masculino": 45,
        "feminino": 35
    },
    "34": {
        "masculino": 50,
        "feminino": 45
    },
    "35": {
        "masculino": 55,
        "feminino": 50
    },
    "36": {
        "masculino": 65,
        "feminino": 55
    },
    "37": {
        "masculino": 70,
        "feminino": 65
    },
    "38": {
        "masculino": 75,
        "feminino": 70
    },
    "39": {
        "masculino": 80,
        "feminino": 75
    },
    "40": {
        "masculino": 80,
        "feminino": 80
    },
    "41": {
        "masculino": 85,
        "feminino": 80
    },
    "42": {
        "masculino": 90,
        "feminino": 85
    },
    "43": {
        "masculino": 90,
        "feminino": 90
    },
    "44": {
        "masculino": 90,
        "feminino": 90
    },
    "45": {
        "masculino": 95,
        "feminino": 95
    },
    "46": {
        "masculino": 95,
        "feminino": 95
    },
    "47": {
        "masculino": 95,
        "feminino": 95
    },
    "48": {
        "masculino": 95,
        "feminino": 95
    },
    "49": {
        "masculino": 100,
        "feminino": 100
    }
}
const MUDANCA = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 10,
        "feminino": 5
    },
    "27": {
        "masculino": 10,
        "feminino": 10
    },
    "28": {
        "masculino": 15,
        "feminino": 10
    },
    "29": {
        "masculino": 20,
        "feminino": 15
    },
    "30": {
        "masculino": 20,
        "feminino": 15
    },
    "31": {
        "masculino": 25,
        "feminino": 20
    },
    "32": {
        "masculino": 30,
        "feminino": 25
    },
    "33": {
        "masculino": 35,
        "feminino": 30
    },
    "34": {
        "masculino": 45,
        "feminino": 35
    },
    "35": {
        "masculino": 50,
        "feminino": 45
    },
    "36": {
        "masculino": 55,
        "feminino": 50
    },
    "37": {
        "masculino": 60,
        "feminino": 55
    },
    "38": {
        "masculino": 65,
        "feminino": 60
    },
    "39": {
        "masculino": 70,
        "feminino": 70
    },
    "40": {
        "masculino": 75,
        "feminino": 75
    },
    "41": {
        "masculino": 80,
        "feminino": 75
    },
    "42": {
        "masculino": 85,
        "feminino": 80
    },
    "43": {
        "masculino": 90,
        "feminino": 85
    },
    "44": {
        "masculino": 90,
        "feminino": 90
    },
    "45": {
        "masculino": 90,
        "feminino": 90
    },
    "46": {
        "masculino": 95,
        "feminino": 95
    },
    "47": {
        "masculino": 95,
        "feminino": 95
    },
    "48": {
        "masculino": 95,
        "feminino": 95
    },
    "49": {
        "masculino": 100,
        "feminino": 100
    }
}
const ORDEM = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 5,
        "feminino": 5
    },
    "28": {
        "masculino": 5,
        "feminino": 5
    },
    "29": {
        "masculino": 10,
        "feminino": 10
    },
    "30": {
        "masculino": 15,
        "feminino": 15
    },
    "31": {
        "masculino": 20,
        "feminino": 20
    },
    "32": {
        "masculino": 25,
        "feminino": 25
    },
    "33": {
        "masculino": 30,
        "feminino": 30
    },
    "34": {
        "masculino": 35,
        "feminino": 35
    },
    "35": {
        "masculino": 45,
        "feminino": 45
    },
    "36": {
        "masculino": 50,
        "feminino": 50
    },
    "37": {
        "masculino": 60,
        "feminino": 60
    },
    "38": {
        "masculino": 65,
        "feminino": 65
    },
    "39": {
        "masculino": 70,
        "feminino": 70
    },
    "40": {
        "masculino": 80,
        "feminino": 80
    },
    "41": {
        "masculino": 85,
        "feminino": 85
    },
    "42": {
        "masculino": 100,
        "feminino": 100
    }
}
const PERSISTENCIA = {
    "1": {
        "masculino": 5,
        "feminino": 5
    },
    "2": {
        "masculino": 5,
        "feminino": 5
    },
    "3": {
        "masculino": 5,
        "feminino": 5
    },
    "4": {
        "masculino": 5,
        "feminino": 5
    },
    "5": {
        "masculino": 5,
        "feminino": 5
    },
    "6": {
        "masculino": 5,
        "feminino": 5
    },
    "7": {
        "masculino": 5,
        "feminino": 5
    },
    "8": {
        "masculino": 5,
        "feminino": 5
    },
    "9": {
        "masculino": 5,
        "feminino": 5
    },
    "10": {
        "masculino": 5,
        "feminino": 5
    },
    "11": {
        "masculino": 5,
        "feminino": 5
    },
    "12": {
        "masculino": 5,
        "feminino": 5
    },
    "13": {
        "masculino": 5,
        "feminino": 5
    },
    "14": {
        "masculino": 5,
        "feminino": 5
    },
    "15": {
        "masculino": 5,
        "feminino": 5
    },
    "16": {
        "masculino": 5,
        "feminino": 5
    },
    "17": {
        "masculino": 5,
        "feminino": 5
    },
    "18": {
        "masculino": 5,
        "feminino": 5
    },
    "19": {
        "masculino": 5,
        "feminino": 5
    },
    "20": {
        "masculino": 5,
        "feminino": 5
    },
    "21": {
        "masculino": 5,
        "feminino": 5
    },
    "22": {
        "masculino": 5,
        "feminino": 5
    },
    "23": {
        "masculino": 5,
        "feminino": 5
    },
    "24": {
        "masculino": 5,
        "feminino": 5
    },
    "25": {
        "masculino": 5,
        "feminino": 5
    },
    "26": {
        "masculino": 5,
        "feminino": 5
    },
    "27": {
        "masculino": 5,
        "feminino": 5
    },
    "28": {
        "masculino": 5,
        "feminino": 5
    },
    "29": {
        "masculino": 5,
        "feminino": 5
    },
    "30": {
        "masculino": 5,
        "feminino": 5
    },
    "31": {
        "masculino": 5,
        "feminino": 5
    },
    "32": {
        "masculino": 5,
        "feminino": 5
    },
    "33": {
        "masculino": 5,
        "feminino": 5
    },
    "34": {
        "masculino": 5,
        "feminino": 5
    },
    "35": {
        "masculino": 10,
        "feminino": 10
    },
    "36": {
        "masculino": 15,
        "feminino": 10
    },
    "37": {
        "masculino": 15,
        "feminino": 15
    },
    "38": {
        "masculino": 20,
        "feminino": 20
    },
    "39": {
        "masculino": 25,
        "feminino": 25
    },
    "40": {
        "masculino": 30,
        "feminino": 30
    },
    "41": {
        "masculino": 35,
        "feminino": 35
    },
    "42": {
        "masculino": 45,
        "feminino": 40
    },
    "43": {
        "masculino": 50,
        "feminino": 45
    },
    "44": {
        "masculino": 55,
        "feminino": 55
    },
    "45": {
        "masculino": 60,
        "feminino": 60
    },
    "46": {
        "masculino": 65,
        "feminino": 65
    },
    "47": {
        "masculino": 70,
        "feminino": 70
    },
    "48": {
        "masculino": 80,
        "feminino": 75
    },
    "49": {
        "masculino": 80,
        "feminino": 80
    },
    "50": {
        "masculino": 85,
        "feminino": 85
    },
    "51": {
        "masculino": 90,
        "feminino": 90
    },
    "52": {
        "masculino": 90,
        "feminino": 90
    },
    "53": {
        "masculino": 95,
        "feminino": 95
    },
    "54": {
        "masculino": 95,
        "feminino": 95
    },
    "55": {
        "masculino": 95,
        "feminino": 95
    },
    "56": {
        "masculino": 100,
        "feminino": 100
    }
}
export function calculaResultado(data) {
    const result = {
        "Afago": 0,
        "Afiliacao": 0,
        "Agressividade": 0,
        "Assistencia": 0,
        "Autonomia": 0,
        "Deferencia": 0,
        "Desempenho": 0,
        "Dominancia": 0,
        "Exibicao": 0,
        "Intracepcao": 0,
        "Mudanca": 0,
        "Ordem": 0,
        "Persistencia": 0
    }



    data.forEach(p => {


        switch (p.escala) {
            case "Afago":
                result.Afago += p.response;
                break;
            case "Afiliação":
                result.Afiliacao += p.response;
                break;
            case "Agressividade":
                result.Agressividade += p.response;
                break;
            case "Assistência":
                result.Assistencia += p.response;
                break;
            case "Autonomia":
                result.Autonomia += p.response;
                break;
            case "Deferência":
                result.Deferencia += p.response;
                break;
            case "Desempenho":
                result.Desempenho += p.response;
                break;
            case "Dominância":
                result.Dominancia += p.response;
                break;
            case "Exibição":
                result.Exibicao += p.response;
                break;
            case "Intracepção":
                result.Intracepcao += p.response;
                break;

            case "Mudança":
                result.Mudanca += p.response;
                break;
            case "Ordem":
                result.Ordem += p.response;
                break;
            case "Persistência":
                result.Persistencia += p.response;
                break;
            default:

                break;
        }
    })



    return {
        masculino: {
            "afago_n":result.Afago,
            "afago": AFAGO[result.Afago.toString()].masculino,
            "afiliacao_n":result.Afiliacao,
            "afiliacao": AFILIACAO[result.Afiliacao.toString()].masculino,
            "agressividade_n":result.Agressividade,
            "agressividade": AGRESSIVIDADE[result.Agressividade.toString()].masculino,
            "assistencia_n":result.Assistencia,
            "assistencia": ASSISTENCIA[result.Assistencia.toString()].masculino,
            "autonomia_n":result.Autonomia,
            "autonomia": AUTONOMIA[result.Autonomia.toString()].masculino,
            "deferencia_n":result.Deferencia,
            "deferencia": DEFERENCIA[result.Deferencia.toString()].masculino,
            "desempenho_n":result.Desempenho,
            "desempenho": DESEMPENHO[result.Desempenho.toString()].masculino,
            "dominancia_n":result.Dominancia,
            "dominancia": DOMINANCIA[result.Dominancia.toString()].masculino,
            "exibicao_n":result.Exibicao,
            "exibicao": EXIBICAO[result.Exibicao.toString()].masculino,
            "intracepcao_n":result.Intracepcao,
            "intracepcao": INTRACEPCAO[result.Intracepcao.toString()].masculino,
            "mudanca_n":result.Mudanca,
            "mudanca": MUDANCA[result.Mudanca.toString()].masculino,
            "ordem_n":result.Ordem,
            "ordem": ORDEM[result.Ordem.toString()].masculino,
            "persistencia_n":result.Persistencia,
            "persistencia": PERSISTENCIA[result.Persistencia.toString()].masculino
        },
        feminino: {
            "afago_n":result.Afago,
            "afago": AFAGO[result.Afago.toString()].feminino,
            "afiliacao_n":result.Afiliacao,
            "afiliacao": AFILIACAO[result.Afiliacao.toString()].feminino,
            "agressividade_n":result.Agressividade,
            "agressividade": AGRESSIVIDADE[result.Agressividade.toString()].feminino,
            "assistencia_n":result.Assistencia,
            "assistencia": ASSISTENCIA[result.Assistencia.toString()].feminino,
            "autonomia_n":result.Autonomia,
            "autonomia": AUTONOMIA[result.Autonomia.toString()].feminino,
            "deferencia_n":result.Deferencia,
            "deferencia": DEFERENCIA[result.Deferencia.toString()].feminino,
            "desempenho_n":result.Desempenho,
            "desempenho": DESEMPENHO[result.Desempenho.toString()].feminino,
            "dominancia_n":result.Dominancia,
            "dominancia": DOMINANCIA[result.Dominancia.toString()].feminino,
            "exibicao_n":result.Exibicao,
            "exibicao": EXIBICAO[result.Exibicao.toString()].feminino,
            "intracepcao_n":result.Intracepcao,
            "intracepcao": INTRACEPCAO[result.Intracepcao.toString()].feminino,
            "mudanca_n":result.Mudanca,
            "mudanca": MUDANCA[result.Mudanca.toString()].feminino,
            "ordem_n":result.Ordem,
            "ordem": ORDEM[result.Ordem.toString()].feminino,
            "persistencia_n":result.Persistencia,
            "persistencia": PERSISTENCIA[result.Persistencia.toString()].feminino
        }
    }


}
