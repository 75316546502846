
import {esavi, calculaEsavi} from "../data/DataEsavi";
import QuestionESAVI from "../components/ESAVI/QuestionEsavi";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Title from "../components/Title";
import {Divider } from "@mui/material";
import ButtonSubmit from "../components/ButtonSubmit";
import { useNavigate } from "react-router-dom";
import Layout from "./layout";

// pages\ESAVIPage.js

export default function EsaviPage() {

    
    const navigate = useNavigate()
    const [dataValues, setDataValues] = useState([...esavi])


    useEffect(() => {
        
        const coockieResponses = Cookies.get("ESAVI")
        const copyData = [...esavi]

        if (coockieResponses) {
            copyData.forEach((p, i) => {
                p.response = JSON.parse(coockieResponses)[i]
            })
            setDataValues(copyData)
        }
    }, [])
   
    

   
    const handleTotalSelectedValueCalculation = () => {
  
        let hadNull = false
        let responsesData = []

        dataValues.forEach((p, i) => {
           
            if (p.response === undefined) {
                hadNull = true
                setDataValues(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "error"
                    return newState
                })


            }
            else {
                
                responsesData.push(p.response)
                setDataValues(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "success"
                    return newState
                })


            }


            

        })
        if (hadNull) {
            alert("Por favor, responda todas as questões")

        }

        
        else {
            const results = calculaEsavi(dataValues)
           

            Cookies.set("ESAVI", JSON.stringify(responsesData));

            if (typeof window !== undefined) {
                localStorage.setItem("ESAVI", JSON.stringify(results), { expires: 30 });

            }
            navigate("/ESAVIResult")

            
        }
    }


    const handleOnChange = (e, index) => {
        setDataValues(prevState => {
            const newState = [...prevState]
            const value = e.target.value
            newState[index].response = value
            newState[index].color = "success"
            
    
            return newState
        })
        
      
        


    }
    let instructions = (
        <div className="d-none d-md-block">
            <div>Responsda as Questões de acordo com as seguintes legendas:</div>
            <p className='mt-3'>N = Nunca</p>
            <p>PV = Poucas Vezes</p>
            <p>AV = Às Vezes</p>
            <p>MV = Muitas Vezes</p>
            <p>S = Sempre</p>

        </div>

    )


    return (
        <Layout>
            <Title title="ESAVI" instructions={instructions} />
            {dataValues.map((p, i) => {
                return (
                    

                    <div key={i}>
                    <QuestionESAVI
                            pergunta={(i + 1) + ". " + p.pergunta}
                            resposta={p.response}
                            escala={p.escala}
                            seriePercentil={p.seriePercentil}
                            color={p.color}
                            id={i}
                            
                            onChange={handleOnChange}
                        />
                        
                    </div>


                    
                )
            }
            )}
            <Divider />
            <ButtonSubmit onClick={handleTotalSelectedValueCalculation} />


        </Layout>

    )




}