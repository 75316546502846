import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EATAPage from './pages/EATAPage';
import HomePage from './pages/Home';
import EATAResut from './pages/EataResult';
import NeoFIRPage from './pages/NeoFIRPage';
import NeoFIRResult from './pages/NeoFIRResult';
import NeoPIRPage from './pages/NeoPIRPage';
import NeoPIRResult from './pages/NeoPIRResult';
import BFPPage from './pages/BFPPage';
import BFPResult from './pages/BFPResult';
import IFPIIPage from './pages/IFPIIPage';
import IFPIIResult from './pages/IFPIIResult';
import ModuloTestes from './pages/ModuloTestes';
import ESAVIPage from './pages/ESAVIPage';
import ESAVIResult from './pages/ESAVIResult';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/EATAPage" element={<EATAPage />} />
        <Route path="/EataResult" element={<EATAResut />} />
        <Route path="/NeoFIRPage" element={<NeoFIRPage />} />
        <Route path="/NeoFIRResult" element={<NeoFIRResult />} />
        <Route path="/NeoPIRPage" element={<NeoPIRPage />} />
        <Route path="/NeoPIRResult" element={<NeoPIRResult />} />
        <Route path="/BFPPage" element={<BFPPage />} />
        <Route path="/BFPResult" element={<BFPResult />} />
        <Route path="/IFPIIPage" element={<IFPIIPage />} />
        <Route path="/IFPIIResult" element={<IFPIIResult />} />
        <Route path="/ModuloTestes" element={<ModuloTestes />} />
        <Route path="/ESAVIPage" element={<ESAVIPage />} />
        <Route path="/ESAVIResult" element={<ESAVIResult />} />


        

      </Routes>
    </Router>
  );
}

export default App;