
import Slider from '@mui/material/Slider';
import { useState, useEffect } from 'react';
import Prompt from '../Prompt';
// d:/Source/PROJETO_JETO/psicotecnico/components/BFP/QuestionBPF.js
export default function QuestionBPF({ pergunta, resposta, escala, seriePercentil, key, onChange, id, color = 'primary' }) {

    const [value, setValue] = useState(resposta || 1);

    useEffect(() => {
        setValue(resposta);
    }, [resposta]);
    const marks = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
    ]
    //return um card com pergunta resposta keyu um divider para respoetas de 1 a 7 e um divider
    return (
        <div key={key} id={'question-' + id} escala={escala} serie-percentil={seriePercentil}>
            <div className="m-2 sombra-forte rounded" sx={{ minWidth: 275 }}>
                <div>

                    <Prompt prompt={pergunta} color={color} />
                    
                    <div className='p-3'>

                        <Slider

                            aria-label="Always visible"
                            min={1}
                            max={7}
                            step={1}
                            color={color}
                            marks={marks}
                            value={value}
                            onChange={(e, newValue) => {
                                setValue(newValue);
                                onChange(e, id);

                            }}



                        />
                    </div>

                </div>

            </div>
        </div>
    );
}
