import { useState, useEffect } from 'react'
import { NeoPIR} from "../data/DataNeoPIR"
import QuestionNeoPIR from "../components/NEOPIR/QuestionNeoPIR";
import Layout from "../pages/layout"
import Title from '../components/Title'
import Cookies from 'js-cookie';
import ButtonSubmit from '../components/ButtonSubmit';
import {useNavigate} from 'react-router-dom'


// pages\NeoPIRPage.js

export default function NeoPIRPage() {

    const [data, setData] = useState([...NeoPIR])
    const navigate = useNavigate()


    useEffect(() => {

        if (Cookies.get('NeoPIR')) {
            const cookie = (JSON.parse(Cookies.get('NeoPIR')))
            const copyData = [...NeoPIR]
            copyData.forEach((p, i) => {
                p.response = cookie[i]
            })
            setData(copyData)

        }

    }, [])

    let instructions = (
        <div className="d-none d-md-block">
            <div>Responsda as Questões de acordo com as seguintes legendas:</div>
            <p className='mt-3'>DF = Discordo Fortemente</p>
            <p>D = Discordo</p>
            <p>N = Neutro</p>
            <p>C = Concordo</p>
            <p>C = Concordo Fortemente</p>

        </div>

    )

    const handleTotalSelectedValueCalculation = () => {

        const cookie = []
        let hadnull = false
        let result = {

            "Neuroticismo": {
                "Ansiedade": 0,
                "Depressão": 0,
                "Embaraço": 0,
                "Impulsividade": 0,
                "Raiva": 0,
                "Vulnerabilidade": 0
            },
            "Extroversão": {
                "Acolhimento Caloroso": 0,
                "Assertividade": 0,
                "Atividade": 0,
                "Busca de Sensações": 0,
                "Emoções Positivas": 0,
                "Gregarismo": 0
            },
            "Abertura à Experiência": {
                "Ações Variadas": 0,
                "Estética": 0,
                "Fantasia": 0,
                "Ideias": 0,
                "Sentimentos": 0,
                "Valores": 0
            },
            "Amabilidade": {
                "Altruísmo": 0,
                "Complacência": 0,
                "Confiança": 0,
                "Franqueza": 0,
                "Modéstia": 0,
                "Sensibilidade": 0
            },
            "Conscienciosidade": {
                "Auto Disciplina": 0,
                "Competência": 0,
                "Esforço por Realização": 0,
                "Ordem": 0,
                "Ponderação": 0,
                "Senso de Dever": 0
            }
        }


       
        data.forEach((p, i) => {

            if (p.response === undefined) {
                hadnull = true
                setData(prevState => {
                    const newState = [...prevState]
                    newState[i].color = "error"
                    return newState
                })

                
            }

            else {
           
                p.color = "success"
                cookie.push(p.response)
                if (p.escala === -1) {
                    result[p.fator][p.aspecto] += 100 - p.response
                }
                else {
                    result[p.fator][p.aspecto] += p.response
                }
            }
        })

        if (hadnull) {
            alert("Por favor, responda todas as perguntas")
        }
        else{
            Cookies.set('NeoPIR', JSON.stringify(cookie), { expires: 1 })
            if(typeof window !== "undefined"){
                localStorage.setItem("NEOPIR", JSON.stringify(result))
            }
            navigate("/NeoPIRResult")
        }
      
        
        


       
    }

    const handleOnChange = (e, index) => {
        setData(prevState => {
            const newState = [...prevState]
            const value = e.target.value
            newState[index].response = value
            if (newState[index].escala === -1) {
                newState[index].result = 100 - value
            }
            else {
                newState[index].result = value
            }
            newState[index].color = "success"


            return newState
        })






    }


    return (
        <Layout>
            <Title title="Neo PI-R" instructions={instructions} />
            {

                data.map((p, i) => (
                    <QuestionNeoPIR
                        pergunta={(i + 1) + ". " + p.prompt}
                        resposta={parseInt(p.response)}
                        color={p.color}
                        id={i}
                        key={i}

                        onChange={handleOnChange}


                    />
                ))
            }

            <ButtonSubmit onClick={handleTotalSelectedValueCalculation} />

        </Layout >
    )

}
