import Title from "../components/Title";
import Layout from "./layout";
import {useEffect, useState } from "react";
import {Card } from "react-bootstrap";
import {Table, TableCell, TableBody, TableRow } from "@mui/material";
import { notasF1, notasF2, notasF3, notasF4, } from "../data/DataEsavi";
import BackHome from "../components/EATA/BackHome";
// pages\ESAVIResult.js
export default function ESAVIResult() {
    const [data, setData] = useState({
        f1: 0,
        f2: 0,
        f3: 0,
        f4: 0
    });

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("ESAVI"));
        if (savedData) {
            setData(savedData);
        }
    }, []);

   
    const instructions = (
        <p className="text-justify">Escala de Avaliação da Impulsividade,
            é um instrumento psicológico criado no Brasil para
            avaliar a propensão a reações rápidas e não planejadas.
            O teste leva em consideração a falta de ponderação das
            consequências negativas que tais ações
            podem ter para o indivíduo e para outras pessoas.</p>
    )
    return (
        <Layout>
            <Title title="ESAVI Result" instructions={instructions} />

            <Card border="rounded mt-4 ">
                <div className="px-1 py-3 bg-masculino text-light h5 rounded">Falta de Concentração e Persistência</div>
                <Table padding="none">
                    <TableBody>
                        <TableRow>
                            <TableCell className="p-2">Pontos</TableCell>
                            <TableCell>{data.f1}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Nota Fator</TableCell>
                            <TableCell>{notasF1[data.f1]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Resultado</TableCell>
                            <TableCell>{calculaAptidao("f1", data)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Nota</TableCell>
                            <TableCell><IntervaloFechado min={2} max={3} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Pontuação</TableCell>
                            <TableCell><IntervaloFechado min={15} max={30} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell>{ClassificaESAVI("f1", data.f1)}</TableCell>
                        </TableRow>


                    </TableBody>
                </Table>

            </Card>
            <Card border="rounded mt-2 ">
                <div className="px-1 py-3 bg-masculino text-light h5 rounded">Controle Cognitivo</div>
                <Table padding="none">
                    <TableBody>
                        <TableRow>
                            <TableCell className="p-2">Pontos</TableCell>
                            <TableCell>{data.f2}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Nota Fator</TableCell>
                            <TableCell>{notasF2[data.f2]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Resultado</TableCell>
                            <TableCell>{calculaAptidao("f2", data)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Nota</TableCell>
                            <TableCell><IntervaloFechado min={3} max={4} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Pontuação</TableCell>
                            <TableCell><IntervaloFechado min={23} max={36} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell>{ClassificaESAVI("f2", data.f2)}</TableCell>
                        </TableRow>


                    </TableBody>
                </Table>

            </Card>
            <Card border="rounded mt-2">
                <div className=" px-1 py-3 bg-masculino text-light h5 rounded">Planejamento de Futuro</div>
                <Table padding="none">
                    <TableBody>
                        <TableRow>
                            <TableCell className="p-2">Pontos</TableCell>
                            <TableCell>{data.f3}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Nota Fator</TableCell>
                            <TableCell>{notasF3[data.f3]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Resultado</TableCell>
                            <TableCell>{calculaAptidao("f3", data)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Nota</TableCell>
                            <TableCell><IntervaloFechado min={3} max={4} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Pontuação</TableCell>
                            <TableCell><IntervaloFechado min={23} max={36} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell>{ClassificaESAVI("f3", data.f3)}</TableCell>
                        </TableRow>


                    </TableBody>
                </Table>

            </Card>
            <Card border="rounded mt-2">
                <div className=" px-1 py-3 bg-masculino text-light h5 rounded">Audácia e Temericidade</div>
                <Table padding="none">
                    <TableBody>
                        <TableRow>
                            <TableCell className="p-2">Pontos</TableCell>
                            <TableCell>{data.f4}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Nota Fator</TableCell>
                            <TableCell>{notasF4[data.f4]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Resultado</TableCell>
                            <TableCell>{calculaAptidao("f4", data)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Nota</TableCell>
                            <TableCell><IntervaloFechado min={2} max={3} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Intervalo de Pontuação</TableCell>
                            <TableCell><IntervaloFechado min={15} max={30} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="p-2">Classificação</TableCell>
                            <TableCell>{ClassificaESAVI("f4", data.f4)}</TableCell>
                        </TableRow>


                    </TableBody>
                </Table>

            </Card>

        <BackHome />

        </Layout>
    )
}

function IntervaloFechado({ min, max }) {
    return (
        <>
            <span className="mr-1">{min}</span><span><img src="/img/intervalo_fechado.svg" width={20} height={20} alt={"intervalo"} /></span><span className="ml-1">{max}</span>
        </>
    )
}

function calculaAptidao(fator, data) {
    if (fator === "f1") {
        return (
            notasF1[data.f1] >= 2 || notasF1[data.f1] <= 3 ?
                <span className="text-success">Apto</span>
                :
                <span className="text-danger">Inapto</span>

        )
    }
    else if (fator === "f2") {
        return (
            notasF2[data.f2] >= 3 || notasF2 <= 4 ?
                <span className="text-success">Apto</span>
                :
                <span className="text-danger">Inapto</span>

        )
    }
    else if (fator === "f3") {
        return (
            notasF3[data.f3] >= 3 || notasF3 <= 4 ?
                <span className="text-success">Apto</span>
                :
                <span className="text-danger">Inapto</span>

        )
    }
    else if (fator === "f4") {
        return (
            notasF4[data.f4] >= 2 || notasF4 <= 3 ?
                <span className="text-success">Apto</span>
                :
                <span className="text-danger">Inapto</span>

        )
    }
}

function ClassificaESAVI(fator, pontos) {

    if (fator === "f1") {
        switch (true) {
            case (pontos >= 0 && pontos <= 14):
                return (
                    <>
                        <span>Extremo Inferior </span><IntervaloFechado min={0} max={14} />

                    </>

                );
            case (pontos >= 15 && pontos <= 20):
                return (
                    <>
                        <span>Baixo </span><IntervaloFechado min={15} max={20} />
                    </>
                );
            case (pontos >= 21 && pontos <= 30):
                return (
                    <>
                        <span>Médio </span><IntervaloFechado min={21} max={30} />
                    </>
                );
            case (pontos >= 31 && pontos <= 37):
                return (
                    <>
                        <span>Alto </span><IntervaloFechado min={31} max={37} />
                    </>
                );
            default:
                return (
                    <>
                        <span>Extremo Superior </span><IntervaloFechado min={38} max={"\u221e"} />
                    </>
                );
        }
    }
    //faça tambem para f2
    else if (fator === "f2") {
        switch (true) {
            case (pontos >= 0 && pontos <= 22):
                return (
                    <>
                        <span>Extremo Inferior </span><IntervaloFechado min={0} max={22} />
                    </>
                )
            case (pontos >= 23 && pontos <= 28):
                return (
                    <>
                        <span>Baixo </span><IntervaloFechado min={23} max={28} />
                    </>
                )
            case (pontos >= 29 && pontos <= 36):
                return (
                    <>
                        <span>Médio </span><IntervaloFechado min={29} max={36} />
                    </>
                )
            case (pontos >= 37 && pontos <= 39):
                return (
                    <>
                        <span>Alto </span><IntervaloFechado min={37} max={39} />
                    </>

                )
         default:
                return (
                    <>
                        <span>Extremo Superior </span><IntervaloFechado min={40} max={"\u221e"} />
                    </>
                )
        }
    }
    else if (fator === "f3") {
        switch (true) {
            case (pontos >= 0 && pontos <= 9):
                return (
                    <>
                        <span>Extremo Inferior </span><IntervaloFechado min={0} max={9} />
                    </>
                )
            case (pontos >= 10 && pontos <= 13):
                return (
                    <>
                        <span>Baixo </span><IntervaloFechado min={10} max={13} />
                    </>
                )
            case (pontos >= 14 && pontos <= 19):
                return (
                    <>
                        <span>Médio </span><IntervaloFechado min={14} max={19} />
                    </>
                )
            case (pontos >= 20 && pontos <= 22):
                return (
                    <>
                        <span>Alto </span><IntervaloFechado min={20} max={22} />
                    </>

                )
       
            default:
                return (
                    <>
                        <span>Extremo Superior </span><IntervaloFechado min={24} max={"\u221e"} />
                    </>
                )

        }

    }
    else {
        switch (true) {
            case (pontos >= 0 && pontos <= 10):
                return (
                    <>
                        <span>Extremo Inferior </span><IntervaloFechado min={0} max={10} />
                    </>
                )
            case (pontos >= 11 && pontos <= 13):
                return (
                    <>
                        <span>Baixo </span><IntervaloFechado min={11} max={13} />
                    </>
                )
            case (pontos >= 14 && pontos <= 19):
                return (
                    <>
                        <span>Médio </span><IntervaloFechado min={14} max={19} />
                    </>
                )
            case (pontos >= 20 && pontos <= 22):
                return (
                    <>
                        <span>Alto </span><IntervaloFechado min={20} max={22} />
                    </>
                )
          default:
                return (
                    <>
                        <span>Extremo Superior </span><IntervaloFechado min={23} max={"\u221e"} />
                    </>
                )
        }
    }



}