
import { Accordion, AccordionDetails, Typography, AccordionSummary, TableBody, Table, TableRow, TableCell, TableHead, Divider } from "@mui/material";
import Layout from "./layout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Title from "../components/Title";
import IntervaloFechado from "../components/IntervaloFechado";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import BackHome from "../components/EATA/BackHome";

const rangeAnsiedade = [0, 60]
const rangeDepressao = [0, 60]
const rangeEmbaraco = [0, 60]
const rangeImpulsividade = [0, 60]
const rangeRaiva = [0, 60]
const rangeVulnerabilidade = [0, 60]
const rangeAcolhimentoCaloroso = [20, 90]
const rangeAssertividade = [30, 100]
const rangeAtividade = [30, 100]
const rangeBuscaDeSensacoes = [0, 100]
const rangeEmocoesPositivas = [20, 90]
const rangeGregarismo = [20, 90]
const rangeAcoesVariadas = [20, 80]
const rangeEstetica = [0, 100]
const rangeFantasia = [20, 80]
const rangeIdeias = [20, 80]
const rangeSentimentos = [20, 80]
const rangeValores = [20, 80]
const rangeAltruismo = [30, 100]
const rangeComplacencia = [20, 80]
const rangeConfianca = [20, 80]
const rangeFranqueza = [20, 80]
const rangeModestia = [20, 100]
const rangeSensibilidade = [20, 80]
const rangeAutoDisciplina = [30, 100]
const rangeCompetencia = [40, 100]
const rangeEsforcoPorRealizacao = [30, 90]
const rangeOrdem = [30, 90]
const rangePonderacao = [30, 90]
const rangeSensoDeDever = [30, 100]

export default function NeoPIRResult() {

  let result = {
    "Neuroticismo": {
      "Ansiedade": 0,
      "Depressão": 0,
      "Embaraço": 0,
      "Impulsividade": 0,
      "Raiva": 0,
      "Vulnerabilidade": 0
    },

    "Extroversão": {
      "Acolhimento Caloroso": 0,
      "Assertividade": 0,
      "Atividade": 0,
      "Busca de Sensações": 0,
      "Emoções Positivas": 0,
      "Gregarismo": 0
    },
    "Abertura à Experiência": {
      "Ações Variadas": 0,
      "Estética": 0,
      "Fantasia": 0,
      "Ideias": 0,
      "Sentimentos": 0,
      "Valores": 0
    },
    "Amabilidade": {
      "Altruísmo": 0,
      "Complacência": 0,
      "Confiança": 0,
      "Franqueza": 0,
      "Modéstia": 0,
      "Sensibilidade": 0
    },

    "Conscienciosidade": {
      "Auto Disciplina": 0,
      "Competência": 0,
      "Esforço por Realização": 0,
      "Ordem": 0,
      "Ponderação": 0,
      "Senso de Dever": 0
    }
  }

  if (typeof window !== "undefined") {
    result = JSON.parse(localStorage.getItem("NEOPIR"))
 
  }


  const instructions = (
    <div>

      <p>
        O NEO-PI-R é um teste de personalidade que avalia cinco fatores de personalidade: Neuroticismo, Extroversão, Abertura à Experiência, Amabilidade e Conscienciosidade. Cada fator é composto por seis subcategorias. Os resultados são apresentados em um gráfico de radar, onde cada subcategoria é representada por um ponto em um eixo. O ponto é posicionado de acordo com a pontuação obtida na subcategoria. A pontuação varia de 0 a 100, e quanto mais próximo de 100, maior é a pontuação na subcategoria.
      </p>
      <p>
        O teste é baseado na teoria dos cinco grandes fatores de personalidade, que sugere que a personalidade humana pode ser descrita em termos de cinco dimensões: Neuroticismo, Extroversão, Abertura à Experiência, Amabilidade e Conscienciosidade. Cada dimensão é composta por seis subcategorias, que representam traços específicos de personalidade.
      </p>
      <p>
        O NEO-PI-R é frequentemente utilizado em contextos clínicos e de pesquisa para avaliar a personalidade e compreender melhor o comportamento humano. Os resultados do teste podem ser úteis para identificar padrões de comportamento, compreender a motivação e a tomada de decisão, e fornecer insights sobre a saúde mental e o bem-estar emocional.
      </p>
    </div>

  )
  return (
    <Layout>

      <Title title="Neo PI-R" instructions={instructions} />
      <div id="acordions" className="mt-4">
        <Card className="my-2">
          <CardHeader className="bg-neuroticismo border">
            <h2>Neuroticismo</h2>
          </CardHeader>
          <div className="m-2">
          
          </div>

          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={`bg-${apto(rangeAnsiedade, result["Neuroticismo"]["Ansiedade"]) ? "success" : "danger"} text-white`}


            >
              <h4>{`Ansiedade(${apto(rangeAnsiedade, result["Neuroticismo"]["Ansiedade"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Neuroticismo"]["Ansiedade"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAnsiedade[0]} max={rangeAnsiedade[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>

            </AccordionDetails>

          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel1b-content"
              id="panel1b-header"
              className={`bg-${apto(rangeDepressao, result["Neuroticismo"]["Depressão"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Depressão(${apto(rangeDepressao, result["Neuroticismo"]["Depressão"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Neuroticismo"]["Depressão"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeDepressao[0]} max={rangeDepressao[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel1c-content"
              id="panel1c-header"
              className={`bg-${apto(rangeEmbaraco, result["Neuroticismo"]["Embaraço"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Embaraço(${apto(rangeEmbaraco, result["Neuroticismo"]["Embaraço"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Neuroticismo"]["Embaraço"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeEmbaraco[0]} max={rangeEmbaraco[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              className={`bg-${apto(rangeImpulsividade, result["Neuroticismo"]["Impulsividade"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Impulsividade(${apto(rangeImpulsividade, result["Neuroticismo"]["Impulsividade"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Neuroticismo"]["Impulsividade"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeImpulsividade[0]} max={rangeImpulsividade[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel1e-content"
              id="panel1e-header"
              className={`bg-${apto(rangeRaiva, result["Neuroticismo"]["Raiva"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Raiva(${apto(rangeRaiva, result["Neuroticismo"]["Raiva"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Neuroticismo"]["Raiva"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeRaiva[0]} max={rangeRaiva[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel1f-content"
              id="panel1f-header"
              className={`bg-${apto(rangeVulnerabilidade, result["Neuroticismo"]["Vulnerabilidade"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Vulnerabilidade(${apto(rangeVulnerabilidade, result["Neuroticismo"]["Vulnerabilidade"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Neuroticismo"]["Vulnerabilidade"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeVulnerabilidade[0]} max={rangeVulnerabilidade[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Divider className="my-4" />
        <Card className="my-2">

          <CardHeader className="bg-extroversao border">
            <h2>Extroversão</h2>
          </CardHeader>
          
          <div className="m-2">

          </div>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={`bg-${apto(rangeAcolhimentoCaloroso, result["Extroversão"]["Acolhimento Caloroso"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Acolhimento Caloroso(${apto(rangeAcolhimentoCaloroso, result["Extroversão"]["Acolhimento Caloroso"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Extroversão"]["Acolhimento Caloroso"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAcolhimentoCaloroso[0]} max={rangeAcolhimentoCaloroso[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel2b-content"
              id="panel2b-header"
              className={`bg-${apto(rangeAssertividade, result["Extroversão"]["Assertividade"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Assertividade(${apto(rangeAssertividade, result["Extroversão"]["Assertividade"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Extroversão"]["Assertividade"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAssertividade[0]} max={rangeAssertividade[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel2c-content"
              id="panel2c-header"
              className={`bg-${apto(rangeAtividade, result["Extroversão"]["Atividade"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Atividade(${apto(rangeAtividade, result["Extroversão"]["Atividade"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Extroversão"]["Atividade"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAtividade[0]} max={rangeAtividade[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel2d-content"
              id="panel2d-header"
              className={`bg-${apto(rangeBuscaDeSensacoes, result["Extroversão"]["Busca de Sensações"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Busca de Sensações(${apto(rangeBuscaDeSensacoes, result["Extroversão"]["Busca de Sensações"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Extroversão"]["Busca de Sensações"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeBuscaDeSensacoes[0]} max={rangeBuscaDeSensacoes[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel2e-content"
              id="panel2e-header"
              className={`bg-${apto(rangeEmocoesPositivas, result["Extroversão"]["Emoções Positivas"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Emoções Positivas(${apto(rangeEmocoesPositivas, result["Extroversão"]["Emoções Positivas"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Extroversão"]["Emoções Positivas"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeEmocoesPositivas[0]} max={rangeEmocoesPositivas[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel2f-content"
              id="panel2f-header"
              className={`bg-${apto(rangeGregarismo, result["Extroversão"]["Gregarismo"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Gregarismo(${apto(rangeGregarismo, result["Extroversão"]["Gregarismo"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Extroversão"]["Gregarismo"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeGregarismo[0]} max={rangeGregarismo[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Divider className="my-4" />
        <Card className="my-2">
          <CardHeader className="bg-abertura border">
            <h2>Abertura à Experiência</h2>
          </CardHeader>
          <div className="m-2">
     
          </div>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className={`bg-${apto(rangeAcoesVariadas, result["Abertura à Experiência"]["Ações Variadas"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Ações Variadas(${apto(rangeAcoesVariadas, result["Abertura à Experiência"]["Ações Variadas"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Abertura à Experiência"]["Ações Variadas"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAcoesVariadas[0]} max={rangeAcoesVariadas[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel3b-content"
              id="panel3b-header"
              className={`bg-${apto(rangeEstetica, result["Abertura à Experiência"]["Estética"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Estética(${apto(rangeEstetica, result["Abertura à Experiência"]["Estética"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Abertura à Experiência"]["Estética"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeEstetica[0]} max={rangeEstetica[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel3c-content"
              id="panel3c-header"
              className={`bg-${apto(rangeFantasia, result["Abertura à Experiência"]["Fantasia"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Fantasia(${apto(rangeFantasia, result["Abertura à Experiência"]["Fantasia"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Abertura à Experiência"]["Fantasia"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeFantasia[0]} max={rangeFantasia[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel3d-content"
              id="panel3d-header"
              className={`bg-${apto(rangeIdeias, result["Abertura à Experiência"]["Ideias"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Ideias(${apto(rangeIdeias, result["Abertura à Experiência"]["Ideias"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Abertura à Experiência"]["Ideias"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeIdeias[0]} max={rangeIdeias[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel3e-content"
              id="panel3e-header"
              className={`bg-${apto(rangeSentimentos, result["Abertura à Experiência"]["Sentimentos"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Sentimentos(${apto(rangeSentimentos, result["Abertura à Experiência"]["Sentimentos"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Abertura à Experiência"]["Sentimentos"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeSentimentos[0]} max={rangeSentimentos[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel3f-content"
              id="panel3f-header"
              className={`bg-${apto(rangeValores, result["Abertura à Experiência"]["Valores"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Valores(${apto(rangeValores, result["Abertura à Experiência"]["Valores"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Abertura à Experiência"]["Valores"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeValores[0]} max={rangeValores[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          </Card>
        <Divider className="my-4" />
        <Card className="my-2">
          <CardHeader className="bg-amabilidade border">
            <h2>Amabilidade</h2>
          </CardHeader>
          <div className="m-2">
           
          </div>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel4a-content"
              id="panel4a-header"
              className={`bg-${apto(rangeAltruismo, result["Amabilidade"]["Altruísmo"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Altruísmo(${apto(rangeAltruismo, result["Amabilidade"]["Altruísmo"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Amabilidade"]["Altruísmo"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAltruismo[0]} max={rangeAltruismo[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel4b-content"
              id="panel4b-header"
              className={`bg-${apto(rangeComplacencia, result["Amabilidade"]["Complacência"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Complacência(${apto(rangeComplacencia, result["Amabilidade"]["Complacência"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Amabilidade"]["Complacência"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeComplacencia[0]} max={rangeComplacencia[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>

          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel4c-content"
              id="panel4c-header"
              className={`bg-${apto(rangeConfianca, result["Amabilidade"]["Confiança"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Confiança(${apto(rangeConfianca, result["Amabilidade"]["Confiança"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Amabilidade"]["Confiança"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeConfianca[0]} max={rangeConfianca[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel4d-content"
              id="panel4d-header"
              className={`bg-${apto(rangeFranqueza, result["Amabilidade"]["Franqueza"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Franqueza(${apto(rangeFranqueza, result["Amabilidade"]["Franqueza"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Amabilidade"]["Franqueza"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeFranqueza[0]} max={rangeFranqueza[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel4e-content"
              id="panel4e-header"
              className={`bg-${apto(rangeModestia, result["Amabilidade"]["Modéstia"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Modéstia(${apto(rangeModestia, result["Amabilidade"]["Modéstia"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Amabilidade"]["Modéstia"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeModestia[0]} max={rangeModestia[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel4f-content"
              id="panel4f-header"
              className={`bg-${apto(rangeSensibilidade, result["Amabilidade"]["Sensibilidade"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Sensibilidade(${apto(rangeSensibilidade, result["Amabilidade"]["Sensibilidade"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Amabilidade"]["Sensibilidade"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeSensibilidade[0]} max={rangeSensibilidade[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          </Card>
        <Divider className="my-4" />

        <Card className="my-2">
          <CardHeader className="bg-conscienciosidade border">
            <h2>Conscienciosidade</h2>
          </CardHeader>
          <div className="m-2">
          
          </div>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel5a-content"
              id="panel5a-header"
              className={`bg-${apto(rangeAutoDisciplina, result["Conscienciosidade"]["Auto Disciplina"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Auto Disciplina(${apto(rangeAutoDisciplina, result["Conscienciosidade"]["Auto Disciplina"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Conscienciosidade"]["Auto Disciplina"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeAutoDisciplina[0]} max={rangeAutoDisciplina[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>

          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel5b-content"
              id="panel5b-header"
              className={`bg-${apto(rangeCompetencia, result["Conscienciosidade"]["Competência"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Competência(${apto(rangeCompetencia, result["Conscienciosidade"]["Competência"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Conscienciosidade"]["Competência"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeCompetencia[0]} max={rangeCompetencia[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
         
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel5c-content"
              id="panel5c-header"
              className={`bg-${apto(rangeEsforcoPorRealizacao, result["Conscienciosidade"]["Esforço por Realização"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Esforço por Realização(${apto(rangeEsforcoPorRealizacao, result["Conscienciosidade"]["Esforço por Realização"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Conscienciosidade"]["Esforço por Realização"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeEsforcoPorRealizacao[0]} max={rangeEsforcoPorRealizacao[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          {
            //oS PROXIMOS SÃO Ordem, Ponderação e Senso de Dever
          }
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel5d-content"
              id="panel5d-header"
              className={`bg-${apto(rangeOrdem, result["Conscienciosidade"]["Ordem"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Ordem(${apto(rangeOrdem, result["Conscienciosidade"]["Ordem"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Conscienciosidade"]["Ordem"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeOrdem[0]} max={rangeOrdem[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel5e-content"
              id="panel5e-header"
              className={`bg-${apto(rangePonderacao, result["Conscienciosidade"]["Ponderação"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Ponderação(${apto(rangePonderacao, result["Conscienciosidade"]["Ponderação"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Conscienciosidade"]["Ponderação"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangePonderacao[0]} max={rangePonderacao[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion className="border">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="h1 text-light" />}
              aria-controls="panel5f-content"
              id="panel5f-header"
              className={`bg-${apto(rangeSensoDeDever, result["Conscienciosidade"]["Senso de Dever"]) ? "success" : "danger"} text-white`}
            >
              <h4>{`Senso de Dever(${apto(rangeSensoDeDever, result["Conscienciosidade"]["Senso de Dever"]) ? "Apto" : "Inapto"})`}</h4>
            </AccordionSummary>
            <AccordionDetails className="bg-light">
              <Table>
                <TableHead>
                <TableRow>
                    <TableCell className="h5">Nota Obtida</TableCell>
                    <TableCell className="h5">{(result["Conscienciosidade"]["Senso de Dever"] / 8).toFixed(2) + "%"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="h5">Intervalo de Normalidade</TableCell>
                    <TableCell className="h5"><IntervaloFechado min={rangeSensoDeDever[0]} max={rangeSensoDeDever[1]} /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </AccordionDetails>
          </Accordion>

          
        </Card>

      </div>
     
     
      <BackHome />


    </Layout>
  )

}

function apto(range, value) {

  return value / 8 >= range[0] && value / 8 <= range[1]
}