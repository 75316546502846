// data\DataNeoFFII.js
export const DataNeoFFII = [
    {
        "id": 0,
        "pergunta": "Sou uma pessoa despreocupada.",
        "dominio": "NEUROTICISMO",
        "fator": -1,
        "result": null
    },
    {
        "id": 1,
        "pergunta": "Gosto de ter muita gente à minha volta.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 2,
        "pergunta": "Gosto de me concentrar numa fantasia e explorar todas as suas possibilidades, deixando ela crescer e se desenvolver.",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 3,
        "pergunta": "Tento ser educado com todas as pessoas que encontro",
        "dominio": "AMABILIDADE",
        "fator": 1,
        "result": null
    },
    
    {
        "id": 4,
        "pergunta": "Mantenho as minhas coisas limpas e em ordem.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    

    {
        "id": 5,
        "pergunta": "Algumas vezes senti ressentimento e amargura.",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 6,
        "pergunta": "Sou propenso (a) a rir facilmente.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 7,
        "pergunta": "Acho interessante aprender e cultivar novos hobbies (passatempos).",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 8,
        "pergunta": "Às vezes, intimido ou bajulo as pessoas para que elas façam o que eu quero.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 9,
        "pergunta": "Eu sou bom (boa) em organizar meu tempo de maneira a fazer as coisas dentro do prazo.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 10,
        "pergunta": "Quando estou sob uma grande tensão, algumas vezes sinto que não vou resistir.",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 11,
        "pergunta": "Prefiro trabalhos que possa fazer sozinho(a), sem ser incomodado(a) por outras pessoas.",
        "dominio": "EXTROVERSÃO",
        "fator": -1,
        "result": null
    },
    {
        "id": 12,
        "pergunta": "Fico admirado(a) com a variedade de padrões que encontro na arte e na natureza.",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 13,
        "pergunta": "Algumas pessoas pensam que sou invejoso(a) e egoísta.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 14,
        "pergunta": "Freqüentemente entro em situações sem estar totalmente preparado(a) para elas.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 15,
        "pergunta": "Raramente me sinto só ou triste.",
        "dominio": "NEUROTICISMO",
        "fator": -1,
        "result": null
    },
    {
        "id": 16,
        "pergunta": "Gosto muito de falar com as outras pessoas.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 17,
        "pergunta": "Acredito que deixar estudantes ouvirem pessoas com idéias controversas, só vai confundi-los e desorientá-los.",
        "dominio": "ABERTURA",
        "fator": -1,
        "result": null
    },
    {
        "id": 18,
        "pergunta": "Se alguém começa uma briga, estou sempre pronto para revidar.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 19,
        "pergunta": "Tento cumprir todas as minhas obrigações com responsabilidade.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 20,
        "pergunta": "Freqüentemente sinto-me tenso(a) e agitado(a).",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 21,
        "pergunta": "Gosto de estar em lugares animados.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 22,
        "pergunta": "A poesia pouco ou nada me sensibiliza.",
        "dominio": "ABERTURA",
        "fator": -1,
        "result": null
    },
    {
        "id": 23,
        "pergunta": "Sou melhor que a maioria das pessoas e tenho consciência disso.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 24,
        "pergunta": "Tenho objetivos claros e busco atingi-los de forma organizada.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 25,
        "pergunta": "Às vezes, sinto que eu não valho nada.",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 26,
        "pergunta": "Não gosto de multidões e por isso as evito.",
        "dominio": "EXTROVERSÃO",
        "fator": -1,
        "result": null
    },
    {
        "id": 27,
        "pergunta": "Tenho dificuldade em soltar a minha imaginação (devanear).",
        "dominio": "ABERTURA",
        "fator": -1,
        "result": null
    },
    {
        "id": 28,
        "pergunta": "Quando sou insultado(a), tento perdoar e esquecer.",
        "dominio": "AMABILIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 29,
        "pergunta": "Perco muito tempo antes de me concentrar no trabalho.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 30,
        "pergunta": "Raramente me sinto amedrontado(a) ou ansioso(a).",
        "dominio": "NEUROTICISMO",
        "fator": -1,
        "result": null
    },
    {
        "id": 31,
        "pergunta": "Freqüentemente, sinto-me explodindo de energia.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 32,
        "pergunta": "Raramente me dou conta dos humores ou sentimentos que diferentes ambientes causam.",
        "dominio": "ABERTURA",
        "fator": -1,
        "result": null
    },
    {
        "id": 33,
        "pergunta": "Tendo a pensar o melhor acerca das pessoas.",
        "dominio": "AMABILIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 34,
        "pergunta": "Trabalho muito para conseguir o que quero.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 35,
        "pergunta": "Muitas vezes, fico irritado com a maneira como as pessoas me  tratam.",
        "dominio": "NEUROTICISMO",
        "fator": -1,
        "result": null
    },
    {
        "id": 36,
        "pergunta": "Sou uma pessoa alegre e bem-disposta.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 37,
        "pergunta": "Raramente experimento fortes emoções.",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 38,
        "pergunta": "Algumas pessoas consideram-me frio(a) e calculista.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 39,
        "pergunta": "Quando assumo um compromisso as pessoas confiam que vou cumprir.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 40,
        "pergunta": "Muitas vezes, quando as coisas dão errado, fico desanimado e tenho vontade de desistir.",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 41,
        "pergunta": "Eu não gosto muito de ficar conversando com as pessoas.",
        "dominio": "EXTROVERSÃO",
        "fator": -1,
        "result": null
    },
    {
        "id": 42,
        "pergunta": "Às vezes, ao ler poesia ou ao olhar para uma obra-de-arte, sinto um arrepio, uma onda de emoção.",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 43,
        "pergunta": "Sou cabeçudo(a) e teimoso(a).",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 44,
        "pergunta": "Às vezes, não sou tão confiável como deveria ser.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 45,
        "pergunta": "Raramente estou triste ou deprimido(a).",
        "dominio": "NEUROTICISMO",
        "fator": -1,
        "result": null
    },
    {
        "id": 46,
        "pergunta": "Minha vida é agitada.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 47,
        "pergunta": "Tenho pouco interesse em especular sobre a natureza do universo ou da condição humana.",
        "dominio": "ABERTURA",
        "fator": -1,
        "result": null
    },
    {
        "id": 48,
        "pergunta": "Geralmente procuro ser atencioso(a) e delicado(a).",
        "dominio": "AMABILIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 49,
        "pergunta": "Eu sou uma pessoa produtiva que sempre consegue fazer as coisas.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    },
    {
        "id": 50,
        "pergunta": "Sinto-me, muitas vezes, desamparado(a), desejando que alguém resolva os meus problemas.",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 51,
        "pergunta": "Sou uma pessoa muito ativa.",
        "dominio": "EXTROVERSÃO",
        "fator": 1,
        "result": null
    },
    {
        "id": 52,
        "pergunta": "Tenho muita curiosidade intelectual.",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 53,
        "pergunta": "Quando não gosto de alguém, faço questão de demonstrar.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 54,
        "pergunta": "Parece que nunca consigo ser organizado(a).",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 55,
        "pergunta": "Algumas vezes me senti tão envergonhado(a) que simplesmente queria sumir.",
        "dominio": "NEUROTICISMO",
        "fator": 1,
        "result": null
    },
    {
        "id": 56,
        "pergunta": "Prefiro tratar da minha vida a ser um(a) líder para outras pessoas.",
        "dominio": "EXTROVERSÃO",
        "fator": -1,
        "result": null
    },
    {
        "id": 57,
        "pergunta": "Muitas vezes sinto prazer em brincar com teorias e idéias abstratas.",
        "dominio": "ABERTURA",
        "fator": 1,
        "result": null
    },
    {
        "id": 58,
        "pergunta": "Se for necessário, estou disposto a manipular as pessoas para conseguir aquilo que quero.",
        "dominio": "AMABILIDADE",
        "fator": -1,
        "result": null
    },
    {
        "id": 59,
        "pergunta": "Esforço-me por ser excelente em tudo que faço.",
        "dominio": "CONSCIENCIOSIDADE",
        "fator": 1,
        "result": null
    }
]



export const tableScoreT = [
    {
      "id_Escala": "1",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "6",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "7",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "8",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "9",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "10",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "11",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "12",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "13",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "14",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "15",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "16",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "17",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "18",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "19",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "20",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "21",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "22",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "23",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "24",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "25",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "26",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "27",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "28",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "29",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "30",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "31",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "32",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "33",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "34",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "35",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "36",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "37",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "38",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "39",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "40",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "41",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "42",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "43",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "44",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "45",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "46",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "47",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "48",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "49",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "50",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "143",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "51",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "142",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "52",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "141",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "53",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "140",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "54",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "139",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "55",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "138",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "56",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "137",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "57",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "136",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "58",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "135",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "59",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "134",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "60",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "133",
      "percentil": "90",
      "escore_T": "67"
    },
    {
      "id_Escala": "61",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "132",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "62",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "131",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "63",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "64",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "65",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "66",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "67",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "68",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "125",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "69",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "124",
      "percentil": "85",
      "escore_T": "63"
    },
    {
      "id_Escala": "70",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "123",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "71",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "122",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "72",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "121",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "73",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "120",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "74",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "119",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "75",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "118",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "76",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "117",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "77",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "116",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "78",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "115",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "79",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "114",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "80",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "113",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "81",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "112",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "82",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "111",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "83",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "110",
      "percentil": "70",
      "escore_T": "57"
    },
    {
      "id_Escala": "84",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "109",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "85",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "108",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "86",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "87",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "88",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "105",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "89",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "104",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "90",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "103",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "91",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "102",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "92",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "101",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "93",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "100",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "94",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "99",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "95",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "98",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "96",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "97",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "97",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "96",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "98",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "95",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "99",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "94",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "100",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "93",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "101",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "92",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "102",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "91",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "103",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "90",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "104",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "89",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "105",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "88",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "106",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "87",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "107",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "86",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "108",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "85",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "109",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "84",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "110",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "83",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "111",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "82",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "112",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "81",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "113",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "80",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "114",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "79",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "115",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "78",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "116",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "77",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "117",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "76",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "118",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "75",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "119",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "74",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "120",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "73",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "121",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "72",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "122",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "71",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "123",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "70",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "124",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "69",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "125",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "68",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "126",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "67",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "127",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "66",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "128",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "65",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "129",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "64",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "130",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "63",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "131",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "62",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "132",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "61",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "133",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "134",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "135",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "136",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "3",
      "escore_T": "33"
    },
    {
      "id_Escala": "137",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "3",
      "escore_T": "33"
    },
    {
      "id_Escala": "138",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "139",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "2",
      "escore_T": "32"
    },
    {
      "id_Escala": "140",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "141",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "142",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "143",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "144",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "145",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "146",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "147",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "148",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "149",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "150",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "151",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "152",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "153",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "154",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "155",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "156",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "157",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "158",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "159",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "160",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "161",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "162",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "163",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "164",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "165",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "166",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "167",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "168",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "169",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "170",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "171",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "172",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "173",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "174",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "175",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "176",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "177",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "178",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "179",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "180",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "181",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "182",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "183",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "184",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "185",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "186",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "187",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "188",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "189",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "190",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "191",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "192",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "193",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "194",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "195",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "196",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "197",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "198",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "199",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "200",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "201",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "202",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "203",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "204",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "205",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "206",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "207",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "208",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "209",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "210",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "211",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "212",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "213",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "214",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "215",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "216",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "217",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "218",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "219",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "220",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "221",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "222",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "223",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "224",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "225",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "226",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "227",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "228",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "229",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "230",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "231",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "232",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "233",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "234",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "235",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "236",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "237",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "238",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "239",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "240",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "241",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "242",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "243",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "244",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "245",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "246",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "247",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "248",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "249",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "250",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "251",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "252",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "253",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "254",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "255",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "256",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "257",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "258",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "259",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "260",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "261",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "125",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "262",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "124",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "263",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "264",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "265",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "266",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "267",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "268",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "269",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "270",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "271",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "272",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "273",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "274",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "275",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "276",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "277",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "278",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "279",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "280",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "281",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "105",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "282",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "104",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "283",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "103",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "284",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "285",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "286",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "287",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "288",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "289",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "290",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "291",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "292",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "293",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "294",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "295",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "296",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "297",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "298",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "299",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "300",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "301",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "302",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "84",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "303",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "83",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "304",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "305",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "306",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "307",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "308",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "309",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "310",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "311",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "312",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "313",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "314",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "315",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "316",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "317",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "318",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "319",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "320",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "321",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "322",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "323",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "324",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "325",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "326",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "327",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "328",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "329",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "330",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "331",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "332",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "333",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "334",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "335",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "336",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "337",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "338",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "339",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "340",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "341",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "342",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "343",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "344",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "345",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "346",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "347",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "348",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "349",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "350",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "351",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "352",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "353",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "354",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "355",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "356",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "357",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "358",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "359",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "360",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "361",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "362",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "363",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "364",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "365",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "366",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "367",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "368",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "369",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "370",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "371",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "372",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "373",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "374",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "375",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "376",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "377",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "378",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "379",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "380",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "381",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "382",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "383",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "384",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "385",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "386",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "387",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "388",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "389",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "390",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "391",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "392",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "393",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "394",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "395",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "396",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "397",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "398",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "399",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "400",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "401",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "402",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "403",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "404",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "405",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "406",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "407",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "408",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "409",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "410",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "411",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "412",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "413",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "414",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "415",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "416",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "417",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "418",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "419",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "420",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "421",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "422",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "423",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "424",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "97",
      "escore_T": "72"
    },
    {
      "id_Escala": "425",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "426",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "427",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "428",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "96",
      "escore_T": "70"
    },
    {
      "id_Escala": "429",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "430",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "431",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "432",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "433",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "90",
      "escore_T": "67"
    },
    {
      "id_Escala": "434",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "435",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "436",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "437",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "438",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "439",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "440",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "441",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "442",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "443",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "444",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "445",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "446",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "447",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "448",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "449",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "450",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "451",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "452",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "453",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "454",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "455",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "456",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "457",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "458",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "459",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "460",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "461",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "462",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "463",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "464",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "465",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "466",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "467",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "468",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "469",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "470",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "471",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "472",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "473",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "474",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "475",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "476",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "477",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "478",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "479",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "480",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "481",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "482",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "483",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "484",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "485",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "486",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "487",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "488",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "489",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "490",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "491",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "492",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "493",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "494",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "495",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "496",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "2",
      "escore_T": "32"
    },
    {
      "id_Escala": "497",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "498",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "499",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "500",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "501",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "502",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "503",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "504",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "505",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "506",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "507",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "508",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "509",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "510",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "511",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "512",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "513",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "514",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "515",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "516",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "517",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "518",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "519",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "520",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "521",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "522",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "523",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "524",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "525",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "526",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "527",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "528",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "529",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "530",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "531",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "532",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "533",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "534",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "535",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "536",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "537",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "538",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "539",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "540",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "541",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "542",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "543",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "544",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "545",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "546",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "547",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "548",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "549",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "550",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "551",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "552",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "553",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "554",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "555",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "556",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "557",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "558",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "559",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "560",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "561",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "562",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "563",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "564",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "565",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "566",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "567",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "568",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "569",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "570",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "571",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "572",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "573",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "574",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "575",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "576",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "577",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "578",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "579",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "580",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "581",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "582",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "583",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "584",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "585",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "586",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "587",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "588",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "589",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "590",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "591",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "592",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "593",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "594",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "595",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "596",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "597",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "598",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "599",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "600",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "601",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "602",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "603",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "604",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "605",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "606",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "607",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "608",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "609",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "610",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "611",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "612",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "613",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "614",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "615",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "616",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "617",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "618",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "619",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "620",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "621",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "622",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "98",
      "escore_T": "69"
    },
    {
      "id_Escala": "623",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "98",
      "escore_T": "69"
    },
    {
      "id_Escala": "624",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "97",
      "escore_T": "68"
    },
    {
      "id_Escala": "625",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "97",
      "escore_T": "68"
    },
    {
      "id_Escala": "626",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "627",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "96",
      "escore_T": "66"
    },
    {
      "id_Escala": "628",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "629",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "630",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "631",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "632",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "633",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "634",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "635",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "636",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "637",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "638",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "639",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "640",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "641",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "642",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "643",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "644",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "645",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "127",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "646",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "647",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "648",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "649",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "650",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "651",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "652",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "653",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "654",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "655",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "656",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "657",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "658",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "659",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "660",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "661",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "662",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "663",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "109",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "664",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "108",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "665",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "107",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "666",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "667",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "15",
      "escore_T": "42"
    },
    {
      "id_Escala": "668",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "669",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "670",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "671",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "672",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "673",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "674",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "675",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "676",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "677",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "678",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "679",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "93",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "680",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "92",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "681",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "682",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "683",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "89",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "684",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "88",
      "percentil": "4",
      "escore_T": "31"
    },
    {
      "id_Escala": "685",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "686",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "687",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "688",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "3",
      "escore_T": "29"
    },
    {
      "id_Escala": "689",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "690",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "691",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "692",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "693",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "694",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "695",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "696",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "697",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "698",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "699",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "700",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "701",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "702",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "703",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "704",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "705",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "706",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "707",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "708",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "709",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "710",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "711",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "712",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "713",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "714",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "715",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "716",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "717",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "718",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "719",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "720",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "721",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "722",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "723",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "724",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "725",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "726",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "727",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "728",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "729",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "730",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "731",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "732",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "733",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "734",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "735",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "736",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "737",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "738",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "739",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "740",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "741",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "742",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "743",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "744",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "745",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "746",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "747",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "748",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "749",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "750",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "751",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "752",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "753",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "754",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "755",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "756",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "757",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "758",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "759",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "760",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "761",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "762",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "763",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "764",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "765",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "766",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "767",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "768",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "769",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "770",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "771",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "772",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "773",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "774",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "775",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "776",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "777",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "778",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "779",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "780",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "781",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "782",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "783",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "784",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "785",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "786",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "787",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "788",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "789",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "790",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "791",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "792",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "793",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "794",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "795",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "796",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "797",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "798",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "799",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "800",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "801",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "802",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "803",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "804",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "805",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "806",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "807",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "808",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "809",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "97",
      "escore_T": "68"
    },
    {
      "id_Escala": "810",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "811",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "812",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "813",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "96",
      "escore_T": "66"
    },
    {
      "id_Escala": "814",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "815",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "150",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "816",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "149",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "817",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "148",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "818",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "147",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "819",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "146",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "820",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "821",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "822",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "823",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "824",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "825",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "826",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "827",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "828",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "829",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "830",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "831",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "832",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "833",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "834",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "835",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "130",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "836",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "129",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "837",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "128",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "838",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "127",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "839",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "840",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "841",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "842",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "843",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "50",
      "escore_T": "52"
    },
    {
      "id_Escala": "844",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "845",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "45",
      "escore_T": "51"
    },
    {
      "id_Escala": "846",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "847",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "848",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "849",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "850",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "851",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "852",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "853",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "30",
      "escore_T": "47"
    },
    {
      "id_Escala": "854",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "855",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "856",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "857",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "858",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "107",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "859",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "860",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "861",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "862",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "863",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "864",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "865",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "866",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "867",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "868",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "869",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "870",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "871",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "872",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "873",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "874",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "875",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "876",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "877",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "878",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "879",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "880",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "881",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "882",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "883",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "884",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "885",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "886",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "887",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "888",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "889",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "3",
      "escore_T": "29"
    },
    {
      "id_Escala": "890",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "891",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "892",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "893",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "894",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "895",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "896",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "897",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "898",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "899",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "900",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "901",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "902",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "903",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "904",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "905",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "906",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "907",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "908",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "909",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "910",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "911",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "912",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "913",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "914",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "915",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "916",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "917",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "918",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "919",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "920",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "921",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "922",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "923",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "924",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "925",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "926",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "927",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "928",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "929",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "930",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "931",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "932",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "933",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "934",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "935",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "936",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "937",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "938",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "939",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "940",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "941",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "942",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "943",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "944",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "945",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "946",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "947",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "948",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "949",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "950",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "951",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "952",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "953",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "954",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "955",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "956",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "957",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "958",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "959",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "960",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "961",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "962",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "963",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "964",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "965",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "966",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "967",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "968",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "969",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "970",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "971",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "972",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "973",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "974",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "975",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "976",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "977",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "978",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "979",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "980",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "981",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "982",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "983",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "984",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "985",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "986",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "987",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "988",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "989",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "990",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "991",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "992",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "993",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "994",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "995",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "996",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "997",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "998",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "999",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1000",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1001",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1002",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1003",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1004",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "1005",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1006",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "1007",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1008",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1009",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1010",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1011",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1012",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1013",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1014",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1015",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1016",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1017",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "1018",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "1019",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1020",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "1021",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1022",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1023",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1024",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1025",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1026",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1027",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1028",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1029",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1030",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1031",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1032",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1033",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1034",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1035",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1036",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "74"
    },
    {
      "id_Escala": "1037",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "1038",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1039",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1040",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "1041",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "1042",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "1043",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1044",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1045",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1046",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1047",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1048",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "1049",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1050",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "1051",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "1052",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1053",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "1054",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "20",
      "escore_T": "40"
    },
    {
      "id_Escala": "1055",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1056",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "1057",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "7",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1058",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "33"
    },
    {
      "id_Escala": "1059",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1060",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1061",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1062",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1063",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1064",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1065",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1066",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1067",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1068",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1069",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1070",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "1071",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1072",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1073",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "1074",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "1075",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1076",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1077",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1078",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1079",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1080",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "1081",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1082",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1083",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "1084",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "40"
    },
    {
      "id_Escala": "1085",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1086",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1087",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1088",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1089",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1090",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1091",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1092",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1093",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1094",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1095",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1096",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1097",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1098",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1099",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1100",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1101",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1102",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1103",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "1104",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1105",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1106",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1107",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1108",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1109",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1110",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "1111",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "1112",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1113",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1114",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "55",
      "escore_T": "50"
    },
    {
      "id_Escala": "1115",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "1116",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "1117",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1118",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "1119",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1120",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1121",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1122",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1123",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1124",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1125",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1126",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1127",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1128",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1129",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1130",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1131",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1132",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1133",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1134",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1135",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1136",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1137",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1138",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "73"
    },
    {
      "id_Escala": "1139",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "1140",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1141",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "22",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1142",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1143",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1144",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1145",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1146",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "17",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1147",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1148",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1149",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1150",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1151",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "12",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1152",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "11",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1153",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1154",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "1155",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1156",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "7",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1157",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1158",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1159",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1160",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1161",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1162",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1163",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1164",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1165",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "69"
    },
    {
      "id_Escala": "1166",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1167",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "1168",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1169",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1170",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1171",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "25",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1172",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1173",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "1174",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1175",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1176",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "20",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "1177",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "1178",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1179",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1180",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1181",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1182",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1183",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1184",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1185",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1186",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1187",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1188",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1189",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1190",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1191",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1192",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1193",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1194",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1195",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1196",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1197",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1198",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1199",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1200",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1201",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1202",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1203",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1204",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "1205",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "1206",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1207",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1208",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1209",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1210",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "1211",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1212",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1213",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1214",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1215",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1216",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1217",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1218",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1219",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1220",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1221",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1222",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1223",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1224",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1225",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1226",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1227",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1228",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1229",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1230",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1231",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1232",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1233",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1234",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1235",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "1236",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1237",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "1238",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1239",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1240",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1241",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1242",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1243",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1244",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1245",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "1246",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1247",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1248",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1249",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "1250",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1251",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1252",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1253",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1254",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1255",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1256",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1257",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1258",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1259",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1260",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1261",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1262",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1263",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1264",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1265",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1266",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1267",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1268",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "1269",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1270",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1271",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "1272",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1273",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1274",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1275",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1276",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1277",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1278",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "55",
      "escore_T": "49"
    },
    {
      "id_Escala": "1279",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "45",
      "escore_T": "47"
    },
    {
      "id_Escala": "1280",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "1281",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1282",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "40"
    },
    {
      "id_Escala": "1283",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1284",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1285",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1286",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1287",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1288",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1289",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1290",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1291",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1292",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1293",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1294",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1295",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1296",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1297",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1298",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1299",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1300",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1301",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1302",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "1303",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1304",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1305",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1306",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "1307",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1308",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1309",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "1310",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1311",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1312",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1313",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "1314",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1315",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1316",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1317",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1318",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1319",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1320",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1321",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1322",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1323",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1324",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1325",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1326",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1327",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1328",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1329",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1330",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "1331",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "68"
    },
    {
      "id_Escala": "1332",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1333",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "1334",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1335",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1336",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1337",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "1338",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1339",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "1340",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1341",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1342",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "1343",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "1344",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "1345",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1346",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1347",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1348",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1349",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1350",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1351",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1352",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1353",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1354",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1355",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1356",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1357",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1358",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1359",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1360",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1361",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1362",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1363",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1364",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1365",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1366",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1367",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1368",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1369",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1370",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1371",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1372",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1373",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1374",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "1375",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1376",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1377",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1378",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1379",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1380",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1381",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1382",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "1383",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1384",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1385",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1386",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1387",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1388",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1389",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1390",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1391",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1392",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1393",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1394",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1395",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1396",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "1397",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1398",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1399",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "1400",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "1401",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1402",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1403",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1404",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1405",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "1406",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1407",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "1408",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1409",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1410",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1411",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1412",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1413",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1414",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1415",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1416",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1417",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1418",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1419",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1420",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1421",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1422",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1423",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1424",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1425",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1426",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1427",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1428",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1429",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1430",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1431",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "1432",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1433",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1434",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1435",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1436",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1437",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1438",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1439",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "1440",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1441",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "1442",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1443",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "1444",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1445",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1446",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1447",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1448",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1449",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1450",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1451",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1452",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1453",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1454",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1455",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1456",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1457",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1458",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1459",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1460",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1461",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1462",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1463",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1464",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1465",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1466",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1467",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1468",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1469",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1470",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1471",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "22",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1472",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1473",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1474",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "1475",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1476",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "1477",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1478",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "1479",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "1480",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1481",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1482",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "38"
    },
    {
      "id_Escala": "1483",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1484",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1485",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1486",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1487",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1488",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1489",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1490",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1491",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1492",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1493",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1494",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1495",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1496",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1497",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1498",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1499",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1500",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "1501",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1502",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1503",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "1504",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1505",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1506",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1507",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "1508",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1509",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1510",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1511",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1512",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1513",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1514",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1515",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1516",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1517",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1518",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1519",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1520",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1521",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1522",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1523",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1524",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1525",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1526",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1527",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1528",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "1529",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1530",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "1531",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1532",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1533",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1534",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1535",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1536",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1537",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "1538",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1539",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1540",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "1541",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "1542",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1543",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1544",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1545",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1546",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1547",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1548",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1549",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1550",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1551",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1552",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1553",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1554",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1555",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1556",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1557",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1558",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1559",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1560",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1561",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1562",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1563",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1564",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1565",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1566",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1567",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "1568",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1569",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1570",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1571",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "1572",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1573",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "1574",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1575",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1576",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "1577",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1578",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1579",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1580",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1581",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1582",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1583",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1584",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1585",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1586",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1587",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1588",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1589",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1590",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1591",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1592",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1593",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1594",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1595",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1596",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1597",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "1598",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1599",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "1600",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1601",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "61"
    },
    {
      "id_Escala": "1602",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "1603",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1604",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "1605",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1606",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1607",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1608",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1609",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1610",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "1611",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "1612",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1613",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1614",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1615",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1616",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1617",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1618",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1619",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1620",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1621",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1622",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1623",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1624",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1625",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1626",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1627",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1628",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "68"
    },
    {
      "id_Escala": "1629",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1630",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1631",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1632",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "1633",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "25",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1634",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "1635",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1636",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1637",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1638",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "20",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "1639",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1640",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1641",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1642",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1643",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1644",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1645",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1646",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1647",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1648",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1649",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1650",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1651",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1652",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1653",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1654",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1655",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1656",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1657",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1658",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1659",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1660",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1661",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1662",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "1663",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1664",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1665",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1666",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1667",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "1668",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1669",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "1670",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1671",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "1672",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "1673",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1674",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1675",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1676",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1677",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "1678",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1679",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "1680",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1681",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "1682",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1683",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1684",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1685",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1686",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1687",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1688",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1689",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1690",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1691",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1692",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1693",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1694",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1695",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1696",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1697",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1698",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1699",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1700",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "1701",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "61"
    },
    {
      "id_Escala": "1702",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1703",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1704",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1705",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1706",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1707",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1708",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1709",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1710",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "1711",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1712",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1713",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1714",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1715",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1716",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1717",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1718",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1719",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1720",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1721",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1722",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1723",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1724",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1725",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "1726",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1727",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1728",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1729",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1730",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "1731",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1732",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "1733",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "1734",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1735",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1736",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "1737",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1738",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1739",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "1740",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1741",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1742",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "1743",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1744",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1745",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1746",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1747",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1748",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1749",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1750",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1751",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1752",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1753",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1754",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1755",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1756",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1757",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1758",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1759",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1760",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1761",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1762",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1763",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1764",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1765",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "60"
    },
    {
      "id_Escala": "1766",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "1767",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1768",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "61",
      "escore_T": "52"
    },
    {
      "id_Escala": "1769",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1770",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "1771",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "1772",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1773",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1774",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1775",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1776",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1777",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1778",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1779",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1780",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1781",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1782",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1783",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1784",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1785",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1786",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1787",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1788",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1789",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1790",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1791",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "1792",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1793",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1794",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "1795",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1796",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1797",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "1798",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1799",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1800",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "81",
      "escore_T": "59"
    },
    {
      "id_Escala": "1801",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1802",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "1803",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "61",
      "escore_T": "53"
    },
    {
      "id_Escala": "1804",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1805",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1806",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "1807",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1808",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1809",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1810",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1811",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1812",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1813",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1814",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1815",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1816",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "1817",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1818",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1819",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1820",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1821",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1822",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1823",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1824",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1825",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "69"
    },
    {
      "id_Escala": "1826",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1827",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "1828",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1829",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "1830",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1831",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "25",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "1832",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "61",
      "escore_T": "52"
    },
    {
      "id_Escala": "1833",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "1834",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "1835",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1836",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "20",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1837",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1838",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "1839",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "1840",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1841",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "1842",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1843",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "1844",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "1845",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1846",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1847",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1848",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1849",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1850",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1851",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1852",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1853",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1854",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1855",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1856",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1857",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1858",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1859",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1860",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1861",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "1862",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1863",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "1864",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1865",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1866",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "1867",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "55"
    },
    {
      "id_Escala": "1868",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "1869",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "1870",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1871",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1872",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "1873",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1874",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1875",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1876",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "1877",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "1878",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "1879",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1880",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1881",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "1882",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1883",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1884",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1885",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1886",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1887",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1888",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1889",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1890",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "1891",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "1892",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "1893",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "1894",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1895",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "1896",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "1897",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "1898",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "1899",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "81",
      "escore_T": "59"
    },
    {
      "id_Escala": "1900",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "1901",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "1902",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "61",
      "escore_T": "53"
    },
    {
      "id_Escala": "1903",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "1904",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "1905",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "1906",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "1907",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1908",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1909",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1910",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1911",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "1912",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1913",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1914",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1915",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1916",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1917",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1918",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1919",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1920",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1921",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1922",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1923",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "1924",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "1925",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "1926",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "1927",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "1928",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "1929",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "1930",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "1931",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "1932",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "1933",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "1934",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "1935",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "1936",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "1937",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "1938",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "1939",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "1940",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "1941",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "1942",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "1943",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "1944",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "1945",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "1946",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "1947",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "1948",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "1949",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "1950",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "1951",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "1952",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "1953",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1954",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1955",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "1",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "1956",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1957",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1958",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1959",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1960",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1961",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1962",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1963",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1964",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1965",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1966",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1967",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1968",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1969",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1970",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1971",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1972",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1973",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1974",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1975",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1976",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1977",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1978",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1979",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1980",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1981",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1982",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1983",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1984",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1985",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1986",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1987",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1988",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1989",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1990",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1991",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1992",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1993",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1994",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1995",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1996",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "1997",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1998",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "1999",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2000",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2001",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2002",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2003",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "98",
      "escore_T": "76"
    },
    {
      "id_Escala": "2004",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "98",
      "escore_T": "76"
    },
    {
      "id_Escala": "2005",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "143",
      "percentil": "98",
      "escore_T": "75"
    },
    {
      "id_Escala": "2006",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "142",
      "percentil": "98",
      "escore_T": "75"
    },
    {
      "id_Escala": "2007",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "141",
      "percentil": "98",
      "escore_T": "75"
    },
    {
      "id_Escala": "2008",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "140",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "2009",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "139",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "2010",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "138",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "2011",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "137",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "2012",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "136",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2013",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "135",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2014",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "134",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2015",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "133",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2016",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "132",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "2017",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "131",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "2018",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "130",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "2019",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "129",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "2020",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "128",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "2021",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "127",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "2022",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "126",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "2023",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "125",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "2024",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "124",
      "percentil": "90",
      "escore_T": "67"
    },
    {
      "id_Escala": "2025",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "123",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2026",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "122",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2027",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "121",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2028",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "120",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2029",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "119",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2030",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "118",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2031",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "117",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2032",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "116",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2033",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "115",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2034",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "114",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2035",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "113",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2036",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "112",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2037",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "111",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2038",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "110",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2039",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "109",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2040",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "108",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2041",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "107",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2042",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "106",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2043",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "105",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2044",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "104",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "2045",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "103",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2046",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "102",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "2047",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "101",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2048",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "100",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "2049",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "99",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "2050",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "98",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "2051",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "97",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2052",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "96",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2053",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "95",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2054",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "94",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2055",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "93",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "2056",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "92",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "2057",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "91",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "2058",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "90",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "2059",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "89",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2060",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "88",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2061",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "87",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "2062",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "86",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "2063",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "85",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "2064",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "84",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "2065",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "83",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "2066",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "82",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "2067",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "81",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "2068",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "80",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "2069",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "79",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "2070",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "78",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2071",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "77",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2072",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "76",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "2073",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "75",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "2074",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "74",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "2075",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "73",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "2076",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "72",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2077",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "71",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2078",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "70",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "2079",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "69",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2080",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "68",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2081",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "67",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2082",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "66",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2083",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "65",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "2084",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "64",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "2085",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "63",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2086",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "62",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2087",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "61",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "2088",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "60",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "2089",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "59",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2090",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "58",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2091",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "57",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2092",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "56",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2093",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "55",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2094",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "2095",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "2096",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "2097",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "2098",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "2099",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "2100",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "2101",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "2102",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "2103",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "2104",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "2105",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "2106",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "2107",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "2108",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "2109",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2110",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2111",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "2112",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "2113",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2114",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2115",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2116",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2117",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2118",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2119",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2120",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2121",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2122",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2123",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2124",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2125",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2126",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2127",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2128",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2129",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2130",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2131",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2132",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2133",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2134",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2135",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2136",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2137",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2138",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2139",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2140",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2141",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2142",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2143",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2144",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2145",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2146",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2147",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2148",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2149",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2150",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2151",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2152",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2153",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2154",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2155",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2156",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2157",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2158",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2159",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2160",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2161",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2162",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2163",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2164",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2165",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2166",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2167",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2168",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2169",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2170",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2171",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2172",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2173",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2174",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2175",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2176",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2177",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2178",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2179",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2180",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2181",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2182",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2183",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2184",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2185",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2186",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2187",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2188",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "2189",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "2190",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2191",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2192",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2193",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2194",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "2195",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "2196",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "2197",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "2198",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "143",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "2199",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "142",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "2200",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "141",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "2201",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2202",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2203",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2204",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2205",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2206",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2207",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "85",
      "escore_T": "63"
    },
    {
      "id_Escala": "2208",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2209",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2210",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2211",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2212",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2213",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2214",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2215",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2216",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "125",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2217",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "124",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "2218",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "123",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2219",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "122",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "2220",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "121",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2221",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "2222",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "2223",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2224",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2225",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2226",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "2227",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "2228",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "2229",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "2230",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2231",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2232",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "2233",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "2234",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "2235",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "2236",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "105",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "2237",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "104",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "2238",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "103",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "2239",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "102",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2240",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "101",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "2241",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "2242",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "2243",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "2244",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2245",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2246",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2247",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2248",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2249",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2250",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "2251",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "2252",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2253",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "2254",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "2255",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2256",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2257",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "84",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2258",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "83",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2259",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "2260",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "2261",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "2262",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "2263",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "2",
      "escore_T": "32"
    },
    {
      "id_Escala": "2264",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "2265",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "2266",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "2267",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "2268",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "2269",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "2270",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "2271",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "2272",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "2273",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "2274",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "2275",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "2",
      "escore_T": "25"
    },
    {
      "id_Escala": "2276",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2277",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2278",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2279",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2280",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2281",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2282",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2283",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2284",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2285",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2286",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2287",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2288",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2289",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2290",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2291",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2292",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2293",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2294",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2295",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2296",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2297",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2298",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2299",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2300",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2301",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2302",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2303",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2304",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2305",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2306",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2307",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2308",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2309",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2310",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2311",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2312",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2313",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2314",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2315",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2316",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2317",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2318",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2319",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2320",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2321",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2322",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2323",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2324",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2325",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2326",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2327",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2328",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2329",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2330",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2331",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2332",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2333",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2334",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2335",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2336",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2337",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2338",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2339",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2340",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2341",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2342",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2343",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2344",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2345",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2346",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2347",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2348",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2349",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2350",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2351",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2352",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2353",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2354",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2355",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2356",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2357",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2358",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2359",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2360",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2361",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2362",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2363",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2364",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2365",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2366",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2367",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2368",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2369",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2370",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2371",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2372",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2373",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2374",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2375",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "2376",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "2377",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "2378",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2379",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "97",
      "escore_T": "72"
    },
    {
      "id_Escala": "2380",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "2381",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "2382",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "96",
      "escore_T": "70"
    },
    {
      "id_Escala": "2383",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "2384",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "2385",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "2386",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "90",
      "escore_T": "68"
    },
    {
      "id_Escala": "2387",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "90",
      "escore_T": "67"
    },
    {
      "id_Escala": "2388",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "90",
      "escore_T": "67"
    },
    {
      "id_Escala": "2389",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2390",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2391",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2392",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2393",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2394",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2395",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2396",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2397",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2398",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2399",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2400",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2401",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2402",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2403",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "2404",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "2405",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2406",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2407",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2408",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2409",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "2410",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "2411",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2412",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "2413",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2414",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "2415",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "2416",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "2417",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "2418",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2419",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2420",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "2421",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "2422",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "2423",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "2424",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "2425",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "2426",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "2427",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2428",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2429",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "2430",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "2431",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "2432",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "2433",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "2434",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2435",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "2436",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "20",
      "escore_T": "40"
    },
    {
      "id_Escala": "2437",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2438",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "2439",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "2440",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2441",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2442",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "2443",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "2444",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2445",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2446",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2447",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "2448",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "2449",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "3",
      "escore_T": "33"
    },
    {
      "id_Escala": "2450",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "3",
      "escore_T": "33"
    },
    {
      "id_Escala": "2451",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "2",
      "escore_T": "32"
    },
    {
      "id_Escala": "2452",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "2453",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "2454",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "2455",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "2456",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "2457",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "2458",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "2459",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "2460",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2461",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2462",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "2463",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "2464",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2465",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2466",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2467",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2468",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2469",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2470",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2471",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2472",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2473",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2474",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2475",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2476",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2477",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2478",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2479",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2480",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2481",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2482",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2483",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2484",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2485",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2486",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2487",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2488",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2489",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2490",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2491",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2492",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2493",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2494",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2495",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2496",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2497",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2498",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2499",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2500",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2501",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2502",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2503",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2504",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2505",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2506",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2507",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2508",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2509",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2510",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2511",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2512",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2513",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2514",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2515",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2516",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2517",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2518",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2519",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2520",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2521",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2522",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2523",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2524",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2525",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2526",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2527",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2528",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2529",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2530",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2531",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2532",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2533",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2534",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2535",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2536",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2537",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2538",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2539",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2540",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2541",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2542",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2543",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2544",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2545",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2546",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2547",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2548",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2549",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2550",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2551",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2552",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2553",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2554",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2555",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2556",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2557",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2558",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2559",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2560",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2561",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2562",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2563",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2564",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2565",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2566",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2567",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2568",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2569",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2570",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2571",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "2572",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "2573",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2574",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2575",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2576",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "2577",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "2578",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "98",
      "escore_T": "69"
    },
    {
      "id_Escala": "2579",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "98",
      "escore_T": "69"
    },
    {
      "id_Escala": "2580",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "97",
      "escore_T": "68"
    },
    {
      "id_Escala": "2581",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "97",
      "escore_T": "67"
    },
    {
      "id_Escala": "2582",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "2583",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "96",
      "escore_T": "66"
    },
    {
      "id_Escala": "2584",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "143",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "2585",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "2586",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "2587",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2588",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2589",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2590",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "2591",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "2592",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "90",
      "escore_T": "61"
    },
    {
      "id_Escala": "2593",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2594",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "2595",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "2596",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2597",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2598",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "2599",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2600",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2601",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2602",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "2603",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "2604",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2605",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "2606",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2607",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "55",
      "escore_T": "53"
    },
    {
      "id_Escala": "2608",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "2609",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "50",
      "escore_T": "52"
    },
    {
      "id_Escala": "2610",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "2611",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "2612",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "2613",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "2614",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "2615",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "2616",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "2617",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "30",
      "escore_T": "47"
    },
    {
      "id_Escala": "2618",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "2619",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "2620",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "2621",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "2622",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "2623",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "20",
      "escore_T": "44"
    },
    {
      "id_Escala": "2624",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "2625",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2626",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2627",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2628",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2629",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2630",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "10",
      "escore_T": "40"
    },
    {
      "id_Escala": "2631",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "2632",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2633",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2634",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "2635",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "2636",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2637",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2638",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2639",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "2640",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "2641",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "2642",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "2643",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "2644",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "2645",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "4",
      "escore_T": "31"
    },
    {
      "id_Escala": "2646",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "2647",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "2648",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "2649",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "2650",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "2651",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "2652",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "2653",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "2654",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "2655",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "25"
    },
    {
      "id_Escala": "2656",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "2",
      "escore_T": "25"
    },
    {
      "id_Escala": "2657",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "2",
      "escore_T": "24"
    },
    {
      "id_Escala": "2658",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "2",
      "escore_T": "24"
    },
    {
      "id_Escala": "2659",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "2",
      "escore_T": "23"
    },
    {
      "id_Escala": "2660",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "2",
      "escore_T": "22"
    },
    {
      "id_Escala": "2661",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "2",
      "escore_T": "22"
    },
    {
      "id_Escala": "2662",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "2",
      "escore_T": "21"
    },
    {
      "id_Escala": "2663",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "2",
      "escore_T": "21"
    },
    {
      "id_Escala": "2664",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "2",
      "escore_T": "20"
    },
    {
      "id_Escala": "2665",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2666",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2667",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2668",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2669",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2670",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2671",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2672",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2673",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2674",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2675",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2676",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2677",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2678",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2679",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2680",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2681",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2682",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2683",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2684",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2685",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2686",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2687",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2688",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2689",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2690",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2691",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2692",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2693",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2694",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2695",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2696",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2697",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2698",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2699",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2700",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2701",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2702",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2703",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2704",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2705",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2706",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2707",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2708",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2709",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2710",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2711",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2712",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2713",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2714",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2715",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2716",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2717",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2718",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2719",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2720",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2721",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2722",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2723",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2724",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2725",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2726",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2727",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2728",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2729",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2730",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2731",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2732",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2733",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2734",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2735",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2736",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2737",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2738",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2739",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2740",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2741",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2742",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2743",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2744",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2745",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2746",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2747",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2748",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2749",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2750",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2751",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "2752",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2753",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2754",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "2755",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "2756",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2757",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "2758",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2759",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "2760",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "2761",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "2762",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "2763",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "2764",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "2765",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "2766",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "2767",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "2768",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2769",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "2770",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "150",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2771",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "149",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2772",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "148",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2773",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "147",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2774",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "146",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2775",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2776",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2777",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2778",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2779",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2780",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "2781",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2782",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "2783",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2784",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "2785",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "2786",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "2787",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2788",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "2789",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2790",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "2791",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "129",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "2792",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "128",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "2793",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "127",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2794",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2795",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2796",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "2797",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "2798",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "2799",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "2800",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "2801",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "2802",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "2803",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "2804",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "2805",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "2806",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "2807",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "2808",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "2809",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "2810",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "2811",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "2812",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2813",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2814",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "2815",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "2816",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "2817",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "2818",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2819",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "2820",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "2821",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "2822",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2823",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2824",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "2825",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "2826",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2827",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2828",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "2829",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "2830",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2831",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2832",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2833",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2834",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2835",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "2836",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "2837",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "2838",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "2839",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "2840",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "2841",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "2842",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "2843",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "2844",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "2845",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "2846",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "2847",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "2848",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "2849",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2850",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2851",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "2852",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "2853",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2854",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2855",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2856",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "2857",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2858",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2859",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2860",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2861",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "2862",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2863",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2864",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2865",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2866",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2867",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2868",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2869",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2870",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2871",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2872",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2873",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2874",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2875",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2876",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2877",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2878",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2879",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2880",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2881",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2882",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2883",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2884",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2885",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2886",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2887",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2888",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2889",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2890",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2891",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2892",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2893",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2894",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2895",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2896",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2897",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2898",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2899",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2900",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2901",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2902",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2903",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2904",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2905",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2906",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2907",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2908",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2909",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2910",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2911",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2912",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2913",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2914",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2915",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2916",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2917",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2918",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2919",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2920",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2921",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2922",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2923",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2924",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "2925",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "2926",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "2927",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "2928",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "2929",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "2930",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2931",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "2932",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "2933",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2934",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "2935",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "2936",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "2937",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "2938",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "2939",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "2940",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "2941",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "2942",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "2943",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "2944",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "2945",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "30"
    },
    {
      "id_Escala": "2946",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "2947",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2948",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2949",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "2950",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2951",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2952",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2953",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2954",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2955",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2956",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2957",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2958",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2959",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2960",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2961",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2962",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "73"
    },
    {
      "id_Escala": "2963",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "2964",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "22",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "2965",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "67"
    },
    {
      "id_Escala": "2966",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "2967",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "2968",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "2969",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "17",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "2970",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "16",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "2971",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "2972",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "2973",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "55",
      "escore_T": "49"
    },
    {
      "id_Escala": "2974",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "12",
      "percentil": "45",
      "escore_T": "47"
    },
    {
      "id_Escala": "2975",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "11",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "2976",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "2977",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "2978",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "2979",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "7",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "2980",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "34"
    },
    {
      "id_Escala": "2981",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "2982",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "2983",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "2984",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "2985",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "2986",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "2987",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2988",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2989",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "2990",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "2991",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "2992",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "74"
    },
    {
      "id_Escala": "2993",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "2994",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "2995",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "2996",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "2997",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "2998",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "2999",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3000",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3001",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3002",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3003",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "3004",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "3005",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "3006",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "45",
      "escore_T": "47"
    },
    {
      "id_Escala": "3007",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "12",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3008",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "30",
      "escore_T": "43"
    },
    {
      "id_Escala": "3009",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3010",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3011",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3012",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "7",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3013",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3014",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3015",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3016",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3017",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3018",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3019",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3020",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3021",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3022",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3023",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3024",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3025",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3026",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3027",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3028",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3029",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3030",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "3031",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3032",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "3033",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3034",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "3035",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "3036",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "3037",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3038",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3039",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3040",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3041",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3042",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3043",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3044",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3045",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3046",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3047",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3048",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3049",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3050",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3051",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3052",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3053",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3054",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3055",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3056",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3057",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3058",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "73"
    },
    {
      "id_Escala": "3059",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "3060",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3061",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3062",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3063",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3064",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3065",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3066",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3067",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3068",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "3069",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "3070",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "3071",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3072",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "40",
      "escore_T": "45"
    },
    {
      "id_Escala": "3073",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "30",
      "escore_T": "43"
    },
    {
      "id_Escala": "3074",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3075",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3076",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3077",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3078",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "33"
    },
    {
      "id_Escala": "3079",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3080",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3081",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3082",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3083",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3084",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3085",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3086",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3087",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3088",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3089",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3090",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3091",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3092",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3093",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3094",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3095",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "74"
    },
    {
      "id_Escala": "3096",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "22",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "3097",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "21",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3098",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "20",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3099",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3100",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3101",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "17",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3102",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "16",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3103",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "15",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3104",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3105",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3106",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "12",
      "percentil": "55",
      "escore_T": "50"
    },
    {
      "id_Escala": "3107",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "11",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "3108",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "10",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "3109",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3110",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3111",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "7",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3112",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "6",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3113",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "5",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3114",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3115",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3116",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3117",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3118",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3119",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3120",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3121",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "75"
    },
    {
      "id_Escala": "3122",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "73"
    },
    {
      "id_Escala": "3123",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "3124",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "69"
    },
    {
      "id_Escala": "3125",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "67"
    },
    {
      "id_Escala": "3126",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "65"
    },
    {
      "id_Escala": "3127",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "70",
      "escore_T": "63"
    },
    {
      "id_Escala": "3128",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "55",
      "escore_T": "61"
    },
    {
      "id_Escala": "3129",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "50",
      "escore_T": "58"
    },
    {
      "id_Escala": "3130",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "40",
      "escore_T": "56"
    },
    {
      "id_Escala": "3131",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "30",
      "escore_T": "54"
    },
    {
      "id_Escala": "3132",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "25",
      "escore_T": "52"
    },
    {
      "id_Escala": "3133",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "20",
      "escore_T": "50"
    },
    {
      "id_Escala": "3134",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "15",
      "escore_T": "48"
    },
    {
      "id_Escala": "3135",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "46"
    },
    {
      "id_Escala": "3136",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "44"
    },
    {
      "id_Escala": "3137",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "5",
      "escore_T": "42"
    },
    {
      "id_Escala": "3138",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "40"
    },
    {
      "id_Escala": "3139",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "38"
    },
    {
      "id_Escala": "3140",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "36"
    },
    {
      "id_Escala": "3141",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "34"
    },
    {
      "id_Escala": "3142",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3143",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3144",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3145",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3146",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3147",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3148",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3149",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3150",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3151",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3152",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3153",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3154",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3155",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3156",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3157",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "3158",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3159",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3160",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3161",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3162",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3163",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3164",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3165",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3166",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "3167",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "3168",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "3169",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3170",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3171",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "43"
    },
    {
      "id_Escala": "3172",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "25",
      "escore_T": "41"
    },
    {
      "id_Escala": "3173",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3174",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3175",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3176",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3177",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3178",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3179",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3180",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3181",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3182",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3183",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3184",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3185",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3186",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3187",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3188",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3189",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3190",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "77"
    },
    {
      "id_Escala": "3191",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "74"
    },
    {
      "id_Escala": "3192",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "3193",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3194",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3195",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3196",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3197",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3198",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3199",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "55"
    },
    {
      "id_Escala": "3200",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "53"
    },
    {
      "id_Escala": "3201",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "3202",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "3203",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "3204",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3205",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3206",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3207",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3208",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3209",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3210",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3211",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3212",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3213",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3214",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3215",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3216",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3217",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3218",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3219",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3220",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3221",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3222",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3223",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3224",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3225",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3226",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3227",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3228",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3229",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3230",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3231",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3232",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3233",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "55",
      "escore_T": "50"
    },
    {
      "id_Escala": "3234",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "3235",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "3236",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3237",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3238",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3239",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3240",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3241",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3242",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3243",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3244",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3245",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3246",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3247",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3248",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3249",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3250",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3251",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3252",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3253",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3254",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3255",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "3256",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3257",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3258",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3259",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3260",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3261",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "3262",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3263",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "3264",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "3265",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3266",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3267",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3268",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3269",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3270",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3271",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "3272",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3273",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3274",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3275",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3276",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3277",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3278",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3279",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3280",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3281",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3282",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3283",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3284",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3285",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3286",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3287",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3288",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3289",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3290",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3291",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3292",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3293",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3294",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "3295",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "3296",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "3297",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "3298",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3299",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3300",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "3301",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "3302",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3303",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3304",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "3305",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3306",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3307",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3308",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3309",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3310",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3311",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3312",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3313",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3314",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3315",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3316",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3317",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3318",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3319",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "3320",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3321",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3322",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3323",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3324",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3325",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3326",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3327",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3328",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "3329",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "3330",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "3331",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3332",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3333",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3334",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3335",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3336",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3337",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "3338",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3339",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3340",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3341",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3342",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3343",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3344",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3345",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3346",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3347",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3348",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3349",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3350",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3351",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3352",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "3353",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3354",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3355",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3356",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3357",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3358",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "3359",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "3360",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3361",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3362",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3363",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3364",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3365",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3366",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3367",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3368",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3369",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3370",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "3371",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3372",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3373",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3374",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3375",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3376",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3377",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3378",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3379",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3380",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3381",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3382",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3383",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3384",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3385",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3386",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3387",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3388",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3389",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3390",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3391",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3392",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3393",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "3394",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "3395",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "3396",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "3397",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3398",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3399",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3400",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3401",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3402",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3403",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3404",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3405",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3406",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3407",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3408",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3409",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3410",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3411",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3412",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3413",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3414",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3415",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3416",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3417",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3418",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3419",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3420",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3421",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3422",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3423",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3424",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3425",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "3426",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "22",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3427",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "21",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3428",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3429",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3430",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3431",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "75",
      "escore_T": "55"
    },
    {
      "id_Escala": "3432",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "65",
      "escore_T": "52"
    },
    {
      "id_Escala": "3433",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "3434",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3435",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3436",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3437",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "38"
    },
    {
      "id_Escala": "3438",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3439",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3440",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3441",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3442",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3443",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3444",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3445",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3446",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3447",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3448",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3449",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "3450",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3451",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3452",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "29",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3453",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3454",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3455",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3456",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "3457",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "75",
      "escore_T": "55"
    },
    {
      "id_Escala": "3458",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3459",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "50",
      "escore_T": "52"
    },
    {
      "id_Escala": "3460",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3461",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "3462",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3463",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "3464",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3465",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3466",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3467",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3468",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3469",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3470",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3471",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3472",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3473",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3474",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3475",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3476",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3477",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3478",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3479",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3480",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3481",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3482",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3483",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3484",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3485",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3486",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3487",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3488",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3489",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3490",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "3491",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3492",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3493",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3494",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3495",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "3496",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "3497",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3498",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3499",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3500",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3501",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3502",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3503",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3504",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3505",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3506",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3507",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3508",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3509",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3510",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3511",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3512",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3513",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3514",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3515",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3516",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3517",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3518",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3519",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "3520",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3521",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3522",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3523",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3524",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3525",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3526",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "3527",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3528",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3529",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3530",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3531",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3532",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "3533",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3534",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3535",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3536",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "3537",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3538",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "3539",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3540",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3541",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3542",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3543",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3544",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3545",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3546",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3547",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3548",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3549",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3550",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3551",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3552",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3553",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3554",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3555",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3556",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3557",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3558",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3559",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "3560",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3561",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "3562",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "3563",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3564",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3565",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3566",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "3567",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "38"
    },
    {
      "id_Escala": "3568",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "3569",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3570",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "3571",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3572",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3573",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3574",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3575",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3576",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3577",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3578",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3579",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3580",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3581",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3582",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "3583",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "3584",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3585",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3586",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3587",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3588",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3589",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "75",
      "escore_T": "55"
    },
    {
      "id_Escala": "3590",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3591",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3592",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3593",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3594",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3595",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3596",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3597",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3598",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "3599",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3600",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3601",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3602",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3603",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3604",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3605",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3606",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3607",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3608",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3609",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3610",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3611",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3612",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3613",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3614",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3615",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3616",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3617",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3618",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3619",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3620",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3621",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3622",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3623",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3624",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3625",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "3626",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3627",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3628",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3629",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3630",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3631",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "3632",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3633",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3634",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3635",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3636",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "3637",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3638",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3639",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3640",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3641",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3642",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3643",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3644",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3645",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3646",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3647",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3648",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3649",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3650",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3651",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3652",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "3653",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3654",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3655",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "3656",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3657",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "3658",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "3659",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3660",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3661",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "3662",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3663",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "3664",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "3665",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "3666",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3667",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "3668",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3669",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "3670",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3671",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3672",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3673",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3674",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3675",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3676",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3677",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3678",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3679",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3680",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3681",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3682",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3683",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3684",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3685",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "3686",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3687",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3688",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "3689",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3690",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "3691",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3692",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3693",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3694",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3695",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3696",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3697",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3698",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3699",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3700",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3701",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3702",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3703",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3704",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3705",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3706",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3707",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3708",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3709",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3710",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3711",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3712",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3713",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3714",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3715",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3716",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "73"
    },
    {
      "id_Escala": "3717",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3718",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3719",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3720",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3721",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "3722",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3723",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "61",
      "escore_T": "52"
    },
    {
      "id_Escala": "3724",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3725",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "3726",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3727",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3728",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3729",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "3730",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3731",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3732",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3733",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3734",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3735",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3736",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3737",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3738",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3739",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3740",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3741",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3742",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3743",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3744",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3745",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3746",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3747",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3748",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3749",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3750",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3751",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3752",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "3753",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3754",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3755",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3756",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "3757",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "3758",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3759",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "3760",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3761",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3762",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3763",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "3764",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3765",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3766",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3767",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "3768",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3769",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3770",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "3771",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "3772",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "3773",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3774",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3775",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3776",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3777",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3778",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3779",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3780",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3781",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3782",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "29",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3783",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "3784",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3785",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "3786",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "3787",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3788",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "3789",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "3790",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "3791",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "20",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3792",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "3793",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3794",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3795",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "3796",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "3797",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "3798",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3799",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3800",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3801",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "3802",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3803",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3804",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3805",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3806",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3807",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3808",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3809",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3810",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3811",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3812",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3813",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3814",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3815",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "3816",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "3817",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3818",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3819",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3820",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3821",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "3822",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3823",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "61",
      "escore_T": "53"
    },
    {
      "id_Escala": "3824",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "3825",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "3826",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3827",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "3828",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3829",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "3830",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "3831",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "3832",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "3833",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3834",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3835",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3836",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "3837",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3838",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3839",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3840",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3841",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3842",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3843",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3844",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3845",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3846",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3847",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3848",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3849",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3850",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3851",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3852",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3853",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3854",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3855",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3856",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "3857",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "61",
      "escore_T": "54"
    },
    {
      "id_Escala": "3858",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "3859",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "3860",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3861",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "3862",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "3863",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3864",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "40"
    },
    {
      "id_Escala": "3865",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3866",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "3867",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3868",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3869",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3870",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3871",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3872",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3873",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3874",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3875",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3876",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3877",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3878",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3879",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3880",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3881",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "3882",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "3883",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3884",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "3885",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3886",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "3887",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "3888",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "3889",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "3890",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "61",
      "escore_T": "54"
    },
    {
      "id_Escala": "3891",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "3892",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "3893",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "3894",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "3895",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "3896",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "3897",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "3898",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "3899",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "3900",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "3901",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "3902",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "3903",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "3904",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "3905",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "3906",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "3907",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3908",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3909",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3910",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "2",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "3911",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3912",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3913",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3914",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3915",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3916",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3917",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3918",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3919",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3920",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3921",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3922",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3923",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3924",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3925",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3926",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3927",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3928",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3929",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3930",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3931",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3932",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3933",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3934",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3935",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3936",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3937",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3938",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "3939",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3940",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "3941",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3942",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3943",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "3944",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3945",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "3946",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3947",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "3948",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3949",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "3950",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3951",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "3952",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "3953",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "3954",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "3955",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "3956",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "3957",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "3958",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "3959",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "3960",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "143",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "3961",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "142",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "3962",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "141",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "3963",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "140",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "3964",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "139",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "3965",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "138",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "3966",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "137",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3967",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "136",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "3968",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "135",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "3969",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "134",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "3970",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3971",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3972",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "3973",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3974",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "3975",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "85",
      "escore_T": "63"
    },
    {
      "id_Escala": "3976",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "85",
      "escore_T": "63"
    },
    {
      "id_Escala": "3977",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "3978",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "125",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "3979",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "124",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3980",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "123",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "3981",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "122",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "3982",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "121",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "3983",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "120",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "3984",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "119",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3985",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "118",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "3986",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "117",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "3987",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "116",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "3988",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "115",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3989",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "114",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "3990",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "113",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "3991",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "112",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "3992",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "111",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "3993",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "3994",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "3995",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3996",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "3997",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "3998",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "105",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "3999",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "104",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "4000",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "103",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "4001",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "102",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4002",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "101",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4003",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "100",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4004",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "99",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "4005",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "98",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "4006",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "97",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "4007",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "96",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "4008",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "95",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "4009",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "94",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "4010",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "93",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "4011",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "92",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "4012",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "91",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "4013",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "90",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "4014",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "89",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "4015",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "88",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "4016",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "87",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "4017",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "86",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "4018",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "85",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "4019",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "84",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4020",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "83",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4021",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "82",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "4022",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "81",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4023",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "80",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4024",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "79",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "4025",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "78",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4026",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "77",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4027",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "76",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4028",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "75",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4029",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "74",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4030",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "73",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4031",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "72",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4032",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "71",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4033",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "70",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "4034",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "69",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "4035",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "68",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4036",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "67",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4037",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "66",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4038",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "65",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4039",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "4040",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "4",
      "escore_T": "34"
    },
    {
      "id_Escala": "4041",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "4042",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "4",
      "escore_T": "33"
    },
    {
      "id_Escala": "4043",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "3",
      "escore_T": "33"
    },
    {
      "id_Escala": "4044",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "4045",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "4046",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "4047",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "4048",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "4049",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "4050",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "4051",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "4052",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "4053",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "4054",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "4055",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4056",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4057",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4058",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4059",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4060",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4061",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4062",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4063",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4064",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4065",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4066",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4067",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4068",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4069",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4070",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4071",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4072",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4073",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4074",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4075",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4076",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4077",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4078",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4079",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4080",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4081",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4082",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4083",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4084",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4085",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4086",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4087",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4088",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4089",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4090",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4091",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4092",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4093",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4094",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4095",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4096",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4097",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4098",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4099",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4100",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4101",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4102",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4103",
      "id_Teste": "1",
      "id_Teste_Variavel": "29",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4104",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4105",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4106",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4107",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4108",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4109",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4110",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4111",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4112",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4113",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4114",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4115",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4116",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4117",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4118",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4119",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4120",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4121",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4122",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4123",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4124",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4125",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4126",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4127",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4128",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4129",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4130",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4131",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4132",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4133",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4134",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4135",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "4136",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "4137",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "4138",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "4139",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "4140",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "98",
      "escore_T": "71"
    },
    {
      "id_Escala": "4141",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "4142",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "4143",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "4144",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "96",
      "escore_T": "69"
    },
    {
      "id_Escala": "4145",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "4146",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "4147",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "4148",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "4149",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "4150",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "4151",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "4152",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "4153",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4154",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4155",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4156",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4157",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "4158",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "4159",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4160",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4161",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4162",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4163",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4164",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4165",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "4166",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "4167",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4168",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4169",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "4170",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "126",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "4171",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "4172",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "4173",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "4174",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "4175",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4176",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4177",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "55",
      "escore_T": "53"
    },
    {
      "id_Escala": "4178",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "4179",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "4180",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4181",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "4182",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "4183",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "4184",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "4185",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "4186",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "4187",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "4188",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "4189",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "107",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "4190",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "106",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "4191",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "105",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "4192",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "104",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "4193",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "103",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "4194",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "4195",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "20",
      "escore_T": "44"
    },
    {
      "id_Escala": "4196",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "4197",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "4198",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4199",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "15",
      "escore_T": "42"
    },
    {
      "id_Escala": "4200",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4201",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4202",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4203",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4204",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4205",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4206",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4207",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4208",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4209",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "87",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4210",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4211",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4212",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "84",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4213",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "83",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4214",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4215",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4216",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4217",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4218",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "4219",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "4220",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "4",
      "escore_T": "31"
    },
    {
      "id_Escala": "4221",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "4",
      "escore_T": "31"
    },
    {
      "id_Escala": "4222",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "4223",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "4224",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "4225",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "4226",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "4227",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "4228",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4229",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4230",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4231",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4232",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4233",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4234",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4235",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4236",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4237",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4238",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4239",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4240",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4241",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4242",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4243",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4244",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4245",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4246",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4247",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4248",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4249",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4250",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4251",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4252",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4253",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4254",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4255",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4256",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4257",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4258",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4259",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4260",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4261",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4262",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4263",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4264",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4265",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4266",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4267",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4268",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4269",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4270",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4271",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4272",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4273",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4274",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4275",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4276",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4277",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4278",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4279",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4280",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4281",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4282",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4283",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4284",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4285",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4286",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4287",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4288",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4289",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4290",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4291",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4292",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4293",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4294",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4295",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4296",
      "id_Teste": "1",
      "id_Teste_Variavel": "22",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4297",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4298",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4299",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4300",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4301",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4302",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4303",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4304",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4305",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4306",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4307",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4308",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4309",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4310",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4311",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4312",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4313",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4314",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4315",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4316",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4317",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4318",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4319",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4320",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4321",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4322",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4323",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4324",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4325",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4326",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4327",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4328",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4329",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4330",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4331",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "98",
      "escore_T": "74"
    },
    {
      "id_Escala": "4332",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "98",
      "escore_T": "73"
    },
    {
      "id_Escala": "4333",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "98",
      "escore_T": "72"
    },
    {
      "id_Escala": "4334",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "97",
      "escore_T": "72"
    },
    {
      "id_Escala": "4335",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "4336",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "4337",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "4338",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "4339",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "4340",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "4341",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "4342",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "4343",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "4344",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "4345",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "4346",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "4347",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4348",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4349",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4350",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4351",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "4352",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "4353",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4354",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4355",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "4356",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4357",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4358",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "4359",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "4360",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4361",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "128",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "4362",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "127",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "4363",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "4364",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "4365",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "4366",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "4367",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4368",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "4369",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "4370",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4371",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "4372",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "4373",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "4374",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "4375",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "4376",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "4377",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "4378",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "4379",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "4380",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "4381",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "108",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "4382",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "107",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "4383",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "4384",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4385",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4386",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4387",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4388",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4389",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4390",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4391",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "4392",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "4393",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "15",
      "escore_T": "38"
    },
    {
      "id_Escala": "4394",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4395",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4396",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4397",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4398",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4399",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4400",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4401",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4402",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4403",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "4404",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "3",
      "escore_T": "32"
    },
    {
      "id_Escala": "4405",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "4406",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "2",
      "escore_T": "31"
    },
    {
      "id_Escala": "4407",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "2",
      "escore_T": "30"
    },
    {
      "id_Escala": "4408",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "4409",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "4410",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "4411",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "4412",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4413",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4414",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4415",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4416",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4417",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4418",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4419",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4420",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4421",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4422",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4423",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4424",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4425",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4426",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4427",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4428",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4429",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4430",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4431",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4432",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4433",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4434",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4435",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4436",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4437",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4438",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4439",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4440",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4441",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4442",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4443",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4444",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4445",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4446",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4447",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4448",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4449",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4450",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4451",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4452",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4453",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4454",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4455",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4456",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4457",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4458",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4459",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4460",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4461",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4462",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4463",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4464",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4465",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4466",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4467",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4468",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4469",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4470",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4471",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4472",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4473",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4474",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4475",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4476",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4477",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4478",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4479",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4480",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4481",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4482",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4483",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4484",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4485",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4486",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4487",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4488",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4489",
      "id_Teste": "1",
      "id_Teste_Variavel": "7",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4490",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4491",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4492",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4493",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4494",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4495",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4496",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4497",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4498",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4499",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4500",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4501",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4502",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4503",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4504",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4505",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4506",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4507",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4508",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4509",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4510",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4511",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4512",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4513",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4514",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4515",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4516",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4517",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4518",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4519",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4520",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4521",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4522",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4523",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4524",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4525",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4526",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "4527",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "4528",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "4529",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "4530",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "97",
      "escore_T": "71"
    },
    {
      "id_Escala": "4531",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "151",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "4532",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "150",
      "percentil": "97",
      "escore_T": "70"
    },
    {
      "id_Escala": "4533",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "149",
      "percentil": "97",
      "escore_T": "69"
    },
    {
      "id_Escala": "4534",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "148",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "4535",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "147",
      "percentil": "96",
      "escore_T": "68"
    },
    {
      "id_Escala": "4536",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "146",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "4537",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "145",
      "percentil": "96",
      "escore_T": "66"
    },
    {
      "id_Escala": "4538",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "144",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "4539",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "4540",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4541",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4542",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4543",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "4544",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "4545",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4546",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4547",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4548",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4549",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4550",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "4551",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4552",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "130",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4553",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "129",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "4554",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "128",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "4555",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "127",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "4556",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "4557",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4558",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4559",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "4560",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4561",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "4562",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "4563",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "4564",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "4565",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "4566",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "4567",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "4568",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "4569",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "4570",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "4571",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "111",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "4572",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "110",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "4573",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "109",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "4574",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "108",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4575",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "107",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4576",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4577",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4578",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "10",
      "escore_T": "40"
    },
    {
      "id_Escala": "4579",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4580",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4581",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4582",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "4583",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "4584",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4585",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4586",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4587",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "95",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4588",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "94",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4589",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "93",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4590",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "92",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "4591",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "91",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "4592",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "90",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "4593",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "89",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "4594",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "88",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "4595",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "2",
      "escore_T": "29"
    },
    {
      "id_Escala": "4596",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "4597",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "4598",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "4599",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "4600",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4601",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4602",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4603",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4604",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4605",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4606",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4607",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4608",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4609",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4610",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4611",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4612",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4613",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4614",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4615",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4616",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4617",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4618",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4619",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4620",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4621",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4622",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4623",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4624",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4625",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4626",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4627",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4628",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4629",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4630",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4631",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4632",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4633",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4634",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4635",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4636",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4637",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4638",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4639",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4640",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4641",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4642",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4643",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4644",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4645",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4646",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4647",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4648",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4649",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4650",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4651",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4652",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4653",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4654",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4655",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4656",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4657",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4658",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4659",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4660",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4661",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4662",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4663",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4664",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4665",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4666",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4667",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4668",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4669",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4670",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4671",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4672",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4673",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4674",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4675",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4676",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4677",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4678",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4679",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4680",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4681",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4682",
      "id_Teste": "1",
      "id_Teste_Variavel": "8",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4683",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "192",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4684",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "191",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4685",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "190",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4686",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "189",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4687",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "188",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4688",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "187",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4689",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "186",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4690",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "185",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4691",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "184",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4692",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "183",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4693",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "182",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4694",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "181",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4695",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "180",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4696",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "179",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4697",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "178",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4698",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "177",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4699",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "176",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4700",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "175",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4701",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "174",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4702",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "173",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4703",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "172",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4704",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "171",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4705",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "170",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4706",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "169",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4707",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "168",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4708",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "167",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "4709",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "166",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "4710",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "165",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "4711",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "164",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "4712",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "163",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "4713",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "162",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "4714",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "161",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "4715",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "160",
      "percentil": "98",
      "escore_T": "70"
    },
    {
      "id_Escala": "4716",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "159",
      "percentil": "98",
      "escore_T": "69"
    },
    {
      "id_Escala": "4717",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "158",
      "percentil": "98",
      "escore_T": "69"
    },
    {
      "id_Escala": "4718",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "157",
      "percentil": "97",
      "escore_T": "68"
    },
    {
      "id_Escala": "4719",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "156",
      "percentil": "97",
      "escore_T": "68"
    },
    {
      "id_Escala": "4720",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "155",
      "percentil": "97",
      "escore_T": "67"
    },
    {
      "id_Escala": "4721",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "154",
      "percentil": "96",
      "escore_T": "67"
    },
    {
      "id_Escala": "4722",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "153",
      "percentil": "96",
      "escore_T": "66"
    },
    {
      "id_Escala": "4723",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "152",
      "percentil": "96",
      "escore_T": "66"
    },
    {
      "id_Escala": "4724",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "151",
      "percentil": "96",
      "escore_T": "65"
    },
    {
      "id_Escala": "4725",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "150",
      "percentil": "96",
      "escore_T": "65"
    },
    {
      "id_Escala": "4726",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "149",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "4727",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "148",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4728",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "147",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4729",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "146",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4730",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "145",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4731",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "144",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "4732",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "143",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "4733",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "142",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4734",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "141",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4735",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "140",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4736",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "139",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4737",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "138",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4738",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "137",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4739",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "136",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "4740",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "135",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "4741",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "134",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4742",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "133",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4743",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "132",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "4744",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "131",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "4745",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "130",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "4746",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "129",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "4747",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "128",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "4748",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "127",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "4749",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "126",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4750",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "125",
      "percentil": "55",
      "escore_T": "53"
    },
    {
      "id_Escala": "4751",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "124",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "4752",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "123",
      "percentil": "50",
      "escore_T": "52"
    },
    {
      "id_Escala": "4753",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "122",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "4754",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "121",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "4755",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "120",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "4756",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "119",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "4757",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "118",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "4758",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "117",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "4759",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "116",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "4760",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "115",
      "percentil": "35",
      "escore_T": "48"
    },
    {
      "id_Escala": "4761",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "114",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "4762",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "113",
      "percentil": "30",
      "escore_T": "47"
    },
    {
      "id_Escala": "4763",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "112",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "4764",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "111",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "4765",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "110",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "4766",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "109",
      "percentil": "25",
      "escore_T": "45"
    },
    {
      "id_Escala": "4767",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "108",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "4768",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "107",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "4769",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "106",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4770",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "105",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "4771",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "104",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "4772",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "103",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4773",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "102",
      "percentil": "15",
      "escore_T": "42"
    },
    {
      "id_Escala": "4774",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "101",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4775",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "100",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "4776",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "99",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4777",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "98",
      "percentil": "10",
      "escore_T": "40"
    },
    {
      "id_Escala": "4778",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "97",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4779",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "96",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "4780",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "95",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4781",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "94",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4782",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "93",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4783",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "92",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4784",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "91",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4785",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "90",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4786",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "89",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4787",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "88",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4788",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "87",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4789",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "86",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "4790",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "85",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4791",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "84",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4792",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "83",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "4793",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "82",
      "percentil": "4",
      "escore_T": "32"
    },
    {
      "id_Escala": "4794",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "81",
      "percentil": "4",
      "escore_T": "31"
    },
    {
      "id_Escala": "4795",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "80",
      "percentil": "3",
      "escore_T": "31"
    },
    {
      "id_Escala": "4796",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "79",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "4797",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "78",
      "percentil": "3",
      "escore_T": "30"
    },
    {
      "id_Escala": "4798",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "77",
      "percentil": "3",
      "escore_T": "29"
    },
    {
      "id_Escala": "4799",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "76",
      "percentil": "3",
      "escore_T": "29"
    },
    {
      "id_Escala": "4800",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "75",
      "percentil": "3",
      "escore_T": "28"
    },
    {
      "id_Escala": "4801",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "74",
      "percentil": "2",
      "escore_T": "28"
    },
    {
      "id_Escala": "4802",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "73",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "4803",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "72",
      "percentil": "2",
      "escore_T": "27"
    },
    {
      "id_Escala": "4804",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "71",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "4805",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "70",
      "percentil": "2",
      "escore_T": "26"
    },
    {
      "id_Escala": "4806",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "69",
      "percentil": "2",
      "escore_T": "25"
    },
    {
      "id_Escala": "4807",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "68",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4808",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "67",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4809",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "66",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4810",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "65",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4811",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "64",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4812",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "63",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4813",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "62",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4814",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "61",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4815",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "60",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4816",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "59",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4817",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "58",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4818",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "57",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4819",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "56",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4820",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "55",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4821",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "54",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4822",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "53",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4823",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "52",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4824",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "51",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4825",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "50",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4826",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "49",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4827",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "48",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4828",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "47",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4829",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "46",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4830",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "45",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4831",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "44",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4832",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "43",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4833",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "42",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4834",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "41",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4835",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "40",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4836",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "39",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4837",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "38",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4838",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "37",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4839",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "36",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4840",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "35",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4841",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "34",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4842",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "33",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4843",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "32",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4844",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "31",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4845",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "30",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4846",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "29",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4847",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "28",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4848",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "27",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4849",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "26",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4850",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "25",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4851",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "24",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4852",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "23",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4853",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "22",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4854",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "21",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4855",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "20",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4856",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "19",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4857",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "18",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4858",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4859",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4860",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4861",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4862",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4863",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4864",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4865",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4866",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4867",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4868",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4869",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4870",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4871",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4872",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4873",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4874",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4875",
      "id_Teste": "1",
      "id_Teste_Variavel": "15",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4876",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4877",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4878",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4879",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "4880",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "4881",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "4882",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "4883",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4884",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4885",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4886",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4887",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "4888",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "4889",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "4890",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "4891",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "4892",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "4893",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4894",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4895",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4896",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4897",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "4898",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "4899",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "4900",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4901",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4902",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4903",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4904",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4905",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4906",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4907",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4908",
      "id_Teste": "1",
      "id_Teste_Variavel": "23",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4909",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4910",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4911",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4912",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4913",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4914",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "4915",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "4916",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "4917",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "4918",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "4919",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "4920",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "4921",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "4922",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "4923",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "4924",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "4925",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "4926",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "4927",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "45",
      "escore_T": "47"
    },
    {
      "id_Escala": "4928",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "4929",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4930",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "4931",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "4932",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4933",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4934",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "4935",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "4936",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "4937",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "4938",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "4939",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "4940",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4941",
      "id_Teste": "1",
      "id_Teste_Variavel": "24",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "4942",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4943",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "4944",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "4945",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "74"
    },
    {
      "id_Escala": "4946",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "73"
    },
    {
      "id_Escala": "4947",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "4948",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "4949",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "4950",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "4951",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4952",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "62"
    },
    {
      "id_Escala": "4953",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "4954",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "4955",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "4956",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "4957",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "65",
      "escore_T": "52"
    },
    {
      "id_Escala": "4958",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "4959",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "4960",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "4961",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "4962",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4963",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "4964",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "4965",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "4966",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "4967",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "34"
    },
    {
      "id_Escala": "4968",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "4969",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "4970",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "4971",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "4972",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "4973",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "4974",
      "id_Teste": "1",
      "id_Teste_Variavel": "25",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "4975",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "4976",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "4977",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "4978",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "4979",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "4980",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "4981",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "4982",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "4983",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "4984",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "4985",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "4986",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "4987",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "4988",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "4989",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "55",
      "escore_T": "50"
    },
    {
      "id_Escala": "4990",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "4991",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "4992",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "4993",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "4994",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "4995",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "4996",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "4997",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "4998",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "4999",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5000",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5001",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5002",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5003",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5004",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5005",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5006",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5007",
      "id_Teste": "1",
      "id_Teste_Variavel": "26",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5008",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5009",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "5010",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5011",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5012",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "5013",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5014",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5015",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5016",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "5017",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5018",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5019",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5020",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5021",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5022",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "5023",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5024",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "5025",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5026",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5027",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "43"
    },
    {
      "id_Escala": "5028",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "5029",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5030",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5031",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "5032",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5033",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5034",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5035",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5036",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5037",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5038",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5039",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5040",
      "id_Teste": "1",
      "id_Teste_Variavel": "27",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5041",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5042",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5043",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5044",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5045",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "5046",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5047",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "74"
    },
    {
      "id_Escala": "5048",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "5049",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5050",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5051",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "5052",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5053",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5054",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5055",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5056",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5057",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "5058",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5059",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "5060",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5061",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "12",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5062",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5063",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5064",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5065",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "5066",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5067",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5068",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5069",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5070",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5071",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5072",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5073",
      "id_Teste": "1",
      "id_Teste_Variavel": "28",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5074",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5075",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5076",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "75"
    },
    {
      "id_Escala": "5077",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "90",
      "escore_T": "73"
    },
    {
      "id_Escala": "5078",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "71"
    },
    {
      "id_Escala": "5079",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "69"
    },
    {
      "id_Escala": "5080",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "75",
      "escore_T": "67"
    },
    {
      "id_Escala": "5081",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "70",
      "escore_T": "65"
    },
    {
      "id_Escala": "5082",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "60",
      "escore_T": "64"
    },
    {
      "id_Escala": "5083",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "50",
      "escore_T": "61"
    },
    {
      "id_Escala": "5084",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "40",
      "escore_T": "58"
    },
    {
      "id_Escala": "5085",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "30",
      "escore_T": "56"
    },
    {
      "id_Escala": "5086",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "20",
      "escore_T": "54"
    },
    {
      "id_Escala": "5087",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "20",
      "escore_T": "52"
    },
    {
      "id_Escala": "5088",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "15",
      "escore_T": "50"
    },
    {
      "id_Escala": "5089",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "48"
    },
    {
      "id_Escala": "5090",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "46"
    },
    {
      "id_Escala": "5091",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "44"
    },
    {
      "id_Escala": "5092",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "42"
    },
    {
      "id_Escala": "5093",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "40"
    },
    {
      "id_Escala": "5094",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "38"
    },
    {
      "id_Escala": "5095",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "36"
    },
    {
      "id_Escala": "5096",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "34"
    },
    {
      "id_Escala": "5097",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5098",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5099",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5100",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5101",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5102",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5103",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5104",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5105",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5106",
      "id_Teste": "1",
      "id_Teste_Variavel": "16",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5107",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5108",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5109",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5110",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5111",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5112",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5113",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "5114",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5115",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5116",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5117",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5118",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5119",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "5120",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "5121",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "5122",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "5123",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5124",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "5125",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "5126",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5127",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "5128",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5129",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5130",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5131",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5132",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5133",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5134",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5135",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5136",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5137",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5138",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5139",
      "id_Teste": "1",
      "id_Teste_Variavel": "17",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5140",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5141",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5142",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5143",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5144",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "5145",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5146",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5147",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "5148",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5149",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5150",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "5151",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "85",
      "escore_T": "63"
    },
    {
      "id_Escala": "5152",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5153",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5154",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5155",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "5156",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "5157",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "55",
      "escore_T": "50"
    },
    {
      "id_Escala": "5158",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "5159",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "13",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5160",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "5161",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "25",
      "escore_T": "41"
    },
    {
      "id_Escala": "5162",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5163",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "9",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5164",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "8",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5165",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "33"
    },
    {
      "id_Escala": "5166",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5167",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5168",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5169",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5170",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5171",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5172",
      "id_Teste": "1",
      "id_Teste_Variavel": "18",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5173",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5174",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5175",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "5176",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5177",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5178",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "5179",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5180",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5181",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "5182",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5183",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5184",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5185",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5186",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5187",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5188",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "5189",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5190",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "5191",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5192",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "40"
    },
    {
      "id_Escala": "5193",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5194",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "5195",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5196",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5197",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5198",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5199",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5200",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5201",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5202",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5203",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5204",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5205",
      "id_Teste": "1",
      "id_Teste_Variavel": "19",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5206",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5207",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5208",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5209",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5210",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5211",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5212",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "5213",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5214",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5215",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5216",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "5217",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "5218",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5219",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "5220",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5221",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "5222",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5223",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5224",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5225",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5226",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5227",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5228",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5229",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5230",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5231",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5232",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5233",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5234",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5235",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5236",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5237",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5238",
      "id_Teste": "1",
      "id_Teste_Variavel": "20",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5239",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5240",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "5241",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5242",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5243",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "5244",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5245",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5246",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5247",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "5248",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5249",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "5250",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "45",
      "escore_T": "50"
    },
    {
      "id_Escala": "5251",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "5252",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "5253",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "5254",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "5255",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5256",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5257",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "5258",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5259",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "5260",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5261",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5262",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5263",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5264",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5265",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5266",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5267",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5268",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5269",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5270",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5271",
      "id_Teste": "1",
      "id_Teste_Variavel": "21",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5272",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5273",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5274",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "5275",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5276",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5277",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "5278",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5279",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5280",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5281",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5282",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5283",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "5284",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5285",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5286",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5287",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "5288",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5289",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5290",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5291",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5292",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "5293",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5294",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5295",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5296",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5297",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5298",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5299",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5300",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5301",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5302",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5303",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5304",
      "id_Teste": "1",
      "id_Teste_Variavel": "30",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5305",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5306",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "5307",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5308",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5309",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "66"
    },
    {
      "id_Escala": "5310",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "5311",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5312",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5313",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "5314",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5315",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "5316",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5317",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "5318",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5319",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "5320",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "5321",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5322",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5323",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5324",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5325",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5326",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5327",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5328",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5329",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5330",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5331",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5332",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5333",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5334",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5335",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5336",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5337",
      "id_Teste": "1",
      "id_Teste_Variavel": "31",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5338",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5339",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5340",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5341",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5342",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5343",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "5344",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5345",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5346",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5347",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5348",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "5349",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5350",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "45",
      "escore_T": "48"
    },
    {
      "id_Escala": "5351",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5352",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5353",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5354",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5355",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "5356",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5357",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5358",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5359",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5360",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5361",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5362",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5363",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5364",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5365",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5366",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5367",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5368",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5369",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5370",
      "id_Teste": "1",
      "id_Teste_Variavel": "32",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5371",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5372",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5373",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5374",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5375",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5376",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5377",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5378",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5379",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "72"
    },
    {
      "id_Escala": "5380",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "23",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5381",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "22",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5382",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "5383",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "20",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5384",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "19",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5385",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "18",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5386",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5387",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "60",
      "escore_T": "51"
    },
    {
      "id_Escala": "5388",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5389",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "14",
      "percentil": "40",
      "escore_T": "46"
    },
    {
      "id_Escala": "5390",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "30",
      "escore_T": "44"
    },
    {
      "id_Escala": "5391",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5392",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "15",
      "escore_T": "38"
    },
    {
      "id_Escala": "5393",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "5394",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5395",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5396",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5397",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5398",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5399",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5400",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5401",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5402",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5403",
      "id_Teste": "1",
      "id_Teste_Variavel": "33",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5404",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5405",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5406",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "5407",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5408",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5409",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "5410",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5411",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "61"
    },
    {
      "id_Escala": "5412",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5413",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5414",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5415",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "5416",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5417",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "49"
    },
    {
      "id_Escala": "5418",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5419",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5420",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5421",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "5422",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5423",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5424",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "5425",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5426",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5427",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5428",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5429",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5430",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5431",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5432",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5433",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5434",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5435",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5436",
      "id_Teste": "1",
      "id_Teste_Variavel": "34",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5437",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5438",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5439",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5440",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "5441",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5442",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5443",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5444",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5445",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5446",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5447",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "60",
      "escore_T": "53"
    },
    {
      "id_Escala": "5448",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "5449",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5450",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "35",
      "escore_T": "45"
    },
    {
      "id_Escala": "5451",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "25",
      "escore_T": "42"
    },
    {
      "id_Escala": "5452",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5453",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5454",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5455",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5456",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5457",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5458",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5459",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5460",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5461",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5462",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5463",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5464",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5465",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5466",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5467",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5468",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5469",
      "id_Teste": "1",
      "id_Teste_Variavel": "35",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5470",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "79"
    },
    {
      "id_Escala": "5471",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5472",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5473",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5474",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "5475",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5476",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5477",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "5478",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5479",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5480",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "58"
    },
    {
      "id_Escala": "5481",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "5482",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "54"
    },
    {
      "id_Escala": "5483",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "5484",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5485",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5486",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5487",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5488",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5489",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5490",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5491",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5492",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5493",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5494",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5495",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5496",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5497",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5498",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5499",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5500",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5501",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5502",
      "id_Teste": "1",
      "id_Teste_Variavel": "1",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5503",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "78"
    },
    {
      "id_Escala": "5504",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5505",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5506",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "5507",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5508",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5509",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "65"
    },
    {
      "id_Escala": "5510",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5511",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5512",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5513",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "5514",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5515",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5516",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5517",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "5518",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5519",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "5520",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5521",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5522",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "36"
    },
    {
      "id_Escala": "5523",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5524",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "31"
    },
    {
      "id_Escala": "5525",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5526",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5527",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5528",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5529",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5530",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5531",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5532",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5533",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5534",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5535",
      "id_Teste": "1",
      "id_Teste_Variavel": "2",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5536",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5537",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "5538",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5539",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "5540",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5541",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5542",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "80",
      "escore_T": "57"
    },
    {
      "id_Escala": "5543",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "25",
      "percentil": "70",
      "escore_T": "54"
    },
    {
      "id_Escala": "5544",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "5545",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5546",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5547",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "21",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5548",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "20",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "5549",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5550",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5551",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "17",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5552",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5553",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5554",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5555",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5556",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5557",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5558",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5559",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5560",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5561",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5562",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5563",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5564",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5565",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5566",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5567",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5568",
      "id_Teste": "1",
      "id_Teste_Variavel": "3",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5569",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5570",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5571",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5572",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5573",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5574",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "71"
    },
    {
      "id_Escala": "5575",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5576",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5577",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "5578",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "61"
    },
    {
      "id_Escala": "5579",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "5580",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5581",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5582",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "5583",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "50"
    },
    {
      "id_Escala": "5584",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "5585",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5586",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "20",
      "escore_T": "43"
    },
    {
      "id_Escala": "5587",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "5588",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5589",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "5590",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5591",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "5592",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5593",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5594",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5595",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5596",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5597",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5598",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5599",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5600",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5601",
      "id_Teste": "1",
      "id_Teste_Variavel": "4",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5602",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5603",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5604",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5605",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5606",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5607",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5608",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5609",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "25",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5610",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "5611",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "90",
      "escore_T": "61"
    },
    {
      "id_Escala": "5612",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "80",
      "escore_T": "59"
    },
    {
      "id_Escala": "5613",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5614",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "5615",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5616",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "5617",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5618",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5619",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5620",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5621",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5622",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5623",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5624",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5625",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5626",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5627",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5628",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5629",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5630",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5631",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5632",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5633",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5634",
      "id_Teste": "1",
      "id_Teste_Variavel": "5",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5635",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5636",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5637",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5638",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5639",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "67"
    },
    {
      "id_Escala": "5640",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "5641",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5642",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "80",
      "escore_T": "60"
    },
    {
      "id_Escala": "5643",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "75",
      "escore_T": "57"
    },
    {
      "id_Escala": "5644",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "5645",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "5646",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "40",
      "escore_T": "50"
    },
    {
      "id_Escala": "5647",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "30",
      "escore_T": "47"
    },
    {
      "id_Escala": "5648",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "5649",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "15",
      "escore_T": "42"
    },
    {
      "id_Escala": "5650",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "5",
      "escore_T": "39"
    },
    {
      "id_Escala": "5651",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "37"
    },
    {
      "id_Escala": "5652",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "34"
    },
    {
      "id_Escala": "5653",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5654",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5655",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5656",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5657",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5658",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5659",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5660",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5661",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5662",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5663",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5664",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5665",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5666",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5667",
      "id_Teste": "1",
      "id_Teste_Variavel": "6",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5668",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5669",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5670",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5671",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5672",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5673",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5674",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5675",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "60"
    },
    {
      "id_Escala": "5676",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "5677",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "70",
      "escore_T": "55"
    },
    {
      "id_Escala": "5678",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "61",
      "escore_T": "52"
    },
    {
      "id_Escala": "5679",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5680",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "35",
      "escore_T": "46"
    },
    {
      "id_Escala": "5681",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5682",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5683",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "10",
      "escore_T": "38"
    },
    {
      "id_Escala": "5684",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5685",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5686",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5687",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5688",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5689",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5690",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5691",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5692",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5693",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5694",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5695",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5696",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5697",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5698",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5699",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5700",
      "id_Teste": "1",
      "id_Teste_Variavel": "9",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5701",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5702",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5703",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5704",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5705",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5706",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5707",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "5708",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5709",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5710",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "5711",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5712",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "55"
    },
    {
      "id_Escala": "5713",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "61",
      "escore_T": "53"
    },
    {
      "id_Escala": "5714",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5715",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5716",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "5717",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5718",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5719",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5720",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5721",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5722",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "5723",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5724",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5725",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5726",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5727",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5728",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5729",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5730",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5731",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5732",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5733",
      "id_Teste": "1",
      "id_Teste_Variavel": "10",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5734",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "5735",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "68"
    },
    {
      "id_Escala": "5736",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5737",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "5738",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "28",
      "percentil": "90",
      "escore_T": "61"
    },
    {
      "id_Escala": "5739",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "27",
      "percentil": "85",
      "escore_T": "59"
    },
    {
      "id_Escala": "5740",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5741",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "25",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5742",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "24",
      "percentil": "55",
      "escore_T": "52"
    },
    {
      "id_Escala": "5743",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "23",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5744",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "22",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "5745",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "21",
      "percentil": "25",
      "escore_T": "44"
    },
    {
      "id_Escala": "5746",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "20",
      "percentil": "20",
      "escore_T": "42"
    },
    {
      "id_Escala": "5747",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "19",
      "percentil": "15",
      "escore_T": "40"
    },
    {
      "id_Escala": "5748",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "18",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5749",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "5",
      "escore_T": "35"
    },
    {
      "id_Escala": "5750",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "16",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5751",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "15",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5752",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "14",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5753",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5754",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5755",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5756",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5757",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5758",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5759",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5760",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5761",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5762",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5763",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5764",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5765",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5766",
      "id_Teste": "1",
      "id_Teste_Variavel": "11",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5767",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "80"
    },
    {
      "id_Escala": "5768",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5769",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5770",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5771",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "99",
      "escore_T": "70"
    },
    {
      "id_Escala": "5772",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5773",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "65"
    },
    {
      "id_Escala": "5774",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "63"
    },
    {
      "id_Escala": "5775",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5776",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "5777",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "75",
      "escore_T": "56"
    },
    {
      "id_Escala": "5778",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "53"
    },
    {
      "id_Escala": "5779",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "55",
      "escore_T": "51"
    },
    {
      "id_Escala": "5780",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "40",
      "escore_T": "48"
    },
    {
      "id_Escala": "5781",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "30",
      "escore_T": "46"
    },
    {
      "id_Escala": "5782",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "17",
      "percentil": "20",
      "escore_T": "44"
    },
    {
      "id_Escala": "5783",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "16",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "5784",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "10",
      "escore_T": "39"
    },
    {
      "id_Escala": "5785",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "5786",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "13",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5787",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "12",
      "percentil": "5",
      "escore_T": "32"
    },
    {
      "id_Escala": "5788",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "11",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5789",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5790",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5791",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5792",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5793",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5794",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5795",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5796",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5797",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5798",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5799",
      "id_Teste": "1",
      "id_Teste_Variavel": "12",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5800",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "76"
    },
    {
      "id_Escala": "5801",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "74"
    },
    {
      "id_Escala": "5802",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "72"
    },
    {
      "id_Escala": "5803",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "95",
      "escore_T": "70"
    },
    {
      "id_Escala": "5804",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5805",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5806",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "64"
    },
    {
      "id_Escala": "5807",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5808",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5809",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "80",
      "escore_T": "58"
    },
    {
      "id_Escala": "5810",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "5811",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "21",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5812",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "60",
      "escore_T": "52"
    },
    {
      "id_Escala": "5813",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5814",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "40",
      "escore_T": "49"
    },
    {
      "id_Escala": "5815",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "35",
      "escore_T": "47"
    },
    {
      "id_Escala": "5816",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5817",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "15",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5818",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "15",
      "escore_T": "41"
    },
    {
      "id_Escala": "5819",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5820",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5821",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "35"
    },
    {
      "id_Escala": "5822",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "10",
      "percentil": "5",
      "escore_T": "33"
    },
    {
      "id_Escala": "5823",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "31"
    },
    {
      "id_Escala": "5824",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "29"
    },
    {
      "id_Escala": "5825",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "27"
    },
    {
      "id_Escala": "5826",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "25"
    },
    {
      "id_Escala": "5827",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "23"
    },
    {
      "id_Escala": "5828",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5829",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5830",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5831",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5832",
      "id_Teste": "1",
      "id_Teste_Variavel": "13",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5833",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "32",
      "percentil": "99",
      "escore_T": "77"
    },
    {
      "id_Escala": "5834",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "31",
      "percentil": "99",
      "escore_T": "75"
    },
    {
      "id_Escala": "5835",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "30",
      "percentil": "99",
      "escore_T": "73"
    },
    {
      "id_Escala": "5836",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "29",
      "percentil": "99",
      "escore_T": "71"
    },
    {
      "id_Escala": "5837",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "28",
      "percentil": "95",
      "escore_T": "69"
    },
    {
      "id_Escala": "5838",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "27",
      "percentil": "95",
      "escore_T": "68"
    },
    {
      "id_Escala": "5839",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "5",
      "resultado": "26",
      "percentil": "95",
      "escore_T": "66"
    },
    {
      "id_Escala": "5840",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "25",
      "percentil": "90",
      "escore_T": "64"
    },
    {
      "id_Escala": "5841",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "24",
      "percentil": "90",
      "escore_T": "62"
    },
    {
      "id_Escala": "5842",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "23",
      "percentil": "85",
      "escore_T": "60"
    },
    {
      "id_Escala": "5843",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "22",
      "percentil": "81",
      "escore_T": "58"
    },
    {
      "id_Escala": "5844",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "4",
      "resultado": "21",
      "percentil": "70",
      "escore_T": "56"
    },
    {
      "id_Escala": "5845",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "20",
      "percentil": "65",
      "escore_T": "54"
    },
    {
      "id_Escala": "5846",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "19",
      "percentil": "55",
      "escore_T": "53"
    },
    {
      "id_Escala": "5847",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "18",
      "percentil": "50",
      "escore_T": "51"
    },
    {
      "id_Escala": "5848",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "17",
      "percentil": "45",
      "escore_T": "49"
    },
    {
      "id_Escala": "5849",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "16",
      "percentil": "40",
      "escore_T": "47"
    },
    {
      "id_Escala": "5850",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "3",
      "resultado": "15",
      "percentil": "30",
      "escore_T": "45"
    },
    {
      "id_Escala": "5851",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "14",
      "percentil": "25",
      "escore_T": "43"
    },
    {
      "id_Escala": "5852",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "13",
      "percentil": "20",
      "escore_T": "41"
    },
    {
      "id_Escala": "5853",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "12",
      "percentil": "15",
      "escore_T": "39"
    },
    {
      "id_Escala": "5854",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "11",
      "percentil": "10",
      "escore_T": "37"
    },
    {
      "id_Escala": "5855",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "2",
      "resultado": "10",
      "percentil": "10",
      "escore_T": "36"
    },
    {
      "id_Escala": "5856",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "9",
      "percentil": "5",
      "escore_T": "34"
    },
    {
      "id_Escala": "5857",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "8",
      "percentil": "1",
      "escore_T": "32"
    },
    {
      "id_Escala": "5858",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "7",
      "percentil": "1",
      "escore_T": "30"
    },
    {
      "id_Escala": "5859",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "6",
      "percentil": "1",
      "escore_T": "28"
    },
    {
      "id_Escala": "5860",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "5",
      "percentil": "1",
      "escore_T": "26"
    },
    {
      "id_Escala": "5861",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "4",
      "percentil": "1",
      "escore_T": "24"
    },
    {
      "id_Escala": "5862",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "3",
      "percentil": "1",
      "escore_T": "22"
    },
    {
      "id_Escala": "5863",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "2",
      "percentil": "1",
      "escore_T": "21"
    },
    {
      "id_Escala": "5864",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "1",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5865",
      "id_Teste": "1",
      "id_Teste_Variavel": "14",
      "id_Teste_Tabela": "3",
      "id_Teste_Classificacao": "1",
      "resultado": "0",
      "percentil": "1",
      "escore_T": "20"
    },
    {
      "id_Escala": "5866",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5867",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5868",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5869",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5870",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5871",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5872",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5873",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5874",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5875",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5876",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5877",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5878",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "5879",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5880",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "5881",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "5882",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "5883",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "5884",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5885",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5886",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5887",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5888",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "5889",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "34"
    },
    {
      "id_Escala": "5890",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5891",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5892",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5893",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5894",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5895",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5896",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5897",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "5898",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "5899",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5900",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5901",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5902",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5903",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5904",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5905",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5906",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5907",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "5908",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "5909",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "5910",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "5911",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "26"
    },
    {
      "id_Escala": "5912",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "5913",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5914",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5915",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5916",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5917",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5918",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5919",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "5920",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5921",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5922",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5923",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "5924",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5925",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "31"
    },
    {
      "id_Escala": "5926",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "5927",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5928",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5929",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5930",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5931",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "5932",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "5933",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "5934",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "5935",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "34"
    },
    {
      "id_Escala": "5936",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5937",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "5938",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5939",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5940",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5941",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5942",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5943",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5944",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5945",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5946",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5947",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5948",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5949",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5950",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "5951",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "5952",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "5953",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "5954",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "5955",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "5956",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "5957",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "31"
    },
    {
      "id_Escala": "5958",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "5959",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "5960",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "5961",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "26"
    },
    {
      "id_Escala": "5962",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "5963",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "5964",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5965",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5966",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5967",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5968",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5969",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5970",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "5971",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "5972",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "36"
    },
    {
      "id_Escala": "5973",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "5974",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "5975",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "5976",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "5977",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "5978",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "5979",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "5980",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "5981",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "5982",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "5983",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "5984",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "43"
    },
    {
      "id_Escala": "5985",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "5986",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "40"
    },
    {
      "id_Escala": "5987",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "38"
    },
    {
      "id_Escala": "5988",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "5989",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "5990",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "5991",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "5992",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "5993",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "5994",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "40"
    },
    {
      "id_Escala": "5995",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "5996",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "5997",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "43"
    },
    {
      "id_Escala": "5998",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "5999",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6000",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6001",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "38"
    },
    {
      "id_Escala": "6002",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "36"
    },
    {
      "id_Escala": "6003",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "7",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6004",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6005",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6006",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6007",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6008",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6009",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6010",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6011",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6012",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6013",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6014",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6015",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6016",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6017",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6018",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6019",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6020",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6021",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "47"
    },
    {
      "id_Escala": "6022",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6023",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "47"
    },
    {
      "id_Escala": "6024",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "45"
    },
    {
      "id_Escala": "6025",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6026",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6027",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6028",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6029",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6030",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "45"
    },
    {
      "id_Escala": "6031",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6032",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6033",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6034",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6035",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6036",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6037",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "8",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6038",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6039",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6040",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6041",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6042",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "58"
    },
    {
      "id_Escala": "6043",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6044",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "64"
    },
    {
      "id_Escala": "6045",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6046",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6047",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6048",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6049",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6050",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6051",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6052",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6053",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6054",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6055",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6056",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6057",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6058",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6059",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6060",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6061",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "64"
    },
    {
      "id_Escala": "6062",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6063",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6064",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6065",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6066",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6067",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6068",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "9",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "58"
    },
    {
      "id_Escala": "6069",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6070",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6071",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6072",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6073",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6074",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6075",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6076",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6077",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6078",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6079",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6080",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6081",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6082",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6083",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "78"
    },
    {
      "id_Escala": "6084",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6085",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6086",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6087",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6088",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6089",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6090",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6091",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6092",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "74"
    },
    {
      "id_Escala": "6093",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "76"
    },
    {
      "id_Escala": "6094",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6095",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6096",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "80"
    },
    {
      "id_Escala": "6097",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "67"
    },
    {
      "id_Escala": "6098",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6099",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6100",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6101",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6102",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6103",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6104",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6105",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "76"
    },
    {
      "id_Escala": "6106",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "78"
    },
    {
      "id_Escala": "6107",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6108",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6109",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6110",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "4",
      "id_Teste_Classificacao": "10",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6111",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6112",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6113",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6114",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6115",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6116",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6117",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6118",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6119",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6120",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6121",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6122",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6123",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "6124",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6125",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6126",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "6127",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "6128",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "6129",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6130",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6131",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6132",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6133",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6134",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "34"
    },
    {
      "id_Escala": "6135",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6136",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6137",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6138",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6139",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6140",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6141",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6142",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6143",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6144",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6145",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6146",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6147",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6148",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6149",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6150",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6151",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6152",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "6153",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6154",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "6155",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6156",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "26"
    },
    {
      "id_Escala": "6157",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6158",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6159",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6160",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6161",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6162",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6163",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6164",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "6165",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6166",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6167",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6168",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6169",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6170",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "31"
    },
    {
      "id_Escala": "6171",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6172",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6173",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6174",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6175",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6176",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6177",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6178",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "6179",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "6180",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "34"
    },
    {
      "id_Escala": "6181",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6182",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "6183",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6184",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6185",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6186",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6187",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6188",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6189",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6190",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6191",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6192",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6193",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6194",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6195",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6196",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6197",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "6198",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6199",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "6200",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6201",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6202",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "31"
    },
    {
      "id_Escala": "6203",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6204",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "6205",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6206",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "26"
    },
    {
      "id_Escala": "6207",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6208",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6209",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6210",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6211",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6212",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6213",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6214",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6215",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6216",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "6217",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "36"
    },
    {
      "id_Escala": "6218",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6219",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6220",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6221",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6222",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6223",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6224",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6225",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6226",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6227",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6228",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6229",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "43"
    },
    {
      "id_Escala": "6230",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6231",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "40"
    },
    {
      "id_Escala": "6232",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "38"
    },
    {
      "id_Escala": "6233",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6234",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6235",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6236",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6237",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6238",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6239",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "40"
    },
    {
      "id_Escala": "6240",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6241",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6242",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "43"
    },
    {
      "id_Escala": "6243",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6244",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6245",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6246",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "38"
    },
    {
      "id_Escala": "6247",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "36"
    },
    {
      "id_Escala": "6248",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "7",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6249",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6250",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6251",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6252",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6253",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6254",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6255",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6256",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6257",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6258",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6259",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6260",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6261",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6262",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6263",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6264",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6265",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6266",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "47"
    },
    {
      "id_Escala": "6267",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6268",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "47"
    },
    {
      "id_Escala": "6269",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "45"
    },
    {
      "id_Escala": "6270",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6271",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6272",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6273",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6274",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6275",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "45"
    },
    {
      "id_Escala": "6276",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6277",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6278",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6279",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6280",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6281",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6282",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "8",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6283",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6284",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6285",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6286",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6287",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "58"
    },
    {
      "id_Escala": "6288",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6289",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "64"
    },
    {
      "id_Escala": "6290",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6291",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6292",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6293",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6294",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6295",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6296",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6297",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6298",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6299",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6300",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6301",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6302",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6303",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6304",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6305",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6306",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "64"
    },
    {
      "id_Escala": "6307",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6308",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6309",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6310",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6311",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6312",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6313",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "9",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "58"
    },
    {
      "id_Escala": "6314",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6315",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6316",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6317",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6318",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6319",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6320",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6321",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "74"
    },
    {
      "id_Escala": "6322",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "74"
    },
    {
      "id_Escala": "6323",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6324",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6325",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6326",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6327",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6328",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "74"
    },
    {
      "id_Escala": "6329",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6330",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6331",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6332",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6333",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6334",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6335",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6336",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6337",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6338",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "76"
    },
    {
      "id_Escala": "6339",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6340",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6341",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6342",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "67"
    },
    {
      "id_Escala": "6343",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6344",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6345",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6346",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6347",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6348",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6349",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "74"
    },
    {
      "id_Escala": "6350",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "76"
    },
    {
      "id_Escala": "6351",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6352",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6353",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6354",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6355",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "5",
      "id_Teste_Classificacao": "10",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6356",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6357",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6358",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6359",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6360",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6361",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6362",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6363",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6364",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6365",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6366",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6367",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6368",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "6369",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6370",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6371",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "6372",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "6373",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "6374",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6375",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6376",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6377",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6378",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6379",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "34"
    },
    {
      "id_Escala": "6380",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6381",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6382",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6383",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6384",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6385",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6386",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6387",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "21"
    },
    {
      "id_Escala": "6388",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6389",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6390",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6391",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6392",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6393",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6394",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6395",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6396",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6397",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "6398",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6399",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "6400",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6401",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "26"
    },
    {
      "id_Escala": "6402",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6403",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6404",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6405",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6406",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6407",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6408",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6409",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "6410",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6411",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6412",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6413",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "24"
    },
    {
      "id_Escala": "6414",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6415",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "31"
    },
    {
      "id_Escala": "6416",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6417",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6418",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6419",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6420",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6421",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6422",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6423",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "6424",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "6425",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "34"
    },
    {
      "id_Escala": "6426",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6427",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "6428",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6429",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6430",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6431",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6432",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6433",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6434",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6435",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6436",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6437",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6438",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6439",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6440",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6441",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "6442",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "27"
    },
    {
      "id_Escala": "6443",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6444",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "30"
    },
    {
      "id_Escala": "6445",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6446",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "20"
    },
    {
      "id_Escala": "6447",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "12",
      "percentil": "0",
      "escore_T": "31"
    },
    {
      "id_Escala": "6448",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "13",
      "percentil": "0",
      "escore_T": "32"
    },
    {
      "id_Escala": "6449",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "11",
      "percentil": "0",
      "escore_T": "29"
    },
    {
      "id_Escala": "6450",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "10",
      "percentil": "0",
      "escore_T": "28"
    },
    {
      "id_Escala": "6451",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "9",
      "percentil": "0",
      "escore_T": "26"
    },
    {
      "id_Escala": "6452",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "8",
      "percentil": "0",
      "escore_T": "25"
    },
    {
      "id_Escala": "6453",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "7",
      "percentil": "0",
      "escore_T": "23"
    },
    {
      "id_Escala": "6454",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "6",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6455",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "5",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6456",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "4",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6457",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "3",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6458",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "2",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6459",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "1",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6460",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "0",
      "percentil": "0",
      "escore_T": "22"
    },
    {
      "id_Escala": "6461",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "6",
      "resultado": "14",
      "percentil": "0",
      "escore_T": "33"
    },
    {
      "id_Escala": "6462",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "36"
    },
    {
      "id_Escala": "6463",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6464",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6465",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6466",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6467",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6468",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6469",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6470",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6471",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6472",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6473",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6474",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "43"
    },
    {
      "id_Escala": "6475",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6476",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "40"
    },
    {
      "id_Escala": "6477",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "38"
    },
    {
      "id_Escala": "6478",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6479",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6480",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6481",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6482",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "44"
    },
    {
      "id_Escala": "6483",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "37"
    },
    {
      "id_Escala": "6484",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "40"
    },
    {
      "id_Escala": "6485",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6486",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6487",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "21",
      "percentil": "0",
      "escore_T": "43"
    },
    {
      "id_Escala": "6488",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "20",
      "percentil": "0",
      "escore_T": "42"
    },
    {
      "id_Escala": "6489",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "19",
      "percentil": "0",
      "escore_T": "41"
    },
    {
      "id_Escala": "6490",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "18",
      "percentil": "0",
      "escore_T": "39"
    },
    {
      "id_Escala": "6491",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "17",
      "percentil": "0",
      "escore_T": "38"
    },
    {
      "id_Escala": "6492",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "16",
      "percentil": "0",
      "escore_T": "36"
    },
    {
      "id_Escala": "6493",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "7",
      "resultado": "15",
      "percentil": "0",
      "escore_T": "35"
    },
    {
      "id_Escala": "6494",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6495",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6496",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6497",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6498",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6499",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6500",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6501",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6502",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6503",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6504",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6505",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6506",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6507",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6508",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6509",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6510",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6511",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "47"
    },
    {
      "id_Escala": "6512",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6513",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "47"
    },
    {
      "id_Escala": "6514",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "45"
    },
    {
      "id_Escala": "6515",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6516",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6517",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "54"
    },
    {
      "id_Escala": "6518",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6519",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "50"
    },
    {
      "id_Escala": "6520",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "22",
      "percentil": "0",
      "escore_T": "45"
    },
    {
      "id_Escala": "6521",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "28",
      "percentil": "0",
      "escore_T": "53"
    },
    {
      "id_Escala": "6522",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "23",
      "percentil": "0",
      "escore_T": "46"
    },
    {
      "id_Escala": "6523",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "24",
      "percentil": "0",
      "escore_T": "48"
    },
    {
      "id_Escala": "6524",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "25",
      "percentil": "0",
      "escore_T": "49"
    },
    {
      "id_Escala": "6525",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "29",
      "percentil": "0",
      "escore_T": "55"
    },
    {
      "id_Escala": "6526",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "26",
      "percentil": "0",
      "escore_T": "51"
    },
    {
      "id_Escala": "6527",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "8",
      "resultado": "27",
      "percentil": "0",
      "escore_T": "52"
    },
    {
      "id_Escala": "6528",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6529",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6530",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6531",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6532",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "58"
    },
    {
      "id_Escala": "6533",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6534",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "64"
    },
    {
      "id_Escala": "6535",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6536",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6537",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6538",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6539",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6540",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6541",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6542",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6543",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6544",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6545",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6546",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "57"
    },
    {
      "id_Escala": "6547",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6548",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6549",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "60"
    },
    {
      "id_Escala": "6550",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6551",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "64"
    },
    {
      "id_Escala": "6552",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "33",
      "percentil": "0",
      "escore_T": "61"
    },
    {
      "id_Escala": "6553",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "34",
      "percentil": "0",
      "escore_T": "62"
    },
    {
      "id_Escala": "6554",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "35",
      "percentil": "0",
      "escore_T": "63"
    },
    {
      "id_Escala": "6555",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "36",
      "percentil": "0",
      "escore_T": "65"
    },
    {
      "id_Escala": "6556",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "32",
      "percentil": "0",
      "escore_T": "59"
    },
    {
      "id_Escala": "6557",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "30",
      "percentil": "0",
      "escore_T": "56"
    },
    {
      "id_Escala": "6558",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "9",
      "resultado": "31",
      "percentil": "0",
      "escore_T": "58"
    },
    {
      "id_Escala": "6559",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6560",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6561",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6562",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6563",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6564",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6565",
      "id_Teste": "2",
      "id_Teste_Variavel": "40",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6566",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6567",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6568",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6569",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6570",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6571",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6572",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6573",
      "id_Teste": "2",
      "id_Teste_Variavel": "41",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "78"
    },
    {
      "id_Escala": "6574",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6575",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6576",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "70"
    },
    {
      "id_Escala": "6577",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6578",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6579",
      "id_Teste": "2",
      "id_Teste_Variavel": "42",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6580",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "71"
    },
    {
      "id_Escala": "6581",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6582",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "74"
    },
    {
      "id_Escala": "6583",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6584",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "77"
    },
    {
      "id_Escala": "6585",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6586",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "80"
    },
    {
      "id_Escala": "6587",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "67"
    },
    {
      "id_Escala": "6588",
      "id_Teste": "2",
      "id_Teste_Variavel": "43",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6589",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "42",
      "percentil": "0",
      "escore_T": "73"
    },
    {
      "id_Escala": "6590",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "37",
      "percentil": "0",
      "escore_T": "66"
    },
    {
      "id_Escala": "6591",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "38",
      "percentil": "0",
      "escore_T": "68"
    },
    {
      "id_Escala": "6592",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "39",
      "percentil": "0",
      "escore_T": "69"
    },
    {
      "id_Escala": "6593",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "41",
      "percentil": "0",
      "escore_T": "72"
    },
    {
      "id_Escala": "6594",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "43",
      "percentil": "0",
      "escore_T": "75"
    },
    {
      "id_Escala": "6595",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "44",
      "percentil": "0",
      "escore_T": "76"
    },
    {
      "id_Escala": "6596",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "45",
      "percentil": "0",
      "escore_T": "78"
    },
    {
      "id_Escala": "6597",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "46",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6598",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "47",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6599",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "48",
      "percentil": "0",
      "escore_T": "79"
    },
    {
      "id_Escala": "6600",
      "id_Teste": "2",
      "id_Teste_Variavel": "44",
      "id_Teste_Tabela": "6",
      "id_Teste_Classificacao": "10",
      "resultado": "40",
      "percentil": "0",
      "escore_T": "71"
    }
  ]