
import Layout from "./layout";
import Title from "../components/Title";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import BackHome from "../components/EATA/BackHome";
// pages\ESAVIResult.js
export default function EATAResult() {
    let result = 0;
    if (typeof window !== "undefined"){
        result = localStorage.getItem("EATA")
    }
    let percentil = percentiles[result];
    let resultado = function () {
        if (result >= 7 && result <= 12) {
            return "Apto";
        }
        return "Inapto";
    };
    let cor = () => {
        if (result >= 7 && result <= 12) {
            return "green";
        }
        return "red";
    };
    let information = (
        <div>
            <p className="text-justify">
                O EATA (Escala para Avaliação de Tendência à Agressividade) é um instrumento de avaliação psicológica que visa identificar e mensurar a propensão de um indivíduo a apresentar comportamentos agressivos. Criado por Sisto (2012), o EATA preenche uma lacuna na área de avaliação da agressividade, especialmente em jovens e adultos.
            </p>
        </div>
    )
    const Mensagem = (result) => {
        if (result <= 7){
            return (
                <div>Niveis de agressividade abaixo do desejado</div>
            )
        }
        if (result >= 12){
            return (
                <div>Niveis de agressividade acima do desejado</div>
            )
        }
        return (
            <div>Niveis de agressividade entre os desejados</div>
        )
        
    }
    return (
        <Layout>
            <Title title="EATA" instructions={information} />
            <div className="preenche-pagina mt-5" >


                <Table padding="normal" >
                    <TableHead>
                        <TableRow>
                            <TableCell className="text-center"> Total</TableCell>
                            <TableCell className="text-center">Percentil</TableCell>
                            <TableCell className="text-center">Resultado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="text-center">{result}</TableCell>
                            <TableCell className="text-center">{percentil}</TableCell>
                            <TableCell className="text-light font-weight-bold text-center font-weight-bold" style={{ backgroundColor: cor() }}>{resultado()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            <div className="text-center m-5">
                {Mensagem(result)}


           
            </div>

            </div>
            <BackHome/>
        </Layout>
    )
}
const percentiles = {
    0: 1,
    1: 2,
    2: 4,
    3: 7,
    4: 12,
    5: 18,
    6: 24,
    7: 30,
    8: 37,
    9: 43,
    10: 49,
    11: 54,
    12: 58,
    13: 61,
    14: 65,
    15: 68,
    16: 72,
    17: 75,
    18: 78,
    19: 80,
    20: 82,
    21: 83,
    22: 85,
    23: 87,
    24: 88,
    25: 89,
    26: 90,
    27: 91,
    28: 92,
    29: 92,
    30: 93,
    31: 93,
    32: 93,
    33: 93,
    34: 93,
    35: 93,
    36: 93,
    37: 94,
    38: 94,
    39: 94,
    40: 94,
    41: 94,
    42: 94,
    43: 94,
    44: 94,
    45: 94,
    46: 94,
    47: 94,
    48: 94,
    49: 94,
    50: 94,
    51: 94,
    52: 94,
    53: 95,
    54: 95,
    55: 95,
    56: 96,
    57: 96,
    58: 96,
    59: 97,
    60: 98,
    61: 98,
    62: 99,
    63: 99,
    64: 99,
    65: 99,
    66: 99,
    67: 100,
    68: 100,
    69: 100,
    70: 100,
    71: 100,
    72: 100,
    73: 100,
    74: 100,
    75: 100,
    76: 100,
    77: 100,
    78: 100,
    79: 100,
    80: 100
}